import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { MDBBtn, MDBIcon } from "mdbreact";
import { API } from "aws-amplify";
import { selectManufacturer, updateManufacturerDetails } from "../../actions/manufacturersActions";
import { setLoaderState } from "../../actions/loaderActions";

class ManufacturerDetailsSelected extends Component {
  async componentDidMount() {
    try {
      this.props.dispatch(setLoaderState(true));
      const { manufacturerId } = this.props.match.params;
      if (this.props.id !== manufacturerId) {
        const response = await API.get("telishadeAPI", "/manufacturer/" + manufacturerId);
        this.props.dispatch(updateManufacturerDetails(response.details));
        this.props.dispatch(selectManufacturer(manufacturerId));
      }
    } catch (error) {
      // If the manufacturer didn't exist, then redirect back
      if (error.response && error.response.status === 404) {
        this.props.history.replace("/manufacturers/");
      }
    } finally {
      this.props.dispatch(setLoaderState(false));
    }
  }

  render() {
    return (
      <div className="manufacturer-details-panel">
        <div className="manufacturer-header">
          <h2>{this.props.details.name}</h2>
        </div>
        {!this.props.details.name ? (
          "No name to display"
        ) : (
          <span>
            <strong>Company Registration No.: </strong> {this.props.details.regNo}
            <br />
            <strong>Email:</strong> {this.props.details.email}
            <br />
            <strong>Phone: </strong> {this.props.details.phone}
            <br />
            <strong>Website: </strong> {this.props.details.website}
            <br />
            <strong>fax: </strong> {this.props.details.fax}
            <br />
            <strong>Mobile: </strong> {this.props.details.mobile}
            <br />
            <strong>Purchasing Account Name: </strong> {this.props.details.accountName}
            <br />
            <strong>Purchasing Account Number: </strong> {this.props.details.accountNumber}
            <br />
            <div id="editButton">
              <MDBBtn
                onClick={() =>
                  this.props.history.push(
                    `/manufacturers/${this.props.match.params.manufacturerId}/edit`
                  )
                }
                color="blue"
                id="custEditBtn"
              >
                Edit
              </MDBBtn>
              <MDBBtn
                onClick={() =>
                  this.props.history.push(
                    `/manufacturers/${this.props.match.params.manufacturerId}/delete`
                  )
                }
                color="red"
                id="custDeleteBtn"
              >
                <MDBIcon icon="trash" />
              </MDBBtn>
            </div>
          </span>
        )}
      </div>
    );
  }
}

class ManufacturerDetails extends Component {
  render() {
    return this.props.id !== null ? (
      <Fragment>
        <Switch>
          <Route path="/manufacturers/add" />
          <Route
            path="/manufacturers/:manufacturerId/branches"
            render={routeProps => <ManufacturerDetailsSelected {...this.props} {...routeProps} />}
          />
          <Route path="/manufacturers/">
            <div className="manufacturer-details-panel">
              <div className="manufacturer-header">
                <h2>Select a manufacturer</h2>
              </div>
            </div>
          </Route>
        </Switch>
      </Fragment>
    ) : (
      <Fragment>
        <div className="manufacturer-details-panel" />{" "}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state.manufacturers.manufacturer;
};

export default withRouter(connect(mapStateToProps)(ManufacturerDetails));
