import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { customerFields } from "../fields";
import { API } from "aws-amplify";
import { selectCustomer, updateCustomerDetails } from "../../../actions/customersActions";
import { setRefresh } from "../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";

class AddCustomer extends Component {
  async APIMethod(formData) {
    this.props.dispatch(setAlgoliaLoaderState(true));
    try {
      const response = await API.post("telishadeAPI", "/customer/", {
        body: { details: formData }
      });
      this.props.dispatch(setRefresh(true));
      await this.props.dispatch(selectCustomer(response.id));
      await this.props.dispatch(updateCustomerDetails(response.details));
      this.props.history.push("/customers/" + response.id + "/branches");
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  }

  render() {
    const fields = [...customerFields];

    return (
      <PopUpForm
        fields={fields}
        header="Add New Customer"
        button="ACCEPT"
        buttoncolour="green"
        button2="CANCEL"
        button2colour="red"
        buttonClickHandler={undefined}
        button2ClickHandler={undefined}
        apiMethod={details => this.APIMethod(details)}
      />
    );
  }
}

export default withRouter(connect()(AddCustomer));
