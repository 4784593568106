import React, { Component, Fragment } from "react";
import { deepCopy, AUD } from "../../../../../../utilities/utils";
import {
  getBlindFabricPrice,
  getBlindExtraPrice,
  blindExtraTypes,
  getBlindServicePrice
} from "../../../Formulas/BlindsPricing";

export default class ScheduleTotals extends Component {
  get tableHeading() {
    return (
      <Fragment>
        <tr className="heading">
          <td colSpan="4">
            <span className="heading-one">SCHEDULE TOTALS</span>
          </td>
        </tr>
        <tr className="sub-heading">
          <td colSpan="2" className="align-left">
            Component
          </td>
          <td>Total QTY</td>
          <td>Total Price</td>
        </tr>
      </Fragment>
    );
  }

  constructScheduleTotals(response) {
    const jobArray = deepCopy(response);
    let components = {};

    jobArray.forEach(job => {
      if (job.quote) {
        // iterate over each quoteItem
        job.quote.forEach(quote => {
          let totalFabricCost = getBlindFabricPrice(quote, this.props.isRetail, job.discount);
          if ("fabric" in components) {
            components["fabric"].qty++;
            components["fabric"].total += totalFabricCost;
          } else {
            components["fabric"] = { title: "Fabrics", qty: 1, total: totalFabricCost };
          }

          // Combine Extras
          if (quote.extras) {
            blindExtraTypes.forEach(extra => {
              if (extra.key in quote.extras) {
                let addedFlag = false;
                if (extra.key in components) {
                  components[extra.key].qty++;
                  components[extra.key].total += getBlindExtraPrice(
                    extra.key,
                    quote,
                    this.props.isRetail,
                    job.discount
                  );
                  addedFlag = true;
                }
                if (!addedFlag) {
                  components[extra.key] = {
                    title: extra.title,
                    qty: 1,
                    total: getBlindExtraPrice(extra.key, quote, this.props.isRetail, job.discount)
                  };
                }
              }
            });
          }

          // Combine Services
          if ("services" in quote.extras) {
            quote.extras.services.forEach(service => {
              let serviceId = "service " + service.id;

              let addedFlag = false;
              if (serviceId in components) {
                components[serviceId].qty++;
                components[serviceId].total += getBlindServicePrice(
                  service,

                  this.props.isRetail,
                  job.discount
                );
                addedFlag = true;
              }

              if (!addedFlag) {
                components[serviceId] = {
                  title: service.details?.name || "",
                  qty: 1,
                  total: getBlindServicePrice(service, this.props.isRetail, job.discount)
                };
              }
            });
          }
        });
      }
    });

    const keys = Object.keys(components);

    // Add Project Discount
    if (this.props.isRetail) {
      keys.forEach(key => {
        components[key].total =
          (parseFloat(components[key].total) / 100) * (100 - parseFloat(this.props.discount));
      });
    }

    // USE THIS TO TEST SCHEDULE TOTALS AGAINST QUOTE TOTALS:
    // let total = 0;
    // keys.forEach(key => {
    //   total += components[key].total;
    // });
    // console..log("Schedule Total is: ", total);

    return keys.map(key => {
      return components[key].total > 0 ? (
        <Fragment key={key}>
          <tr>
            <td colSpan="2">{components[key].title}</td>
            <td className="data-align-right">{components[key].qty}</td>
            <td className="data-align-right">{AUD(components[key].total)}</td>
          </tr>
        </Fragment>
      ) : (
        <Fragment key={key} />
      );
    });
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <Fragment>
        {this.tableHeading}
        {this.constructScheduleTotals(this.props.response)}
      </Fragment>
    );
  }
}
