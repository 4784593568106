import { SET_ALGOLIA_KEY, REQUEST_KEY } from "../actions/keyActions";

const initalState = {
  key: undefined,
  secondKey: undefined,
  needNewKey: false,
};

export default function keyReducer(state = initalState, action) {
  switch (action.type) {
    case SET_ALGOLIA_KEY:
      return {
        ...state,
        key: action.payload.key,
        secondKey: action.payload.secondKey,
        needNewKey: false,
      };
    case REQUEST_KEY:
      if (!state.needNewKey)
        return {
          ...state,
          needNewKey: true,
        };
      break;
    default:
      return state;
  }
}
