import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SearchableList from "../Generic/Algolia/SearchableList";
import { MDBBtn, MDBIcon } from "mdbreact";
import { selectAddress, updateAddressDetails } from "../../actions/staffActions";
import AddFAB from "../Generic/Images/AddFab.png";
import config from "../../config";

class AddressList extends Component {
  HitComponent = ({ hit }) => {
    const listItemClickHandler = () => {
      this.props.dispatch(updateAddressDetails(hit.details));
      this.props.dispatch(selectAddress(hit.objectID));
      this.props.history.push(`/staff/${hit.staffId}/addresses/${hit.objectID}`);
    };

    let className = "";
    if (this.props.id === hit.objectID) {
      className = { className: "selected" };
    }

    return (
      <Fragment>
        <p {...className} onClick={listItemClickHandler}>
          <strong>{hit.details.name}</strong>
          <br />
          {hit.details.addressType}
          <br />
          {hit.details.address.streetName ? `${hit.details.address.streetNumber} ` : ""}
          {hit.details.address.streetName ? hit.details.address.streetName : "No Street Address"}
          {hit.details.address.city ? `, ${hit.details.address.city} ` : ""}
          {hit.details.address.state} {hit.details.address.postcode}
          <br />
        </p>
        {className.className === "selected" ? (
          <Fragment>
            <MDBBtn
              onClick={() => {
                this.props.history.push(`/staff/${hit.staffId}/addresses/${hit.objectID}/edit`);
              }}
              color="blue"
              id="brEditBtn"
            >
              Edit
            </MDBBtn>
            <MDBBtn
              onClick={() => {
                this.props.history.push(`/staff/${hit.staffId}/addresses/${hit.objectID}/delete`);
              }}
              color="red"
              id="brDeleteBtn"
            >
              <MDBIcon icon="trash" />
            </MDBBtn>
          </Fragment>
        ) : (
          ""
        )}
      </Fragment>
    );
  };

  render() {
    return (
      <Fragment>
        <div className="address-list">
          <SearchableList
            appId={`${config.algolia.appId}`}
            index={`${config.algolia.prefix}staff_addresses_name_asc`}
            hitComponent={this.HitComponent}
            selectedObjectId={this.props.id}
            filters={`isDeleted:false AND staffId:${this.props.match.params.staffId}`}
          />
          <img
            className={"fabButton"}
            id="addAddressPlusBtn"
            alt="Add"
            src={AddFAB}
            onClick={() =>
              this.props.history.push(`/staff/${this.props.match.params.staffId}/addresses/add`)
            }
          />
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  return state.staff.address;
};

export default withRouter(connect(mapStateToProps)(AddressList));
