// *** Action Type Constants ***
export const SELECT_TAB = "CUSTOMERS::SELECT_TAB";
export const SELECT_CUSTOMER = "CUSTOMERS::SELECT_CUSTOMER";
export const SELECT_BRANCH = "CUSTOMERS::SELECT_BRANCH";
export const SELECT_PROJECT = "CUSTOMERS::SELECT_PROJECT";
export const SELECT_CONTACT = "CUSTOMERS::SELECT_CONTACT";
export const UPDATE_CUSTOMER_DETAILS = "CUSTOMERS::UPDATE_DETAILS";
export const UPDATE_BRANCH_DETAILS = "CUSTOMERS::UPDATE_BRANCH_DETAILS";
export const UPDATE_PROJECT_DETAILS = "CUSTOMERS::UPDATE_PROJECT_DETAILS";
export const UPDATE_CONTACT_DETAILS = "CUSTOMERS::UPDATE_CONTACT_DETAILS";
export const SET_CUSTOMERLIST_LOADING_STATE = "CUSTOMERS::SET_CUSTOMERLIST_LOADING_STATE";
export const SET_BRANCHLIST_LOADING_STATE = "CUSTOMERS::SET_BRANCHLIST_LOADING_STATE";
export const SET_PROJECTLIST_LOADING_STATE = "CUSTOMERS::SET_PROJECTLIST_LOADING_STATE";
export const SET_CONTACTLIST_LOADING_STATE = "CUSTOMERS::SET_CONTACTLIST_LOADING_STATE";

// *** Action Creators ***
export function selectCustomer(id) {
  return {
    type: SELECT_CUSTOMER,
    id
  };
}

export function selectTab(tabText) {
  return {
    type: SELECT_TAB,
    selectedTabText: tabText
  };
}

export function selectBranch(id) {
  return {
    type: SELECT_BRANCH,
    id
  };
}

export function selectProject(id) {
  return {
    type: SELECT_PROJECT,
    id
  };
}

export function selectContact(id) {
  return {
    type: SELECT_CONTACT,
    id
  };
}

export function updateBranchDetails(details) {
  return {
    type: UPDATE_BRANCH_DETAILS,
    details
  };
}

export function updateProjectDetails(details) {
  return {
    type: UPDATE_PROJECT_DETAILS,
    details
  };
}

export function updateCustomerDetails(details) {
  return {
    type: UPDATE_CUSTOMER_DETAILS,
    details
  };
}

export function updateContactDetails(details) {
  return {
    type: UPDATE_CONTACT_DETAILS,
    details
  };
}

export function setCustomerListLoadingState(bool) {
  return {
    type: SET_CUSTOMERLIST_LOADING_STATE,
    bool
  };
}

export function setBranchListLoadingState(bool) {
  return {
    type: SET_BRANCHLIST_LOADING_STATE,
    bool
  };
}

export function setProjectListLoadingState(bool) {
  return {
    type: SET_PROJECTLIST_LOADING_STATE,
    bool
  };
}

export function setContactListLoadingState(bool) {
  return {
    type: SET_CONTACTLIST_LOADING_STATE,
    bool
  };
}
