export const POPULATE_WORKSHEET = "POPULATE_WORKSHEET";
export const ADD_ROW = "ADD_ROW";
export const CHANGE_CURRENT_PRODUCT = "CHANGE_CURRENT_PRODUCT";
export const SELECT_INDEX = "SELECT_INDEX";
export const WSTOGGLE_MULTISELECT = "WSTOGGLE_MULTISELECT";
export const DUPLICATE_WORKSHEET_ITEM = "DUPLICATE_WORKSHEET_ITEM";
export const SET_WORKSHEET_PRODUCT_ID = "SET_WORKSHEET_PRODUCT_ID";
export const SET_WORKSHEET_PROJECT_DETAILS = "SET_WORKSHEET_PROJECT_DETAILS";
export const SET_FORMATTER_CACHE = "SET_FORMATTER_CACHE";
export const SET_WORKSHEET_ROW_DATA = "SET_WORKSHEET_ROW_DATA";

export function setWorksheetProjectDetails(projectDetails) {
  return {
    type: SET_WORKSHEET_PROJECT_DETAILS,
    projectDetails: projectDetails
  };
}

export function populateWorksheet(items) {
  return {
    type: POPULATE_WORKSHEET,
    worksheet: {
      items
    }
  };
}

export function addRow(rowData) {
  return {
    type: ADD_ROW,
    payload: rowData
  };
}

export function changeCurrrentProduct(product) {
  return {
    type: CHANGE_CURRENT_PRODUCT,
    currentProduct: product
  };
}

export function selectIndex(indexs) {
  return {
    type: SELECT_INDEX,
    worksheet: {
      selectedIndexs: indexs
    }
  };
}

export function toggleMultiselect(state) {
  return {
    type: WSTOGGLE_MULTISELECT,
    worksheet: {
      multiselect: state
    }
  };
}

export function duplicateWorksheetItem(value) {
  return {
    type: DUPLICATE_WORKSHEET_ITEM,
    row: value
  };
}

export function setWorksheetProductID(ID) {
  return {
    type: SET_WORKSHEET_PRODUCT_ID,
    productId: ID
  };
}

export function setFormatterCache(newCache) {
  return {
    type: SET_FORMATTER_CACHE,
    payload: newCache
  };
}

export function setWorksheetRow(rowData, rowNumber) {
  return {
    type: SET_WORKSHEET_ROW_DATA,
    payload: { rowData, rowNumber }
  };
}
