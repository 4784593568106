import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";

//MDB
import { MDBBtn } from "mdbreact";

class NewPassword extends Component {
  constructor(props) {
    super(props);

    // Using just regular React state for the login page...
    // don't see a reason to use redux store
    this.state = {
      errormsg: "One Time Code Sent to Email Address",
      email: "",
      code: "",
      newPassword: "",
      confirmPassword: "",
      failed: false
    };
  }

  handleChange = event => {
    // Handles editing of the form
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      if (this.state.newPassword === this.state.confirmPassword) {
        try {
          await Auth.forgotPasswordSubmit(
            this.state.email,
            this.state.code,
            this.state.confirmPassword
          );
          this.props.history.push("/login");
        } catch (err) {
          console.error(err.message);
          if (err.code === "NotAuthorizedException")
            this.setState({ errormsg: "Wrong Username or Password" });
          if (err.code === "LimitExceededException")
            this.setState({
              errormsg: "Password Changed Too Frequently, Try Again Later"
            });
          if (err.code === "InvalidParameterException" || err.code === "git ")
            this.setState({
              errormsg:
                "Invalid Password. \n Password must contain atleast one Uppercase, Special Character and Number"
            });
          if (err.code === "NetworkError") {
            this.setState({
              errormsg: "Error, Disconnected from Internet"
            });
          }
          if (err.code === "CodeMismatchException") {
            this.setState({ errormsg: "One Time Code is Invalid" });
          }
          this.setState({ failed: true });
        }
      } else {
        this.setState({ errormsg: "Error, Passwords Do Not Match" });
      }
    }
  };

  render() {
    return (
      <div className="loginContainer">
        <div className="filler">
          <h1>Telishade</h1>
        </div>
        <div className="Login card">
          <form onSubmit={this.handleSubmit} className="needs-validation login-form" noValidate>
            <div className="errorMessage">{this.state.errormsg}</div>
            <div className="formRow">
              <label>Email</label>
              <input
                id="email"
                placeholder="Enter Email Address"
                className="form-control"
                value={this.state.email}
                onChange={this.handleChange}
                type="email"
                autoComplete="username"
                required
              />
              <div className="invalid-tooltip">Please provide a valid email.</div>
            </div>
            <div className="formRow">
              <label>One Time Code</label>
              <input
                id="code"
                placeholder="Enter Code"
                className="form-control"
                value={this.state.code}
                autoComplete="one-time-code"
                onChange={this.handleChange}
                type="text"
                required
              />
              <div className="invalid-tooltip">Please provide a valid email.</div>
            </div>
            <div className="formRow">
              <label>New Password</label>
              <input
                id="newPassword"
                placeholder="Enter New Password"
                className="form-control"
                value={this.state.newPassword}
                autoComplete="new-password"
                onChange={this.handleChange}
                type="password"
                required
              />
              <div className="invalid-tooltip">Please provide a valid password.</div>
            </div>
            <div className="formRow">
              <label>Confirm Password</label>
              <input
                id="confirmPassword"
                placeholder="Confirm New Password"
                className="form-control"
                autoComplete="new-password"
                value={this.state.confirmPassword}
                onChange={this.handleChange}
                type="password"
                required
              />
              <div className="invalid-tooltip">Please confirm password.</div>
            </div>
            <div className="loginBtn">
              <MDBBtn color="blue" type="submit">
                Submit
              </MDBBtn>
              <a href="/login">cancel</a>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect()(NewPassword);
