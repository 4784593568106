import React, { Component } from "react";
import ReactDOM from "react-dom";

export class TextAreaEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }

  componentDidMount() {}

  getValue() {
    const variable = this.props.column.key;
    let obj = {};
    if (this.state.value !== undefined) obj[variable] = this.state.value;
    else obj[variable] = "";

    return obj;
  }

  getInputNode() {
    // If applicable, should return back the primary html input node that is used to edit the data
    // Otherwise return null
    // If value is an input node, then this node will be focussed on when the editor opens

    return ReactDOM.findDOMNode(this).getElementsByTagName("textarea")[0];
  }

  handleChange = (event) => {
    let value = event.target.value;
    if (value[value.length - 1] === "\n") value = value.slice(0, -1);
    this.setState({ value: value });
  };

  render() {
    // return UI for the editor
    return (
      <textarea
        className="gridTextArea"
        value={this.state.value}
        onBlur={this.props.onBlur}
        onChange={this.handleChange}
        autoFocus
      />
    );
  }
}

export class NumberEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
    if (this.state.value === undefined) this.state = { value: 0 };
  }

  componentDidMount() {}

  componentDidUpdate() {
    if (this.props.noEmpty) {
      if (this.state.value === "") {
        this.setState({ value: 0 });
      }
    }
  }

  getValue() {
    const variable = this.props.column.key;
    let obj = {};
    if (this.state.value !== undefined) obj[variable] = this.state.value;
    else obj[variable] = "";

    return obj;
  }

  getInputNode() {
    // If applicable, should return back the primary html input node that is used to edit the data
    // Otherwise return null
    // If value is an input node, then this node will be focussed on when the editor opens

    return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
  }

  handleChange = (event) => {
    if (event.target.value !== "") {
      this.setState({ value: parseFloat(event.target.value) });
    } else this.setState({ value: event.target.value });
  };

  //Temporary Fix to Double Input
  handleFocus = () => {
    this.setState({ value: "" });
  };

  render() {
    // return UI for the editor
    return (
      <input
        className="WorksheetNumberInput"
        type="number"
        value={this.state.value}
        onBlur={this.props.onBlur}
        onFocus={this.handleFocus}
        onChange={this.handleChange}
        autoFocus
      />
    );
  }
}

export class CustomDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
    if (
      this.state.value === undefined ||
      this.state.value === "" ||
      this.state.value === null ||
      this.setState.value === true
    )
      this.state.value = this.props.options[0].value;
  }
  getStyle() {
    return {
      width: "100%",
      height: "100%",
    };
  }

  getValue() {
    const updated = {};
    updated[this.props.column.key] = this.state.value;
    return updated;
  }

  getInputNode() {
    return ReactDOM.findDOMNode(this).getElementsByTagName("select")[0];
  }

  // onClick() {
  //   if (this.state.value === undefined)
  //     this.setState({ value: this.props.options[0].value });
  // }

  // onDoubleClick() {
  //   this.getInputNode().focus();
  // }
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  render() {
    return (
      <select
        className="workSheetSelectField"
        style={this.getStyle()}
        value={this.state.value}
        onBlur={this.props.onBlur}
        onChange={this.handleChange}
      >
        <option value="" style={{ fontStyle: "italic" }}>
          Clear
        </option>
        {this.renderOptions()}
      </select>
    );
  }

  renderOptions() {
    let options = [];
    this.props.options.forEach(function (name) {
      if (typeof name === "string") {
        options.push(
          <option key={name} value={name}>
            {name}
          </option>
        );
      } else {
        options.push(
          <option key={name.value} value={name.value} title={name.title}>
            {name.text || name.value}
          </option>
        );
      }
    }, this);
    return options;
  }
}
