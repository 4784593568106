import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { staffFields } from "../fields";
import { API } from "aws-amplify";
import { setRefresh } from "../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";

class AddStaff extends Component {
  APIMethod = async formData => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));
      const response = await API.post("telishadeAPI", "/staff/", {
        body: { details: formData }
      });
      this.props.history.push(`/staff/${response.id}/addresses`);
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setAlgoliaLoaderState(false));
    } catch (e) {
      throw e;
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    const fields = [...staffFields];

    return (
      <PopUpForm
        fields={fields}
        header="Add New Staff"
        button="ACCEPT"
        buttoncolour="green"
        button2="CANCEL"
        button2colour="red"
        buttonClickHandler={undefined}
        button2ClickHandler={undefined}
        apiMethod={details => this.APIMethod(details)}
      />
    );
  }
}

export default withRouter(connect()(AddStaff));
