import React, { Component, Fragment } from "react";
import { numberWithCommas } from "../../../../../../utilities/utils";

class CurtainsTotals extends Component {
  constructTotals(quotesArray) {
    let totalCost = 0;
    let totalCostNoDiscount = 0;
    quotesArray.forEach(jobQuote => {
      jobQuote.quote.forEach(quoteItem => {
        totalCost += this.calculateCurtainItemTotal(
          quoteItem,
          this.props.discount,
          jobQuote.discount
        );
        totalCostNoDiscount += this.calculateCurtainItemTotal(quoteItem);
      });
    });
    const NoDiscountTotalGst = totalCostNoDiscount + (totalCostNoDiscount / 100) * 10;
    const discountTotal = totalCost; //- (totalCost / 100) * this.props.discount;
    const gstTotal = discountTotal + (discountTotal / 100) * 10;
    return (
      <Fragment>
        <tr className="details">
          <td className="padding-cell" />
          <td>Total Costs of Items Ex.GST</td>
          {this.props.showDiscounts ? (
            <td>{`$${numberWithCommas(Math.ceil(totalCostNoDiscount.toFixed(2)))}`}</td>
          ) : (
            <Fragment />
          )}
          {this.props.showDiscounts ? (
            <td>{`-$${numberWithCommas(
              Math.ceil(Math.ceil(totalCostNoDiscount) - Math.ceil(totalCost).toFixed(2))
            )}`}</td>
          ) : (
            <Fragment />
          )}
          <td>{`$${numberWithCommas(Math.ceil(totalCost.toFixed(2)))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>GST</td>
          {this.props.showDiscounts ? (
            <td className="border-on-top">{`$${numberWithCommas(
              Math.ceil((totalCostNoDiscount / 100) * 10).toFixed(2)
            )}`}</td>
          ) : (
            <Fragment />
          )}
          {this.props.showDiscounts ? <td className="border-on-top" /> : <Fragment />}
          <td className="border-on-top">{`$${numberWithCommas(
            Math.ceil((discountTotal / 100) * 10).toFixed(2)
          )}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Total incl. GST</td>
          {this.props.showDiscounts ? (
            <td className="border-on-top">{`$${numberWithCommas(
              Math.ceil(NoDiscountTotalGst.toFixed(2)).toFixed(2)
            )}`}</td>
          ) : (
            <Fragment />
          )}
          {this.props.showDiscounts ? <td className="border-on-top" /> : <Fragment />}
          <td className="border-on-top">{`$${numberWithCommas(
            Math.ceil(gstTotal.toFixed(2)).toFixed(2)
          )}`}</td>
        </tr>
      </Fragment>
    );
  }

  constructMargins(quotesArray) {
    let WholesaleTotal = 0;
    let RetailTotal = 0;
    quotesArray.forEach(jobQuote => {
      jobQuote.quote.forEach(quoteItem => {
        WholesaleTotal += this.calculateCurtainItemTotal(quoteItem, 0, jobQuote.discount, false);
        RetailTotal += this.calculateCurtainItemTotal(
          quoteItem,
          this.props.discount,
          jobQuote.discount,
          true
        );
      });
    });
    const GrossProfit = RetailTotal - WholesaleTotal;
    const ProfitMargin = (GrossProfit / RetailTotal) * 100;
    return (
      <Fragment>
        <tr className="details">
          <td className="padding-cell" />
          <td>Wholesale Total</td>
          <td>{`$${numberWithCommas(WholesaleTotal.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Retail Total</td>
          <td>{`$${numberWithCommas(RetailTotal.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Gross Profit</td>
          <td>{`$${numberWithCommas(GrossProfit.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Profit Margin</td>
          <td>{`${ProfitMargin.toFixed(2)}%`}</td>
        </tr>
      </Fragment>
    );
  }

  calculateCurtainItemTotal(
    quoteItem,
    discount = 0,
    discountObject,
    isRetail = this.props.isRetail
  ) {
    if (isNaN(discount)) {
      return this.calculateCurtainItemTotal(quoteItem, 0, discountObject, isRetail);
    }
    let totalforItem = 0;
    totalforItem += isRetail ? quoteItem.costInfo.markedUpBasePrice : quoteItem.costInfo.basePrice;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach(componentDiscount => {
        if (componentDiscount.id === quoteItem.description.allDetails.fabricId) {
          totalforItem =
            (totalforItem / 100) *
            (100 -
              (isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }
    totalforItem += this.calculateExtra("bumph", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("driveMechanism", quoteItem, discountObject, isRetail)
      .total;
    totalforItem += this.calculateExtra("leadWeight", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("lining", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("pelmet", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("accessory", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateService(quoteItem, discountObject, isRetail).total;
    totalforItem += isRetail
      ? this.getTrackDiscountValue(
          quoteItem.costInfo.markedUpTrackTotalCost,
          discountObject,
          quoteItem,
          isRetail
        )
      : this.getTrackDiscountValue(
          quoteItem.costInfo.trackTotalCost,
          discountObject,
          quoteItem,
          isRetail
        );
    totalforItem = isRetail ? (totalforItem / 100) * (100 - discount) : totalforItem;
    return totalforItem;
  }

  getTrackDiscountValue(trackCost, discountObject, quoteItem, isRetail) {
    let totalforTrack = trackCost;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach(componentDiscount => {
        if (componentDiscount.id === quoteItem.description.allDetails.trackId) {
          totalforTrack =
            (totalforTrack / 100) *
            (100 -
              (isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }
    return totalforTrack;
  }

  calculateExtra(extraKey, item, discountObject, isRetail = this.props.isRetail) {
    let totalPrice = 0;
    let totalQty = 0;
    if (item.extras[extraKey]) {
      if (item.extras[extraKey].details.pricePerMetre) {
        const totalPricePerMetre =
          parseFloat(item.extras[extraKey].details.pricePerMetre) *
          (parseFloat(item.description.width) / 1000);
        const totalPricePerMetreMarkup =
          (totalPricePerMetre / 100) * (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += isRetail ? totalPricePerMetreMarkup : totalPricePerMetre;
        totalQty++;
      } else if (item.extras[extraKey].details.cost) {
        const totalCost =
          (parseFloat(item.extras[extraKey].details.cost) / 100) *
          (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += isRetail ? totalCost : parseFloat(item.extras[extraKey].details.cost);
        totalQty++;
      }
      if (discountObject && discountObject.components.length > 0) {
        discountObject.components.forEach(componentDiscount => {
          if (item.extras[extraKey].id === componentDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (isRetail
                  ? componentDiscount.retail.percentage
                  : componentDiscount.wholesale.percentage));
          }
        });
      }
    }
    return { total: totalPrice, qty: totalQty };
  }

  calculateService(item, discountObject, isRetail = this.props.isRetail) {
    let totalPrice = 0;
    let totalQty = 0;
    item.extras.services.forEach(service => {
      const totalWithMarkup =
        (parseFloat(service.details.cost) / 100) * (100 + parseFloat(service.details.markup));
      totalPrice += isRetail ? totalWithMarkup : parseFloat(service.details.cost);
      totalQty++;
      if (discountObject && discountObject.servicesAndFees.length > 0) {
        discountObject.servicesAndFees.forEach(serviceDiscount => {
          if (service.id === serviceDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (isRetail
                  ? serviceDiscount.retail.percentage
                  : serviceDiscount.wholesale.percentage));
          }
        });
      }
    });
    return { total: totalPrice, qty: totalQty };
  }

  render() {
    return (
      <div className="doc-box">
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="heading">
              <td className="padding-cell"></td>
              <td colSpan="4">
                <span className="heading-one">PROJECT TOTALS</span>
              </td>
            </tr>
            {this.constructTotals(this.props.response)}
            <tr className="comments">
              <td colSpan="3">Please refer to each individual job Quote for additional detail.</td>
            </tr>
          </tbody>
        </table>
        <table className="Margins" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="comments">
              <td colSpan="3">
                Please Note this Information will not be Shown on the Printed Quote.
              </td>
            </tr>
            <tr className="heading">
              <td className="padding-cell"></td>
              <td colSpan="4">
                <span className="heading-one">Margins</span>
              </td>
            </tr>
            {this.constructMargins(this.props.response)}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CurtainsTotals;
