import React, { Component, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import TabBar from "./TabBar";

import AddressList from "./AddressList";

class StaffTabs extends Component {
  tabNames = ["jobs", "addresses", "documents", "messages"];

  render() {
    return (
      <Fragment>
        <Switch>
          {/* JOBS TAB */}
          <Route
            path="/staff/:staffId/jobs"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} selected="jobs" />
                <div className="empty-component">
                  <h3>This Component Under Construction</h3>
                </div>
              </div>
            )}
          />
          {/* ADDRESSES TAB */}
          <Route
            path="/staff/:staffId/addresses/:addressId"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} selected="addresses" />
                <div className="address-tab">
                  <AddressList {...this.props} {...routeProps} />
                </div>
              </div>
            )}
          />
          <Route
            path="/staff/:staffId/addresses"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} selected="addresses" />
                <div className="address-tab">
                  <AddressList {...this.props} {...routeProps} />
                </div>
              </div>
            )}
          />
          {/* DOCUMENTS TAB */}
          <Route
            path="/staff/:staffId/documents"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} selected="documents" />
                <div className="empty-component">
                  <h3>This Component Under Construction</h3>
                </div>
              </div>
            )}
          />
          {/* MESSAGES TAB */}
          <Route
            path="/staff/:staffId/messages"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} selected="messages" />
                <div className="empty-component">
                  <h3>This Component Under Construction</h3>
                </div>
              </div>
            )}
          />

          {/* DEFAULT REDIRECT TAB */}
          <Route
            path="/staff/:staffId/"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} />
                {/* <JobTab {...this.props} {...routeProps} /> */}
              </div>
            )}
          />
        </Switch>
      </Fragment>
    );
  }
}

export default withRouter(StaffTabs);
