export default [
  {
    text: "Dashboard",
    URL: "/",
    HTMLID: "sideNavItem"
  },
  {
    text: "Customers",
    URL: "/customers",
    HTMLID: "sideNavItem"
  },
  {
    text: "Projects",
    URL: "/projects",
    HTMLID: "sideNavItem"
  },
  {
    text: "Staff",
    URL: "/staff",
    HTMLID: "sideNavItem"
  },
  {
    text: "Products",
    URL: "/products/",
    HTMLID: "sideNavItem",
    subMenu: {
      isMenuOpen: false,
      HTMLID: "subSideNavItem",
      links: [
        {
          text: "Blinds",
          HTMLID: "SideNavItem",
          URL: "/products/blinds/"
        },
        {
          text: "Curtains",
          HTMLID: "SideNavItem",
          URL: "/products/curtains"
        }
      ]
    }
  },
  {
    text: "Manufacturers",
    URL: "/manufacturers",
    HTMLID: "sideNavItem"
  },
  {
    text: "My Account",
    URL: "/account",
    HTMLID: "sideNavItem"
  },
  {
    text: "Log Out",
    URL: "/logout",
    HTMLID: "sideNavItem"
  }
];
