import React, { Component } from "react";
import PopUpForm from "../../../Generic/PopUpDialog/PopUpForm";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import * as VALIDATIONTYPES from "../../../Generic/validationTypes";
import { setRefresh } from "../../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../../actions/loaderActions";

class CreateCurtainServicePopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationTypes: VALIDATIONTYPES.blindService
    };
  }
  handleCancelClick = () => {
    this.props.history.goBack();
  };

  APIMethod = async formData => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));
      const body = {
        details: {
          name: formData.name,
          cost: formData.cost,
          markup: formData.markup,
          serviceFeeType: formData.type
        }
      };
      await API.post("telishadeAPI", "/product/curtain/service-and-fee", {
        body
      });
      this.props.history.push(`/products/curtains/service-and-fee`);
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setAlgoliaLoaderState(false));
    } catch (e) {
      this.props.dispatch(setAlgoliaLoaderState(false));
      throw e;
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.state.validationTypes}
        apiMethod={this.APIMethod}
        header="Add New Curtain Service / Fee"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

export default connect()(CreateCurtainServicePopUp);
