import React, { Component } from "react";
import { connect } from "react-redux";
import "./Reorder.css";
import { populateWorksheet } from "../../../actions/worksheetActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: "13px",
  height: "50px",
  margin: `0 0 ${grid}px 0`,
  position: "relative",

  // change background colour if dragging
  background: isDragging ? "rgba(0, 187, 255, 0.578)" : "white",
  boxShadow: isDragging ? "0px 0px 15px rgba(0, 0, 0, 0.16)" : "",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "white" : "white",
  padding: grid,
  width: "100%"
});

class ReorderWorksheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      localItems: this.props.worksheet.items,
      dragOverKey: undefined,
      draggingKey: 1
    };
  }

  componentDidUpdate() {
    if (this.props.worksheet.items && !this.state.localItems) {
      this.setState({ localItems: this.props.worksheet.items });
    }
  }

  handleItemNoClick = key => {
    this.setState({ editingKey: key });
  };

  componentWillUnmount() {
    this.props.dispatch(populateWorksheet(this.state.localItems));
  }

  MoveItems(moveFrom, moveTo) {
    let newItemList = JSON.parse(JSON.stringify(this.state.localItems));
    newItemList.splice(moveTo, 0, newItemList.splice(moveFrom, 1)[0]);
    this.ReNumberItems(newItemList);
    this.setState({ localItems: newItemList, selectedIndex: undefined });
  }

  ReNumberItems(itemArray) {
    itemArray.forEach((item, key) => {
      item.itemNo = key + 1;
    });
  }

  onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    this.MoveItems(result.source.index, result.destination.index);
  };

  render() {
    if (!this.state.localItems) {
      return "Loading....";
    } else {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="worksheetReorder">
            {(provided, snapshot) => (
              <div
                className="reOrder"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.localItems.map((item, key) => {
                  return (
                    <Draggable draggableId={key.toString()} key={key + 1} index={key}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          className="reorderWorksheetItem"
                        >
                          {`ItemNo: ${item.itemNo} Location: ${item.location}, Width: ${item.width}, Drop: ${item.drop}`}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
  }
}

const mapStateToProps = state => {
  return state.worksheet;
};

export default connect(mapStateToProps)(ReorderWorksheet);
