import React, { Component } from "react";
import PopUpForm from "../../../Generic/PopUpDialog/PopUpForm";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { setRefresh } from "../../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../../actions/loaderActions";
import config from "../../../../config";

class CreateCurtainFabricPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationTypes: [
        {
          id: "name",
          label: "Fabric Name",
          type: "text",
          tooltip: "Please Enter Curtain Fabric Name",
          required: true,
          value: ""
        },
        {
          id: "photo",
          label: "Photo URL",
          type: "text",
          tooltip: "Please Enter Photo URL",
          required: false,
          value: ""
        },
        {
          id: "rollWidth",
          label: "Roll Width",
          type: "number",
          tooltip: "Please Enter Roll Width",
          value: 0
        },
        {
          id: "hPatternRepeat",
          label: "Horizontal Pattern Repeat",
          type: "number",
          tooltip: "Please Enter Horizontal Pattern Repeat",
          value: 0
        },
        {
          id: "vPatternRepeat",
          label: "Vertical Pattern Repeat",
          type: "number",
          tooltip: "Please Enter Vertical Pattern Repeat",
          value: 0
        },
        {
          id: "technicalInfo",
          label: "Technical Information",
          type: "text",
          tooltip: "Please Enter Technical Information",
          value: ""
        },
        {
          id: "manufacturerId",
          label: "Manufacturer ID",
          type: "AlgoliaSelect",
          index: `${config.algolia.prefix}manufacturers_name_asc`,
          filters: "isDeleted:false"
        },
        {
          id: "priceLists",
          label: "Associated Price Lists",
          type: "multiSelect",
          index: `${config.algolia.prefix}products_price_lists_name_asc`,
          filter: "",
          value: []
        },
        {
          id: "fabricSurcharges",
          label: "Associated Fabric Surcharges",
          type: "multiSelect",
          index: `${config.algolia.prefix}products_fabric_surcharges_name_asc`,
          filter: "",
          value: []
        },
        {
          id: "associatedServices",
          label: "Associated Services",
          type: "multiSelect",
          index: `${config.algolia.prefix}products_services_fees_name_asc`,
          value: []
        }
      ]
    };
  }

  handleCancelClick = () => {
    this.props.history.goBack();
  };

  APIMethod = async formData => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));
      const body = {
        details: {
          name: formData.name,
          photo: formData.photo,
          priceLists: formData.priceLists,
          fabricSurcharges: formData.fabricSurcharges,
          manufacturerId: formData.manufacturerId,
          rollWidth: formData.rollWidth,
          vPatternRepeat: formData.vPatternRepeat,
          hPatternRepeat: formData.hPatternRepeat,
          technicalInfo: formData.technicalInfo,
          associatedServices: formData.associatedServices
        }
      };
      await API.post("telishadeAPI", "/product/curtain/component/fabric", {
        body
      });
      this.props.history.push(`/products/curtains/fabric`);
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setAlgoliaLoaderState(false));
    } catch (e) {
      this.props.dispatch(setAlgoliaLoaderState(false));
      throw e;
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.state.validationTypes}
        apiMethod={this.APIMethod}
        header="Add New Curtain Fabric"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

export default connect()(CreateCurtainFabricPopUp);
