// HOW TO USE ME:
// 1. Pass in a Javascript object as my 'fields' prop, I will then
// convert it to an Array and map it to input fields in a pop-up form.
// 2. Pass in a ValidationTypes Object, which customises field requirements and tool tips

import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { MDBBtn } from "mdbreact"; // Button import

//Date Picker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./PopUpForm.css";
import { setLoaderState } from "../../../actions/loaderActions";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import SelectAutoComplete from "../Algolia/SelectAutoComplete";
import MultiSelectList from "../Algolia/MultiSelectList";

class PopUpForm extends Component {
  constructor(props) {
    super(props);

    // Create array of objects for field current values
    let values = {};
    const fields = [...this.props.fields];
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      if (field.value === undefined) {
        values[field.id] = "";
      } else {
        values[field.id] = field.value;
      }
    }
    this.state = {
      formFields: values,
      failed: false,
      error: ""
    };
    this._isMounted = false;
  }

  handleSelectAutoComplete = (id, value) => {
    if (this._isMounted) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          [id]: value.objectID || "",
          fabricColourListId:
            value.colourListId !== undefined
              ? value.colourListId
              : this.state.formFields.fabricColourListId
        }
      });
    }
  };

  handleCancelClick = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    if (this._isMounted) {
      this.setState({ formFields: [] });
    }
    this._isMounted = false;
  }

  handleMultiChange = (event, hit, id) => {
    let Lists = [...this.state.formFields[id], hit.objectID];
    if (this._isMounted) {
      this.setState({ formFields: { ...this.state.formFields, [id]: Lists } });
    }
  };

  handleMultiRemove = (event, hit, id) => {
    let temp = [...this.state.formFields[id]];
    let value = temp.indexOf(hit.objectID);
    if (value !== -1) {
      temp.splice(value, 1);
      if (this._isMounted) {
        this.setState({ formFields: { ...this.state.formFields, [id]: temp } });
      }
    }
  };

  handleChange = (event, customOnChange) => {
    // Handles editing of the form
    if (customOnChange) {
      customOnChange(event);
    }
    if (this._isMounted) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          [event.target.id]: event.target.value
        }
      });
    }
  };

  handleSubmit = async event => {
    event.preventDefault();
    event.target.className += " was-validated"; // Form Validation

    if (event.target.value === undefined) {
      if (this._isMounted) {
        this.setState({ [event.target.id]: "" });
      }
    }

    if (event.target.checkValidity()) {
      if (this.props.apiMethod !== undefined) {
        try {
          const formData = this.state.formFields;
          const response = await this.props.apiMethod(formData);
          if (this.props.updateMethod !== undefined) {
            this.props.updateMethod(response);
          }
        } catch (e) {
          if (this._isMounted) {
            this.setState({ failed: true, error: e });
          }
        } finally {
          this.props.dispatch(setLoaderState(false));
        }
      }
    }
  };

  handleDateChange = (date, id) => {
    if (this._isMounted) {
      this.setState({ formFields: { ...this.state.formFields, [id]: date } });
    }
  };

  render() {
    return (
      <div className="popupFormContainer">
        {this.state.failed ? (
          <ErrorHandler
            error={this.state.error}
            buttonClickHandler={
              this._isMounted
                ? () => {
                    this.setState({ failed: false });
                  }
                : null
            }
            button2ClickHandler={
              this._isMounted
                ? async () => {
                    this.setState({ failed: false });
                    if (this.props.apiMethod !== undefined) {
                      try {
                        const formData = this.state.formFields;
                        const response = await this.props.apiMethod(formData);
                        if (this.props.updateMethod !== undefined) {
                          this.props.updateMethod(response);
                        }
                      } catch (e) {
                        console.error(e);
                        if (this._isMounted) {
                          this.setState({ failed: true, error: e });
                        }
                      }
                    }
                  }
                : null
            }
          />
        ) : (
          ""
        )}
        <div className="popupFormWindow">
          {/* POPUP HEADER */}
          <div className="popupFormHeader">
            {this.props.header === undefined ? "" : this.props.header}
          </div>

          {/* BODY TEXT */}

          {this.props.body === undefined ? (
            <Fragment />
          ) : (
            <div className="popupFormTextContainer">this.props.body</div>
          )}

          {/* EDIT FIELDS */}
          <Fragment>
            <form onSubmit={this.handleSubmit} noValidate className="popupForm">
              {this.props.fields.map((field, key) => {
                if (field.type === "select") {
                  return (
                    <div className="popupFormInputField" key={key}>
                      <div className="detailFields">
                        <label className="datefieldLabel">{field.label}</label>
                        <select
                          required={field.required}
                          id={field.id}
                          value={this.state.formFields[field.id]} // Maps to Value (Auto-Fill)
                          onChange={event => this.handleChange(event, field.onChange)}
                          size="sm"
                          className="browser-default custom-select form-control"
                        >
                          {field.options.map((option, i) => {
                            return (
                              <option key={i} value={option.value}>
                                {option.name}
                              </option>
                            );
                          })}
                        </select>
                        <div className="invalid-tooltip">{field.tooltip}</div>
                      </div>
                    </div>
                  );
                } else if (field.type === "date") {
                  return (
                    <div className="popupFormInputField" key={key}>
                      <div className="detailFields datefield">
                        <label className="datefieldLabel">{field.label}</label>
                        <DatePicker
                          autoComplete="off"
                          id={field.id}
                          className="form-control projectInput"
                          selected={this.state.formFields[field.id]}
                          onChange={date => this.handleDateChange(date, field.id)}
                          dateFormat="MMMM d, yyyy"
                        />
                        <div className="invalid-tooltip">{field.tooltip}</div>
                      </div>
                    </div>
                  );
                } else if (field.type === "multiSelect") {
                  return (
                    <div className="popupFormMultiField" key={key}>
                      <div className="detailFields">
                        <label className="datefieldLabel">{field.label}</label>
                        <MultiSelectList
                          listItemClickHandler={this.handleMultiChange}
                          removeClickHandler={this.handleMultiRemove}
                          id={field.id}
                          selectedValues={this.state.formFields[field.id]}
                          index={field.index}
                          filter={field.filter === undefined ? "" : field.filter}
                          productsMulti={
                            field.productsMulti === undefined ? true : field.productsMulti
                          }
                        />
                        <div className="invalid-tooltip">{field.tooltip}</div>
                      </div>
                    </div>
                  );
                } else if (field.type === "AlgoliaSelect") {
                  return (
                    <div className="popupFormInputField" key={key}>
                      <div className="detailFields">
                        <label className="datefieldLabel">{field.label}</label>
                        <SelectAutoComplete
                          index={field.index}
                          filters={field.filters}
                          handleSelectAutoComplete={(id, value) =>
                            this.handleSelectAutoComplete(id, value)
                          }
                          id={field.id}
                          dependency={this.state.formFields[field.dependency]}
                          dependencyId={field.dependencyId}
                          value={field.value}
                        />
                      </div>
                    </div>
                  );
                } else if (field.type === "TextArea") {
                  return (
                    <div className="popupFormInputField" key={key}>
                      <div className="detailFields">
                        <label className="datefieldLabel">{field.label}</label>
                        <textarea
                          autoComplete="off"
                          required={field.required}
                          id={field.id}
                          value={this.state.formFields[field.id]} // Maps to Value (Auto-Fill)
                          onChange={this.handleChange}
                          size="sm"
                          className="projectInput form-control"
                          type={field.type}
                          pattern={field.pattern}
                        />
                        <div className="invalid-tooltip">{field.tooltip}</div>
                      </div>
                    </div>
                  );
                } else {
                  // Else Type must be text....
                  return (
                    <Fragment key={key}>
                      {field.subHeading ? (
                        <div className="popup-subheading">
                          <h5>{field.subHeading}</h5>
                        </div>
                      ) : (
                        <Fragment />
                      )}
                      <div className="popupFormInputField" key={key}>
                        <div className="detailFields">
                          <label className="datefieldLabel">{field.label}</label>
                          <input
                            autoComplete="off"
                            required={field.required}
                            id={field.id}
                            value={this.state.formFields[field.id] || ""} // Maps to Value (Auto-Fill)
                            onChange={this.handleChange}
                            step="any" // allow for floats
                            size="sm"
                            className="projectInput form-control"
                            type={field.type}
                            pattern={field.pattern}
                          />
                          <div className="invalid-tooltip">{field.tooltip}</div>
                        </div>
                      </div>
                    </Fragment>
                  );
                }
              })}
              {/* SUBMIT AND CANCEL BUTTONS */}
              <div className="popupFormButtonContainer">
                {this.props.button === undefined ? (
                  ""
                ) : (
                  // SUBMIT BUTTON
                  <MDBBtn
                    type="submit"
                    color={this.props.buttoncolour === undefined ? "blue" : this.props.buttoncolour}
                    id="PopUpButton"
                  >
                    {this.props.button}
                  </MDBBtn>
                )}

                {this.props.button2 === undefined ? (
                  ""
                ) : (
                  // CANCEL BUTTON
                  <MDBBtn
                    onClick={
                      this.props.button2ClickHandler === undefined
                        ? this.handleCancelClick
                        : this.props.button2ClickHandler
                    }
                    color={
                      this.props.button2colour === undefined ? "blue" : this.props.button2colour
                    }
                    id="PopUpButton2"
                  >
                    {this.props.button2}
                  </MDBBtn>
                )}
              </div>
            </form>
          </Fragment>
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(PopUpForm));
