import React, { Component } from "react";
import { connect } from "react-redux";
import { Auth, API } from "aws-amplify";
import { login, logout } from "../actions/authenticationActions";
import { setLoaderState } from "../actions/loaderActions";
//MDB
import { MDBBtn } from "mdbreact";
import ErrorHandler from "./ErrorHandler/ErrorHandler";
import { setAlgoliaKey } from "../actions/keyActions";

class Login extends Component {
  constructor(props) {
    super(props);

    // Using just regular React state for the login page...
    // don't see a reason to use redux store
    this.state = {
      errormsg: "",
      email: "",
      password: "",
      failed: false,
      error: undefined,
    };
  }

  componentDidMount() {
    this.props.dispatch(setLoaderState(false));
  }

  getAlgoliaKey = async () => {
    try {
      clearTimeout(this.state.algoliaKeyTimeOut);
      const { key, productKey } = await API.get("telishadeAPI", "/algolia/key");
      this.props.dispatch(setAlgoliaKey(key, productKey));
      const AlgoliaTimeOut = setTimeout(() => {
        this.getAlgoliaKey();
      }, 300000);
      this.setState({ algoliaKeyTimeOut: AlgoliaTimeOut });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        this.getAlgoliaKey();
      }, 1000);
    }
  };

  handleChange = (event) => {
    // Handles editing of the form
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      this.props.dispatch(setLoaderState(true));
      try {
        const { successRedirect } = this.props.authentication;
        await Auth.signIn(this.state.email, this.state.password);
        this.getAlgoliaKey();
        await this.props.dispatch(login());
        this.props.history.push(successRedirect);
        this.props.dispatch(setLoaderState(false));
      } catch (err) {
        // console.error(err.message);
        if (err.code === "NotAuthorizedException") this.setState({ errormsg: "Wrong Username or Password" });
        if (err.code === "NetworkError") {
          this.setState({
            errormsg: "Error, Disconnected from Internet",
          });
        }
        console.error(err);
        this.setState({ error: err });
        if (this.state.error.code !== undefined) this.setState({ failed: true });
        this.props.dispatch(setLoaderState(false));
        await this.props.dispatch(logout());
      }
    }
  };

  render() {
    // TODO: Change form elements to use what has been already used,
    // or change other forms to use react-bootstrap like this
    return (
      <div className="loginContainer">
        <div className="filler">
          <h1>Telishade</h1>
        </div>
        <div className="Login card">
          {this.state.failed ? (
            <ErrorHandler
              error={this.state.error}
              buttonClickHandler={() => {
                this.setState({ failed: false });
              }}
              //This is the Retry Click Handler for Retrying the Form
              button2ClickHandler={async () => {
                this.setState({ failed: false });
                this.props.dispatch(setLoaderState(true));
                try {
                  const { successRedirect } = this.props.authentication;
                  await Auth.signIn(this.state.email, this.state.password);
                  await this.props.dispatch(login());
                  this.props.history.push(successRedirect);
                  this.props.dispatch(setLoaderState(false));
                } catch (err) {
                  this.props.dispatch(setLoaderState(false));
                  this.setState({ failed: true });
                }
              }}
            />
          ) : (
            ""
          )}
          <form id="LoginForm" onSubmit={this.handleSubmit} className="needs-validation login-form" noValidate>
            <div className={this.state.failed ? "loginError" : "loginErrorNone"} />
            <div className="formRow">
              <label>Email</label>
              <input
                id="email"
                placeholder="Enter Email Address"
                className="form-control"
                value={this.state.email}
                onChange={this.handleChange}
                autoComplete="username"
                type="email"
                required
              />
              <div className="invalid-tooltip">Please provide a valid email.</div>
            </div>
            <div className="formRow">
              <label>Password</label>
              <input
                id="password"
                placeholder="Enter Password"
                className="form-control"
                value={this.state.password}
                onChange={this.handleChange}
                autoComplete="password"
                type="password"
                required
              />
              <div className="invalid-tooltip">Please Enter Password.</div>
              <a href="/forgot-password" className="forgotPWLink">
                Forgot Password
              </a>
            </div>

            <div className="loginBtn">
              <MDBBtn color="blue" type="submit">
                Login
              </MDBBtn>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  authentication: state.authentication,
});
export default connect(mapStateToProps)(Login);
