import React from "react";
import { NavLink } from "react-router-dom";
const CurtainComponentList = () => {
  return (
    <ul>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/style">
          Style
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/fabric">
          Fabric
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/track">
          Track
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/tape">
          Tape
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/runner">
          Runner
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/drive-mechanism">
          Drive Mechanisms
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/pricing-group">
          Pricing Groups
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/bumph">
          Bumph
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/lining">
          Lining
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/pelmet">
          Pelmet
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/lead-weight">
          Lead Weight
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/accessory">
          Accessories
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/fabric-surcharge">
          Fabric Surcharge
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/service-and-fee">
          Services and Fees
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/curtains/colour-list">
          Colour Lists
        </NavLink>
      </li>
    </ul>
  );
};

export default CurtainComponentList;
