import React, { Component, Fragment } from "react";
import Spreadsheet from "./Spreadsheet";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MDBBtn } from "mdbreact";
import { API } from "aws-amplify";
import { setLoaderState } from "../../../actions/loaderActions";
import PopUp from "../../Generic/PopUpDialog/PopUp";
import { setRefresh } from "../../../actions/algoliaActions";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class BlindPriceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      delete: false,
      edit: false,
      name: "",
      pgMarkup: undefined,
      x: undefined,
      y: undefined,
      data: undefined,
    };
    this.getBlindPriceList(this.props.match.params.id);
  }

  async getBlindPriceList(ID) {
    try {
      this.props.dispatch(setLoaderState(true));
      const response = await API.get("telishadeAPI", "/product/blind/price-list/" + ID);
      this.setState({
        name: response.details.name,
        x: response.details.x,
        y: response.details.y,
        pgMarkup: response.details.markup,
        data: response.details.data,
      });
      this.props.dispatch(setLoaderState(false));
      return response;
    } catch (err) {
      console.error(err);
      this.props.dispatch(setLoaderState(false));
    }
  }

  handleEditClick = () => {
    this.setState({ edit: !this.state.edit });
  };

  floatData = (data) => {
    let floatedData = JSON.parse(JSON.stringify(data));
    let increment = 0;
    for (let i = 0; i < 50; i++) {
      if (floatedData[i]) {
        if (floatedData[i].title) {
          delete floatedData[i].title;
        }
        if (Object.keys(floatedData[i]).length === 0) {
          delete floatedData[i];
          increment++;
        }
      }
    }
    floatedData.length = floatedData.length - increment;
    floatedData.forEach((row) => {
      Object.keys(row).forEach((column) => {
        if (typeof row[column] === "string") row[column] = parseFloat(row[column].replace(/,/g, ""));
      });
    });
    return floatedData;
  };

  handleDoneClick = async () => {
    const body = {
      details: {
        name: this.state.name,
        markup: this.state.pgMarkup,
        x: this.state.x,
        y: this.state.y,
        data: this.floatData(this.state.data),
      },
    };
    try {
      this.props.dispatch(setLoaderState(true));
      await API.put("telishadeAPI", "/product/blind/price-list/" + this.props.match.params.id, {
        body,
      });
      this.props.dispatch(setLoaderState(false));
    } catch (err) {
      console.error(err);
      this.setState({ failed: true, error: err });
      this.props.dispatch(setLoaderState(false));
    }
    this.setState({ edit: false });
  };

  handleOnChange = (event) => {
    if (event.target.id === "x.start")
      this.setState({
        x: {
          start: parseInt(event.target.value, 10),
          increment: this.state.x.increment,
        },
      });
    if (event.target.id === "y.start")
      this.setState({
        y: {
          start: parseInt(event.target.value, 10),
          increment: this.state.x.increment,
        },
      });
    if (event.target.id === "x.increment")
      this.setState({
        x: {
          start: this.state.x.start,
          increment: parseInt(event.target.value, 10),
        },
      });
    if (event.target.id === "y.increment")
      this.setState({
        y: {
          start: this.state.x.start,
          increment: parseInt(event.target.value, 10),
        },
      });
    else this.setState({ [event.target.id]: event.target.value });
  };

  handleUpdateDataSheet = async (Data) => {
    let increment = 0;
    for (let i = 0; i < 50; i++) {
      delete Data[i].title;
      if (Object.keys(Data[i]).length === 0) {
        delete Data[i];
        increment++;
      }
    }
    Data.length = Data.length - increment;
    Data.forEach((row) => {
      Object.keys(row).forEach((column) => {
        if (typeof row[column] === "string") row[column] = parseFloat(row[column].replace(/,/g, ""));
      });
    });
    const body = {
      details: {
        name: this.state.name,
        markup: this.state.pgMarkup,
        x: this.state.x,
        y: this.state.y,
        data: Data,
      },
    };
    try {
      this.props.dispatch(setLoaderState(true));
      await API.put("telishadeAPI", "/product/blind/price-list/" + this.props.match.params.id, {
        body,
      });
      this.props.dispatch(setLoaderState(false));
    } catch (err) {
      console.error(err);
      this.props.dispatch(setLoaderState(false));
    }
  };

  handleDeleteClick = () => {
    this.setState({ delete: true });
  };

  handleDeletePriceList = async () => {
    const id = this.props.match.params.id;
    try {
      this.props.dispatch(setLoaderState(true));
      await API.del("telishadeAPI", "/product/blind/price-list/" + id);
      this.props.history.push("/products/blinds/pricing-groups");
      this.props.dispatch(setLoaderState(false));
      this.props.dispatch(setRefresh(true));
    } catch (err) {
      this.setState({ failed: true, error: err });
      this.props.dispatch(setLoaderState(false));
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
      this.setState({ delete: false });
    }
  };

  render() {
    return (
      <div className="pricelistContainer">
        {this.state.delete ? (
          <PopUp
            header="Delete Price List?"
            body={"Are you sure you want to delete " + this.state.name + "?"}
            button="Delete"
            buttoncolour="red"
            buttonClickHandler={this.handleDeletePriceList}
            button2="Cancel"
            button2ClickHandler={() => {
              this.setState({ delete: false });
            }}
          />
        ) : (
          ""
        )}
        {this.state.failed ? <ErrorHandler error={this.state.error} /> : ""}
        <div className="pricelist">
          <div className="pricelistHeader">
            <div>
              {this.state.edit ? (
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  value={this.state.name}
                  onChange={this.handleOnChange}
                />
              ) : (
                this.state.name
              )}
            </div>
            <div>
              {this.state.edit ? (
                <Fragment>
                  Markup:
                  <input id="pgMarkup" className="form-control" value={this.state.pgMarkup} onChange={this.handleOnChange} />
                  %
                </Fragment>
              ) : (
                <Fragment>Markup: {this.state.pgMarkup}%</Fragment>
              )}
            </div>
            {this.state.edit ? (
              <MDBBtn color="green" id="editBtn" className="btn" onClick={this.handleDoneClick}>
                Done
              </MDBBtn>
            ) : (
              <Fragment>
                <MDBBtn color="grey" id="plEditBtn" className="btn" onClick={this.handleEditClick}>
                  Edit
                </MDBBtn>
                <MDBBtn color="red" id="plDeleteBtn" className="btn" onClick={this.handleDeleteClick}>
                  Delete
                </MDBBtn>
                <MDBBtn color="green" id="plSaveBtn" className="btn" onClick={this.handleDoneClick}>
                  Save
                </MDBBtn>
              </Fragment>
            )}
          </div>
          {this.state.edit ? (
            <div className="priceListEdit">
              <div className="detailFields">
                <label className="datefieldLabel">X Axis Start Value</label>
                <input
                  id="x.start"
                  value={this.state.x.start}
                  onChange={this.handleOnChange}
                  size="sm"
                  className="projectInput form-control"
                  type="number"
                />
              </div>
              <div className="detailFields">
                <label className="datefieldLabel">X Axis Increment Value</label>
                <input
                  id="x.increment"
                  value={this.state.x.increment}
                  onChange={this.handleOnChange}
                  size="sm"
                  className="projectInput form-control"
                  type="number"
                />
              </div>
              <div className="detailFields">
                <label className="datefieldLabel">Y Axis Start Value</label>
                <input
                  id="y.start"
                  value={this.state.y.start}
                  onChange={this.handleOnChange}
                  size="sm"
                  className="projectInput form-control"
                  type="number"
                />
              </div>
              <div className="detailFields">
                <label className="datefieldLabel">Y Axis Increment Value</label>
                <input
                  id="y.increment"
                  value={this.state.y.increment}
                  onChange={this.handleOnChange}
                  size="sm"
                  className="projectInput form-control"
                  type="number"
                />
              </div>
            </div>
          ) : this.state.x === undefined || this.state.y === undefined ? (
            ""
          ) : (
            <Spreadsheet
              data={this.state.data}
              x={this.state.x}
              y={this.state.y}
              updateMethod={this.handleUpdateDataSheet}
              onDataUpdate={(data) => this.setState({ data: data })}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.products;
};

export default withRouter(connect(mapStateToProps)(BlindPriceList));
