import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LetterHead from "../../TableComponents/LetterHead";
import Heading from "../../TableComponents/Heading";
import SubHeading from "../../TableComponents/SubHeading";
import JobHeader from "../JobHeader";

class MarginReport extends Component {
  totalsColumns = [
    "Description",
    "QTY",
    "Wholesale Cost",
    "Discount",
    "Discount %",
    "Wholesale inc Discount",
    "Markup %",
    "Retail Cost",
    "Discount",
    "Discount %",
    "Retail Cost inc Discount",
    "Gross Profit",
    "Margin"
  ];

  constructor(props) {
    super(props);

    this.state = {
      items: [
        {
          description: "Test Screen - Euro Ammo",
          qty: 2,
          wholesaleCost: 1467.0,
          wholesaleDiscount: -118.4,
          wholesaleDiscountPercent: 8.07,
          wholesaleIncDisc: 1348.6,
          wholesaleMarkupPercent: 32.24,
          retailCost: 1940.0,
          retailDiscount: -192.5,
          retailDiscountPercent: 9.92,
          retailIncDisc: 1747.5,
          grossProfit: 398.9,
          margin: 22.83
        },
        {
          description:
            "Drive Mechanism - EASYDRIVE cluth with Spring Assist 1.03 (Control Side not specified)",
          qty: 3,
          wholesaleCost: 1467.0,
          wholesaleDiscount: -118.4,
          wholesaleDiscountPercent: 8.07,
          wholesaleIncDisc: 1348.6,
          wholesaleMarkupPercent: 32.24,
          retailCost: 1940.0,
          retailDiscount: -192.5,
          retailDiscountPercent: 9.92,
          retailIncDisc: 1747.5,
          grossProfit: 398.9,
          margin: 22.83
        }
      ],

      totalsGross: {
        wholesaleCost: 1467.0,
        wholesaleDiscount: -118.4,
        wholesaleDiscountPercent: 8.07,
        wholesaleIncDisc: 1348.6,
        wholesaleMarkupPercent: 32.24,
        retailCost: 1940.0,
        retailDiscount: -192.5,
        retailDiscountPercent: 9.92,
        retailIncDisc: 1747.5,
        grossProfit: 398.9,
        margin: 22.83
      },

      totalsWithDiscount: {
        wholesaleCost: 1467.0,
        wholesaleDiscount: -118.4,
        wholesaleDiscountPercent: 8.07,
        wholesaleIncDisc: 1348.6,
        wholesaleMarkupPercent: 32.24,
        retailCost: 1940.0,
        retailDiscount: -192.5,
        retailDiscountPercent: 9.92,
        retailIncDisc: 1747.5,
        grossProfit: 398.9,
        margin: 22.83
      }
    };
  }

  formatCurrency = number => {
    if (Math.sign(number)) {
      // If pos, returns 1, if neg returns -1
      return `$${number.toFixed(2)}`;
    } else {
      return `-$${(number * -1).toFixed(2)}`;
    }
  };

  formatPercentage = number => {
    return `${number.toFixed(2)}%`;
  };

  render() {
    const totalsGross = this.state.totalsGross;

    return (
      <div id="Document">
        <LetterHead />
        <JobHeader type="Margin Report" />
        <div className="doc-box">
          <table className="margin-report" cellPadding="0" cellSpacing="0">
            <tbody>
              <Heading
                title="G R O S S &nbsp; P R O F I T &nbsp; M A R G I N &nbsp; R E P O R T"
                colSpan="13"
              />
              <SubHeading columnNames={this.totalsColumns} />
              {this.state.items.map((lineItem, key) => {
                return (
                  <tr className="details" key={key}>
                    <td>{lineItem.description}</td>
                    <td>{lineItem.qty}</td>
                    <td>{this.formatCurrency(lineItem.wholesaleCost)}</td>
                    <td>{this.formatCurrency(lineItem.wholesaleDiscount)}</td>
                    <td>{this.formatPercentage(lineItem.wholesaleDiscountPercent)}</td>
                    <td>{this.formatCurrency(lineItem.wholesaleIncDisc)}</td>
                    <td>{this.formatPercentage(lineItem.wholesaleMarkupPercent)}</td>
                    <td>{this.formatCurrency(lineItem.retailCost)}</td>
                    <td>{this.formatCurrency(lineItem.retailDiscount)}</td>
                    <td>{this.formatPercentage(lineItem.retailDiscountPercent)}</td>
                    <td>{this.formatCurrency(lineItem.retailIncDisc)}</td>
                    <td>{this.formatCurrency(lineItem.grossProfit)}</td>
                    <td>
                      <strong>{this.formatPercentage(lineItem.margin)}</strong>
                    </td>
                  </tr>
                );
              })}
              <tr className="margin-report-totals">
                <td />
                <td>
                  <strong>
                    Total <br />
                    Cost
                  </strong>
                </td>
                <td>{this.formatCurrency(totalsGross.wholesaleCost)}</td>
                <td>{this.formatCurrency(totalsGross.wholesaleDiscount)}</td>
                <td>{this.formatPercentage(totalsGross.wholesaleDiscountPercent)}</td>
                <td>{this.formatCurrency(totalsGross.wholesaleIncDisc)}</td>
                <td>{this.formatPercentage(totalsGross.wholesaleMarkupPercent)}</td>
                <td>{this.formatCurrency(totalsGross.retailCost)}</td>
                <td>{this.formatCurrency(totalsGross.retailDiscount)}</td>
                <td>{this.formatPercentage(totalsGross.retailDiscountPercent)}</td>
                <td>{this.formatCurrency(totalsGross.retailIncDisc)}</td>
                <td>{this.formatCurrency(totalsGross.grossProfit)}</td>
                <td>
                  <strong>{this.formatPercentage(totalsGross.margin)}</strong>
                </td>
              </tr>
              <tr className="comments">
                <td colSpan="12">
                  Please refer to each individual job Quote for additional detail.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withRouter(MarginReport);
