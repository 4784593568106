import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class TabBar extends Component {
  tabClickHandler = tabText => {
    this.props.history.push(
      `/manufacturers/${this.props.match.params.manufacturerId}/` + tabText
    );
  };

  render() {
    return (
      <div className="tab-bar">
        {this.props.tabNames.map((tabText, index) => {
          return (
            <li
              key={index}
              className={this.props.selected === tabText ? "selected" : ""}
              onClick={() => this.tabClickHandler(tabText)}
            >
              {tabText}
            </li>
          );
        })}
      </div>
    );
  }
}

export default withRouter(TabBar);
