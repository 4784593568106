import React, { Component } from "react";

class SubHeading extends Component {
  render() {
    return (
      <thead>
        <tr className="sub-heading">
          {this.props.columnNames.map((columnName, key) => {
            return <td key={key}>{columnName}</td>;
          })}
        </tr>
      </thead>
    );
  }
}

export default SubHeading;
