import React, { Component, Fragment } from "react";

class BlindsExtras extends Component {
  constructScheduleofItems(jobArray) {
    const newJobArray = JSON.parse(JSON.stringify(jobArray));
    return newJobArray.map((job) => {
      return job.quote.map((quote, index) => (
        <Fragment key={index}>
          <tr className="details jobNumber">
            <td>{quote.itemNo}</td>
            <td>{quote.description.allDetails.productCode}</td>
            <td>{quote.description.location}</td>
            <td>{this.constructItemDescription(quote)}</td>
            <td>{this.calculateBlindItemTotal(quote)}</td>
          </tr>
          {this.props.detailed
            ? this.constructDetailedCostBreakdown(quote, index + "detailed")
            : null}
        </Fragment>
      ));
    });
  }

  constructDetailedCostBreakdown(quoteItem, key) {
    const blindBreakdownDefinition = [
      { value: "Base Bar", key: "baseBar" },
      { value: "Drive Mechanism", key: "driveMechanism" },
      { value: "Double Bracket", key: "doubleBracket" },
      { value: "Kassette", key: "kassette" },
      { value: "Side Channel", key: "sideChannel" },
      { value: "Accessory", key: "accessory" },
      { value: "Link System", key: "linking" },
    ];
    return (
      <Fragment key={key}>
        <tr className="details">
          <td></td>
          <td></td>
          <td></td>
          <td>Fabric</td>
          <td>
            {this.props.isRetail
              ? (quoteItem.baseCost / 100) * parseInt(quoteItem.baseMarkup) + quoteItem.baseCost
              : quoteItem.baseCost}
          </td>
        </tr>
        {blindBreakdownDefinition.map((component, index) => {
          if (quoteItem.extras[component.key]) {
            return (
              <tr className="details" key={index}>
                <td></td>
                <td></td>
                <td></td>
                <td>{component.value}</td>
                <td>
                  {this.calculateExtra(component.key, quoteItem).total === 0
                    ? "Included"
                    : this.calculateExtra(component.key, quoteItem).total}
                </td>
              </tr>
            );
          } else return null;
        })}
        {quoteItem.extras.services.map((service, index) => (
          <tr className="details" key={index}>
            <td></td>
            <td></td>
            <td></td>
            <td>{service.details.name}</td>
            <td>
              {this.props.isRetail
                ? service.details.cost + (service.details.cost / 100) * service.details.markup
                : service.details.cost}
            </td>
          </tr>
        ))}
      </Fragment>
    );
  }

  convertObjectforRender(responseObject) {
    let formattedArray = [];
    formattedArray.push({
      name: this.props.jobDetails.details.name,
      jobNo: this.props.jobDetails.details.number,
      id: responseObject.id,
      quote: responseObject.quote,
      discount:
        this.props.jobDetails.details.options.discount === ""
          ? 0
          : parseFloat(this.props.jobDetails.details.options.discount),
    });
    return formattedArray;
  }

  constructItemDescription(item) {
    const { fabric, driveMechanism, rollerStyle, width, drop } = item.description;
    return `${fabric} / ${rollerStyle} / ${driveMechanism} / Width: ${width}mm, Drop: ${drop}mm`;
  }

  calculateBlindItemTotal(quoteItem, discount = 0) {
    let totalforItem = 0;
    totalforItem += this.props.isRetail
      ? (quoteItem.baseCost / 100) * parseInt(quoteItem.baseMarkup) + quoteItem.baseCost
      : quoteItem.baseCost;
    totalforItem = (totalforItem / 100) * (100 - discount);
    totalforItem += this.calculateExtra("baseBar", quoteItem).total;
    totalforItem += this.calculateExtra("driveMechanism", quoteItem).total;
    totalforItem += this.calculateExtra("kassette", quoteItem).total;
    totalforItem += this.calculateExtra("linking", quoteItem).total;
    totalforItem += this.calculateExtra("sideChannel", quoteItem).total;
    totalforItem += this.calculateExtra("doubleBracket", quoteItem).total;
    totalforItem += this.calculateExtra("accessory", quoteItem).total;
    totalforItem += this.calculateService(quoteItem).total;
    return totalforItem.toFixed(2);
  }

  calculateExtra(extraKey, item) {
    let totalPrice = 0;
    let totalQty = 0;
    if (item.extras[extraKey]) {
      if (item.extras[extraKey].details.costPerMetre) {
        const totalcostPerMetre =
          parseFloat(item.extras[extraKey].details.costPerMetre) *
          (parseFloat(item.description.width) / 1000);
        const totalcostPerMetreMarkup =
          (totalcostPerMetre / 100) * (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail ? totalcostPerMetreMarkup : totalcostPerMetre;
        totalQty++;
      } else if (item.extras[extraKey].details.cost) {
        const totalCost =
          (parseFloat(item.extras[extraKey].details.cost) / 100) *
          (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail
          ? totalCost
          : parseFloat(item.extras[extraKey].details.cost);
        totalQty++;
      }
    }
    return { total: totalPrice, qty: totalQty };
  }

  calculateService(item) {
    let totalPrice = 0;
    let totalQty = 0;
    item.extras.services.forEach((service) => {
      const totalWithMarkup =
        (parseFloat(service.details.cost) / 100) * (100 + parseFloat(service.details.markup));
      totalPrice += this.props.isRetail ? totalWithMarkup : parseFloat(service.details.cost);
      totalQty++;
    });
    return { total: totalPrice, qty: totalQty };
  }

  render() {
    return (
      <div
        className="doc-box page-break-before"
        id="ScheduleofItems"
        style={{ display: this.props.display ? "" : "None" }}
      >
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="heading">
              <td colSpan="5">
                <span className="heading-one">JOB QUOTE - SCHEDULE OF ITEMS</span>
              </td>
            </tr>
            <tr className="sub-heading">
              <td>Item Number</td>
              <td>Product Code</td>
              <td>Location</td>
              <td>Description</td>
              <td>Unit Price</td>
            </tr>
            {this.constructScheduleofItems(this.convertObjectforRender(this.props.response))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default BlindsExtras;
