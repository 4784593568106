import React, { Component } from "react";
class DocumentTab extends Component {
  render() {
    return (
      <div>
        <div className="empty-tab">
          <h2>No Documents to display for Customer</h2>
        </div>
      </div>
    );
  }
}
export default DocumentTab;
