import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import LetterHead from "../../../TableComponents/LetterHead";
import ProjectHeader from "../../ProjectHeader";
import CurtainsSummary from "./CurtainsSummary";
import CurtainsBillOfRates from "./CurtainsBillOfRates";
import CurtainsExtras from "./CurtainsExtras";
import CurtainsTotals from "./CurtainsTotals";
import Terms from "../../../TableComponents/Terms";
import { connect } from "react-redux";
import config from "../../../../../../config";
import algoliasearch from "algoliasearch";
import { API } from "aws-amplify";
import Modal from "../../../../PopUps/Modal";
import {
  emptyCustomer,
  emptyJobBranch,
  emptyBranchContact
} from "../../../../../Customers/CustomerModels";
import QuoteNotes from "./QuoteNotes";
import DeterminateLoader from "../../../../../Loader/DeterminateLoader";
import { storeCurtainJobQuotePayload } from "../../../../../../actions/documentsActions";
import DiscountView from "../DiscountView/DiscountView";

class QuoteCurtains extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: undefined,
      customerDetails: {
        customer: emptyCustomer,
        jobBranch: emptyJobBranch,
        branchContact: emptyBranchContact
      },
      errorEncountered: undefined,
      errorMessage: "",
      readyToRender: false,
      completedRequests: 0,
      maxRequests: undefined
    };
    this._isMounted = false;
    this.initialiseState();
  }

  componentDidUpdate(prevProps) {
    //This Reloads the quote when the discount view switches
    if (
      this.props.documents.discountView !== prevProps.documents.discountView &&
      this.props.documents.discountView === false
    ) {
      this.setState({
        quote: undefined,
        customerDetails: {
          customer: emptyCustomer,
          jobBranch: emptyJobBranch,
          branchContact: emptyBranchContact
        },
        errorEncountered: undefined,
        errorMessage: "",
        readyToRender: false,
        completedRequests: 0,
        maxRequests: undefined
      });
      this.getProjectQuote();
    }
  }

  async initialiseState() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.initialiseState();
      }, 500);
    } else {
      await this.getProjectQuote();
    }
  }

  async getProjectQuote() {
    let quoteResponseCollection = [];
    let quoteCollection = [];
    let customerDetails = "";

    const jobs = await this.getJobsForProject();
    this.setState({ maxRequests: jobs.length });
    //Get Quote for Every Job in Project
    jobs.forEach(async (job, index) => {
      let quote = undefined;
      try {
        quote = this.getQuoteForJob(job.objectID);
      } catch (error) {
        this.handleAPIError(error);
        console.error(error);
      }
      quoteResponseCollection.push(quote);
    });

    customerDetails = await this.getCustomerInformation(this.props.projectDetails.details);

    try {
      quoteResponseCollection = await Promise.all(
        quoteResponseCollection.map((promise, i) =>
          promise.catch(error => {
            error.jobNo = i + 1;
            throw error;
          })
        )
      );
    } catch (error) {
      this.handleAPIError(error);
      // console.error(error);
    }

    //Filter Out Blind only Jobs, Format for Display
    if (!this.state.errorEncountered) {
      if (quoteResponseCollection.length > 0) {
        quoteResponseCollection.forEach((response, key) => {
          if (response.quote.length > 0) {
            quoteCollection.push({
              name: jobs[key].details.name,
              jobNo: jobs[key].details.number,
              id: response.id,
              quote: response.quote,
              discount: response.discounts
            });
          }
        });
      } else {
        this.handleZeroItems();
      }
    }

    const combinedQuoteCollection = this.convertQuoteForDiscount(quoteCollection);
    this.props.dispatch(storeCurtainJobQuotePayload(combinedQuoteCollection));
    if (this._isMounted) {
      this.setState({ quote: quoteCollection, customerDetails, readyToRender: true });
    }
  }

  convertQuoteForDiscount(quoteCollection) {
    if (quoteCollection.length === 0) return { quote: [] };
    let combinedquote = JSON.parse(JSON.stringify(quoteCollection[0]));
    for (let i = 1; i < quoteCollection.length; i++) {
      combinedquote.quote.push(...quoteCollection[i].quote);
    }
    return combinedquote;
  }

  handleAPIError(e) {
    let errMsg = "";
    if (e.response && e.response.data && e.response.data.Message) {
      errMsg = e.response.data.Message;
    }

    if (e.message && e.message.includes("timeout")) {
      errMsg = "Response not received from server.  Please try again later.";
    } else if (e && e.jobNo) {
      errMsg =
        e.response.data.Message === "Math Error" ? "Error with Job No." + e.jobNo + " pricing" : "";
    }

    this.updateErrorState(errMsg);
  }

  handleZeroItems() {
    this.updateErrorState("no items");
  }

  handleNoResponseFromAPI() {}

  updateErrorState(errorMessage) {
    if (this._isMounted) {
      this.setState({
        errorEncountered: true,
        errorMessage: errorMessage
      });
    }
  }

  async getCustomerInformation(details) {
    let customer = undefined;
    let branch = undefined;
    let contact = undefined;

    try {
      const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
      const customerIndex = client.initIndex(`${config.algolia.prefix}customers_name_asc`);
      const branchIndex = client.initIndex(`${config.algolia.prefix}customers_branches_name_asc`);
      const contactIndex = client.initIndex(
        `${config.algolia.prefix}customers_branches_contacts_name_asc`
      );

      if (details?.customerId) {
        const customer = await customerIndex.getObject(details.customerId);
        const branch = await branchIndex.getObject(details.customerBranchId);
        const contact = await contactIndex.getObject(details.customerBranchContactId);
        return { jobBranch: branch, branchContact: contact, customer };
      } else {
        customer = emptyCustomer;
        branch = emptyJobBranch;
        contact = emptyBranchContact;
      }

      return { jobBranch: branch, branchContact: contact, customer };
    } catch (error) {
      return {
        jobBranch: emptyJobBranch,
        branchContact: emptyBranchContact,
        customer: emptyCustomer
      };
    }
  }

  async getJobsForProject() {
    try {
      const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
      const index = client.initIndex(`${config.algolia.prefix}projects_jobs_number_asc`);
      const response = await index.search({
        query: "",
        filters: "isDeleted:false AND projectId:" + this.props.match.params.id,
        hitsPerPage: 1000
      });
      return response.hits;
    } catch (error) {
      if (this._isMounted) {
        this.setState({
          errorEncountered: true,
          errorMessage: "Error with curtains quote, " + error.message
        });
      }
    }
  }

  async getQuoteForJob(JobId) {
    try {
      const response = await API.get(
        "telishadeAPI",
        `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${JobId}/quote/curtain`
      );
      this.setState({ completedRequests: this.state.completedRequests + 1 });
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this.state.quote || !this.state.readyToRender) {
      return (
        <Fragment>
          <DeterminateLoader
            maxValue={this.state.maxRequests}
            currentValue={this.state.completedRequests}
          />
          <div className="loader">LOADING...</div>
        </Fragment>
      );
    } else if (this.state.errorEncountered) {
      if (this.state.errorMessage === "no items") {
        return (
          <div className="no-items">
            <h1>No Curtain items</h1>
          </div>
        );
      } else
        return (
          <Modal
            header="Unable to generate Quote."
            displayMessage={this.state.errorMessage}
            goBackClickHandler={() => {
              this.props.history.push(`/projects/${this.props.match.params.id}/`);
            }}
          />
        );
    } else {
      if (this.props.documents.discountView) return <DiscountView />;
      else
        return (
          <Fragment>
            <div id="Document">
              <LetterHead />
              <ProjectHeader
                customerDetails={this.state.customerDetails}
                projectDetails={this.props.projectDetails}
                docNumber={this.props.docNumber + "-C"}
                type="Quote"
              />
              <CurtainsSummary
                isRetail={this.props.isRetail}
                response={this.state.quote}
                grouping={this.props.options.grouping}
                sorting={this.props.options.sorting}
                showDiscounts={this.props.options.showDiscounts}
                projectDiscount={parseFloat(
                  this.props.projectDetails.details.options.discount.retail.percentage
                )}
              />
              {this.props.options.billofRates ? (
                <CurtainsBillOfRates
                  display
                  isRetail={this.props.isRetail}
                  response={this.state.quote}
                />
              ) : (
                ""
              )}
              <CurtainsTotals
                showDiscounts={this.props.options.showDiscounts}
                isRetail={this.props.isRetail}
                discount={parseFloat(
                  this.props.projectDetails.details.options.discount.retail.percentage
                )}
                response={this.state.quote}
              />
              <QuoteNotes display={this.props.options.itemNotes} response={this.state.quote} />
              <Terms />
              <CurtainsExtras
                display={this.props.options.scheduleofItems}
                isRetail={this.props.isRetail}
                response={this.state.quote}
              />
            </div>
          </Fragment>
        );
    }
  }
}

const mapStateToProps = state => {
  return {
    AlgoliaKey: state.key,
    projectDetails: state.projects.projectDetails,
    documents: state.documents
  };
};

export default withRouter(connect(mapStateToProps)(QuoteCurtains));
