import config from "../../../../config";
export default function handleCurtainResponse(Data, Component) {
  if (Component === "style") {
    return [
      {
        id: "name",
        label: "Style Name",
        type: "text",
        tooltip: "Please Enter Blind Style Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "photo",
        label: "Photo URL",
        type: "text",
        tooltip: "Please Enter Photo URL",
        required: false,
        value: Data.details.photo,
      },
      {
        id: "priceLists",
        label: "Associated Price Lists",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_price_lists_name_asc`,
        value: Data.details.priceLists,
      },
      {
        id: "associatedServices",
        label: "Associated Services",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_services_fees_name_asc`,
        value: Data.details.associatedServices,
      },
    ];
  }
  if (Component === "fabric") {
    return [
      {
        id: "name",
        label: "Fabric Name",
        type: "text",
        tooltip: "Please Enter Curtain Fabric Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "photo",
        label: "Photo URL",
        type: "text",
        tooltip: "Please Enter Photo URL",
        required: false,
        value: Data.details.photo,
      },
      {
        id: "rollWidth",
        label: "Roll Width",
        type: "number",
        tooltip: "Please Enter Roll Width",
        value: Data.details.rollWidth,
      },
      {
        id: "hPatternRepeat",
        label: "Horizontal Pattern Repeat",
        type: "number",
        tooltip: "Please Enter Horizontal Pattern Repeat",
        value: Data.details.hPatternRepeat,
      },
      {
        id: "vPatternRepeat",
        label: "Vertical Pattern Repeat",
        type: "number",
        tooltip: "Please Enter Vertical Pattern Repeat",
        value: Data.details.vPatternRepeat,
      },
      {
        id: "technicalInfo",
        label: "Technical Information",
        type: "text",
        tooltip: "Please Enter Technical Information",
        value: Data.details.technicalInfo,
      },
      {
        id: "manufacturerId",
        label: "Manufacturer ID",
        type: "AlgoliaSelect",
        index: `${config.algolia.prefix}manufacturers_name_asc`,
        value: Data.details.manufacturerId,
      },
      {
        id: "priceLists",
        label: "Associated Price Lists",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_price_lists_name_asc`,
        filter: "",
        value: Data.details.priceLists,
      },
      {
        id: "fabricSurcharges",
        label: "Associated Fabric Surcharges",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_fabric_surcharges_name_asc`,
        filter: "",
        value: Data.details.fabricSurcharges,
      },
      {
        id: "associatedServices",
        label: "Associated Services",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_services_fees_name_asc`,
        value: Data.details.associatedServices,
      },
    ];
  }
  if (Component === "drive-mechanism-manual") {
    return [
      {
        id: "name",
        label: "Style Name",
        type: "text",
        tooltip: "Please Enter Drive Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "cost",
        label: "Drive Cost",
        type: "number",
        tooltip: "Please Enter Cost",
        required: true,
        value: Data.details.cost,
      },
      {
        id: "markup",
        label: "Drive Markup",
        type: "number",
        tooltip: "Please Enter Markup",
        required: true,
        value: Data.details.markup,
      },
      {
        id: "manufacturerId",
        label: "Manufacturer ID",
        type: "AlgoliaSelect",
        index: `${config.algolia.prefix}manufacturers_name_asc`,
        filters: "isDeleted:false",
        value: Data.details.manufacturerId,
      },
      {
        id: "associatedServices",
        label: "Associated Services",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_services_fees_name_asc`,
        value: Data.details.associatedServices,
      },
    ];
  }
  if (Component === "drive-mechanism-motorised") {
    return [
      {
        id: "name",
        label: "Drive Mechanism Name",
        type: "text",
        tooltip: "Please Enter Drive Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "cost",
        label: "Drive Cost",
        type: "number",
        tooltip: "Please Enter Cost",
        required: true,
        value: Data.details.cost,
      },
      {
        id: "markup",
        label: "Drive Markup",
        type: "number",
        tooltip: "Please Enter Markup",
        required: true,
        value: Data.details.markup,
      },
      {
        id: "power",
        label: "Drive Power",
        type: "number",
        tooltip: "Please Enter Power",
        required: true,
        value: Data.details.power,
      },
      {
        id: "speed",
        label: "Drive Speed",
        type: "number",
        tooltip: "Please Enter Speed",
        required: true,
        value: Data.details.speed,
      },
      {
        id: "lift",
        label: "Drive Lift",
        type: "number",
        tooltip: "Please Enter Lift",
        required: true,
        value: Data.details.lift,
      },
      {
        id: "manufacturerId",
        label: "Manufacturer ID",
        type: "AlgoliaSelect",
        index: `${config.algolia.prefix}manufacturers_name_asc`,
        filters: "isDeleted:false",
        value: Data.details.manufacturerId,
      },
      {
        id: "associatedServices",
        label: "Associated Services",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_services_fees_name_asc`,
        value: Data.details.associatedServices,
      },
    ];
  }
  if (Component === "pricing-group") {
    return [
      {
        id: "name",
        label: "Price List Name",
        type: "text",
        tooltip: "Please Enter Price List Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "cost",
        label: "Cost (per M)",
        type: "number",
        tooltip: "Please Enter Cost Value",
        required: true,
        value: Data.details.cost,
      },
      {
        id: "markup",
        label: "Price List Markup",
        type: "number",
        tooltip: "Please Enter Markup Value",
        required: false,
        value: Data.details.markup,
      },
      {
        id: "minCost",
        label: "Minimum Cost",
        type: "number",
        tooltip: "Please Enter Minimum Cost",
        required: false,
        value: Data.details.minCost,
      },
      {
        id: "makeupCost",
        label: "Makeup Cost",
        type: "number",
        tooltip: "Please Enter Makeup Cost",
        required: false,
        value: Data.details.makeupCost,
      },
      {
        id: "maxLength",
        label: "Max Length",
        type: "number",
        tooltip: "Please Enter Max Length",
        required: false,
        value: Data.details.maxLength,
      },
      {
        id: "manualFabrication",
        label: "Manual Fabrication",
        type: "number",
        tooltip: "Please Enter Manual Fabrication",
        required: false,
        value: Data.details.manualFabrication,
      },
    ];
  }
  if (Component === "bumph") {
    return [
      {
        id: "name",
        label: "Fabric Name",
        type: "text",
        tooltip: "Please Enter Curtain Fabric Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "pricePerMetre",
        label: "Price Per Metre",
        type: "number",
        tooltip: "Please Enter Price Per Metre",
        value: Data.details.pricePerMetre,
      },
      {
        id: "markup",
        label: "Markup",
        type: "number",
        tooltip: "Please Enter Markup",
        value: Data.details.markup,
      },
      {
        id: "fabricWidth",
        label: "Fabric Width",
        type: "number",
        tooltip: "Please Enter Fabric Width",
        value: Data.details.fabricWidth,
      },
      {
        id: "technicalInfo",
        label: "Technical Info",
        type: "text",
        tooltip: "Please Enter Technical Info",
        value: Data.details.technicalInfo,
      },
      {
        id: "manufacturerId",
        label: "Manufacturer ID",
        type: "AlgoliaSelect",
        index: `${config.algolia.prefix}manufacturers_name_asc`,
        filters: "isDeleted:false",
        value: Data.details.manufacturerId,
      },
      {
        id: "associatedServices",
        label: "Associated Services",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_services_fees_name_asc`,
        value: Data.details.associatedServices,
      },
    ];
  }
  if (Component === "lining") {
    return [
      {
        id: "name",
        label: "Fabric Name",
        type: "text",
        tooltip: "Please Enter Curtain Lining Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "pricePerMetre",
        label: "Price Per Metre",
        type: "number",
        tooltip: "Please Enter Price Per Metre",
        value: Data.details.pricePerMetre,
      },
      {
        id: "markup",
        label: "Markup",
        type: "number",
        tooltip: "Please Enter Markup",
        value: Data.details.markup,
      },
      {
        id: "fabricWidth",
        label: "Fabric Width",
        type: "number",
        tooltip: "Please Enter Fabric Width",
        value: Data.details.fabricWidth,
      },
      {
        id: "manufacturerId",
        label: "Manufacturer ID",
        type: "AlgoliaSelect",
        index: `${config.algolia.prefix}manufacturers_name_asc`,
        filters: "isDeleted:false",
        value: Data.details.manufacturerId,
      },
      {
        id: "technicalInfo",
        label: "Technical Info",
        type: "text",
        tooltip: "Please Enter Technical Info",
        value: Data.details.technicalInfo,
      },
      {
        id: "associatedServices",
        label: "Associated Services",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_services_fees_name_asc`,
        value: Data.details.associatedServices,
      },
    ];
  }
  if (Component === "pelmet") {
    return [
      {
        id: "name",
        label: "Fabric Name",
        type: "text",
        tooltip: "Please Enter Curtain Fabric Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "pricePerMetre",
        label: "Price Per Metre",
        type: "number",
        tooltip: "Please Enter Price Per Metre",
        value: Data.details.pricePerMetre,
      },
      {
        id: "markup",
        label: "Markup",
        type: "number",
        tooltip: "Please Enter Markup",
        value: Data.details.markup,
      },
      {
        id: "manufacturerId",
        label: "Manufacturer ID",
        type: "AlgoliaSelect",
        index: `${config.algolia.prefix}manufacturers_name_asc`,
        filters: "isDeleted:false",
        value: Data.details.manufacturerId,
      },
      {
        id: "technicalInfo",
        label: "Technical Info",
        type: "text",
        tooltip: "Please Enter Technical Info",
        value: Data.details.technicalInfo,
      },
      {
        id: "associatedServices",
        label: "Associated Services",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_services_fees_name_asc`,
        value: Data.details.associatedServices,
      },
    ];
  }
  if (Component === "track") {
    return [
      {
        id: "name",
        label: "Fabric Name",
        type: "text",
        tooltip: "Please Enter Curtain Fabric Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "description",
        label: "Description",
        type: "text",
        tooltip: "Please Enter Track Description",
        value: Data.details.description,
      },
      {
        id: "pricePerMetre",
        label: "Price Per Metre",
        type: "number",
        tooltip: "Please Enter Price Per Metre",
        value: Data.details.pricePerMetre,
      },
      {
        id: "height",
        label: "Track Height",
        type: "number",
        tooltip: "Please Enter Track Height",
        value: Data.details.height,
      },
      {
        id: "endAllowance",
        label: "Track End Allowance",
        type: "number",
        tooltip: "Please Track End Allowance",
        value: Data.details.endAllowance,
      },
      {
        id: "basePrice",
        label: "Base Price",
        type: "number",
        tooltip: "Please Enter Base Price",
        value: Data.details.basePrice,
      },
      {
        id: "markup",
        label: "Markup",
        type: "number",
        tooltip: "Please Enter Markup",
        value: Data.details.markup,
      },
      {
        id: "technicalInfo",
        label: "Technical Info",
        type: "text",
        tooltip: "Please Enter Technical Info",
        value: Data.details.technicalInfo,
      },
      {
        id: "manufacturerId",
        label: "Manufacturer ID",
        type: "AlgoliaSelect",
        index: `${config.algolia.prefix}manufacturers_name_asc`,
        filters: "isDeleted:false",
        value: Data.details.manufacturerId,
      },
      {
        id: "associatedServices",
        label: "Associated Services",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_services_fees_name_asc`,
        value: Data.details.associatedServices,
      },
      {
        id: "associatedStyles",
        label: "Associated Styles",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_components_name_asc`,
        filter: " AND type:style",
        value: Data.details.associatedStyles,
      },
    ];
  }
  if (Component === "lead-weight") {
    return [
      {
        id: "name",
        label: "Fabric Name",
        type: "text",
        tooltip: "Please Enter Curtain Fabric Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "description",
        label: "Description",
        type: "text",
        tooltip: "Please Enter Track Description",
        value: Data.details.description,
      },
      {
        id: "pricePerMetre",
        label: "Price Per Metre",
        type: "number",
        tooltip: "Please Enter Price Per Metre",
        value: Data.details.pricePerMetre,
      },
      {
        id: "markup",
        label: "Markup",
        type: "number",
        tooltip: "Please Enter Markup",
        value: Data.details.markup,
      },
      {
        id: "manufacturerId",
        label: "Manufacturer ID",
        type: "AlgoliaSelect",
        index: `${config.algolia.prefix}manufacturers_name_asc`,
        filters: "isDeleted:false",
        value: Data.details.manufacturerId,
      },
      {
        id: "technicalInfo",
        label: "Technical Info",
        type: "text",
        tooltip: "Please Enter Technical Info",
        value: Data.details.technicalInfo,
      },
      {
        id: "associatedServices",
        label: "Associated Services",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_services_fees_name_asc`,
        value: Data.details.associatedServices,
      },
    ];
  }
  if (Component === "fabric-surcharge") {
    return [
      {
        id: "name",
        label: "Fabric Name",
        type: "text",
        tooltip: "Please Enter Curtain Fabric Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "costPerSquareMetre",
        label: "Cost Per Square Metre",
        type: "number",
        tooltip: "Please Enter Price Per Square Metre",
        value: Data.details.costPerSquareMetre,
      },
      {
        id: "markup",
        label: "Markup",
        type: "number",
        tooltip: "Please Enter Markup",
        value: Data.details.markup,
      },
      {
        id: "adjustWidthFullness",
        label: "Adjust Width Fullness?",
        type: "select",
        options: [
          { name: "True", value: true },
          { name: "False", value: false },
        ],
        value: Data.details.adjustWidthFullness,
      },
      {
        id: "adjustWidthSymbol",
        label: "Adjust Width Symbol",
        type: "select",
        options: [
          { name: "<", value: "<" },
          { name: ">", value: ">" },
        ],
        value: Data.details.adjustWidthSymbol,
      },
      {
        id: "adjustWidth",
        label: "Adjust Width",
        type: "number",
        tooltip: "Please Enter Adjust Width",
        value: Data.details.adjustWidth,
      },
      {
        id: "adjustWidthAndOr",
        label: "Adjust Width And Or",
        type: "select",
        options: [
          { name: "And", value: "AND" },
          { name: "Or", value: "OR" },
        ],
        value: Data.details.adjustWidthAndOr,
      },
      {
        id: "adjustWidthDropSymbol",
        label: "Adjust Width Drop Symbol",
        type: "select",
        options: [
          { name: "<", value: "<" },
          { name: ">", value: ">" },
        ],
        value: Data.details.adjustWidthDropSymbol,
      },
      {
        id: "adjustDrop",
        label: "Adjust Drop",
        type: "number",
        tooltip: "Please Enter Adjust Drop",
        value: Data.details.adjustDrop,
      },
    ];
  }
  if (Component === "tape") {
    return [
      {
        id: "name",
        label: "Curtain Tape Name",
        type: "text",
        tooltip: "Please Enter Curtain Tape Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "description",
        label: "Description",
        type: "text",
        tooltip: "Please Enter Taper Description",
        value: Data.details.description,
      },
      {
        id: "hookWidth",
        label: "Hook Width",
        type: "number",
        tooltip: "Please Enter Hook Width",
        value: Data.details.hookWidth,
      },
      {
        id: "extraEnd",
        label: "Extra Ends",
        type: "number",
        tooltip: "Please Enter End Extra",
        value: Data.details.extraEnd,
      },
      {
        id: "manufacturerId",
        label: "Manufacturer ID",
        type: "AlgoliaSelect",
        index: `${config.algolia.prefix}manufacturers_name_asc`,
        filters: "isDeleted:false",
        value: Data.details.manufacturerId,
      },
      {
        id: "associatedServices",
        label: "Associated Services",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_services_fees_name_asc`,
        value: Data.details.associatedServices,
      },
      {
        id: "associatedRunners",
        label: "Associated Runners",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_components_name_asc`,
        filter: " AND type:runner",
        value: Data.details.associatedRunners,
      },
    ];
  }
  if (Component === "runner") {
    return [
      {
        id: "name",
        label: "Curtain Runner Name",
        type: "text",
        tooltip: "Please Enter Curtain Runner Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "description",
        label: "Description",
        type: "text",
        tooltip: "Please Enter Runner Description",
        value: Data.details.description,
      },
      {
        id: "width",
        label: "Runner Width",
        type: "number",
        tooltip: "Please Enter Runner Width",
        value: Data.details.width,
      },
      {
        id: "drop",
        label: "Runner Drop",
        type: "number",
        tooltip: "Please Enter Runner Height",
        value: Data.details.drop,
      },
      {
        id: "manufacturerId",
        label: "Manufacturer ID",
        type: "AlgoliaSelect",
        index: `${config.algolia.prefix}manufacturers_name_asc`,
        filters: "isDeleted:false",
        value: Data.details.manufacturerId,
      },
      {
        id: "associatedServices",
        label: "Associated Services",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_services_fees_name_asc`,
        value: Data.details.associatedServices,
      },
      {
        id: "associatedTracks",
        label: "Associated Tracks",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_components_name_asc`,
        filter: " AND type:track",
        value: Data.details.associatedTracks,
      },
    ];
  }
  if (Component === "accessory") {
    return [
      {
        id: "name",
        label: "Accessory Name",
        type: "text",
        tooltip: "Please Enter Blind Accessory Name",
        required: true,
        value: Data.details.name,
      },
      {
        id: "cost",
        label: "Accessory Cost",
        type: "number",
        tooltip: "Please Enter Blind Accessory Cost",
        required: true,
        value: Data.details.cost,
      },
      {
        id: "markup",
        label: "Markup (%)",
        type: "number",
        tooltip: "Please Enter Blind Accessory Markup",
        required: true,
        value: Data.details.markup,
      },
      {
        id: "technicalInfo",
        label: "Technical Info",
        type: "text",
        tooltip: "Please Enter Blind Accessory technicalInfo",
        required: false,
        value: Data.details.technicalInfo,
      },
      {
        id: "manufacturerId",
        label: "Manufacturer ID",
        type: "AlgoliaSelect",
        index: `${config.algolia.prefix}manufacturers_name_asc`,
        filters: "isDeleted:false",
        value: Data.details.manufacturerId,
      },
      {
        id: "associatedServices",
        label: "Associated Services",
        type: "multiSelect",
        index: `${config.algolia.prefix}products_services_fees_name_asc`,
        value: Data.details.associatedServices,
      },
    ];
  }
}
