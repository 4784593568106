import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";

//MDB
import { MDBBtn } from "mdbreact";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    // Using just regular React state for the login page...
    // don't see a reason to use redux store
    this.state = {
      email: "",
      failed: false
    };
  }

  handleChange = event => {
    // Handles editing of the form
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      try {
        await Auth.forgotPassword(this.state.email);
        this.props.history.push("/new-password");
      } catch (e) {
        console.error(e.message);
        // TODO: Add user feedback for when login fails
        this.setState({ failed: true });
      }
    }
  };

  render() {
    return (
      <div className="loginContainer">
        <div className="filler">
          <h1>Telishade</h1>
        </div>
        <div className="Login card">
          <form onSubmit={this.handleSubmit} className="needs-validation login-form" noValidate>
            <div className={this.state.failed ? "loginError" : "loginErrorNone"}>
              Incorrect Username
            </div>
            <div className="formRow">
              <label>Email</label>
              <input
                id="email"
                placeholder="Enter Email Address"
                className="form-control"
                value={this.state.email}
                onChange={this.handleChange}
                type="email"
                autoComplete="username"
                required
              />
              <div className="invalid-tooltip">Please provide a valid email.</div>
            </div>
            <div className="loginBtn">
              <MDBBtn color="blue" type="submit">
                Submit
              </MDBBtn>
              <a href="/login">Back</a>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default connect()(ForgotPassword);
