import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { API } from "aws-amplify";
import { setRefresh } from "../../../actions/algoliaActions";

class JobProducts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      blinds: false,
      curtains: false,
      blindProductId: undefined,
      curtainProductId: undefined,
      blindPrices: undefined,
      curtainPrices: undefined,
      blindErrors: undefined,
      curtainErrors: undefined,
      blindsList: [],
      curtainsList: [],
    };

    this.getProductInformation();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    this._isMounted = false;
  }

  refreshAlgoliaList() {
    this.props.dispatch(setRefresh(false));
    this.props.dispatch(setRefresh(true));
    this.props.dispatch(setRefresh(false));
  }

  calculateBlindPrice(quoteItem) {
    const baseWithMarkup =
      (parseFloat(quoteItem.baseCost) / 100) * (parseFloat(quoteItem.baseMarkup) + 100);
    let ExtrasTotal = 0;
    ExtrasTotal += this.calculateExtrasPrice(quoteItem, "accessory");
    ExtrasTotal += this.calculateExtrasPrice(quoteItem, "baseBar");
    ExtrasTotal += this.calculateExtrasPrice(quoteItem, "doubleBracket");
    ExtrasTotal += this.calculateExtrasPrice(quoteItem, "kassette");
    ExtrasTotal += this.calculateExtrasPrice(quoteItem, "sideChannel");
    return Math.ceil(baseWithMarkup + ExtrasTotal);
  }

  calculateExtrasPrice(quoteItem, extraKey) {
    if (quoteItem.description.width === "") {
      quoteItem.description.width = 0;
    }
    if (quoteItem.extras[extraKey]) {
      if ("cost" in quoteItem.extras[extraKey].details) {
        const extraWithMarkup =
          (parseFloat(quoteItem.extras[extraKey].details.cost) / 100) *
          (parseFloat(quoteItem.extras[extraKey].details.markup) + 100);
        return extraWithMarkup;
      } else if ("costPerMetre" in quoteItem.extras[extraKey].details) {
        const extraWithMarkup =
          ((parseFloat(quoteItem.extras[extraKey].details.costPerMetre) *
            (parseFloat(quoteItem.description.width) / 1000)) /
            100) *
          (parseFloat(quoteItem.extras[extraKey].details.markup) + 100);
        return extraWithMarkup;
      } else if ("pricePerMetre" in quoteItem.extras[extraKey].details) {
        if (quoteItem.extras[extraKey].details.pricePerMetre === "") {
          return 0;
        } else if (quoteItem.extras[extraKey].details.markup === "") {
          return (
            parseFloat(quoteItem.extras[extraKey].details.pricePerMetre) *
            (parseFloat(quoteItem.description.width) / 1000)
          );
        }
        const extraWithMarkup =
          ((parseFloat(quoteItem.extras[extraKey].details.pricePerMetre) *
            (parseFloat(quoteItem.description.width) / 1000)) /
            100) *
          (parseFloat(quoteItem.extras[extraKey].details.markup) + 100);
        return extraWithMarkup;
      }
    } else {
      return 0;
    }
  }

  calculateCurtainsPrice(quoteItem) {
    const baseWithMarkup =
      quoteItem.costInfo.markedUpBasePrice + quoteItem.costInfo.markedUpTrackTotalCost;
    let ExtrasTotal = 0;
    ExtrasTotal += this.calculateExtrasPrice(quoteItem, "bumph");
    ExtrasTotal += this.calculateExtrasPrice(quoteItem, "leadWeight");
    ExtrasTotal += this.calculateExtrasPrice(quoteItem, "lining");
    ExtrasTotal += this.calculateExtrasPrice(quoteItem, "driveMechanism");
    ExtrasTotal += this.calculateExtrasPrice(quoteItem, "pelmet");
    return Math.ceil(baseWithMarkup + ExtrasTotal);
  }

  async getProductInformation() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getProductInformation();
      }, 500);
    } else {
      try {
        const curtainProduct = await API.get("telishadeAPI", "/product/curtain");
        const blindProduct = await API.get("telishadeAPI", "/product/blind");
        const JobProductsBlinds = await API.get(
          "telishadeAPI",
          `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${this.props.match.params.JobNo}/items/blind`
        );

        const JobProductsCurtains = await API.get(
          "telishadeAPI",
          `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${this.props.match.params.JobNo}/items/curtain`
        );

        const BlindPromises = JobProductsBlinds.items.map(async (i) => {
          if (this._isMounted) {
            try {
              const result = await this.getQuoteInfoBlinds(i.id);
              if ("error" in result.quote) {
                this.setState({
                  blindPrices: { [i.id]: "failed", ...this.state.blindPrices },
                  blindErrors: { [i.id]: result.quote.error, ...this.state.blindErrors },
                });
              } else {
                const price = this.calculateBlindPrice(result.quote);
                this.setState({
                  blindPrices: { [i.id]: price, ...this.state.blindPrices },
                });
              }
            } catch (e) {
              this.setState({
                blindPrices: { [i.id]: "failed", ...this.state.blindPrices },
                blindErrors: { [i.id]: e, ...this.state.blindErrors },
              });
            }
          }
        });

        const CurtainPromises = JobProductsCurtains.items.map(async (i) => {
          if (this._isMounted) {
            try {
              const result = await this.getQuoteInfoCurtains(i.id);
              if ("error" in result.quote) {
                this.setState({
                  curtainPrices: { [i.id]: "failed", ...this.state.curtainPrices },
                  curtainErrors: { [i.id]: result.quote.error, ...this.state.curtainErrors },
                });
              } else {
                const price = this.calculateCurtainsPrice(result.quote);
                this.setState({
                  curtainPrices: {
                    [i.id]: price,
                    ...this.state.curtainPrices,
                  },
                });
              }
            } catch (e) {
              this.setState({
                curtainPrices: { [i.id]: "failed", ...this.state.curtainPrices },
                curtainErrors: { [i.id]: e, ...this.state.curtainErrors },
              });
            }
          }
        });
        let blinds = false;
        let curtains = false;
        if (JobProductsBlinds.items.length > 0) {
          blinds = true;
        }
        if (JobProductsCurtains.items.length > 0) {
          curtains = true;
        }
        if (this._isMounted) {
          this.setState({
            loaded: true,
            blinds,
            curtains,
            blindProductId: blindProduct.id,
            curtainProductId: curtainProduct.id,
            blindsList: JobProductsBlinds.items,
            curtainsList: JobProductsCurtains.items,
          });
        }

        await Promise.all(BlindPromises);
        await Promise.all(CurtainPromises);
      } catch (e) {
        console.error(e);
      }
    }
  }

  async getQuoteInfoBlinds(jobItemId) {
    try {
      const blah = await API.get(
        "telishadeAPI",
        `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${this.props.match.params.JobNo}/item/blind/${jobItemId}/quote`
      );
      return blah;
    } catch (e) {
      throw e;
    }
  }

  async getQuoteInfoCurtains(jobItemId) {
    try {
      const blah = await API.get(
        "telishadeAPI",
        `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${this.props.match.params.JobNo}/item/curtain/${jobItemId}/quote`
      );
      return blah;
    } catch (e) {
      throw e;
    }
  }

  HitComponentBlinds = (hit) => {
    const listItemClickHandler = () => {
      if (this.state.blindPrices[hit.id] === "failed") {
        this.props.displayToast(this.state.blindErrors[hit.id].message);
      }
    };
    let status = "pending";
    let className = { className: "jobItemListElement" };
    if (this.state.blindPrices) {
      if (this.state.blindPrices[hit.id] === "failed") {
        status = "failed";
      } else if (this.state.blindPrices[hit.id]) {
        status = "success";
      }
    }
    if (status === "failed") {
      return (
        <p {...className} onClick={listItemClickHandler}>
          <span className="itemNo">ItemNo: {hit.details.itemNo}</span>
          <span className="location">Location: {hit.details.location}</span>
          <span className="width">Width: {hit.details.width}</span>
          <span className="drop">Drop: {hit.details.drop}</span>
          {hit.details.driveType && <span className="drive">Drive: {hit.details.driveType}</span>}
          {hit.details.driveSide && (
            <span className="driveSide">Drive Side: {hit.details.driveSide}</span>
          )}
          <span className="pricingFail">Cost: Error</span>
        </p>
      );
    } else if (status === "success") {
      return (
        <p {...className} onClick={listItemClickHandler}>
          <span className="itemNo">ItemNo: {hit.details.itemNo}</span>
          <span className="location">Location: {hit.details.location}</span>
          <span className="width">Width: {hit.details.width}</span>
          <span className="drop">Drop: {hit.details.drop}</span>
          {hit.details.driveType && <span className="drive">Drive: {hit.details.driveType}</span>}
          {hit.details.driveSide && (
            <span className="driveSide">Drive Side: {hit.details.driveSide}</span>
          )}
          <span className="pricingSuccess">Cost: ${this.state.blindPrices[hit.id]}</span>
        </p>
      );
    } else if (status === "pending") {
      return (
        <p {...className} onClick={listItemClickHandler}>
          <span className="itemNo">ItemNo: {hit.details.itemNo}</span>
          <span className="location">Location: {hit.details.location}</span>
          <span className="width">Width: {hit.details.width}</span>
          <span className="drop">Drop: {hit.details.drop}</span>
          {hit.details.driveType && <span className="drive">Drive: {hit.details.driveType}</span>}
          {hit.details.driveSide && (
            <span className="driveSide">Drive Side: {hit.details.driveSide}</span>
          )}
          <span className="pricingPending">Cost: Calculating..</span>
        </p>
      );
    }
  };

  HitComponentCurtains = (hit) => {
    const listItemClickHandler = () => {
      if (this.state.curtainPrices[hit.id] === "failed") {
        this.props.displayToast(this.state.curtainErrors[hit.id].message);
      }
    };
    let status = "pending";
    let className = { className: "jobItemListElement" };
    if (this.state.curtainPrices) {
      if (this.state.curtainPrices[hit.id] === "failed") {
        status = "failed";
      } else if (this.state.curtainPrices[hit.id]) {
        status = "success";
      }
    }
    if (status === "failed") {
      return (
        <p {...className} onClick={listItemClickHandler}>
          <span className="itemNo">ItemNo: {hit.details.itemNo}</span>
          <span className="location">Location: {hit.details.location}</span>
          <span className="width">Width: {hit.details.width}</span>
          <span className="drop">Drop: {hit.details.drop}</span>
          {hit.details.driveType && <span className="drive">Drive: {hit.details.driveType}</span>}
          {hit.details.opening && <span className="opening">Opening: {hit.details.opening}</span>}
          <span className="pricingFail">Cost: Error</span>
        </p>
      );
    } else if (status === "success") {
      return (
        <p {...className} onClick={listItemClickHandler}>
          <span className="itemNo">ItemNo: {hit.details.itemNo}</span>
          <span className="location">Location: {hit.details.location}</span>
          <span className="width">Width: {hit.details.width}</span>
          <span className="drop">Drop: {hit.details.drop}</span>
          {hit.details.driveType && <span className="drive">Drive: {hit.details.driveType}</span>}
          {hit.details.opening && <span className="opening">Opening: {hit.details.opening}</span>}
          <span className="pricingSuccess">Cost: ${this.state.curtainPrices[hit.id]}</span>
        </p>
      );
    } else if (status === "pending") {
      return (
        <p {...className} onClick={listItemClickHandler}>
          <span className="itemNo">ItemNo: {hit.details.itemNo}</span>
          <span className="location">Location: {hit.details.location}</span>
          <span className="width">Width: {hit.details.width}</span>
          <span className="drop">Drop: {hit.details.drop}</span>
          {hit.details.driveType && <span className="drive">Drive: {hit.details.driveType}</span>}
          {hit.details.opening && <span className="opening">Opening: {hit.details.opening}</span>}
          <span className="pricingPending">Cost: Calculating..</span>
        </p>
      );
    }
  };

  render() {
    if (!this.state.loaded) {
      return <div className="loader">LOADING...</div>;
    } else {
      return (
        <div className="jobProductList">
          {this.state.blinds ? (
            <div className="BlindsProductList">
              <div className="ProductListHeader">Blinds</div>
              <div className="searchable-list">
                <ul className="ais-Hits-list">
                  {this.state.blindsList.map((item, key) => {
                    return <li key={key}>{this.HitComponentBlinds(item)}</li>;
                  })}
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}
          {this.state.curtains ? (
            <div className="CurtainsProductList">
              <div className="ProductListHeader">Curtains</div>
              <div className="searchable-list">
                <ul className="ais-Hits-list">
                  {this.state.curtainsList.map((item, key) => {
                    return <li key={key}>{this.HitComponentCurtains(item)}</li>;
                  })}
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return { ...state.projects, AlgoliaKey: state.key };
};

export default withRouter(connect(mapStateToProps)(JobProducts));
