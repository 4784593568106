import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import algoliasearch from "algoliasearch";
import config from "../../config";
import StaffList from "./StaffList";
import StaffDetails from "./StaffDetails";
import StaffTabs from "./StaffTabs";
import AddStaff from "./PopUps/AddStaff";
import AddAddress from "./PopUps/AddAddress";
import EditStaff from "./PopUps/EditStaff";
import EditAddress from "./PopUps/EditAddress";
import DeleteStaff from "./PopUps/DeleteStaff";
import DeleteAddress from "./PopUps/DeleteAddress";
import "./Staff.css";

class Staff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultRoute: undefined
    };

    if (this.props.staff.id === null) {
      this.getFirstStaffMemberForRouting();
    }
  }

  async getFirstStaffMemberForRouting() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getFirstStaffMemberForRouting();
      }, 500);
    } else {
      try {
        const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
        const staffIndex = client.initIndex(`${config.algolia.prefix}staff_name_asc`);
        const staff = await staffIndex.search({
          query: "",
          filters: `isDeleted:false`,
          hitsPerPage: 1
        });
        const firstStaffMember = staff.hits[0];
        if (this._isMounted) {
          this.setState({ defaultRoute: firstStaffMember.objectID });
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="staff">
        <StaffList />

        <div className="selected-staff-window">
          <StaffDetails />
          <StaffTabs />
          <Switch>
            <Route exact path="/staff/add" component={AddStaff} />
            <Route path="/staff/:staffId/edit" component={EditStaff} />
            <Route path="/staff/:staffId/delete" component={DeleteStaff} />
            <Route path="/staff/:staffId/addresses/add" component={AddAddress} />
            <Route path="/staff/:staffId/addresses/:addressId/edit" component={EditAddress} />
            <Route path="/staff/:staffId/addresses/:addressId/delete" component={DeleteAddress} />
            <Redirect
              to={
                this.props.staff.id !== null
                  ? `/staff/${this.props.staff.id}/addresses`
                  : this.state.defaultRoute
                  ? `/staff/${this.state.defaultRoute}/addresses`
                  : "/staff"
              }
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.staff, AlgoliaKey: state.key };
};

export default withRouter(connect(mapStateToProps)(Staff));
