import React, { Component, Fragment } from "react";

class CurtainsSummary extends Component {
  constructScheduleofItems(jobArray) {
    const newJobArray = JSON.parse(JSON.stringify(jobArray));
    return newJobArray.map(job => {
      return job.quote.map((quote, index) => (
        <Fragment key={index}>
          <tr className="details jobNumber">
            <td>{quote.itemNo}</td>
            <td>{quote.description.allDetails.productCode}</td>
            <td>{quote.description.allDetails.location}</td>
            <td>{this.constructItemDescription(quote)}</td>
            <td>{this.calculateCurtainItemTotal(quote)}</td>
          </tr>
          {this.props.detailed
            ? this.constructDetailedCostBreakdown(quote, index + "detailed")
            : null}
        </Fragment>
      ));
    });
  }

  constructDetailedCostBreakdown(quoteItem, key) {
    const blindBreakdownDefinition = [
      { value: "Bumph", key: "bumph" },
      { value: "Drive Mechanism", key: "driveMechanism" },
      { value: "Lead Weight", key: "leadWeight" },
      { value: "Lining", key: "lining" },
      { value: "Pelmet", key: "pelmet" },
      { value: "Accessory", key: "accessory" }
    ];
    const fabricCost = quoteItem.costInfo.right
      ? this.props.isRetail
        ? quoteItem.costInfo.right.markedUpFabricCost + quoteItem.costInfo.left.markedUpFabricCost
        : quoteItem.costInfo.right.fabricCost + quoteItem.costInfo.left.fabricCost
      : this.props.isRetail
      ? quoteItem.costInfo.markedUpFabricCost
      : quoteItem.costInfo.fabricCost;
    const makeupCost =
      quoteItem.costInfo.right && quoteItem.costInfo.right.makeUpCost
        ? this.props.isRetail
          ? quoteItem.costInfo.right.markedUpMakeUpCost + quoteItem.costInfo.left.markedUpMakeUpCost
          : quoteItem.costInfo.right.makeUpCost + quoteItem.costInfo.left.makeUpCost
        : this.props.isRetail
        ? quoteItem.costInfo.markedUpMakeUpCost
        : quoteItem.costInfo.makeUpCost;

    const trackCost = this.props.isRetail
      ? quoteItem.costInfo.markedUpTrackTotalCost
      : quoteItem.costInfo.trackTotalCost;

    return (
      <Fragment key={key}>
        <tr className="details">
          <td></td>
          <td></td>
          <td></td>
          <td>Fabric</td>
          <td>{fabricCost.toFixed(2)}</td>
        </tr>
        <tr className="details">
          <td></td>
          <td></td>
          <td></td>
          <td>Makeup</td>
          <td>{makeupCost.toFixed(2)}</td>
        </tr>
        <tr className="details">
          <td></td>
          <td></td>
          <td></td>
          <td>Track</td>
          <td>{trackCost.toFixed(2)}</td>
        </tr>
        {blindBreakdownDefinition.map((component, index) => {
          if (quoteItem.extras[component.key]) {
            return (
              <tr className="details" key={index}>
                <td></td>
                <td></td>
                <td></td>
                <td>{component.value}</td>
                <td>
                  {this.calculateExtra(component.key, quoteItem).total === 0
                    ? "Included"
                    : this.calculateExtra(component.key, quoteItem).total}
                </td>
              </tr>
            );
          } else return null;
        })}
        {quoteItem.extras.services.map((service, index) => (
          <tr className="details" key={index}>
            <td></td>
            <td></td>
            <td></td>
            <td>{service.details.name}</td>
            <td>
              {this.props.isRetail
                ? service.details.cost + (service.details.cost / 100) * service.details.markup
                : service.details.cost}
            </td>
          </tr>
        ))}
      </Fragment>
    );
  }

  constructItemDescription(item) {
    const { fabric, driveMechanism, headerStyle, track, width, drop } = item.description;

    return `${fabric} / ${headerStyle} / ${track} / ${driveMechanism} / Width: ${width}mm, Drop: ${drop}mm`;
  }

  //TODO: Discount Math will need to be tidied up when the discount changes go through
  calculateCurtainItemTotal(quoteItem, discount = 0) {
    let totalforItem = 0;
    totalforItem += this.props.isRetail
      ? quoteItem.costInfo.markedUpBasePrice
      : quoteItem.costInfo.basePrice;
    totalforItem = (totalforItem / 100) * (100 - discount);
    totalforItem += this.calculateExtra("bumph", quoteItem).total;
    totalforItem += this.calculateExtra("driveMechanism", quoteItem).total;
    totalforItem += this.calculateExtra("leadWeight", quoteItem).total;
    totalforItem += this.calculateExtra("lining", quoteItem).total;
    totalforItem += this.calculateExtra("pelmet", quoteItem).total;
    totalforItem += this.calculateExtra("accessory", quoteItem).total;
    totalforItem += this.calculateService(quoteItem).total;
    totalforItem += this.props.isRetail
      ? quoteItem.costInfo.markedUpTrackTotalCost
      : quoteItem.costInfo.trackTotalCost;
    return Math.ceil(totalforItem);
  }

  calculateExtra(extraKey, item) {
    let totalPrice = 0;
    let totalQty = 0;
    if (item.extras[extraKey]) {
      if (item.extras[extraKey].details.pricePerMetre) {
        const totalPricePerMetre =
          parseFloat(item.extras[extraKey].details.pricePerMetre) *
          (parseFloat(item.description.width) / 1000);
        const totalPricePerMetreMarkup =
          (totalPricePerMetre / 100) * (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail ? totalPricePerMetreMarkup : totalPricePerMetre;
        totalQty++;
      } else if (item.extras[extraKey].details.cost) {
        const totalCost =
          (parseFloat(item.extras[extraKey].details.cost) / 100) *
          (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail
          ? totalCost
          : parseFloat(item.extras[extraKey].details.cost);
        totalQty++;
      }
    }
    return { total: totalPrice, qty: totalQty };
  }

  calculateService(item) {
    let totalPrice = 0;
    let totalQty = 0;
    item.extras.services.forEach(service => {
      const totalWithMarkup =
        (parseFloat(service.details.cost) / 100) * (100 + parseFloat(service.details.markup));
      totalPrice += this.props.isRetail ? totalWithMarkup : parseFloat(service.details.cost);
      totalQty++;
    });
    return { total: totalPrice, qty: totalQty };
  }

  render() {
    return (
      <div
        className="doc-box page-break-before"
        id="ScheduleofItems"
        style={{ display: this.props.display ? "" : "None" }}
      >
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="heading">
              <td colSpan="5">
                <span className="heading-one">JOB QUOTE - SCHEDULE OF ITEMS</span>
              </td>
            </tr>
            <tr className="sub-heading">
              <td>Item Number</td>
              <td>Product Code</td>
              <td>Location</td>
              <td>Description</td>
              <td>Unit Price</td>
            </tr>
            {this.constructScheduleofItems(this.props.response)}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CurtainsSummary;
