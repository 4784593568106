// *** Action Type Constants ***
export const SELECT_MANUFACTURER = "MANUFACTURERS::SELECT_MANUFACTURER";
export const SELECT_BRANCH = "MANUFACTURERS::SELECT_BRANCH";
export const SELECT_CONTACT = "MANUFACTURERS::SELECT_CONTACT";
export const UPDATE_MANUFACTURER_DETAILS = "MANUFACTURERS::UPDATE_DETAILS";
export const UPDATE_BRANCH_DETAILS = "MANUFACTURERS::UPDATE_BRANCH_DETAILS";
export const UPDATE_CONTACT_DETAILS = "MANUFACTURERS::UPDATE_CONTACT_DETAILS";

// *** Action Creators ***
export function selectManufacturer(id) {
  return {
    type: SELECT_MANUFACTURER,
    id
  };
}

export function selectBranch(id) {
  return {
    type: SELECT_BRANCH,
    id
  };
}

export function selectContact(id) {
  return {
    type: SELECT_CONTACT,
    id
  };
}

export function updateBranchDetails(details) {
  return {
    type: UPDATE_BRANCH_DETAILS,
    details
  };
}

export function updateManufacturerDetails(details) {
  return {
    type: UPDATE_MANUFACTURER_DETAILS,
    details
  };
}

export function updateContactDetails(details) {
  return {
    type: UPDATE_CONTACT_DETAILS,
    details
  };
}
