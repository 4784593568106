import React, { Component, Fragment } from "react";
import algoliasearch from "algoliasearch";
import config from "../../../../../config";
import { connect } from "react-redux";
import JobHeader from "./PurchaseOrderHeader";
import LetterHead from "../../TableComponents/LetterHead";
class PurchaseOrderDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idCache: undefined,
      headerRows: this.determineHeadings(),
    };
    this.populateCache();
  }

  determineHeadings() {
    const displayData = [
      { id: "productCode", name: "Product Code" },
      { id: "location", name: "Location" },
      { id: "styleId", name: "Style" },
      { id: "width", name: "Width" },
      { id: "drop", name: "Drop" },
      { id: "opening", name: "Opening" },
      { id: "driveType", name: "Drive Type" },
      { id: "driveMechanismId", name: "Drive Mechanism" },
      { id: "driveColour", name: "Drive Colour" },
      { id: "rollDirection", name: "Roll Direction" },
      { id: "driveSide", name: "Drive Side" },
      //Blind Additionals
      { id: "kassetteId", name: "Fascia" },
      { id: "kassetteColour", name: "Fascia Colour" },
      { id: "fasciaFit", name: "Fascia Fit" },
      { id: "doubleBracketSystemId", name: "Double Bracket" },
      { id: "chainType", name: "Chain Type" },
      { id: "chainDrop", name: "Chain Length" },
      { id: "railColour", name: "Rail Colour" },
      { id: "sideChannelId", name: "Side Channel" },
      { id: "baseBarId", name: "Base Bar" },
      { id: "linkSystemId", name: "Link System" },
      { id: "linkedTo", name: "Linked To" },
      { id: "linkingPosition", name: "Linking Position" },
      //Curtain Additionals
      { id: "trackType", name: "Track Type" },
      { id: "trackId", name: "Track" },
      { id: "runnerId", name: "Runner" },
      { id: "tapeId", name: "Tape" },
      { id: "liningId", name: "Lining" },
      { id: "leadWeightId", name: "Lead Weight" },
      { id: "cordStickType", name: "Cord/Stick Type" },
      { id: "fit", name: "Fit" },
      { id: "controlSide", name: "Control Side" },
      //Fabrics
      { id: "fabricId", name: "Fabric" },
      { id: "fabricColour", name: "Fabric Colour" },
      { id: "cost", name: "Cost" },
    ];
    let headings = [];
    //Filters out Columns in Purchase order that has no data
    displayData.forEach((data) => {
      let testString = "";
      this.props.manufacturer.lines.forEach((lineItem) => {
        testString += lineItem.details[data.id] || data.id === "cost" ? lineItem.details[data.id] : "";
      });
      if (testString === "" || testString === 0 || testString === "0") {
      } else {
        headings.push(data);
      }
    });
    return headings;
  }

  getHeadingRow() {
    return (
      <tr className="sub-heading">
        {this.state.headerRows.map((heading) => {
          if (heading.id === "productCode") {
            return (
              <Fragment key={heading.id}>
                <td className="sub-heading">QTY</td>
                <td className="sub-heading">{heading.name}</td>
              </Fragment>
            );
          } else
            return (
              <td className="sub-heading" key={heading.id}>
                {heading.name}
              </td>
            );
        })}
      </tr>
    );
  }

  async getComponentDetails(componentId) {
    try {
      if (componentId === "" || componentId === undefined) return "";
      const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.secondKey);
      const index = client.initIndex(`${config.algolia.prefix}products_components_name_asc`);
      const response = await index.search({
        query: "",
        filters: "isDeleted:false AND objectID:" + componentId,
        hitsPerPage: 1,
      });
      return response.hits[0].details.name;
    } catch (e) {
      console.error(e);
    }
  }

  async getColourDetails(colourId) {
    try {
      if (colourId === "" || colourId === undefined) return "";
      const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.secondKey);
      const index = client.initIndex(`${config.algolia.prefix}products_colour_list_items_name_asc`);
      const response = await index.search({
        query: "",
        filters: "isDeleted:false AND objectID:" + colourId,
        hitsPerPage: 1,
      });
      return response.hits[0].details.name;
    } catch (e) {
      console.error(e);
    }
  }

  async populateCache() {
    let uniqueIdCollection = [];
    let uniqueColourIdCollection = [];
    const componentData = [
      "styleId",
      "driveMechanismId",
      "kassetteId",
      "doubleBracketSystemId",
      "sideChannelId",
      "baseBarId",
      "linkSystemId",
      "fabricId",
      "trackId",
      "runnerId",
      "tapeId",
      "liningId",
      "leadWeightId",
    ];
    const colourData = ["driveColour", "railColour", "kassetteColour", "fabricColour"];
    this.props.manufacturer.lines.forEach((lineItem, key) => {
      componentData.forEach((componentData) => {
        if (!uniqueIdCollection.includes(lineItem.details[componentData])) {
          uniqueIdCollection.push(lineItem.details[componentData]);
        }
      });
      colourData.forEach((colourData) => {
        if (!uniqueColourIdCollection.includes(lineItem.details[colourData])) {
          uniqueColourIdCollection.push(lineItem.details[colourData]);
        }
      });
    });
    let promiseCollection = uniqueIdCollection.map((id) => this.getComponentDetails(id));
    let colourPromiseCollection = uniqueColourIdCollection.map((id) => this.getColourDetails(id));
    promiseCollection = await Promise.all(promiseCollection);

    colourPromiseCollection = await Promise.all(colourPromiseCollection);
    let newCache = {};
    uniqueIdCollection.forEach((id, index) => {
      newCache = {
        ...newCache,
        [id]: promiseCollection[index],
      };
    });
    uniqueColourIdCollection.forEach((id, index) => {
      newCache = {
        ...newCache,
        colours: { ...newCache.colours, [id]: colourPromiseCollection[index] },
      };
    });
    this.setState({ idCache: newCache });
  }

  areLineItemsSame(lineItem1, lineItem2) {
    let areSame = true;
    this.state.headerRows.forEach((heading) => {
      if (heading.id !== "itemNo") {
        if (lineItem1.details[heading.id] !== lineItem2.details[heading.id]) {
          areSame = false;
        }
      }
    });
    return areSame;
  }

  constructFromResponse() {
    let lineItemCollection = [];
    const shouldCombine = this.props.options.combine;
    this.props.manufacturer.lines.forEach((lineItem) => {
      let addedFlag = false;
      lineItemCollection.forEach((addedLineItem) => {
        if (this.areLineItemsSame(lineItem, addedLineItem.item) && shouldCombine) {
          addedLineItem.qty++;
          addedFlag = true;
        }
      });
      if (!addedFlag) {
        lineItemCollection.push({ item: lineItem, qty: 1 });
      }
    });

    if (this.props.options.sortingKey) {
      lineItemCollection = this.handleSorting(lineItemCollection, this.props.options.sortingKey);
    }
    return lineItemCollection.map((lineItem, key) => {
      // ==== NOTE THIS COMPONENT SET EXCLUDES VALUES THAT ARE SOURCED FROM WORKSHEET: ======
      const componentData = [
        "styleId",
        "driveMechanismId",
        "kassetteId",
        "doubleBracketSystemId",
        "sideChannelId",
        "baseBarId",
        "linkSystemId",
        "fabricId",
        "trackId",
        "runnerId",
        "tapeId",
        "liningId",
        "leadWeightId",
      ];
      const colourData = ["driveColour", "railColour", "kassetteColour", "fabricColour"];
      return (
        <tr key={key} className="details">
          {this.state.headerRows.map((heading) => {
            if (componentData.includes(heading.id)) {
              return <td key={heading.id + "-data"}>{this.state.idCache[lineItem.item.details[heading.id]]}</td>;
            }
            if (colourData.includes(heading.id)) {
              return <td key={heading.id + "-data"}>{this.state.idCache.colours[lineItem.item.details[heading.id]]}</td>;
            }
            if (heading.id === "productCode") {
              return (
                <Fragment key={heading.id + "-data"}>
                  <td>{lineItem.qty}</td>
                  <td>{lineItem.item.details[heading.id]}</td>
                </Fragment>
              );
            }
            if (heading.id === "cost") {
              return (
                <td className="details jobNumber" key={heading.id + "-data"}>
                  ${this.calculatePrice(lineItem.item)}
                </td>
              );
            }
            if (heading.id === "driveType") {
              return (
                <td className="captilizeData" key={heading.id + "-data"}>
                  {lineItem.item.details[heading.id]}
                </td>
              );
            } else return <td key={heading.id + "-data"}>{lineItem.item.details[heading.id]}</td>;
          })}
        </tr>
      );
    });
  }

  calculatePrice(item) {
    let totalPrice = 0;
    const componentData = ["fabric", "driveMechanism", "baseBars", "kassette", "sideChannel"];
    componentData.forEach((component) => {
      if (item.components && item.components[component]) {
        if (component === "fabric") {
          totalPrice += parseFloat(item.components[component].baseCost.cost);
        } else if (item.components[component].details.costPerMetre) {
          totalPrice += item.components[component].details.costPerMetre * (item.details.width / 1000);
        } else if (item.components[component].details.pricePerMetre) {
          totalPrice += item.components[component].details.pricePerMetre * (item.details.width / 1000);
        } else if (item.components[component].details.cost) {
          totalPrice += parseFloat(item.components[component].details.cost);
        }
      }
    });
    return totalPrice;
  }

  handleSorting(lineItemCollection, sortingKey) {
    let sortedCollection = lineItemCollection;
    for (let i = 0; i < sortedCollection.length; i++) {
      for (let j = i; j < sortedCollection.length; j++) {
        if (this.getSortingMethod(sortingKey, sortedCollection[i].item, sortedCollection[j].item)) {
          const temp = sortedCollection[i];
          sortedCollection[i] = sortedCollection[j];
          sortedCollection[j] = temp;
        }
      }
    }
    return sortedCollection;
  }

  getSortingMethod(sortingKey, item1, item2) {
    if (sortingKey === "itemNo") return item1.details.itemNo > item2.details.itemNo;
    if (sortingKey === "productCode") return item1.details.productCode > item2.details.productCode;
    if (sortingKey === "location") return item1.details.location > item2.details.location;
  }

  render() {
    if (!this.state.idCache) return <div className="loader">LOADING...</div>;
    else
      return (
        <div className="purchaseOrderDocument" id="Document">
          <div className="doc-box">
            <LetterHead />
            <table cellPadding="0" cellSpacing="0">
              <tbody>
                <tr className="heading">
                  <td colSpan="20">
                    <span className="heading-one">PURCHASE ORDER</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <JobHeader
            type="Purchase Order"
            deliveryAddress={this.props.deliveryAddress}
            deliveryPerson={
              this.props.options.deliveryAddressType === "customer"
                ? this.props.customerDetails.customer.details.name
                : "Sam Kodsi"
            }
            customDelivery={
              this.props.options.deliveryAddressType === "custom" ? this.props.options.customAddress : undefined
            }
            manufacturerName={this.props.manufacturer.details.name}
            discountNumber={this.props.options.discountNumber}
            account={this.props.options.account}
            customer={this.props.customerDetails.customer}
            jobBranch={this.props.customerDetails.jobBranch}
            branchContact={this.props.customerDetails.branchContact}
            docNumber={`${this.props.docNumber}-${this.props.index + 1}`}
            dateRequired={this.props.options.dateRequired}
          />
          <div className="doc-box">
            <table cellPadding="0" cellSpacing="0">
              <tbody>
                {this.getHeadingRow()}
                {this.constructFromResponse()}
              </tbody>
            </table>
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return { AlgoliaKey: state.key };
};

export default connect(mapStateToProps)(PurchaseOrderDocument);
