import {
  SELECT_STAFF,
  SELECT_ADDRESS,
  UPDATE_STAFF_DETAILS,
  UPDATE_ADDRESS_DETAILS
} from "../actions/staffActions";

// Initial state
const initialState = {
  staff: {
    id: null,
    details: {
      name: "",
      regNo: "",
      email: "",
      website: "",
      phone: "",
      mobile: "",
      fax: ""
    }
  },

  address: {
    id: "",
    details: {
      name: "",
      addressType: "",
      address: {
        streetNumber: "",
        streetName: "",
        city: "",
        postcode: "",
        state: "",
        country: ""
      }
    }
  }
};

// Staffs Reducer
export default function staffReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_STAFF:
      return {
        ...state,
        staff: {
          ...state.staff,
          id: action.id
        }
      };

    case SELECT_ADDRESS:
      return {
        ...state,
        address: {
          ...state.address,
          id: action.id
        }
      };

    case UPDATE_STAFF_DETAILS:
      return {
        ...state,
        staff: {
          ...state.staff,
          details: action.details
        }
      };

    case UPDATE_ADDRESS_DETAILS:
      return {
        ...state,
        address: {
          ...state.address,
          details: action.details
        }
      };

    default:
      return state;
  }
}
