import React, { Fragment } from "react";
import "./Toast.css";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, Zoom } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import { useSelector, useDispatch } from "react-redux";
import { finishToast } from "../../../actions/toastActions";

const ToastManager = () => {
  const dispatch = useDispatch();
  const toastCollection = useSelector(state => state.toast.toastCollection);

  const RemoveToast = toastId => {
    dispatch(finishToast(toastId));
  };

  if (toastCollection.length > 0)
    return (
      <Fragment>
        {toastCollection.map((toast, index) => (
          <Toast
            position={toast.position}
            removeToast={RemoveToast}
            key={index}
            index={index}
            type={toast.type}
            value={toast.message}
          />
        ))}
      </Fragment>
    );
  else return null;
};

const Toast = ({ type, value, index, removeToast, position }) => {
  const [active, setActive] = React.useState(true);
  const [timeout] = React.useState(setTimeout(() => handleToastClose(), 3000));

  const handleToastClose = () => {
    setActive(false);
    clearTimeout(timeout);
  };

  const getToastStyle = () => {
    if (type === "error") return "#f44336";
    else if (type === "warning") return "#ff9800";
    else if (type === "info") return "#2196f3";
    else if (type === "success") return "#4caf50";
    else return "rgb(50, 50, 50)";
  };

  const getToastAlign = () => {
    if (position === "left") return "flex-start";
    if (position === "center") return "center";
    if (position === "right") return "flex-end";
  };

  const getToastIcon = () => {
    const className = "ToastIcon";
    if (type === "error") return <ErrorOutlineIcon className={className} />;
    else if (type === "warning") return <ReportProblemOutlinedIcon className={className} />;
    else if (type === "info") return <InfoOutlinedIcon className={className} />;
    else if (type === "success") return <CheckCircleOutlineOutlinedIcon className={className} />;
    else return "";
  };

  return (
    <Zoom
      onExited={() => {
        if (!active) removeToast(index);
      }}
      in={active}
    >
      <div
        style={{ backgroundColor: getToastStyle(), alignSelf: getToastAlign() }}
        className="customToast"
      >
        {getToastIcon()}
        {value}
        <IconButton onClick={handleToastClose} id="toastCloseButton">
          <CloseIcon style={{}} />
        </IconButton>
      </div>
    </Zoom>
  );
};

export default ToastManager;
