import React, { Component } from "react";
import PopUp from "../Generic/PopUpDialog/PopUp";

export default class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDisplayMessage: "",
      refresh: false,
      retry: false,
      goBack: this.props.goBack | false,
    };
    if (this.props.error !== undefined) {
      if (this.props.error.code === "NotAuthorizedException") {
        this.state.errorDisplayMessage = "Wrong Username or Password";
        if (this.props.error.message === "Password attempts exceeded")
          this.state.errorDisplayMessage = "Maximum Attempts Exceeded";
      }
      if (this.props.error.code === "LimitExceededException")
        this.state.errorDisplayMessage = "Password Changed Too Frequently, Try Again Later";
      if (this.props.error.code === "InvalidParameterException")
        this.state.errorDisplayMessage =
          "Invalid Password. \n Password must contain atleast one Uppercase, Special Character and Number";
      if (this.props.error.code === "NetworkError") {
        this.state.errorDisplayMessage = "Error, Disconnected from Internet";
        this.state.retry = true;
      }
      if (this.props.error.code === "UserNotFoundException")
        this.state.errorDisplayMessage = "Error, User Email doesn't exist";
      if (this.props.error.message === "Network Error") {
        this.state.errorDisplayMessage = "Error Disconnected From internet";
        this.state.retry = true;
      }
      if (this.props.error.message === "Request failed with status code 404") {
        this.state.errorDisplayMessage = "Error, Could Not Find What you were looking for";
        this.state.goBack = true;
      }
      if (this.props.error.message === "Request failed with status code 400") {
        this.state.errorDisplayMessage = "Something went wrong. Please try again later.";
        this.state.goBack = true;
      }

      if (this.props.error.Message) {
        this.state.errorDisplayMessage = this.props.error.Message;
      }

      if (this.props.error.response) {
        if (this.props.error.response.data.Error === "ProjectError") {
          this.state.errorDisplayMessage = "Error Creating Project. Please try again later";
          this.state.retry = true;
        } else if (
          this.props.error.response.data.Error === "ProjectJobError" &&
          this.props.error.response.data.Message === "Not found"
        ) {
          this.state.errorDisplayMessage = "Sorry, We Couldn't Find that Job";
        } else if (this.props.error.response.data.message === "Internal server error") {
          this.state.errorDisplayMessage = `Something went wrong. Please try again later. ErrorCode: ${this.props.error.response.status}`;
          this.state.goBack = true;
        } else {
          this.state.errorDisplayMessage = this.props.error.response.data.Message;
          console.error(this.props.error.response);
        }
      }
    } else {
      this.state.errorDisplayMessage = "Undefined Error Occured, Please Try Again";
    }

    if (
      this.props.error.Error === "ProjectJobItemError" &&
      this.props.error.Message === "Not found"
    ) {
      this.state.errorDisplayMessage = "There are no job items yet.";
    }

    if (this.props.error.message === "missing customer for quote") {
      this.state.errorDisplayMessage = "Please assign a customer and/or branch for this job.";
    }
  }
  render() {
    return (
      <div className="errorDisplayMessage" style={{ color: "red" }}>
        <PopUp
          body={this.state.errorDisplayMessage}
          header="Error!"
          button="close"
          buttoncolour="red"
          headerColour="red"
          button2={
            this.state.refresh
              ? "Refresh"
              : this.state.retry
              ? "Retry"
              : this.state.goBack
              ? "Go Back"
              : undefined
          }
          buttonClickHandler={this.props.buttonClickHandler}
          button2ClickHandler={
            this.state.retry
              ? this.props.button2ClickHandler
              : this.state.goBack
              ? this.props.goBackClickHandler
              : this.state.refresh
              ? () => {
                  window.location.reload();
                }
              : undefined
          }
        />
      </div>
    );
  }
}
