import React, { Component } from "react";
import { connect } from "react-redux";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { branchFields } from "../fields";
import { API } from "aws-amplify";
import { setRefresh } from "../../../actions/algoliaActions";
import { setLoaderState, setAlgoliaLoaderState } from "../../../actions/loaderActions";
import { updateBranchDetails, selectBranch } from "../../../actions/manufacturersActions";

class AddBranch extends Component {
  manufacturerId = this.props.match.params.manufacturerId;

  async APIMethod(formData) {
    this.props.dispatch(setAlgoliaLoaderState(true));
    try {
      this.props.dispatch(setLoaderState(true));

      const response = await API.post(
        "telishadeAPI",
        "/manufacturer/" + this.manufacturerId + "/branch",
        {
          body: {
            details: {
              name: formData.name ? formData.name : "Untitled Address",
              addressType: formData.addressType,
              address: {
                streetNumber: formData.streetNumber,
                streetName: formData.streetName,
                city: formData.city,
                postcode: formData.postcode,
                state: formData.state,
                country: formData.country
              }
            }
          }
        }
      );
      await this.props.dispatch(updateBranchDetails(response.details));
      await this.props.dispatch(selectBranch(response.id));
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.history.push(`/manufacturers/${response.manufacturerId}/branches/${response.id}`);
    } catch (error) {
      this.props.dispatch(setAlgoliaLoaderState(false));
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setLoaderState(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  }

  render() {
    const fields = [...branchFields];

    return (
      <PopUpForm
        fields={fields}
        header="Add New Branch"
        button="ACCEPT"
        buttoncolour="green"
        button2="CANCEL"
        button2colour="red"
        buttonClickHandler={undefined}
        button2ClickHandler={undefined}
        apiMethod={details => this.APIMethod(details)}
      />
    );
  }
}
const mapStateToProps = state => {
  return state.manufacturers.branch;
};

export default connect(mapStateToProps)(AddBranch);
