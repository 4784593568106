import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";
import { API } from "aws-amplify";
import { setRefresh } from "../../../actions/algoliaActions";
import PopUp from "../../Generic/PopUpDialog/PopUp";
import config from "../../../config";
class AddProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        {
          id: "name",
          label: "Project Name",
          type: "text",
          tooltip: "Project Name",
          required: true,
          value: ""
        },
        {
          id: "customerId",
          label: "Customer ID",
          type: "AlgoliaSelect",
          index: `${config.algolia.prefix}customers_name_asc`,
          filters: "isDeleted:false"
        },
        {
          id: "customerBranchId",
          label: "Customer Branch ID",
          type: "AlgoliaSelect",
          index: `${config.algolia.prefix}customers_branches_name_asc`,
          filters: "isDeleted:false",
          dependency: "customerId",
          dependencyId: "customerId"
        },
        {
          id: "customerBranchContactId",
          label: "Customer Contact",
          type: "AlgoliaSelect",
          index: `${config.algolia.prefix}customers_branches_contacts_name_asc`,
          filters: "isDeleted:false",
          dependency: "customerBranchId",
          dependencyId: "branchId"
        },
        {
          id: "startDate",
          label: "Start Date",
          type: "date"
        },
        {
          id: "estimatedDelivery",
          label: "Estimated Delivery",
          type: "date"
        },
        {
          id: "stage",
          label: "Project Stage",
          type: "select",
          options: [
            { name: "QS Budget", value: "qsBudget" },
            { name: "Pre Award Tendering", value: "preAwardTendering" },
            { name: "Builder Awarded Tendering", value: "builderAwardedTendering" },
            { name: "Project Management", value: "projectManagement" },
            { name: "Practical Completion", value: "practicalCompleteion" },
            { name: "Completed", value: "completed" }
          ],
          value: "qsBudget"
        },
        {
          id: "status",
          label: "Project Status",
          type: "select",
          options: [
            { name: "Won", value: "won" },
            { name: "Lost", value: "lost" },
            { name: "Documentation", value: "documentation" },
            { name: "Review Drawings", value: "reviewDrawings" },
            { name: "Quote ", value: "quote" }
          ],
          value: "quote"
        },
        {
          id: "quoteDate",
          label: "Quote Date",
          type: "date"
        },
        {
          id: "staffId",
          label: "Staff ID",
          type: "AlgoliaSelect",
          index: `${config.algolia.prefix}staff_name_asc`,
          filters: "isDeleted:false"
        },
        {
          id: "discount",
          label: "Discount %",
          type: "number"
        },
        {
          id: "terms",
          label: "Terms",
          type: "TextArea"
        }
      ],
      step: 1,
      projectType: "commercial"
    };
  }

  onProjectTypeChange = event => {
    this.setState({ projectType: event.target.value });
  };

  handleNextClick = () => {
    this.setState({ step: 2 });
  };

  APIMethod = async formData => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));

      // Re-map form data
      formData.options = {
        discount: { retail: { percentage: formData.discount } },
        terms: formData.terms
      };
      delete formData.discount;
      delete formData.terms;

      if (formData.customerBranchContactId === undefined) {
        formData.customerBranchContactId = "";
      }

      const response = await API.post("telishadeAPI", "/project/" + this.state.projectType, {
        body: { details: formData }
      });
      this.props.history.push("/projects/" + response.id);
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return this.state.step === 1 ? (
      <PopUp
        header="Edit Project"
        body={
          <Fragment>
            <label className="datefieldLabel">Project Type</label>
            <select
              value={this.state.projectType}
              onChange={this.onProjectTypeChange}
              className="browser-default custom-select form-control"
            >
              <option value="commercial" label="Commercial" />
              <option value="domestic" label="Domestic" />
            </select>
          </Fragment>
        }
        button="Next"
        buttoncolour="blue"
        buttonClickHandler={this.handleNextClick}
        button2="Cancel"
        button2colour="red"
      />
    ) : this.state.step === 2 ? (
      <PopUpForm
        fields={this.state.fields}
        apiMethod={this.APIMethod}
        updateMethod={() => {}}
        header="Add New Project"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    ) : (
      "Hello?"
    );
  }
}

export default withRouter(connect()(AddProject));
