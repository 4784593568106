import React, { Component, Fragment } from "react";
import { numberWithCommas } from "../../../../../../../utilities/utils";

class CurtainsTotals extends Component {
  constructTotals(quotesArray) {
    let totalCost = 0;
    let totalCostNoDiscount = 0;
    quotesArray.forEach(jobQuote => {
      jobQuote.quote.forEach(quoteItem => {
        totalCost += this.calculateCurtainItemTotal(quoteItem, 0, jobQuote.discount);
        totalCostNoDiscount += this.calculateCurtainItemTotal(quoteItem);
      });
    });
    const discountTotal = totalCostNoDiscount - totalCost;
    const gstTotal = totalCost + (totalCost / 100) * 10;
    return (
      <Fragment>
        <tr className="details">
          <td className="padding-cell" />
          <td>Total Costs of Items Ex.GST</td>
          <td>{`$${numberWithCommas(totalCostNoDiscount.toFixed(2))}`}</td>
        </tr>
        {Math.round((totalCostNoDiscount - totalCost) * 10) / 10 !== 0 ? (
          <Fragment>
            <tr className="details">
              <td className="padding-cell" />
              <td>{`Discount`}</td>
              <td className="border-on-bottom">{`-$${numberWithCommas(
                discountTotal.toFixed(2)
              )}`}</td>
            </tr>
            <tr className="details">
              <td className="padding-cell" />
              <td>{`Subtotal EX.GST`}</td>
              <td className="border-on-bottom">{`$${numberWithCommas(totalCost.toFixed(2))}`}</td>
            </tr>
          </Fragment>
        ) : (
          <Fragment />
        )}
        <tr className="details">
          <td className="padding-cell" />
          <td>GST</td>
          <td className="border-on-top">{`$${numberWithCommas(
            Math.ceil((totalCost / 100) * 10).toFixed(2)
          )}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Total incl. GST</td>
          <td className="border-on-top">{`$${numberWithCommas(
            Math.ceil(gstTotal.toFixed(2)).toFixed(2)
          )}`}</td>
        </tr>
      </Fragment>
    );
  }

  constructMargins(quotesArray) {
    let WholesaleTotal = 0;
    let RetailTotal = 0;
    quotesArray.forEach(jobQuote => {
      jobQuote.quote.forEach(quoteItem => {
        WholesaleTotal += this.calculateCurtainItemTotalMargin(
          quoteItem,
          0,
          jobQuote.discount,
          false
        );
        RetailTotal += this.calculateCurtainItemTotalMargin(
          quoteItem,
          this.props.discount,
          jobQuote.discount,
          true
        );
      });
    });
    const GrossProfit = RetailTotal - WholesaleTotal;
    const ProfitMargin = (GrossProfit / RetailTotal) * 100;
    return (
      <Fragment>
        <tr className="details">
          <td className="padding-cell" />
          <td>Wholesale Total</td>
          <td>{`$${numberWithCommas(WholesaleTotal.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Retail Total</td>
          <td>{`$${numberWithCommas(RetailTotal.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Gross Profit</td>
          <td>{`$${numberWithCommas(GrossProfit.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Profit Margin</td>
          <td>{`${ProfitMargin.toFixed(2)}%`}</td>
        </tr>
      </Fragment>
    );
  }

  calculateCurtainItemTotalMargin(
    quoteItem,
    discount = 0,
    discountObject,
    isRetail = this.props.isRetail
  ) {
    if (isNaN(discount)) {
      return this.calculateCurtainItemTotalMargin(quoteItem, 0, discountObject, isRetail);
    }
    let totalforItem = 0;
    totalforItem += isRetail ? quoteItem.costInfo.markedUpBasePrice : quoteItem.costInfo.basePrice;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach(componentDiscount => {
        if (componentDiscount.id === quoteItem.description.allDetails.fabricId) {
          totalforItem =
            (totalforItem / 100) *
            (100 -
              (isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }
    totalforItem += this.calculateExtraMargin("bumph", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtraMargin("driveMechanism", quoteItem, discountObject, isRetail)
      .total;
    totalforItem += this.calculateExtraMargin("leadWeight", quoteItem, discountObject, isRetail)
      .total;
    totalforItem += this.calculateExtraMargin("lining", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtraMargin("pelmet", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtraMargin("accessory", quoteItem, discountObject, isRetail)
      .total;
    totalforItem += this.calculateServiceMargin(quoteItem, discountObject, isRetail).total;
    totalforItem += isRetail
      ? this.getTrackDiscountValueMargin(
          quoteItem.costInfo.markedUpTrackTotalCost,
          discountObject,
          quoteItem,
          isRetail
        )
      : this.getTrackDiscountValueMargin(
          quoteItem.costInfo.trackTotalCost,
          discountObject,
          quoteItem,
          isRetail
        );
    totalforItem = isRetail ? (totalforItem / 100) * (100 - discount) : totalforItem;
    return totalforItem;
  }

  getTrackDiscountValueMargin(trackCost, discountObject, quoteItem, isRetail) {
    let totalforTrack = trackCost;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach(componentDiscount => {
        if (componentDiscount.id === quoteItem.description.allDetails.trackId) {
          totalforTrack =
            (totalforTrack / 100) *
            (100 -
              (isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }
    return totalforTrack;
  }

  calculateExtraMargin(extraKey, item, discountObject, isRetail = this.props.isRetail) {
    let totalPrice = 0;
    let totalQty = 0;
    if (item.extras[extraKey]) {
      if (item.extras[extraKey].details.pricePerMetre) {
        const totalPricePerMetre =
          parseFloat(item.extras[extraKey].details.pricePerMetre) *
          (parseFloat(item.description.width) / 1000);
        const totalPricePerMetreMarkup =
          (totalPricePerMetre / 100) * (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += isRetail ? totalPricePerMetreMarkup : totalPricePerMetre;
        totalQty++;
      } else if (item.extras[extraKey].details.cost) {
        const totalCost =
          (parseFloat(item.extras[extraKey].details.cost) / 100) *
          (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += isRetail ? totalCost : parseFloat(item.extras[extraKey].details.cost);
        totalQty++;
      }
      if (discountObject && discountObject.components.length > 0) {
        discountObject.components.forEach(componentDiscount => {
          if (item.extras[extraKey].id === componentDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (isRetail
                  ? componentDiscount.retail.percentage
                  : componentDiscount.wholesale.percentage));
          }
        });
      }
    }
    return { total: totalPrice, qty: totalQty };
  }

  calculateServiceMargin(item, discountObject, isRetail = this.props.isRetail) {
    let totalPrice = 0;
    let totalQty = 0;
    item.extras.services.forEach(service => {
      const totalWithMarkup =
        (parseFloat(service.details.cost) / 100) * (100 + parseFloat(service.details.markup));
      totalPrice += isRetail ? totalWithMarkup : parseFloat(service.details.cost);
      totalQty++;
      if (discountObject && discountObject.servicesAndFees.length > 0) {
        discountObject.servicesAndFees.forEach(serviceDiscount => {
          if (service.id === serviceDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (isRetail
                  ? serviceDiscount.retail.percentage
                  : serviceDiscount.wholesale.percentage));
          }
        });
      }
    });
    return { total: totalPrice, qty: totalQty };
  }

  calculateCurtainItemTotal(quoteItem, discount = 0, discountObject) {
    if (isNaN(discount)) {
      return this.calculateCurtainItemTotal(quoteItem, 0, discountObject);
    }
    let totalforItem = 0;
    totalforItem += this.props.isRetail
      ? quoteItem.costInfo.markedUpBasePrice
      : quoteItem.costInfo.basePrice;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach(componentDiscount => {
        if (componentDiscount.id === quoteItem.description.allDetails.fabricId) {
          totalforItem =
            (totalforItem / 100) *
            (100 -
              (this.props.isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }
    totalforItem += this.calculateExtra("bumph", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("driveMechanism", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("leadWeight", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("lining", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("pelmet", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("accessory", quoteItem, discountObject).total;
    totalforItem += this.calculateService(quoteItem, discountObject).total;
    totalforItem += this.props.isRetail
      ? this.getTrackDiscountValue(
          quoteItem.costInfo.markedUpTrackTotalCost,
          discountObject,
          quoteItem
        )
      : this.getTrackDiscountValue(quoteItem.costInfo.trackTotalCost, discountObject, quoteItem);
    totalforItem = this.props.isRetail ? (totalforItem / 100) * (100 - discount) : totalforItem;
    return totalforItem;
  }

  getTrackDiscountValue(trackCost, discountObject, quoteItem) {
    let totalforTrack = trackCost;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach(componentDiscount => {
        if (componentDiscount.id === quoteItem.description.allDetails.trackId) {
          totalforTrack =
            (totalforTrack / 100) *
            (100 -
              (this.props.isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }
    return totalforTrack;
  }

  calculateExtra(extraKey, item, discountObject) {
    let totalPrice = 0;
    let totalQty = 0;
    if (item.extras[extraKey]) {
      if (item.extras[extraKey].details.pricePerMetre) {
        const totalPricePerMetre =
          parseFloat(item.extras[extraKey].details.pricePerMetre) *
          (parseFloat(item.description.width) / 1000);
        const totalPricePerMetreMarkup =
          (totalPricePerMetre / 100) * (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail ? totalPricePerMetreMarkup : totalPricePerMetre;
        totalQty++;
      } else if (item.extras[extraKey].details.cost) {
        const totalCost =
          (parseFloat(item.extras[extraKey].details.cost) / 100) *
          (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail
          ? totalCost
          : parseFloat(item.extras[extraKey].details.cost);
        totalQty++;
      }
      if (discountObject && discountObject.components.length > 0) {
        discountObject.components.forEach(componentDiscount => {
          if (item.extras[extraKey].id === componentDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (this.props.isRetail
                  ? componentDiscount.retail.percentage
                  : componentDiscount.wholesale.percentage));
          }
        });
      }
    }

    return { total: totalPrice, qty: totalQty };
  }

  calculateService(item, discountObject) {
    let totalPrice = 0;
    let totalQty = 0;
    item.extras.services.forEach(service => {
      const totalWithMarkup =
        (parseFloat(service.details.cost) / 100) * (100 + parseFloat(service.details.markup));
      totalPrice += this.props.isRetail ? totalWithMarkup : parseFloat(service.details.cost);
      totalQty++;
      if (discountObject && discountObject.servicesAndFees.length > 0) {
        discountObject.servicesAndFees.forEach(serviceDiscount => {
          if (service.id === serviceDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (this.props.isRetail
                  ? serviceDiscount.retail.percentage
                  : serviceDiscount.wholesale.percentage));
          }
        });
      }
    });
    return { total: totalPrice, qty: totalQty };
  }

  render() {
    return (
      <div className="doc-box">
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="heading">
              <td className="padding-cell" />
              <td colSpan="2">
                <span className="heading-one">QUOTE TOTALS</span>
              </td>
            </tr>
            {this.constructTotals(this.props.response)}
          </tbody>
        </table>
        <table className="Margins" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="comments">
              <td colSpan="3">
                Please Note this Information will not be Shown on the Printed Quote.
              </td>
            </tr>
            <tr className="heading">
              <td className="padding-cell"></td>
              <td colSpan="4">
                <span className="heading-one">Margins</span>
              </td>
            </tr>
            {this.constructMargins(this.props.response)}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CurtainsTotals;
