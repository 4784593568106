import React, { Component } from "react";
import { withRouter, Redirect, Switch } from "react-router-dom";
import "./Notes.css";

class JobNotes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedJob: this.props.match.params.JobNo,
      noteField: "",
      notes: [],
      noteNum: 0
    };
  }
  render() {
    return (
      <Switch>
        <Redirect to={"/projects/" + this.props.match.params.id + "/notes"} />
      </Switch>
    );
  }

  // LEAVE THIS IN PLACE AS IT WILL BE USEFUL LATER
  // componentDidMount() {
  //   this.scrollToBottom();
  // }

  // getSnapshotBeforeUpdate(prevProps, prevState) {
  //   // Are we adding new items to the list?
  //   if (prevState.notes.length < this.state.notes.length) {
  //     return 1;
  //   }
  //   return null;
  // }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (snapshot !== null) this.scrollToBottom();
  // }

  // scrollToBottom = () => {
  //   this.messagesEnd.scrollIntoView({ behavior: "smooth" });
  // };

  // handleAddNote = () => {
  //   if (this.state.noteField.trim() !== "") {
  //     this.setState({ noteNum: this.state.noteNum + 1 });
  //     let temp = this.state.notes.concat({
  //       note: this.state.noteField,
  //       id: this.state.noteNum,
  //       timeStamp: new Date(Date.now()),
  //       editing: false
  //     });
  //     this.setState({ notes: temp, noteField: "" });
  //   }
  // };

  // handleNoteRemove = index => {
  //   let temp = this.state.notes;
  //   temp.splice(index.id, 1);
  //   temp.forEach(function(i) {
  //     if (i.id >= index.id) {
  //       i.id = i.id - 1;
  //     }
  //   });
  //   this.setState({ notes: temp });
  //   this.setState({ noteNum: this.state.noteNum - 1 });
  // };

  // handleInputChange = event => {
  //   this.setState({ noteField: event.target.value });
  // };

  // handleNoteChange = (event, index) => {
  //   let temp = this.state.notes;
  //   temp[index.id].note = event.target.value;
  //   this.setState({ notes: temp });
  // };

  // handleEditNote = index => {
  //   let temp = this.state.notes;
  //   for (let i = 0; i < temp.length; i++) {
  //     if (temp[i] !== index) {
  //       temp[i].editing = false;
  //     }
  //   }
  //   if (temp[index.id] !== undefined)
  //     temp[index.id].editing = !temp[index.id].editing;
  //   this.setState({ notes: temp });
  // };

  // handleJobSelectChange = event => {
  //   this.props.history.push(
  //     "/projects/" +
  //       this.props.match.params.id +
  //       "/" +
  //       event.target.value +
  //       "/notes"
  //   );
  //   this.setState({ SelectedJob: event.target.value });
  //   this.setState({
  //     noteField: "",
  //     notes: []
  //   });
  // };

  // render() {
  //   return (
  //     <div className="worksheetContainer">
  //       <div className="worksheetHeader">
  //         <select onChange={this.handleJobSelectChange}>
  //           <option className="currentJob" value={this.state.SelectedJob}>
  //             {findNameforID(this.state.SelectedJob)}
  //           </option>
  //           {ListyBoi.map(job =>
  //             job.id === this.state.SelectedJob ? (
  //               ""
  //             ) : (
  //               <option key={job.id} value={job.id}>
  //                 {job.name}
  //               </option>
  //             )
  //           )}
  //         </select>
  //       </div>
  //       <div className="jobNotes">
  //         <div className="notesDisplay">
  //           {this.state.notes.map(i => {
  //             if (i.editing === false) {
  //               return (
  //                 <div key={i.id} className="noteWrapper">
  //                   <div key={i.timeStamp} className="timeStamp">
  //                     {i.timeStamp.toLocaleString("en-US", {
  //                       timeZone: "Australia/Melbourne",
  //                       year: "2-digit",
  //                       month: "2-digit",
  //                       day: "2-digit",
  //                       hour: "2-digit",
  //                       minute: "2-digit"
  //                     })}
  //                   </div>
  //                   <div key={i.id} className="noteDisplay">
  //                     <p>{i.note}</p>
  //                     <MDBBtn
  //                       color="blue"
  //                       id="editBtn"
  //                       onClick={() => this.handleEditNote(i)}
  //                     >
  //                       Edit
  //                     </MDBBtn>
  //                     <MDBBtn
  //                       color="red"
  //                       id="deleteBtn"
  //                       onClick={() => this.handleNoteRemove(i)}
  //                     >
  //                       Remove
  //                     </MDBBtn>
  //                   </div>
  //                 </div>
  //               );
  //             }
  //             if (i.editing === true) {
  //               return (
  //                 <div key={i.id} className="noteWrapper">
  //                   <div key={i.timeStamp} className="timeStamp">
  //                     {i.timeStamp.toLocaleString("en-US", {
  //                       timeZone: "Australia/Melbourne",
  //                       year: "2-digit",
  //                       month: "2-digit",
  //                       day: "2-digit",
  //                       hour: "2-digit",
  //                       minute: "2-digit"
  //                     })}
  //                   </div>
  //                   <div key={i.id} className="noteDisplay">
  //                     <p>
  //                       <textarea
  //                         value={i.note}
  //                         onChange={event => this.handleNoteChange(event, i)}
  //                       />
  //                     </p>
  //                     <MDBBtn
  //                       color="green"
  //                       id="editBtn"
  //                       onClick={() => this.handleEditNote(i)}
  //                     >
  //                       Accept
  //                     </MDBBtn>
  //                   </div>
  //                 </div>
  //               );
  //             } else return "";
  //           })}
  //           <div
  //             style={{ clear: "both" }}
  //             ref={el => {
  //               this.messagesEnd = el;
  //             }}
  //           />
  //         </div>

  //         <div className="notesEntry">
  //           <textarea
  //             type="text"
  //             placeholder="Type Here..."
  //             value={this.state.noteField}
  //             onChange={this.handleInputChange}
  //             id="noteField"
  //           />
  //           <MDBBtn
  //             color="green"
  //             id="submitNoteBtn"
  //             onClick={this.handleAddNote}
  //           >
  //             New Note
  //           </MDBBtn>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }
}

export default withRouter(JobNotes);
