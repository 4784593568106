import React, { Component } from "react";
import algoliasearch from "algoliasearch";
import { connect } from "react-redux";
import { Switch, Redirect } from "react-router-dom";
import config from "../../../config";

class ProjectWorksheet extends Component {
  constructor(props) {
    super(props);
    this.getJobsForProject();
    this.state = {};
  }

  async getJobsForProject() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getJobsForProject();
      }, 500);
    } else {
      try {
        const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
        const index = client.initIndex(`${config.algolia.prefix}projects_jobs_name_asc`);
        const response = await index.search({
          query: "",
          filters: "isDeleted:false AND projectId:" + this.props.match.params.id
        });
        this.setState({ jobs: response.hits });
      } catch (e) {
        console.error(e);
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.jobs !== undefined ? (
          this.state.jobs[0] !== undefined ? (
            <Switch>
              <Redirect to={"/projects/:id/" + this.state.jobs[0].objectID + "/worksheet"} />
            </Switch>
          ) : (
            <Switch>
              <Redirect to={"/projects/:id"} />
            </Switch>
          )
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { AlgoliaKey: state.key };
};

export default connect(mapStateToProps)(ProjectWorksheet);
