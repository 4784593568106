//Action Type
export const SET_REFRESH = "ALGOLIA::SET_REFRESH";

//Action Creators
export function setRefresh(bool) {
  return {
    type: SET_REFRESH,
    refresh: bool
  };
}
