import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LetterHead from "../../TableComponents/LetterHead";
import ProjectHeader from "../ProjectHeader";
import Heading from "../../TableComponents/Heading";
import SubHeading from "../../TableComponents/SubHeading";

class MarginReport extends Component {
  title = "PROJECT GROSS PROFIT MARGIN REPORT";
  tableColumns = [
    "Job",
    "Wholesale Cost",
    "Discount",
    "Discount %",
    "Wholesale inc Discount",
    "Markup %",
    "Retail Cost",
    "Discount",
    "Discount %",
    "Retail Cost inc Discount",
    "Gross Profit",
    "Margin"
  ];

  totalsColumns = [
    "",
    "Wholesale Cost",
    "Discount",
    "Discount %",
    "Wholesale inc Discount",
    "Markup %",
    "Retail Cost",
    "Discount",
    "Discount %",
    "Retail Cost inc Discount",
    "Gross Profit",
    "Margin"
  ];

  constructor(props) {
    super(props);

    this.state = {
      items: [
        {
          job: "Level 1",
          wholesaleCost: 1467.0,
          wholesaleDiscount: -118.4,
          wholesaleDiscountPercent: 8.07,
          wholesaleIncDisc: 1348.6,
          wholesaleMarkupPercent: 32.24,
          retailCost: 1940.0,
          retailDiscount: -192.5,
          retailDiscountPercent: 9.92,
          retailIncDisc: 1747.5,
          grossProfit: 398.9,
          margin: 22.83
        }
      ],

      totalsGross: {
        wholesaleCost: 1467.0,
        wholesaleDiscount: -118.4,
        wholesaleDiscountPercent: 8.07,
        wholesaleIncDisc: 1348.6,
        wholesaleMarkupPercent: 32.24,
        retailCost: 1940.0,
        retailDiscount: -192.5,
        retailDiscountPercent: 9.92,
        retailIncDisc: 1747.5,
        grossProfit: 398.9,
        margin: 22.83
      },

      totalsWithDiscount: {
        wholesaleCost: 1467.0,
        wholesaleDiscount: -118.4,
        wholesaleDiscountPercent: 8.07,
        wholesaleIncDisc: 1348.6,
        wholesaleMarkupPercent: 32.24,
        retailCost: 1940.0,
        retailDiscount: -192.5,
        retailDiscountPercent: 9.92,
        retailIncDisc: 1747.5,
        grossProfit: 398.9,
        margin: 22.83
      }
    };
  }

  formatCurrency = number => {
    if (Math.sign(number)) {
      // If pos, returns 1, if neg returns -1
      return `$${number.toFixed(2)}`;
    } else {
      return `-$${(number * -1).toFixed(2)}`;
    }
  };

  formatPercentage = number => {
    return `${number.toFixed(2)}%`;
  };

  render() {
    const totalsGross = this.state.totalsGross;
    const totalsWithDiscount = this.state.totalsWithDiscount;

    return (
      <div id="Document">
        <LetterHead />
        <ProjectHeader type="Margin Report" />
        <div className="doc-box">
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <Heading title={this.title} colSpan="12" />
              <SubHeading columnNames={this.totalsColumns} />
              {this.state.items.map((lineItem, key) => {
                return (
                  <tr className="details" key={key}>
                    <td>{lineItem.job}</td>
                    <td>{this.formatCurrency(lineItem.wholesaleCost)}</td>
                    <td>{this.formatCurrency(lineItem.wholesaleDiscount)}</td>
                    <td>{this.formatPercentage(lineItem.wholesaleDiscountPercent)}</td>
                    <td>{this.formatCurrency(lineItem.wholesaleIncDisc)}</td>
                    <td>{this.formatPercentage(lineItem.wholesaleMarkupPercent)}</td>
                    <td>{this.formatCurrency(lineItem.retailCost)}</td>
                    <td>{this.formatCurrency(lineItem.retailDiscount)}</td>
                    <td>{this.formatPercentage(lineItem.retailDiscountPercent)}</td>
                    <td>{this.formatCurrency(lineItem.retailIncDisc)}</td>
                    <td>{this.formatCurrency(lineItem.grossProfit)}</td>
                    <td>
                      <strong>{this.formatPercentage(lineItem.margin)}</strong>
                    </td>
                  </tr>
                );
              })}
              <tr className="margin-report-totals">
                <td>
                  <strong>Totals</strong>
                </td>
                <td>{this.formatCurrency(totalsGross.wholesaleCost)}</td>
                <td>{this.formatCurrency(totalsGross.wholesaleDiscount)}</td>
                <td>{this.formatPercentage(totalsGross.wholesaleDiscountPercent)}</td>
                <td>{this.formatCurrency(totalsGross.wholesaleIncDisc)}</td>
                <td>{this.formatPercentage(totalsGross.wholesaleMarkupPercent)}</td>
                <td>{this.formatCurrency(totalsGross.retailCost)}</td>
                <td>{this.formatCurrency(totalsGross.retailDiscount)}</td>
                <td>{this.formatPercentage(totalsGross.retailDiscountPercent)}</td>
                <td>{this.formatCurrency(totalsGross.retailIncDisc)}</td>
                <td>{this.formatCurrency(totalsGross.grossProfit)}</td>
                <td>
                  <strong>{this.formatPercentage(totalsGross.margin)}</strong>
                </td>
              </tr>
              <tr className="white-sub-heading">
                <td colSpan="17">WITH PROJECT DISCOUNT APPLIED</td>
              </tr>
              <SubHeading columnNames={this.totalsColumns} />
              <tr className="margin-report-totals">
                <td>
                  <strong>Totals</strong>
                </td>
                <td>{this.formatCurrency(totalsWithDiscount.wholesaleCost)}</td>
                <td>{this.formatCurrency(totalsWithDiscount.wholesaleDiscount)}</td>
                <td>{this.formatPercentage(totalsWithDiscount.wholesaleDiscountPercent)}</td>
                <td>{this.formatCurrency(totalsWithDiscount.wholesaleIncDisc)}</td>
                <td>{this.formatPercentage(totalsWithDiscount.wholesaleMarkupPercent)}</td>
                <td>{this.formatCurrency(totalsWithDiscount.retailCost)}</td>
                <td>{this.formatCurrency(totalsWithDiscount.retailDiscount)}</td>
                <td>{this.formatPercentage(totalsWithDiscount.retailDiscountPercent)}</td>
                <td>{this.formatCurrency(totalsWithDiscount.retailIncDisc)}</td>
                <td>{this.formatCurrency(totalsWithDiscount.grossProfit)}</td>
                <td>
                  <strong>{this.formatPercentage(totalsWithDiscount.margin)}</strong>
                </td>
              </tr>
              <tr className="comments">
                <td colSpan="12">
                  Please refer to each individual job Quote for additional detail.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default withRouter(MarginReport);
