import React, { Component, Fragment } from "react";
import { numberWithCommas } from "../../../../../../../utilities/utils";

class DetailedSchedule extends Component {
  calcBasePriceWithMarkUp(lineItem) {
    let result = parseFloat(lineItem.baseCost);
    if (lineItem.baseMarkup && lineItem.baseMarkup > 0) {
      if (this.props.isRetail) {
        const markupPercent = lineItem.baseMarkup / 100;
        result = lineItem.baseCost * (1 + markupPercent);
      }
    }
    return result;
  }

  calcPriceWithMarkUp(cost, markup) {
    if (!cost) {
      cost = 0;
    }
    let result = parseFloat(cost);
    if (this.props.isRetail) {
      if (parseFloat(markup) > 0) {
        const markupPercent = parseFloat(parseFloat(markup) / 100);
        result += parseFloat(cost) * markupPercent;
      }
    }
    return result;
  }

  showDetailedTotal(num) {
    return this.props.detailed ? "" : `$${numberWithCommas(num.toFixed(2))}`;
  }

  calcTotalBlindPrice(lineItem) {
    let result = this.calcPriceWithMarkUp(lineItem.baseCost, lineItem.baseMarkup);

    if (lineItem.extras.driveMechanism) {
      result += this.calcPriceWithMarkUp(
        lineItem.extras.driveMechanism.details.cost,
        lineItem.extras.driveMechanism.details.markup
      );
    }

    if (lineItem.extras.doubleBracket) {
      result += this.calcPriceWithMarkUp(
        lineItem.extras.doubleBracket.details.cost,
        lineItem.extras.doubleBracket.details.markup
      );
    }

    if (lineItem.extras.linking) {
      result += this.calcPriceWithMarkUp(
        lineItem.extras.linking.details.cost,
        lineItem.extras.linking.details.markup
      );
    }

    if (lineItem.extras.sideChannel) {
      // Copy Cost Per Metre x width into "price"
      if (lineItem.description && lineItem.description.width) {
        let price =
          parseFloat(lineItem.extras.sideChannel.details.costPerMetre) *
          (parseFloat(lineItem.description.width) / 1000);

        result += this.calcPriceWithMarkUp(price, lineItem.extras.sideChannel.details.markup);
      } else
        console.error(
          "Line Item " + lineItem.itemNo + " has a cost per metre, but no width is specified"
        );
    }

    if (lineItem.extras.kassette) {
      // Copy Cost Per Metre x width into "price"
      if (lineItem.description && lineItem.description.width) {
        let price =
          parseFloat(lineItem.extras.kassette.details.costPerMetre) *
          (parseFloat(lineItem.description.width) / 1000);

        result += this.calcPriceWithMarkUp(price, lineItem.extras.kassette.details.markup);
      } else
        console.error(
          "Line Item " + lineItem.itemNo + " has a cost per metre, but no width is specified"
        );
    }

    if (lineItem.extras.baseBar) {
      // Copy Cost Per Metre x width into "price"
      if (lineItem.description && lineItem.description.width) {
        let price =
          parseFloat(lineItem.extras.baseBar.details.costPerMetre) *
          (parseFloat(lineItem.description.width) / 1000);

        result += this.calcPriceWithMarkUp(price, lineItem.extras.baseBar.details.markup);
      } else
        console.error(
          "Line Item " + lineItem.itemNo + " has a cost per metre, but no width is specified"
        );
    }

    if (lineItem.extras.accessory) {
      result += this.calcPriceWithMarkUp(
        lineItem.extras.accessory.details.cost,
        lineItem.extras.accessory.details.markup
      );
    }

    if (lineItem.extras.services) {
      lineItem.extras.services.forEach((service, index) => {
        result += this.calcPriceWithMarkUp(service.details.cost, service.details.markup);
      });
    }

    return result;
  }

  calcCostPerMetre(costPerMetre, markup, width) {
    let result = 0;
    let cost = parseFloat(costPerMetre) * (parseFloat(width) / 1000);
    result = this.calcPriceWithMarkUp(cost, markup);
    return result;
  }

  constructFromResponse(quote) {
    let newResponse = quote;
    if (this.props.grouping !== "job") {
      newResponse = this.handleGrouping(this.props.grouping, quote);
    } else {
      newResponse = [{ name: "ordered by job / item number", quote: quote }];
    }

    const construct = newResponse.map((job) => {
      return this.constructJob(job);
    });
    return construct.map((job, jobKey) => (
      <Fragment key={jobKey}>
        {this.props.grouping !== "job" ? (
          <tr className="details jobNumber">
            <td style={{ textTransform: "capitalize" }}>{job.name}</td>
          </tr>
        ) : (
          <Fragment />
        )}
        {job.items.map((item, key) => (
          <Fragment key={key}>
            <tr className="details jobNumber" key={`Job:${jobKey}, Item:${key}`}>
              {this.props.grouping !== "job" ? <td></td> : <Fragment />}
              <td style={{ display: this.props.detailed ? "" : "None" }}>{item.item.itemNo}</td>
              <td>{item.qty}</td>
              <td>{item.location}</td>
              <td>{this.constructItemDescription(item.item)}</td>
              <td></td>
              <td>{}</td>
              <td>{}</td>
              <td></td>
              <td></td>
              <td>{numberWithCommas(this.showDetailedTotal(item.total))}</td>
            </tr>

            {this.props.detailed ? (
              <Fragment key={key}>
                <tr className="details">
                  {this.props.grouping !== "job" ? <td></td> : <Fragment />}
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Fabric</td>
                  <td>${numberWithCommas(item.extras.fabric.cost.toFixed(2))}</td>
                  <td>{numberWithCommas(item.extras.fabric.qty.toFixed(2))}LM</td>
                  <td>
                    {item.qty > 1 ? (item.extras.fabric.qty * item.qty).toFixed(2) + "LM" : ""}
                  </td>
                  <td>
                    {item.qty > 1 ? "$" + (item.extras.fabric.cost * item.qty).toFixed(2) : ""}
                  </td>
                  <td />
                </tr>

                {item.extras.components.map((component, key) => {
                  return (
                    <tr className="details" key={key}>
                      {this.props.grouping !== "job" ? <td></td> : <Fragment />}
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{component.name}</td>
                      <td>
                        {component.cost > 0
                          ? `$${numberWithCommas(component.cost.toFixed(2))}`
                          : "included"}
                      </td>
                      <td>{component.qty}</td>
                      <td>{item.qty > 1 ? component.qty * item.qty : ""}</td>
                      <td>
                        {item.qty > 1
                          ? component.cost * item.qty > 0
                            ? "$" + (component.cost * item.qty).toFixed(2)
                            : "included"
                          : ""}
                      </td>
                      <td />
                    </tr>
                  );
                })}

                {item.extras.services.map((service, key) => (
                  <tr className="details" key={key}>
                    {this.props.grouping !== "job" ? <td></td> : <Fragment />}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{service.name}</td>
                    <td>${numberWithCommas(service.cost.toFixed(2))}</td>
                    <td>{1}</td>
                    <td>{item.qty > 1 ? item.qty : ""}</td>
                    <td>{item.qty > 1 ? "$" + (service.cost * item.qty).toFixed(2) : ""}</td>
                    <td />
                  </tr>
                ))}
                <tr className="details jobNumber">
                  {this.props.grouping !== "job" ? <td></td> : <Fragment />}
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>${numberWithCommas(item.total.toFixed(2))}</td>
                </tr>
              </Fragment>
            ) : (
              <Fragment />
            )}
          </Fragment>
        ))}
      </Fragment>
    ));
  }

  handleGrouping(groupingKey, quote) {
    let newGroupedCollection = [];

    quote.forEach((item) => {
      let groupedFlag = false;
      newGroupedCollection.forEach((groupedItem) => {
        if (this.getGroupingData(groupingKey, item) === groupedItem.name) {
          groupedItem.quote.push(item);
          groupedFlag = true;
        }
      });
      if (!groupedFlag) {
        newGroupedCollection.push({
          name: this.getGroupingData(groupingKey, item),
          quote: [{ ...item }],
        });
      }
    });
    return newGroupedCollection;
  }

  constructJob(JobQuote) {
    let JobItemCollection = [];
    JobQuote.quote.forEach((quoteItem) => {
      let AddedFlag = false;
      JobItemCollection.forEach((collectionItem) => {
        if (this.componentsAreSame(quoteItem, collectionItem.item)) {
          collectionItem.qty++;
          collectionItem.total += this.calcTotalBlindPrice(quoteItem);
          collectionItem.SQM += quoteItem.squareMetres;
          AddedFlag = true;
        }
      });
      if (!AddedFlag) {
        JobItemCollection.push({
          item: quoteItem,
          qty: 1,
          SQM: quoteItem.squareMetres,
          total: this.calcTotalBlindPrice(quoteItem),
          location: quoteItem.description.location,
          extras: this.constructExtraDetails(quoteItem),
        });
      }
    });
    let jobTotal = 0;
    JobItemCollection.forEach((jobItem) => {
      jobTotal += jobItem.total;
    });
    return {
      items: JobItemCollection,
      name: JobQuote.name,
      jobTotal,
    };
  }

  getGroupingData(groupingKey, quoteItem) {
    if (
      groupingKey === "location" ||
      groupingKey === "driveType" ||
      groupingKey === "productCode"
    ) {
      return quoteItem.description.allDetails[groupingKey];
    }
    if (groupingKey === "style") return quoteItem.description.rollerStyle;
    if (groupingKey === "fabric") return quoteItem.description.fabric;
    if (groupingKey === "fabricAndColour")
      return `${quoteItem.description.fabric} - ${quoteItem.description.fabricColour}`;
    if (groupingKey === "driveMechanism") return quoteItem.description.driveMechanism;
  }

  constructItemDescription(item) {
    const { fabric, driveMechanism, rollerStyle, width, drop } = item.description;
    const { productCode } = item.description.allDetails;
    if (productCode !== "") {
      return `${productCode} / ${fabric} / ${rollerStyle} / ${driveMechanism} / Width: ${width}mm, Drop: ${drop}mm`;
    }
    return `${fabric} / ${rollerStyle} / ${driveMechanism} / Width: ${width}mm, Drop: ${drop}mm`;
  }

  constructExtraDetails(quoteItem) {
    let extras = { fabric: {}, components: [], services: [] };
    extras.fabric = {
      cost: this.calcBasePriceWithMarkUp(quoteItem),
      qty: quoteItem.description.width / 1000,
    };
    extras.components = this.constructExtraComponents(quoteItem);
    extras.services = quoteItem.extras.services.map((service) => {
      const totalWithMarkup = this.calcPriceWithMarkUp(
        service.details.cost,
        service.details.markup
      );
      return {
        name: service.details.name,
        cost: totalWithMarkup,
      };
    });
    return extras;
  }

  constructExtraComponents(quoteItem) {
    const componentDefinition = [
      { name: "Accessory", key: "accessory", qty: 1 },
      { name: "Base Bar", key: "baseBar", qty: 1 },
      { name: "Double Bracket", key: "doubleBracket", qty: 1 },
      { name: "Drive Mechanism", key: "driveMechanism", qty: 1 },
      { name: "Fascia", key: "kassette", qty: 1 },
      { name: "Side Channel", key: "sideChannel", qty: 1 },
      { name: "Link System", key: "linking", qty: 1 },
    ];
    let componentArray = [];
    componentDefinition.forEach(({ name, key, qty }) => {
      if (quoteItem.extras[key]) {
        let tempValue = this.calculateExtra(key, quoteItem).total;
        componentArray.push({ name, cost: tempValue, qty });
      }
    });
    return componentArray;
  }

  calculateExtra(extraKey, item) {
    let totalPrice = 0;
    let totalQty = 0;
    if (item.extras[extraKey]) {
      if (item.extras[extraKey].details.costPerMetre) {
        const totalPricePerMetre = this.calcCostPerMetre(
          parseFloat(item.extras[extraKey].details.costPerMetre),
          item.description.width,
          parseFloat(item.extras[extraKey].details.markup)
        );
        totalPrice += totalPricePerMetre;
        totalQty++;
      } else if (item.extras[extraKey].details.cost) {
        totalPrice += this.calcPriceWithMarkUp(
          parseFloat(item.extras[extraKey].details.cost),
          parseFloat(item.extras[extraKey].details.markup)
        );
        totalQty++;
      }
    }
    return { total: totalPrice, qty: totalQty };
  }

  componentsAreSame(Component1, Component2) {
    if (
      Component1.description.allDetails.productCode ===
        Component2.description.allDetails.productCode &&
      Component1.squareMetres === Component2.squareMetres &&
      Component1.description.rollerStyle === Component2.description.rollerStyle &&
      Component1.extras.driveMechanism.id === Component2.extras.driveMechanism.id &&
      Component1.description.fabric === Component2.description.fabric &&
      Component1.description.width === Component2.description.width &&
      Component1.description.drop === Component2.description.drop &&
      Component1.description.location === Component2.description.location &&
      this.calcTotalBlindPrice(Component1) === this.calcTotalBlindPrice(Component2)
    ) {
      return true;
    } else {
      return false;
    }
  }

  getGroupingTitleRow(groupingKey) {
    if (groupingKey === "job") {
      return (
        <Fragment>
          <td style={{ display: this.props.detailed ? "" : "None" }}>Item Number</td>
          <td>Qty</td>
          <td>Location</td>
          <td>Description</td>
          <td>Details of Item</td>
          <td>Cost of Unit</td>
          <td>Unit Qty</td>
          <td>Total Unit Qty</td>
          <td>Total Cost of Unit</td>
          <td>Total Cost of Item</td>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <td>{this.translateHeading(groupingKey)}</td>
          <td style={{ display: this.props.detailed ? "" : "None" }}>Item Number</td>
          <td>Qty</td>
          <td>Location</td>
          <td>Description</td>
          <td>Details of Item</td>
          <td>Cost of Unit</td>
          <td>Unit Qty</td>
          <td>Total Unit Qty</td>
          <td>Total Cost of Unit</td>
          <td>Total Cost of Item</td>
        </Fragment>
      );
    }
  }

  translateHeading(groupingKey) {
    if (groupingKey === "location") return "Item Location";
    if (groupingKey === "driveType") return "Drive Mechanism Type";
    if (groupingKey === "style") return "Header Style";
    if (groupingKey === "fabric") return "Fabric Name";
    if (groupingKey === "driveMechanism") return "Drive Mechanism Name";
    if (groupingKey === "fabricAndColour") return "Fabric and Colour Name";
    if (groupingKey === "productCode") return "Item Product Code";
  }

  render() {
    let tableSize = this.props.grouping === "job" ? "10" : "11";

    return (
      <div className="doc-box">
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="heading">
              <td colSpan={tableSize}>
                <span className="heading-one">Q U O T E - S U M M A R Y</span>
              </td>
            </tr>
            <tr className="sub-heading">{this.getGroupingTitleRow(this.props.grouping)}</tr>
            {this.constructFromResponse(this.props.quote)}
          </tbody>
        </table>
      </div>
    );
  }
}
export default DetailedSchedule;
