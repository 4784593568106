import React, { Component, Fragment } from "react";
import ScheduleTotals from "./ScheduleTotals";
import { numberWithCommas } from "../../../../../../utilities/utils";
import {
  getBlindFabricPrice,
  getBlindExtraPrice,
  getBlindServicePrice
} from "../../../Formulas/BlindsPricing";

class BlindsExtras extends Component {
  constructScheduleofItems(jobArray) {
    const newJobArray = JSON.parse(JSON.stringify(jobArray));
    newJobArray.forEach(job => {
      job.quote = this.constructQuoteObjectbyLocation(job.quote, job.discount);
    });
    return newJobArray.map((job, key) => {
      return (
        <Fragment key={key}>
          <tr className="details-header jobNumber">
            <td colSpan="4">{job.name}</td>
          </tr>
          {job.quote.map((location, key) => {
            return (
              <Fragment key={key}>
                <tr className="details-header">
                  <td colSpan="4">{`Location - ${location.location}`}</td>
                </tr>
                {this.constructLocationInfo(location.locationQuotes)}
              </Fragment>
            );
          })}
        </Fragment>
      );
    });
  }

  constructLocationInfo(locationQuoteArray) {
    let displayObject = {
      fabrics: [],
      makeup: { total: 0, qty: 0 },
      install: { total: 0, qty: 0 }
    };
    locationQuoteArray.forEach(quote => {
      let fabricFlag = false;
      displayObject.fabrics.forEach(addedFabric => {
        if (addedFabric.name === quote.description.fabric) {
          addedFabric.total += getBlindFabricPrice(quote, this.props.isRetail, quote.discountObj);
          addedFabric.qty++;
          fabricFlag = true;
        }
      });
      if (!fabricFlag) {
        displayObject.fabrics.push({
          name: quote.description.fabric,
          total: getBlindFabricPrice(quote, this.props.isRetail, quote.discountObj),
          qty: 1
        });
      }
    });

    // Add project discount
    if (this.props.isRetail) {
      displayObject.fabrics.forEach(fabric => {
        fabric.total = (parseFloat(fabric.total) / 100) * (100 - parseFloat(this.props.discount));
      });
    }

    return (
      <Fragment>
        <tr className="details-header">
          <td>Fabrics</td>
        </tr>
        {displayObject.fabrics.map((fabric, key) => (
          <tr key={key}>
            <td>{fabric.name}</td>
            <td className="data-align-right">
              ${numberWithCommas((fabric.total / fabric.qty).toFixed(2))}
            </td>
            <td className="data-align-right">{numberWithCommas(fabric.qty)}</td>
            <td className="data-align-right">${numberWithCommas(fabric.total.toFixed(2))}</td>
          </tr>
        ))}
        {this.handleComponent("driveMechanism", locationQuoteArray, "Drive Mechanisms")}
        {this.handleComponent("baseBar", locationQuoteArray, "Base Bars")}
        {this.handleComponent("kassette", locationQuoteArray, "Kassettes")}
        {this.handleComponent("linking", locationQuoteArray, "Linkings")}
        {this.handleComponent("sideChannel", locationQuoteArray, "Side Channels")}
        {this.handleComponent("doubleBracket", locationQuoteArray, "Double Brackets")}
        {this.handleComponent("accessory", locationQuoteArray, "Accessories")}
        {this.handleServices(locationQuoteArray)}
      </Fragment>
    );
  }

  handleComponent(ComponentKey, locationQuotes, Heading = ComponentKey) {
    let componentCollection = [];
    locationQuotes.forEach(quote => {
      if (quote.extras[ComponentKey]) {
        let AddedFlag = false;
        componentCollection.forEach(component => {
          if (quote.extras[ComponentKey].id === component.id) {
            component.total += getBlindExtraPrice(
              ComponentKey,
              quote,
              this.props.isRetail,
              quote.discountObj
            );
            component.qty++;
            AddedFlag = true;
          }
        });
        if (!AddedFlag) {
          componentCollection.push({
            name: quote.extras[ComponentKey].details.name,
            id: quote.extras[ComponentKey].id,
            total: getBlindExtraPrice(ComponentKey, quote, this.props.isRetail, quote.discountObj),
            qty: 1
          });
        }
      }
    });

    // Add project discount
    if (this.props.isRetail && this.props.discount !== "") {
      componentCollection.forEach(component => {
        component.total =
          (parseFloat(component.total) / 100) * (100 - parseFloat(this.props.discount));
      });
    }

    if (componentCollection.length > 0) {
      return (
        <Fragment>
          <tr className="details-header">
            <td>{Heading}</td>
          </tr>
          {componentCollection.map((component, key) =>
            component.total <= 0 ? (
              <tr key={key}>
                <td>{component.name}</td>
                <td className="data-align-right">Included</td>
                <td className="data-align-right">{component.qty}</td>
                <td className="data-align-right">Included</td>
              </tr>
            ) : (
              <tr key={key}>
                <td>{component.name}</td>
                <td className="data-align-right">
                  ${numberWithCommas((component.total / component.qty).toFixed(2))}
                </td>
                <td className="data-align-right">{numberWithCommas(component.qty)}</td>
                <td className="data-align-right">
                  ${numberWithCommas(numberWithCommas(component.total.toFixed(2)))}
                </td>
              </tr>
            )
          )}
        </Fragment>
      );
    }
  }

  handleServices(locationQuotes) {
    let serviceCollection = [];
    locationQuotes.forEach(quote => {
      quote.extras.services.forEach(service => {
        let AddedFlag = false;
        serviceCollection.forEach(addedService => {
          if (addedService.id === service.id) {
            addedService.total += getBlindServicePrice(
              service,
              this.props.isRetail,
              quote.discountObj
            );
            addedService.qty++;
            AddedFlag = true;
          }
        });
        if (!AddedFlag) {
          serviceCollection.push({
            id: service.id,
            name: service.details.name,
            total: getBlindServicePrice(service, this.props.isRetail, quote.discountObj),
            qty: 1
          });
        }
      });
    });

    // Add Project discount
    if (this.props.isRetail && this.props.discount !== "") {
      serviceCollection.forEach(service => {
        service.total = (service.total / 100) * (100 - this.props.discount);
      });
    }

    if (serviceCollection.length > 0) {
      return (
        <Fragment>
          <tr className="details-header">
            <td>Services</td>
          </tr>
          {serviceCollection.map((service, key) => (
            <tr key={key}>
              <td>{service.name}</td>
              <td className="data-align-right">
                ${numberWithCommas((service.total / service.qty).toFixed(2))}
              </td>
              <td className="data-align-right">{numberWithCommas(service.qty)}</td>
              <td className="data-align-right">${numberWithCommas(service.total.toFixed(2))}</td>
            </tr>
          ))}
        </Fragment>
      );
    }
  }

  constructQuoteObjectbyLocation(quoteArray, discountObj) {
    let LocationQuoteCollection = [];
    quoteArray.forEach(quote => {
      let AddedFlag = false;
      quote.discountObj = discountObj;
      LocationQuoteCollection.forEach(addedItem => {
        if (quote.description.allDetails.location === addedItem.location) {
          addedItem.locationQuotes.push({ ...quote });
          AddedFlag = true;
        }
      });
      if (!AddedFlag) {
        LocationQuoteCollection.push({
          locationQuotes: [{ ...quote }],
          location: quote.description.allDetails.location
        });
      }
    });
    return LocationQuoteCollection;
  }

  render() {
    return (
      <div
        className="doc-box page-break-before"
        id="ScheduleofItems"
        style={{ display: this.props.display ? "" : "None" }}
      >
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="heading">
              <td colSpan="4">
                <span className="heading-one">PROJECT QUOTE - SCHEDULE OF ITEMS</span>
              </td>
            </tr>
            <tr className="sub-heading">
              <td></td>
              <td>Average Price</td>
              <td>QTY</td>
              <td>Total</td>
            </tr>

            {this.constructScheduleofItems(this.props.response)}
            <ScheduleTotals
              response={this.props.response}
              isRetail={this.props.isRetail}
              discount={parseFloat(this.props.discount)}
            />
          </tbody>
        </table>
      </div>
    );
  }
}

export default BlindsExtras;
