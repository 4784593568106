import React, { Component } from "react";
import {
  MDBContainer,
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter
} from "mdbreact";

class Modal extends Component {
  state = {
    modal: true
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    return (
      <MDBContainer>
        <MDBModal centered size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <MDBModalHeader toggle={this.toggle}>
            {this.props.header ? this.props.header : "Something went wrong."}
          </MDBModalHeader>
          <MDBModalBody>
            {this.props.displayMessage ? (
              <p className="modal-body-text">{this.props.displayMessage} </p>
            ) : (
              "Please try again later."
            )}
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
              color="red"
              onClick={
                this.props.goBackClickHandler ? this.props.goBackClickHandler : () => this.toggle()
              }
            >
              Back
            </MDBBtn>
            {this.props.worksheetClickHandler ? (
              <MDBBtn onClick={this.props.worksheetClickHandler} color="blue">
                Go To Worksheet
              </MDBBtn>
            ) : (
              ""
            )}
          </MDBModalFooter>
        </MDBModal>
      </MDBContainer>
    );
  }
}

export default Modal;
