import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { addressFields } from "../fields";
import { API } from "aws-amplify";
import { selectAddress, updateAddressDetails } from "../../../actions/staffActions";
import { setRefresh } from "../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";

class EditAddress extends Component {
  get fields() {
    // Make a Deep copy of the Fields that will be passed in
    // for editing
    let fields = JSON.parse(JSON.stringify(addressFields));
    const details = { ...this.props.details };

    // AddressType and Name are stored directly in details,
    // whereas the rest of the address is stored in details.address
    for (let i = 0; i < fields.length; i++) {
      // Match the field ID with the value kept in details.address:
      fields[i].value = details.address[fields[i].id];

      // If the ID couldn't be found in details.address, search
      // one step above i.e. for name and AddressType
      if (!fields[i].value) {
        fields[i].value = details[fields[i].id];
      }
    }
    return fields;
  }

  APIMethod = async formData => {
    try {
      const { staffId, addressId } = this.props.match.params;
      this.props.dispatch(setAlgoliaLoaderState(true));
      const response = await API.put("telishadeAPI", `/staff/${staffId}/address/${addressId}`, {
        body: {
          details: {
            name: formData.name,
            addressType: formData.addressType,
            address: {
              streetNumber: formData.streetNumber,
              streetName: formData.streetName,
              city: formData.city,
              postcode: formData.postcode,
              state: formData.state,
              country: formData.country
            }
          }
        }
      });
      this.props.history.replace(`/staff/${staffId}/addresses/`);
      this.props.dispatch(updateAddressDetails(response.details));
      this.props.dispatch(selectAddress(response.id));
      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.dispatch(setRefresh(true));
    } catch (e) {
      throw e;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.fields}
        header="Edit Address Details"
        button="ACCEPT"
        buttoncolour="green"
        button2="CANCEL"
        button2colour="red"
        apiMethod={this.APIMethod}
      />
    );
  }
}

const mapStateToProps = state => state.staff.address;
export default withRouter(connect(mapStateToProps)(EditAddress));
