import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import BranchList from "./BranchList";
import ProjectList from "./ProjectList";

// import SearchableList from "../../Generic/Algolia/SearchableList";
class JobTab extends Component {
  // TODO: Make Algolia Index for Jobs
  render() {
    return (
      <Switch>
        <Route
          path="/customers/:customerId/projects/bid/:branchId"
          render={routeProps => (
            <div className="cust-projects-tab">
              <BranchList {...this.props} {...routeProps} />
              <ProjectList {...this.props} {...routeProps} />
            </div>
          )}
        />
        <Route
          path="/customers/:customerId/projects"
          render={routeProps => (
            <div className="cust-projects-tab">
              <BranchList {...this.props} {...routeProps} />
            </div>
          )}
        />
      </Switch>
    );
  }
}

export default JobTab;
