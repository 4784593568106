import React, { Component } from "react";
import ReactDOM from "react-dom";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, Configure, connectAutoComplete } from "react-instantsearch-dom";

const AutoCompleteSelectSuggestions = ({ hits, query, update, edit }) => {
  // Display results if they exist and a complete match is not already made
  // Clicking on result updates search box to contain that whole result,
  // which will then clear the results as a complete match is made
  if (edit && hits.length >= 1) {
    return (
      <ul>
        {hits.map((hit, i) => (
          <li
            key={i}
            onClick={event => {
              event.target.parentElement.parentElement.getElementsByTagName("input")[0].blur();
              update({
                value: hit.objectID,
                text: hit.details.name,
                edit: false
              });
            }}
          >
            {hit.details.name}
          </li>
        ))}
      </ul>
    );
  } else if (edit && query && hits.length === 0) {
    return (
      <ul>
        <li>No results</li>
      </ul>
    );
  } else return "";
};

class Select extends Component {
  // When component updates, check if the state should update if direct match typed / clicked
  componentDidUpdate() {
    if (this.correctMatch) {
      const hit = this.topSuggestion;
      this.props.update({
        value: hit.objectID,
        text: hit.name
      });
    }
  }

  // Helper function to determine if current query is a direct match
  get correctMatch() {
    const { hits, currentRefinement } = this.props;
    return hits.length && hits[0].name === currentRefinement;
  }

  get topSuggestion() {
    const { hits } = this.props;
    if (hits.length) return hits[0];
    else return undefined;
  }

  updateState = ({ value, text, edit }) => {
    const { refine, update } = this.props;
    refine(text);
    update({ value, text, edit });
  };

  handleChange = value => this.props.refine(value);

  handleClearClick = event => {
    const { target } = event;
    setTimeout(() => {
      if (!target.value) this.props.update({ value: "", text: "" });
    }, 50);
  };

  handleKeyDown = () => {
    if (!this.correctMatch) {
      this.props.update({ value: "", text: "" });
    }
  };

  render() {
    const { hits, currentRefinement, edit } = this.props;

    return (
      <div>
        <input
          autoFocus
          type="search"
          value={currentRefinement}
          onChange={event => this.handleChange(event.currentTarget.value)}
          onClick={this.handleClearClick}
          onKeyDown={this.handleKeyDown}
        />
        <AutoCompleteSelectSuggestions
          hits={hits}
          query={currentRefinement}
          update={this.updateState}
          edit={edit}
        />
      </div>
    );
  }
}
const AutoCompleteSelect = connectAutoComplete(Select);

export default class DependantDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value, text: "", edit: true, key: null };
    this.searchClient = algoliasearch(props.API.id, props.API.key);
    this.index = this.searchClient.initIndex(this.props.index);
  }

  async componentDidMount() {
    if (this.props.value) {
      try {
        const result = await this.index.getObject(this.props.value);
        this.setState({ value: this.props.value, text: result.name });
      } catch (error) {
        console.error(error);
      }
    }
  }

  getValue() {
    const updated = {};
    updated[this.props.column.key] = this.state.value;
    return updated;
  }

  getInputNode() {
    return ReactDOM.findDOMNode(this).getElementsByTagName("input")[0];
  }

  update = newState => {
    this.setState(newState);
  };

  render() {
    if (this.state.key) {
      // const ErrorHandler = this.errorHandler;
    }
    return (
      <div className="WorksheetEditor">
        <InstantSearch
          searchClient={this.searchClient}
          indexName={this.props.index}
          apiKey={this.state.key}
        >
          <Configure
            distinct
            filters={
              "isDeleted:false AND " +
              this.props.dependantTitle +
              ":" +
              this.props.rowData[this.props.dependantRow] +
              this.props.filters
            }
          />
          <AutoCompleteSelect
            value={this.state.value}
            text={this.state.text}
            defaultRefinement={this.state.text}
            update={this.update}
            edit={this.state.edit}
          />
        </InstantSearch>
      </div>
    );
  }
}
