import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import ManufacturerList from "./ManufacturerList";
import Details from "./ManufacturerDetails";
import AddManufacturer from "./PopUps/AddManufacturer";
import AddBranch from "./PopUps/AddBranch";
import AddContact from "./PopUps/AddContact";
import EditManufacturer from "./PopUps/EditManufacturer";
import EditBranch from "./PopUps/EditBranch";
import EditContact from "./PopUps/EditContact";
import DeleteManufacturer from "./PopUps/DeleteManufacturer";
import DeleteBranch from "./PopUps/DeleteBranch";
import DeleteContact from "./PopUps/DeleteContact";
import "./Manufacturers.css";
import Branches from "./BranchWindow/Branches";
import algoliasearch from "algoliasearch";
import config from "../../config";

class Manufacturers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultRoute: undefined
    };

    if (this.props.manufacturer.id === null && this.props.match.path === "/manufacturers") {
      this.getFirstProjectforRouting();
    }
  }

  async getFirstProjectforRouting() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getFirstProjectforRouting();
      }, 500);
    } else {
      try {
        const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
        const projectIndex = client.initIndex(`${config.algolia.prefix}manufacturers_name_asc`);
        const projects = await projectIndex.search({
          query: "",
          filters: `isDeleted:false`,
          hitsPerPage: 1
        });
        this.setState({ defaultRoute: projects.hits[0].objectID });
      } catch (e) {
        console.error(e);
      }
    }
  }

  get routes() {
    return (
      <Switch>
        <Redirect
          from="/manufacturers"
          exact
          to={
            this.props.manufacturer.id !== null
              ? `/manufacturers/${this.props.manufacturer.id}/branches`
              : this.state.defaultRoute
              ? `/manufacturers/${this.state.defaultRoute}/branches`
              : "/manufacturers"
          }
        />
        <Route path="/manufacturers/:manufacturerId/edit" component={EditManufacturer} />
        <Route path="/manufacturers/:manufacturerId/delete" component={DeleteManufacturer} />
        <Route path="/manufacturers/:manufacturerId/branches/add" component={AddBranch} />
        <Route
          path="/manufacturers/:manufacturerId/branches/:branchId/edit"
          component={EditBranch}
        />
        <Route
          path="/manufacturers/:manufacturerId/branches/:branchId/delete"
          component={DeleteBranch}
        />
        <Route
          path="/manufacturers/:manufacturerId/branches/:branchId/contacts/add"
          render={routeProps => <AddContact {...this.props} {...routeProps} />}
        />
        <Route
          path="/manufacturers/:manufacturerId/branches/:branchId/contacts/:contactId/edit"
          render={routeProps => <EditContact {...this.props} {...routeProps} />}
        />
        <Route
          path="/manufacturers/:manufacturerId/branches/:branchId/contacts/:contactId/delete"
          render={routeProps => <DeleteContact {...this.props} {...routeProps} />}
        />
      </Switch>
    );
  }

  render() {
    return (
      <div className="manufacturers">
        <Route
          path="/manufacturers/:manufacturerId"
          render={routeProps => <ManufacturerList {...this.props} {...routeProps} />}
        />
        <div className="selected-manufacturer-window">
          <Route path="/manufacturers/:manufacturerId/branches/" component={Details} />
          <Switch>
            <Route
              path="/manufacturers/:manufacturerId/branches/:branchId/:contactId"
              component={Branches}
            />
            <Route path="/manufacturers/:manufacturerId/branches/:branchId" component={Branches} />
            <Route path="/manufacturers/:manufacturerId/branches/" component={Branches} />
          </Switch>
          <Switch>
            <Route exact path="/manufacturers/add" component={AddManufacturer} />
          </Switch>
          {this.routes}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.manufacturers, AlgoliaKey: state.key };
};

export default connect(mapStateToProps)(Manufacturers);
