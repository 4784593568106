import React, { Component, Fragment } from "react";
import { getBlindServicePrice } from "../../../Formulas/BlindsPricing";

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class BlindsTotals extends Component {
  constructTotals(quotesArray) {
    let totalCost = 0;
    let totalCostNoDiscount = 0;
    quotesArray.forEach(jobQuote => {
      jobQuote.quote.forEach(quoteItem => {
        totalCost += this.calculateBlindItemTotal(
          quoteItem,
          this.props.discount,
          jobQuote.discount
        );
        totalCostNoDiscount += this.calculateBlindItemTotal(quoteItem);
      });
    });
    const NoDiscountTotalGst = totalCostNoDiscount + (totalCostNoDiscount / 100) * 10;
    const discountTotal = totalCost; // - (totalCost / 100) * this.props.discount;
    const gstTotal = discountTotal + (discountTotal / 100) * 10;
    return (
      <Fragment>
        <tr className="details">
          <td className="padding-cell" />
          <td>Total Costs of Items Ex.GST</td>
          {this.props.showDiscounts ? (
            <td>{`$${numberWithCommas(totalCostNoDiscount.toFixed(2))}`}</td>
          ) : (
            <Fragment />
          )}
          {this.props.showDiscounts ? (
            <td>{`-$${numberWithCommas((totalCostNoDiscount - totalCost).toFixed(2))}`}</td>
          ) : (
            <Fragment />
          )}
          <td>{`$${numberWithCommas(totalCost.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>GST</td>
          {this.props.showDiscounts ? (
            <td className="border-on-top">{`$${numberWithCommas(
              Math.ceil((totalCostNoDiscount / 100) * 10).toFixed(2)
            )}`}</td>
          ) : (
            <Fragment />
          )}
          {this.props.showDiscounts ? <td className="border-on-top" /> : <Fragment />}
          <td className="border-on-top">{`$${numberWithCommas(
            Math.ceil((discountTotal / 100) * 10).toFixed(2)
          )}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Total incl. GST</td>
          {this.props.showDiscounts ? (
            <td className="border-on-top">{`$${numberWithCommas(
              Math.ceil(NoDiscountTotalGst.toFixed(2)).toFixed(2)
            )}`}</td>
          ) : (
            <Fragment />
          )}
          {this.props.showDiscounts ? <td className="border-on-top" /> : <Fragment />}
          <td className="border-on-top">{`$${numberWithCommas(
            Math.ceil(gstTotal.toFixed(2)).toFixed(2)
          )}`}</td>
        </tr>
      </Fragment>
    );
  }

  constructMargins(quotesArray) {
    let WholesaleTotal = 0;
    let RetailTotal = 0;
    quotesArray.forEach(jobQuote => {
      jobQuote.quote.forEach(quoteItem => {
        WholesaleTotal += this.calculateBlindItemTotal(quoteItem, 0, jobQuote.discount, false);
        RetailTotal += this.calculateBlindItemTotal(
          quoteItem,
          this.props.discount,
          jobQuote.discount,
          true
        );
      });
    });
    const GrossProfit = RetailTotal - WholesaleTotal;
    const ProfitMargin = (GrossProfit / RetailTotal) * 100;
    return (
      <Fragment>
        <tr className="details">
          <td className="padding-cell" />
          <td>Wholesale Total</td>
          <td>{`$${numberWithCommas(WholesaleTotal.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Retail Total</td>
          <td>{`$${numberWithCommas(RetailTotal.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Gross Profit</td>
          <td>{`$${numberWithCommas(GrossProfit.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Profit Margin</td>
          <td>{`${ProfitMargin.toFixed(2)}%`}</td>
        </tr>
      </Fragment>
    );
  }

  calculateBlindItemTotal(quoteItem, discount = 0, discountObject, isRetail = this.props.isRetail) {
    if (isNaN(discount)) {
      return this.calculateBlindItemTotal(quoteItem, 0, discountObject, isRetail);
    }
    let totalforItem = 0;
    totalforItem += isRetail
      ? (quoteItem.baseCost / 100) * parseInt(quoteItem.baseMarkup) + quoteItem.baseCost
      : quoteItem.baseCost;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach(componentDiscount => {
        if (componentDiscount.id === quoteItem.description.allDetails.fabricId) {
          totalforItem =
            (totalforItem / 100) *
            (100 -
              (isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }

    totalforItem += this.calculateExtra("baseBar", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("driveMechanism", quoteItem, discountObject, isRetail)
      .total;
    totalforItem += this.calculateExtra("kassette", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("linking", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("sideChannel", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("doubleBracket", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("accessory", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateService(quoteItem, discountObject, isRetail).total;

    totalforItem = isRetail ? (totalforItem / 100) * (100 - discount) : totalforItem;
    return totalforItem;
  }

  calculateExtra(extraKey, item, discountObject, isRetail = this.props.isRetail) {
    let totalPrice = 0;
    let totalQty = 0;
    if (item.extras[extraKey]) {
      if (item.extras[extraKey].details.costPerMetre) {
        const totalcostPerMetre =
          parseFloat(item.extras[extraKey].details.costPerMetre) *
          (parseFloat(item.description.width) / 1000);
        const totalcostPerMetreMarkup =
          (totalcostPerMetre / 100) * (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += isRetail ? totalcostPerMetreMarkup : totalcostPerMetre;
        totalQty++;
      } else if (item.extras[extraKey].details.cost) {
        const totalCost =
          (parseFloat(item.extras[extraKey].details.cost) / 100) *
          (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += isRetail ? totalCost : parseFloat(item.extras[extraKey].details.cost);
        totalQty++;
      }
      if (discountObject && discountObject.components.length > 0) {
        discountObject.components.forEach(componentDiscount => {
          if (item.extras[extraKey].id === componentDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (isRetail
                  ? componentDiscount.retail.percentage
                  : componentDiscount.wholesale.percentage));
          }
        });
      }
    }
    return { total: totalPrice, qty: totalQty };
  }

  calculateService(item, discountObject, isRetail = this.props.isRetail) {
    let totalPrice = 0;
    let totalQty = 0;
    item.extras.services.forEach(service => {
      totalPrice += getBlindServicePrice(service, isRetail, discountObject);
      totalQty++;
    });
    return { total: totalPrice, qty: totalQty };
  }

  render() {
    return (
      <div className="doc-box">
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="heading">
              <td className="padding-cell"></td>
              <td colSpan="4">
                <span className="heading-one">PROJECT TOTALS</span>
              </td>
            </tr>
            {this.constructTotals(this.props.response)}
            <tr className="comments">
              <td colSpan="3">Please refer to each individual job Quote for additional detail.</td>
            </tr>
          </tbody>
        </table>
        <table className="Margins" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="comments">
              <td colSpan="3">
                Please Note this Information will not be Shown on the Printed Quote.
              </td>
            </tr>
            <tr className="heading">
              <td className="padding-cell"></td>
              <td colSpan="4">
                <span className="heading-one">Margins</span>
              </td>
            </tr>
            {this.constructMargins(this.props.response)}
          </tbody>
        </table>
      </div>
    );
  }
}

export default BlindsTotals;
