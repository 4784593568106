import {
  POPULATE_SIDE_LIST,
  SELECT_SIDE_LIST_ITEM,
  SELECT_SIDE_LIST_TAB,
  TOGGLE_MULTISELECT,
  TOGGLE_SELECTALL,
  SET_PROJECT_DETAILS,
  SET_JOB_DETAILS,
  TOGGLE_MENU,
  TOGGLE_SUBMENU
} from "../actions/projectsActions";

// Initial state
const initialState = {
  sideList: {
    items: [],
    selected: null,
    selectedTabText: "commercial"
  },
  projectDetails: {},
  jobDetails: {},
  multiselect: false,
  selectAll: false,

  documents: {
    menuCurrentlyOpen: "none",
    subMenuCurrentlyOpen: "none"
  }
};

// projects Reducer
export default function projectsReducer(state = initialState, action) {
  switch (action.type) {
    case POPULATE_SIDE_LIST:
      return {
        ...state,
        sideList: {
          ...state.sideList,
          items: action.sideList.items
        }
      };

    case SELECT_SIDE_LIST_ITEM:
      return {
        ...state,
        sideList: {
          ...state.sideList,
          selected: action.sideList.selected
        }
      };

    case SELECT_SIDE_LIST_TAB:
      return {
        ...state,
        sideList: {
          ...state.sideList,
          selectedTabText: action.selectedTabText
        }
      };

    case TOGGLE_MULTISELECT:
      return {
        ...state,
        multiselect: action.multiselect,
        selectAll: false
      };

    case TOGGLE_SELECTALL:
      return {
        ...state,
        selectAll: action.selectAll
      };

    case SET_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: action.projectDetails
      };

    case SET_JOB_DETAILS:
      return {
        ...state,
        jobDetails: action.jobDetails
      };
    case TOGGLE_MENU:
      return {
        ...state,
        documents: {
          ...state.documents,
          menuCurrentlyOpen: action.menu
        }
      };

    case TOGGLE_SUBMENU:
      return {
        ...state,
        documents: {
          ...state.documents,
          subMenuCurrentlyOpen: action.menu
        }
      };

    default:
      return state;
  }
}
