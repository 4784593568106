import React, { Component } from "react";
import { connect } from "react-redux";
import PopUp from "../../Generic/PopUpDialog/PopUp";
import { manufacturerFields } from "../fields";
import { API } from "aws-amplify";
import { setRefresh } from "../../../actions/algoliaActions";
import { withRouter } from "react-router";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";

class DeleteManufacturer extends Component {
  get fields() {
    let fields = JSON.parse(JSON.stringify(manufacturerFields)); // Making a deep copy
    const details = { ...this.props.details };
    for (let i = 0; i < fields.length; i++) {
      fields[i].value = details[fields[i].id];
    }

    return fields;
  }
  APIMethod = async () => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));
      await API.del("telishadeAPI", `/manufacturer/${this.props.id}`);
      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.history.replace("/manufacturers/");
      this.props.dispatch(setRefresh(true));
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUp
        header="Delete Manufacturer?"
        body={`Are you sure you want to delete ${this.props.details.name}?`}
        button="Delete"
        buttoncolour="red"
        button2="cancel"
        buttonClickHandler={this.APIMethod}
        button2ClickHandler={undefined}
      />
    );
  }
}
const mapStateToProps = state => state.manufacturers.manufacturer;
export default withRouter(connect(mapStateToProps)(DeleteManufacturer));
