// Action Type Constants
export const POPULATE_SIDE_LIST = "POPULATE_SIDE_LIST";
export const SELECT_SIDE_LIST_ITEM = "SELECT_SIDE_LIST_ITEM";
export const UPDATE_PRODUCT_DETAILS = "UPDATE_PRODUCT_DETAILS";
export const POPULATE_PRODUCT_LIST = "POPULATE_PRODUCT_LIST";
export const SELECT_PRODUCT_LIST_ITEM = "SELECT_PRODUCT_LIST_ITEM";
export const SET_CURRENT_COMPONENT = "SET_CURRENT_COMPONENT";
export const SET_CURRENT_PRODUCT = "SET_CURRENT_PRODUCT";
export const SELECT_ID = "SELECT_ID";
export const SET_PRODUCT_ID = "SET_PRODUCT_ID";

//Action Creators
export function updateProductDetails(productDetails) {
  return {
    type: UPDATE_PRODUCT_DETAILS,
    productDetails
  };
}

export function populateProductList(items) {
  return {
    type: POPULATE_PRODUCT_LIST,
    productList: {
      items: items
    }
  };
}

export function selectProductListItem(key) {
  return {
    type: SELECT_PRODUCT_LIST_ITEM,
    productList: {
      selected: key
    }
  };
}

export function setCurrentComponent(component) {
  return {
    type: SET_CURRENT_COMPONENT,
    currentComponent: component
  };
}

export function setCurrentProduct(product) {
  return {
    type: SET_CURRENT_PRODUCT,
    currentProduct: product
  };
}

export function selectID(ID) {
  return {
    type: SELECT_ID,
    selectedID: ID
  };
}

export function setProductID(ID) {
  return {
    type: SET_PRODUCT_ID,
    currentProductID: ID
  };
}
