import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class ConversationsMenu extends Component {
  render() {
    return (
      <div className="subMenuLinksList-expanded">
        <ul>
          <li>
            <div className="not-implemented">Emails</div>
          </li>

          <li>
            <div className="not-implemented">Fax</div>
          </li>

          <li>
            <div className="not-implemented">Letter</div>
          </li>

          <li>
            <div className="not-implemented">Memo</div>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(ConversationsMenu);
