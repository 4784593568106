export const CHANGE_CURRENT_PRODUCT = "DOCUMENTS::CHANGE_CURRENT_PRODUCT";
export const TOGGLE_DISCOUNT_VIEW = "DOCUMENTS::TOGGLE_DISCOUNT_VIEW";
export const STORE_BLIND_JOB_QUOTE_PAYLOAD =
  "DOCUMENTS::STORE_BLIND_QUOTE_PAYLOAD";
export const STORE_CURTAIN_JOB_QUOTE_PAYLOAD =
  "DOCUMENTS::STORE_CURTAIN_QUOTE_PAYLOAD";

// TODO: Implement this feature once Curtains and Blinds quotes both working
// This feature should swap between curtains and blinds depending on the selected tab on worksheet and quote.
export function changeDocumentProduct(product) {
  return {
    type: CHANGE_CURRENT_PRODUCT,
    currentProduct: product
  };
}

export function toggleDiscountView(bool) {
  return {
    type: TOGGLE_DISCOUNT_VIEW,
    discountView: bool
  };
}

export function storeBlindJobQuotePayload(payload) {
  return {
    type: STORE_BLIND_JOB_QUOTE_PAYLOAD,
    payload
  };
}

export function storeCurtainJobQuotePayload(payload) {
  return {
    type: STORE_CURTAIN_JOB_QUOTE_PAYLOAD,
    payload
  };
}
