import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import ReactDataGrid from "react-data-grid";
import Helper from "react-data-grid-multiline-header";

//Custom Editor Had to be Made to Allow for the Input of New Line Characters
class CustomEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }

  componentDidMount() {}

  getValue() {
    const variable = this.props.column.key;
    let obj = {};
    if (this.state.value !== undefined) obj[variable] = this.state.value;
    else obj[variable] = "";

    return obj;
  }

  getInputNode() {
    // If applicable, should return back the primary html input node that is used to edit the data
    // Otherwise return null
    // If value is an input node, then this node will be focussed on when the editor opens

    return ReactDOM.findDOMNode(this).getElementsByTagName("textarea")[0];
  }

  handleChange = (event) => {
    let value = event.target.value;
    if (value[value.length - 1] === "\n") value = value.slice(0, -1);
    this.setState({ value: value });
  };

  render() {
    // return UI for the editor
    return (
      <textarea
        className="gridTextArea"
        value={this.state.value}
        onBlur={this.props.onBlur}
        onChange={this.handleChange}
        autoFocus
      />
    );
  }
}

class Spreadsheet extends Component {
  constructor(props) {
    super(props);
    this.rows = this.createRows(
      parseInt(this.props.y.start, 10),
      parseInt(this.props.y.increment, 10),
      this.props.data
    );
    this.columns = this.createColumns(
      parseInt(this.props.x.start, 10),
      parseInt(this.props.x.increment, 10)
    );
    this.Helper = new Helper(this.columns);
    this.state = {
      rows: this.createRows(
        parseInt(this.props.y.start, 10),
        parseInt(this.props.y.increment, 10),
        this.props.data
      ),
    };
  }
  Helper = new Helper(this.columns);
  columns = [];
  rows = [];

  componentDidMount() {}

  parseExcelCellsToArray(aCopiedCells) {
    let valid = true;
    let l2DMatrix = [];
    let lRows = aCopiedCells.split("\n"); // Cells are split into rows
    let lNumCols = lRows[0].split("\t").length;

    for (let i = 0; i < lRows.length; i++) {
      l2DMatrix[i] = lRows[i].split("\t"); // Rows are split into columns
      if (lNumCols !== l2DMatrix[i].length) {
        // Subsequent rows' number of columns is different to first row
        valid = false;
        break;
      }
    }

    if (!valid || l2DMatrix[0].length < 2) {
      // Length of < 2 indicates first row had no tabs
      const error = { name: "InvalidExcelInput", message: l2DMatrix };
      throw error;
    }
    return l2DMatrix;
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const temp = this.state.rows;
    const value = Object.values(updated); //Stores the Value of the Current Row
    let key = Object.keys(updated); //Stores the Key of the Current Row
    const column = parseInt(key);
    const row = toRow;
    if (value[0] === undefined) value[0] = ""; //Assigns an Undefined Value to be a blank string
    if (value[0] !== undefined) {
      if (value[0].toString().includes("\t")) {
        //Checks if the Entered Text is a Copied Excel Spreadsheet
        const values = this.parseExcelCellsToArray(value[0]);
        for (let i = 0; i < values.length; i++) {
          for (let j = 0; j < values[i].length; j++) {
            temp[i + row] = { ...temp[i + row], [j + column]: values[i][j] };
          }
        }
      } else {
        for (let i = fromRow; i <= toRow; i++) {
          temp[i] = { ...temp[i], ...updated };
        }
      }
    }
    this.setState({ temp });
    this.props.onDataUpdate(temp);
  };

  rowGetter = (i) => {
    return this.state.rows[i];
  };

  createColumns(startingValue, incrementValue) {
    let columns = [{ key: "title", name: "" }];
    let value = startingValue;
    for (let i = 0; i < 50; i++) {
      columns.push({ key: i.toString(), name: value, editor: CustomEditor });
      value = value + incrementValue;
    }
    return columns;
  }

  createRows(startingValue, incrementValue, data) {
    let rows = [];
    let value = startingValue;
    for (let i = 0; i < 50; i++) {
      rows.push({ title: value, ...data[i] });
      value = value + incrementValue;
    }
    return rows;
  }

  render() {
    return (
      <div className="react-grid-multiline-header">
        <ReactDataGrid
          columns={this.columns}
          rowGetter={this.rowGetter}
          minColumnWidth={60}
          rowsCount={this.state.rows.length}
          enableCellSelect={true}
          onGridRowsUpdated={this.onGridRowsUpdated}
        />
      </div>
    );
  }
}

export default connect()(Spreadsheet);
