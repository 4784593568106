import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import algoliasearch from "algoliasearch";
import { API } from "aws-amplify";
import config from "../../../../../config";
// import LetterHead from "../../TableComponents/LetterHead";
// import ProjectHeader from "./../ProjectHeader";
// import POSummary from "./PurchaseOrderComponents/POSummary";
// import PurchaseOrderPerSupplier from "./PurchaseOrderComponents/PurchaseOrderPerSupplier";
import { Theme } from "../../../../Generic/Theme";
//Material UI
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { ThemeProvider, IconButton, Button } from "@material-ui/core";
import PurchaseOrderDocument from "./PurchaseOrderDocument";
import PurchaseOrderComponents from "./PurchaseOrderComponents";
import "./PurchaseOrder.css";
import PopUpForm from "../../../../Generic/PopUpDialog/PopUpForm";
import { savePDF } from "@progress/kendo-react-pdf";
import SettingsIcon from "@material-ui/icons/Settings";
import SaveIcon from "@material-ui/icons/Save";
import DeterminateLoader from "../../../../Loader/DeterminateLoader";
import {
  emptyCustomer,
  emptyJobBranch,
  emptyBranchContact,
} from "../../../../Customers/CustomerModels";

function pageTemplate({ pageNum, totalPages }) {
  return (
    <div className="pageTemplate">
      <div className="pageNumber">
        Pg. {pageNum} of {totalPages}
      </div>
    </div>
  );
}

class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseOrder: undefined,
      hasBlinds: false,
      hasCurtains: false,
      customerDetails: undefined,
      allPurchaseOrders: undefined,
      currentTabSelection: 0,
      optionsPopUp: false,
      selectJobPopUp: false,
      deliveryAddress: undefined,
      selectedJobs: [],
      maxRequests: undefined,
      completedRequests: 0,
      options: {
        discountNumber: "",
        account: "",
        deliveryAddressType: "customer",
        deliveryAddress: "",
        dateRequired: "",
        combine: false,
        sortingKey: "itemNo",
        customAddress: undefined,
        showFabricTotal: true,
      },
    };
    this.getProjectPurchaseOrder();
  }

  async getProjectPurchaseOrder() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getProjectPurchaseOrder();
      }, 500);
    } else {
      const jobsCollection = await this.getJobsForProject();
      const selectedJobs = jobsCollection.map((job) => job.objectID);
      this.setState({ maxRequests: jobsCollection.length * 2 });
      let blindPurchaseOrderCollection = [];
      let curtainPurchaseOrderCollection = [];
      const customerDetails = await this.getCustomerInformation(this.props.projectDetails.details);
      jobsCollection.forEach(async (job) => {
        const blindPurchaseOrder = this.getJobPurchaseOrder(job.objectID, "blind");
        const curtainPurchaseOrder = this.getJobPurchaseOrder(job.objectID, "curtain");
        blindPurchaseOrderCollection.push(blindPurchaseOrder);
        curtainPurchaseOrderCollection.push(curtainPurchaseOrder);
      });

      blindPurchaseOrderCollection = await Promise.all(blindPurchaseOrderCollection);
      curtainPurchaseOrderCollection = await Promise.all(curtainPurchaseOrderCollection);
      const blindPurchaseOrder = this.combineJobPurchaseOrders(
        blindPurchaseOrderCollection,
        selectedJobs
      );

      const curtainPurchaseOrder = this.combineJobPurchaseOrders(
        curtainPurchaseOrderCollection,
        selectedJobs
      );
      this.setState({
        customerDetails,
        deliveryAddress: customerDetails.jobBranch,
        purchaseOrder: { blind: blindPurchaseOrder, curtain: curtainPurchaseOrder },
        selectedJobs,
        allPurchaseOrders: {
          blind: blindPurchaseOrderCollection,
          curtain: curtainPurchaseOrderCollection,
        },
        hasBlinds:
          blindPurchaseOrder &&
          (blindPurchaseOrder.noManufacturers.length > 0 ||
            blindPurchaseOrder.manufacturers.length > 0),
        hasCurtains:
          curtainPurchaseOrder &&
          (curtainPurchaseOrder.noManufacturers.length > 0 ||
            curtainPurchaseOrder.manufacturers.length > 0),
      });
    }
  }

  combineJobPurchaseOrders(jobPurchaseOrders, selectedJobs) {
    let combinedPurchaseOrder = { manufacturers: [], noManufacturers: [], workOrder: [] };
    jobPurchaseOrders.forEach((jobPurchaseOrder) => {
      if (selectedJobs.includes(jobPurchaseOrder.id)) {
        jobPurchaseOrder.manufacturers.forEach((manufacturer) => {
          let isAddedFlag = false;
          combinedPurchaseOrder.manufacturers.forEach((combinedManufacturer) => {
            if (manufacturer.id === combinedManufacturer.id) {
              combinedManufacturer.lines.push(...manufacturer.lines);
              isAddedFlag = true;
            }
          });
          if (!isAddedFlag) {
            combinedPurchaseOrder.manufacturers.push({
              ...JSON.parse(JSON.stringify(manufacturer)),
            });
          }
        });
        combinedPurchaseOrder.workOrder.push(...jobPurchaseOrder.workOrder);
        combinedPurchaseOrder.noManufacturers.push(...jobPurchaseOrder.noManufacturers);
      }
    });
    return combinedPurchaseOrder;
  }

  async getJobsForProject() {
    try {
      const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
      const index = client.initIndex(`${config.algolia.prefix}projects_jobs_number_asc`);
      const response = await index.search({
        query: "",
        filters: "isDeleted:false AND projectId:" + this.props.match.params.id,
        hitsPerPage: 1000,
      });
      return response.hits;
    } catch (e) {
      console.error(e);
    }
  }

  async getJobPurchaseOrder(jobId, productType) {
    try {
      const response = await API.get(
        "telishadeAPI",
        `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${jobId}/purchase-order/${productType}`
      );
      this.setState({ completedRequests: this.state.completedRequests + 1 });
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  async getCustomerInformation(details) {
    let customer = undefined;
    let branch = undefined;
    let contact = undefined;

    const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
    const customerIndex = client.initIndex(`${config.algolia.prefix}customers_name_asc`);
    const branchIndex = client.initIndex(`${config.algolia.prefix}customers_branches_name_asc`);
    const contactIndex = client.initIndex(
      `${config.algolia.prefix}customers_branches_contacts_name_asc`
    );

    if (details?.customerId) {
      customer = await customerIndex.getObject(details.customerId);
      branch = await branchIndex.getObject(details.customerBranchId);
      contact = await contactIndex.getObject(details.customerBranchContactId);
    } else {
      customer = emptyCustomer;
      branch = emptyJobBranch;
      contact = emptyBranchContact;
    }

    return { jobBranch: branch, branchContact: contact, customer };
  }

  handleTabSelect = (event, newValue) => {
    this.setState({ currentTabSelection: newValue });
  };

  handleAddressTypeChange = (event) => {
    this.setState({ options: { ...this.state.options, deliveryAddressType: event.target.value } });
  };

  handleJobSelectChange = (formData) => {
    const blindPurchaseOrder = this.combineJobPurchaseOrders(
      this.state.allPurchaseOrders.blind,
      formData.selectedJobs
    );
    const curtainPurchaseOrder = this.combineJobPurchaseOrders(
      this.state.allPurchaseOrders.curtain,
      formData.selectedJobs
    );
    this.setState({
      purchaseOrder: { blind: blindPurchaseOrder, curtain: curtainPurchaseOrder },
      selectedJobs: formData.selectedJobs,
      selectJobPopUp: false,
    });
  };

  getJobSelectFields = () => {
    return [
      {
        id: "selectedJobs",
        label: "Jobs Included in Purchase Order",
        index: `${config.algolia.prefix}projects_jobs_number_asc`,
        filter: "AND isDeleted:false AND projectId:" + this.props.match.params.id,
        productsMulti: false,
        type: "multiSelect",
        value: this.state.selectedJobs,
      },
    ];
  };

  getValidationTypes = () => {
    return [
      {
        id: "discountNumber",
        label: "Discount Number",
        type: "text",
        value: this.state.options.discountNumber,
      },
      {
        id: "account",
        label: "Account Number",
        type: "text",
        value: this.state.options.account,
      },
      {
        id: "deliveryAddressType",
        label: "Delivery Address Type",
        type: "select",
        options: [
          { name: "Customer Address", value: "customer" },
          { name: "Staff Address", value: "staff" },
          { name: "Custom Address", value: "custom" },
        ],
        onChange: this.handleAddressTypeChange,
        value: this.state.options.deliveryAddressType,
      },
      this.state.options.deliveryAddressType === "customer"
        ? {
            id: "deliveryAddress",
            label: "Delivery  Address",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}customers_branches_name_asc`,
            filters: `isDeleted:false AND customerId:${this.props.projectDetails.details.customerId}`,
            value: this.state.options.deliveryAddress,
          }
        : this.state.options.deliveryAddressType === "staff"
        ? {
            id: "deliveryAddress",
            label: "Delivery  Address",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}staff_addresses_name_asc`,
            filters: `isDeleted:false`,
            value: this.state.options.deliveryAddress,
          }
        : {
            id: "customAddress",
            label: "Custom Delivery Address",
            type: "TextArea",
            value: this.state.options.customAddress,
          },
      {
        id: "dateRequired",
        label: "Date Required",
        type: "date",
        value: this.state.options.dateRequired,
      },
      {
        id: "combine",
        label: "Combine Same Items?",
        type: "select",
        options: [
          { value: true, name: "Yes" },
          { value: false, name: "No" },
        ],
        required: true,
        value: this.state.options.combine.toString(),
      },
      {
        id: "sortingKey",
        label: "Sort Items By",
        type: "select",
        options: [
          { value: "itemNo", name: "Item Number (Default)" },
          { value: "productCode", name: "Product Code" },
        ],
        required: true,
        value: this.state.options.sortingKey.toString(),
      },
      {
        id: "showFabricTotal",
        label: "Show Total Fabric?",
        type: "select",
        options: [
          { value: true, name: "Yes" },
          { value: false, name: "No" },
        ],
        required: true,
        value: this.state.options.showFabricTotal.toString(),
      },
    ];
  };

  handleOptionsPopUp = (formData) => {
    if (
      this.state.options.deliveryAddress !== formData.deliveryAddress &&
      formData.deliveryAddressType !== "custom"
    ) {
      this.handleDeliveryAddressChange(formData.deliveryAddress);
    }
    this.setState({
      options: {
        ...this.state.options,
        discountNumber: formData.discountNumber,
        account: formData.account,
        deliveryAddress: formData.deliveryAddress,
        dateRequired: formData.dateRequired,
        combine: formData.combine === "true",
        sortingKey: formData.sortingKey,
        customAddress: formData.customAddress,
        showFabricTotal: formData.showFabricTotal === "true",
      },
      optionsPopUp: false,
    });
  };

  async handleDeliveryAddressChange(newAddressId) {
    const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
    const contactIndex = client.initIndex(`${config.algolia.prefix}customers_branches_name_asc`);
    const staffContactIndex = client.initIndex(`${config.algolia.prefix}staff_addresses_name_asc`);
    if (this.state.options.deliveryAddressType === "customer") {
      const contact = await contactIndex.getObject(newAddressId);
      this.setState({ deliveryAddress: contact });
    } else if (this.state.options.deliveryAddressType === "staff") {
      const contact = await staffContactIndex.getObject(newAddressId);
      this.setState({ deliveryAddress: contact });
    }
  }

  getCurtainIndexing(index) {
    let newIndex = index;
    if (this.state.hasBlinds) {
      newIndex = newIndex + this.state.purchaseOrder.blind.manufacturers.length + 1;
      if (this.state.purchaseOrder.blind.noManufacturers) newIndex = newIndex + 1;
    }
    return newIndex;
  }

  handleOptionsClick = () => {
    this.setState({ optionsPopUp: true });
  };

  render() {
    if (!this.state.purchaseOrder) {
      return (
        <Fragment>
          <DeterminateLoader
            maxValue={this.state.maxRequests}
            currentValue={this.state.completedRequests}
          />
          <div className="loader">LOADING...</div>;
        </Fragment>
      );
    } else
      return (
        <div className="PurchaseOrderWrapper">
          {this.state.optionsPopUp ? (
            <PopUpForm
              fields={this.getValidationTypes()}
              apiMethod={this.handleOptionsPopUp}
              header="Purchase Order Options"
              button="Accept"
              buttoncolour="green"
              button2="Cancel"
              button2colour="red"
              button2ClickHandler={() => this.setState({ optionsPopUp: false })}
            />
          ) : null}
          {this.state.selectJobPopUp ? (
            <PopUpForm
              fields={this.getJobSelectFields()}
              apiMethod={this.handleJobSelectChange}
              header="Select Jobs for Purchase Order"
              button="Accept"
              buttoncolour="green"
              button2="Cancel"
              button2colour="red"
              button2ClickHandler={() => this.setState({ selectJobPopUp: false })}
            />
          ) : null}
          <ThemeProvider theme={Theme}>
            <AppBar className="manufactuerTabBar">
              <Tabs value={this.state.currentTabSelection} onChange={this.handleTabSelect}>
                {this.state.hasBlinds && this.state.purchaseOrder.blind.workOrder ? (
                  <Tab label={"Work Order (Blinds)"} />
                ) : null}
                {this.state.hasBlinds &&
                  this.state.purchaseOrder.blind.manufacturers.map((manu, index) => (
                    <Tab key={index} label={manu.details.name} />
                  ))}
                {this.state.hasBlinds && this.state.purchaseOrder.blind.noManufacturers ? (
                  <Tab label={"No Manufacturer (Blinds)"} />
                ) : null}
                {this.state.hasCurtains && this.state.purchaseOrder.curtain.workOrder ? (
                  <Tab label={"Work Order (Curtains)"} />
                ) : null}
                {this.state.hasCurtains &&
                  this.state.purchaseOrder.curtain.manufacturers.map((manu, index) => (
                    <Tab key={this.getCurtainIndexing(index)} label={manu.details.name} />
                  ))}
                {this.state.hasCurtains && this.state.purchaseOrder.curtain.noManufacturers ? (
                  <Tab label={"No Manufacturer (Curtains)"} />
                ) : null}
              </Tabs>
              <Button onClick={() => this.setState({ selectJobPopUp: true })} color="secondary">
                Select Jobs
              </Button>
              <IconButton className="optionsButton" onClick={this.handleOptionsClick}>
                <SettingsIcon />
              </IconButton>
              <IconButton
                className="exportButton"
                onClick={() => {
                  try {
                    savePDF(document.getElementById("Document"), {
                      fileName: `${this.props.docNumber}-${this.state.currentTabSelection + 1}.pdf`,
                      repeatHeaders: true,
                      paperSize: "A4",
                      landscape: true,
                      scale: 0.4,
                      pageTemplate: pageTemplate,
                    });
                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </AppBar>
          </ThemeProvider>
          {this.state.hasBlinds && 0 === this.state.currentTabSelection ? (
            <PurchaseOrderDocument
              docNumber={this.props.docNumber}
              key={1}
              customerDetails={this.state.customerDetails}
              deliveryAddress={
                this.state.options.deliveryAddressType === "custom"
                  ? this.state.options.customAddress
                  : this.state.deliveryAddress
              }
              manufacturer={{
                lines: this.state.purchaseOrder.blind.workOrder,
                details: { name: "N/A" },
              }}
              purchaseOrder={this.state.purchaseOrder}
              index={1}
              options={this.state.options}
            />
          ) : null}
          {this.state.hasBlinds &&
            this.state.purchaseOrder.blind.manufacturers.map((manu, index) => {
              if (index + 1 === this.state.currentTabSelection) {
                return (
                  <PurchaseOrderDocument
                    docNumber={this.props.docNumber}
                    key={index + 1}
                    customerDetails={this.state.customerDetails}
                    deliveryAddress={
                      this.state.options.deliveryAddressType === "custom"
                        ? this.state.options.customAddress
                        : this.state.deliveryAddress
                    }
                    manufacturer={manu}
                    purchaseOrder={this.state.purchaseOrder}
                    index={index + 1}
                    options={this.state.options}
                  />
                );
              } else return null;
            })}
          {this.state.hasBlinds &&
          this.state.purchaseOrder.blind.manufacturers.length + 1 ===
            this.state.currentTabSelection ? (
            <PurchaseOrderComponents
              docNumber={this.props.docNumber}
              key={this.state.purchaseOrder.blind.manufacturers.length + 1}
              customerDetails={this.state.customerDetails}
              manufacturer={{
                lines: this.state.purchaseOrder.blind.noManufacturers,
                details: { name: "N/A" },
              }}
              deliveryAddress={
                this.state.options.deliveryAddressType === "custom"
                  ? this.state.options.customAddress
                  : this.state.deliveryAddress
              }
              purchaseOrder={this.state.purchaseOrder}
              index={this.state.purchaseOrder.blind.manufacturers.length + 1}
              options={this.state.options}
            />
          ) : null}
          {this.state.hasCurtains &&
          this.getCurtainIndexing(0) === this.state.currentTabSelection ? (
            <PurchaseOrderDocument
              docNumber={this.props.docNumber}
              key={this.getCurtainIndexing(0)}
              customerDetails={this.state.customerDetails}
              deliveryAddress={
                this.state.options.deliveryAddressType === "custom"
                  ? this.state.options.customAddress
                  : this.state.deliveryAddress
              }
              manufacturer={{
                lines: this.state.purchaseOrder.curtain.workOrder,
                details: { name: "N/A" },
              }}
              purchaseOrder={this.state.purchaseOrder}
              index={this.getCurtainIndexing(0)}
              options={this.state.options}
            />
          ) : null}
          {this.state.hasCurtains &&
            this.state.purchaseOrder.curtain.manufacturers.map((manu, index) => {
              if (this.getCurtainIndexing(index + 1) === this.state.currentTabSelection) {
                return (
                  <PurchaseOrderComponents
                    docNumber={this.props.docNumber}
                    key={this.getCurtainIndexing(index + 1)}
                    customerDetails={this.state.customerDetails}
                    deliveryAddress={
                      this.state.options.deliveryAddressType === "custom"
                        ? this.state.options.customAddress
                        : this.state.deliveryAddress
                    }
                    manufacturer={manu}
                    purchaseOrder={this.state.purchaseOrder}
                    index={this.getCurtainIndexing(index + 1)}
                    options={this.state.options}
                  />
                );
              } else return null;
            })}
          {this.state.hasCurtains &&
          this.getCurtainIndexing(this.state.purchaseOrder.curtain.manufacturers.length + 1) ===
            this.state.currentTabSelection ? (
            <PurchaseOrderComponents
              docNumber={this.props.docNumber}
              key={this.getCurtainIndexing(
                this.state.purchaseOrder.curtain.manufacturers.length + 1
              )}
              customerDetails={this.state.customerDetails}
              manufacturer={{
                lines: this.state.purchaseOrder.curtain.noManufacturers,
                details: { name: "N/A" },
              }}
              deliveryAddress={
                this.state.options.deliveryAddressType === "custom"
                  ? this.state.options.customAddress
                  : this.state.deliveryAddress
              }
              purchaseOrder={this.state.purchaseOrder}
              index={this.getCurtainIndexing(
                this.state.purchaseOrder.curtain.manufacturers.length + 1
              )}
              options={this.state.options}
            />
          ) : null}
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return { AlgoliaKey: state.key, projectDetails: state.projects.projectDetails };
};

export default withRouter(connect(mapStateToProps)(PurchaseOrder));
