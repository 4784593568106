// This Component displays a list of Branches and Contacts
// for a given Manufacturer

import React, { Component, Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import TabBar from "../TabBar";
import BranchList from "./BranchList";
import ContactList from "./ContactList";

class Branches extends Component {
  tabNames = ["branches"];

  render() {
    return (
      <Fragment>
        <Switch>
          {/* THIS ROUTE DISPLAYS CONTACTS IF BRANCHID AVAILABLE*/}
          <Route
            path="/manufacturers/:manufacturerId/branches/:branchId"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} selected="branches" />
                <div className="branch-tab">
                  <BranchList {...this.props} {...routeProps} />
                  <ContactList {...this.props} {...routeProps} />
                </div>
              </div>
            )}
          />
          <Route
            path="/manufacturers/:manufacturerId/branches"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} selected="branches" />
                <div className="branch-tab">
                  <BranchList {...this.props} {...routeProps} />
                  <div className="contact-container">
                    <div className="contact-header">
                      <h6>Contact List</h6>
                    </div>
                    <div className="empty-contacts">
                      <h3>Select a Branch or add new</h3>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
          {/* DEFAULT REDIRECT TAB */}
          <Route
            path="/manufacturers/:manufacturerId/"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} />
              </div>
            )}
          />
        </Switch>
      </Fragment>
    );
  }
}

export default withRouter(Branches);
