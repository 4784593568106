//Action Type Constants
export const TOGGLE_SIDENAV = "TOGGLE_SIDENAV";
export const POPULATE_LINKS = "POPULATE_LINKS";
export const TOGGLE_SUBNAV = "TOGGLE_SUBNAV";
//Action Creators
export function toggleSideNav() {
  return {
    type: TOGGLE_SIDENAV
  };
}

export function toggleSubNav() {
  return {
    type: TOGGLE_SUBNAV
  };
}

export function populateLinks(links) {
  return {
    type: POPULATE_LINKS,
    links: links
  };
}
