import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AccountingMenu from "./AccountingMenu";
import ConversationsMenu from "./ConversationsMenu";
import LegalMenu from "./LegalMenu";
import ReportsMenu from "./ReportsMenu";
import { MDBIcon } from "mdbreact";
import { toggleSubMenu } from "../../../actions/projectsActions";

class DocumentsList extends Component {
  clickHandler = selectedMenu => {
    if (this.props.subMenuCurrentlyOpen === selectedMenu) {
      this.props.dispatch(toggleSubMenu("none"));
    } else {
      this.props.dispatch(toggleSubMenu(selectedMenu));
    }
  };

  render() {
    const iconMargin = "mr-2"; // mr = "margin right" 2= 2rem

    return (
      <div className="subMenuLinksList">
        <ul>
          <li onClick={() => this.clickHandler("accounting")}>
            <MDBIcon
              className={iconMargin}
              icon={this.props.subMenuCurrentlyOpen === "accounting" ? "angle-up" : "angle-down"}
            />
            Accounting
          </li>
          {this.props.subMenuCurrentlyOpen === "accounting" ? <AccountingMenu /> : ""}
          <li onClick={() => this.clickHandler("conversations")}>
            <MDBIcon
              className={iconMargin}
              icon={this.props.subMenuCurrentlyOpen === "conversations" ? "angle-up" : "angle-down"}
            />
            Conversations
          </li>
          {this.props.subMenuCurrentlyOpen === "conversations" ? <ConversationsMenu /> : ""}
          <li onClick={() => this.clickHandler("legal")}>
            <MDBIcon
              className={iconMargin}
              icon={this.props.subMenuCurrentlyOpen === "legal" ? "angle-up" : "angle-down"}
            />
            Legal
          </li>
          {this.props.subMenuCurrentlyOpen === "legal" ? <LegalMenu /> : ""}
          <li onClick={() => this.clickHandler("reports")}>
            <MDBIcon
              className={iconMargin}
              icon={this.props.subMenuCurrentlyOpen === "reports" ? "angle-up" : "angle-down"}
            />
            Reports
          </li>
          {this.props.subMenuCurrentlyOpen === "reports" ? <ReportsMenu /> : ""}
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state.projects.documents;
};

export default withRouter(connect(mapStateToProps)(DocumentsList));
