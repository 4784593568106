import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { setLoaderState } from "../../../actions/loaderActions";
import { API } from "aws-amplify";
import algoliasearch from "algoliasearch";
import { setRefresh } from "../../../actions/algoliaActions";
import config from "../../../config";

class AddProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        {
          id: "jobDup",
          label: "Job to Duplicate",
          type: "AlgoliaSelect",
          index: `${config.algolia.prefix}projects_jobs_name_asc`,
          filters: "isDeleted:false AND projectId:" + this.props.match.params.id,
        },
        {
          id: "jobName",
          label: "New Job Name",
          type: "text",
          value: "",
        },
      ],
    };
  }

  APIMethod = async (formData) => {
    try {
      this.props.dispatch(setLoaderState(true));
      await this.handleDuplicateJob(formData.jobDup, formData.jobName);
      this.props.dispatch(setLoaderState(false));
    } catch (error) {
      throw error;
    }
  };

  handleDuplicateJob = async (jobId, jobName) => {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.handleDuplicateJob();
      }, 500);
    } else {
      this.props.dispatch(setLoaderState(true));
      try {
        const client = await algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
        const index = await client.initIndex(`${config.algolia.prefix}projects_jobs_name_asc`);
        const OldJobs = await index.search({
          query: "",
          filters: "isDeleted:false AND objectID:" + jobId,
          hitsPerPage: 1,
        });
        const jobToDuplicate = OldJobs.hits[0];
        const duplicateJobBody = {
          details: {
            ...jobToDuplicate.details,
            name: jobToDuplicate.details.name + " (Duplicated)",
          },
        };
        if (jobName !== "" && jobName !== undefined) {
          duplicateJobBody.details.name = jobName;
        }
        const JobResponse = await API.post(
          "telishadeAPI",
          `/project/${this.props.projectDetails.type}/${this.props.match.params.id}`,
          { body: duplicateJobBody }
        );
        const curtainProductId = await API.get("telishadeAPI", "/product/curtain");
        const blindProductId = await API.get("telishadeAPI", "/product/blind");
        let OldJobItemBlinds = await API.get(
          "telishadeAPI",
          `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${jobId}/items/blind`
        );
        let OldJobItemCurtain = await API.get(
          "telishadeAPI",
          `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${jobId}/items/curtain`
        );
        let blindPromises = [];
        let curtainPromises = [];
        let chunkMultiples = 25;
        for (let i = 0; i < OldJobItemBlinds.items.length; i++) {
          let JobItemPostBody = {
            productId: blindProductId.id,
            details: { ...OldJobItemBlinds.items[i].details },
          };
          blindPromises.push(
            API.post(
              "telishadeAPI",
              `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${JobResponse.id}/item/${blindProductId.type}`,
              { body: JobItemPostBody }
            )
          );
          if ((i + 1) % chunkMultiples === 0) {
            await Promise.all(blindPromises);
          }
        }
        for (let i = 0; i < OldJobItemCurtain.items.length; i++) {
          let JobItemPostBody = {
            productId: curtainProductId.id,
            details: { ...OldJobItemCurtain.items[i].details },
          };
          curtainPromises.push(
            API.post(
              "telishadeAPI",
              `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${JobResponse.id}/item/${curtainProductId.type}`,
              { body: JobItemPostBody }
            )
          );
          if ((i + 1) % chunkMultiples === 0) {
            await Promise.all(curtainPromises);
          }
        }
        await Promise.all(blindPromises);
        await Promise.all(curtainPromises);
        this.props.history.push(`/projects/${this.props.match.params.id}`);
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      } catch (e) {
        console.error(e);
      } finally {
        this.props.dispatch(setLoaderState(false));
      }
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.state.fields}
        apiMethod={this.APIMethod}
        header="Create New Job from Pre-existing Job"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.projects, AlgoliaKey: state.key };
};

export default withRouter(connect(mapStateToProps)(AddProject));
