import React, { Component } from "react";
import { connect } from "react-redux";
import CurtainComponentList from "./CurtainComponentList";
import { Switch, Route } from "react-router-dom";
import { API } from "aws-amplify";
//Font Awesome Icons
import { setCurrentProduct, setProductID } from "../../../actions/productsActions";
import { setLoaderState } from "../../../actions/loaderActions";
import CurtainListView from "./CurtainListView";
import ProductDetailView from "../ProductDetailView";
import CreateCurtainComponentRoutes from "./CreatePopUps/CreateCurtainComponentRoutes";
import ColourList from "../ColourList/ColourList";
class Curtains extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(setCurrentProduct("Curtains"));
    this.getProductID();
  }

  componentDidMount() {
    this.props.dispatch(setCurrentProduct("Curtains"));
    this.getProductID();
  }

  componentWillUnmount() {}

  async getProductID() {
    try {
      this.props.dispatch(setLoaderState(true));
      const response = await API.get("telishadeAPI", "/product/curtain");
      this.props.dispatch(setProductID(response.id));
      this.props.dispatch(setLoaderState(false));
    } catch (err) {
      try {
        this.props.dispatch(setLoaderState(true));
        const response = await API.post("telishadeAPI", "/product/curtain");
        this.props.dispatch(setProductID(response.id));
        this.props.dispatch(setLoaderState(false));
      } catch (e) {
        console.error(e);
      }
      console.error(err);
      this.props.dispatch(setLoaderState(false));
    }
  }

  render() {
    return (
      <div className="curtains">
        <Switch>
          <Route
            exact
            path="/products/curtains/:component/add"
            component={CreateCurtainComponentRoutes}
          />
        </Switch>
        <div className="productComponentList">
          <CurtainComponentList />
        </div>
        <div className="productContainer">
          <Switch>
            <Route path="/products/curtains/:component/add" component={CurtainListView} />
            <Route path="/products/:product/colour-list/:id" component={ColourList} />
            <Route path="/products/:product/:component/:id" component={ProductDetailView} />
            <Route path="/products/curtains" component={CurtainListView} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state.products;
};

export default connect(mapStateToProps)(Curtains);
