import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { API } from "aws-amplify";
//Font Awesome Icons
import BlindComponentList from "./BlindComponentList";
import { setCurrentProduct, setProductID } from "../../../actions/productsActions";
import ProductDetailView from "../ProductDetailView";
import BlindsListView from "./BlindsListView";
import PriceList from "../PriceList/BlindPriceList";
import { setLoaderState } from "../../../actions/loaderActions";
import CreateBlindComponentRoutes from "./CreatePopUps/CreateBlindComponentRoutes";
import ColourList from "../ColourList/ColourList";

class Blinds extends Component {
  constructor(props) {
    super(props);

    this.props.dispatch(setCurrentProduct("Blinds"));
    this.getProductID();
  }

  componentDidMount() {
    this.props.dispatch(setCurrentProduct("Blinds"));
    this.getProductID();
  }

  async getProductID() {
    try {
      this.props.dispatch(setLoaderState(true));
      const response = await API.get("telishadeAPI", "/product/blind");
      this.props.dispatch(setProductID(response.id));
      this.props.dispatch(setLoaderState(false));
    } catch (err) {
      try {
        this.props.dispatch(setLoaderState(true));
        const response = await API.post("telishadeAPI", "/product/blind");
        this.props.dispatch(setProductID(response.id));
        this.props.dispatch(setLoaderState(false));
      } catch (e) {
        console.error(e);
      }
      console.error(err);
      this.props.dispatch(setLoaderState(false));
    }
  }

  componentWillUnmount() {}

  render() {
    return (
      <div className="blinds">
        <Switch>
          <Route
            exact
            path="/products/blinds/:component/add"
            component={CreateBlindComponentRoutes}
          />
        </Switch>
        <div className="productComponentList">
          <BlindComponentList />
        </div>
        <div className="productContainer">
          <Switch>
            {/*Inital Route Check  */}
            <Route exact path="/products/blinds/:component/add" component={BlindsListView} />
            <Route path="/products/:product/colour-list/:id" component={ColourList} />
            <Route path="/products/blinds/pricing-groups/:id" component={PriceList} />
            <Route path="/products/:product/:component/:id" component={ProductDetailView} />
            <Route path="/products/blinds/" component={BlindsListView} />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state.products;
};

export default connect(mapStateToProps)(Blinds);
