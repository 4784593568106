import { LOGIN, LOGOUT, REDIRECT } from "../actions/authenticationActions";

// Initial state
const initialState = {
  isAuthenticated: false,
  successRedirect: "/"
};

// Authentication Reducer
export default function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated
      };
    case REDIRECT:
      return {
        ...state,
        successRedirect: action.successRedirect
      };
    default:
      return state;
  }
}
