import React, { Component } from "react";
import PopUpForm from "../../../Generic/PopUpDialog/PopUpForm";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { setRefresh } from "../../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../../actions/loaderActions";
import config from "../../../../config";

class CreateBlindStylePopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        {
          id: "name",
          label: "Style Name",
          type: "text",
          tooltip: "Please Enter Blind Style Name",
          required: true,
          value: ""
        },
        {
          id: "photo",
          label: "Photo URL",
          type: "text",
          tooltip: "Please Enter Photo URL",
          required: false,
          value: ""
        },
        {
          id: "priceLists",
          label: "Associated Price Lists",
          type: "multiSelect",
          index: `${config.algolia.prefix}products_price_lists_name_asc`,
          value: []
        },
        {
          id: "associatedServices",
          label: "Associated Services",
          type: "multiSelect",
          index: `${config.algolia.prefix}products_services_fees_name_asc`,
          value: []
        }
      ]
    };
  }
  handleCancelClick = () => {
    this.props.history.goBack();
  };

  handleSubmitClick = formData => {
    const body = {
      details: {
        name: formData.name,
        photo: formData.photo,
        priceLists: formData.priceLists,
        associatedServices: formData.associatedServices
      }
    };
    return API.post("telishadeAPI", "/product/blind/component/style", { body });
  };

  APIMethod = async formData => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));
      const body = {
        details: {
          name: formData.name,
          photo: formData.photo,
          priceLists: formData.priceLists,
          associatedServices: formData.associatedServices
        }
      };
      this.props.history.push(`/products/blinds/style`);
      await API.post("telishadeAPI", "/product/blind/component/style", { body });
      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.dispatch(setRefresh(true));
    } catch (e) {
      this.props.dispatch(setAlgoliaLoaderState(false));
      throw e;
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.state.fields}
        apiMethod={this.APIMethod}
        header="Add New Blind Style"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

export default connect()(CreateBlindStylePopUp);
