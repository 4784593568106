import React from "react";
import {
  Button,
  Menu,
  MenuItem,
  Popover,
  FormControl,
  FilledInput,
  InputLabel
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

import "./OptionsFormatter.css";
import { useDispatch } from "react-redux";
import { setWorksheetRow } from "../../../../actions/worksheetActions";

const OptionsFormatter = props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(props.value || { note: "" });

  const [noteAnchorEl, setNoteAnchorEl] = React.useState(null);

  const dispatch = useDispatch();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNoteInputChange = event => {
    setValue({ ...value, note: event.target.value });
  };

  const handleAddNoteClick = event => {
    setNoteAnchorEl(event.currentTarget);
  };

  const handleNoteSend = () => {
    const newRow = { ...props.row, options: value };
    dispatch(setWorksheetRow(newRow, props.row.itemNo));
  };

  if (props.isScrolling) {
    return <div />;
  } else {
    return (
      <div>
        <Button onClick={handleClick}>
          <FontAwesomeIcon icon={faEllipsisV} />
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleAddNoteClick}>
            {value.note === "" ? "Add Note" : "Edit Note"}
          </MenuItem>
        </Menu>
        <Popover
          anchorEl={noteAnchorEl}
          open={Boolean(noteAnchorEl)}
          onClose={() => setNoteAnchorEl(null)}
          anchorOrigin={{ vertical: "center", horizontal: "left" }}
          transformOrigin={{ vertical: "center", horizontal: "left" }}
        >
          <div className="itemNotePanel">
            <FormControl variant="filled">
              <InputLabel htmlFor="component-filled">Item Note</InputLabel>
              <FilledInput
                id="component-filled"
                multiline
                onChange={handleNoteInputChange}
                value={value.note}
                onBlur={handleNoteSend}
              />
            </FormControl>
          </div>
        </Popover>
      </div>
    );
  }
};

export default OptionsFormatter;
