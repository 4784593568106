//Action Type Constants
export const POPULATE_ACCOUNT_DETAILS = "POPULATE_ACCOUNT_DETAILS";

//Action Creators
export function populateAccountDetails(details) {
  return {
    type: POPULATE_ACCOUNT_DETAILS,
    details
  };
}
