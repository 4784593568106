// *** Action Type Constants ***
export const SELECT_TAB = "STAFF::SELECT_TAB";
export const SELECT_STAFF = "STAFF::SELECT_STAFF";
export const SELECT_ADDRESS = "STAFF::SELECT_ADDRESS";
export const UPDATE_STAFF_DETAILS = "STAFF::UPDATE_DETAILS";
export const UPDATE_ADDRESS_DETAILS = "STAFF::UPDATE_ADDRESS_DETAILS";
export const SET_STAFFLIST_LOADING_STATE = "STAFF::SET_STAFFLIST_LOADING_STATE";
export const SET_ADDRESSLIST_LOADING_STATE =
  "STAFF::SET_ADDRESSLIST_LOADING_STATE";

// *** Action Creators ***
export function selectStaff(id) {
  return {
    type: SELECT_STAFF,
    id
  };
}

export function selectTab(tabText) {
  return {
    type: SELECT_TAB,
    selectedTabText: tabText
  };
}

export function selectAddress(id) {
  return {
    type: SELECT_ADDRESS,
    id
  };
}

export function updateAddressDetails(details) {
  return {
    type: UPDATE_ADDRESS_DETAILS,
    details
  };
}

export function updateStaffDetails(details) {
  return {
    type: UPDATE_STAFF_DETAILS,
    details
  };
}
