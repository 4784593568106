import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";

class JobAccountingMenu extends Component {
  render() {
    return (
      <div className="subMenuLinksList-expanded">
        <ul>
          <li>
            <NavLink
              to={
                "/projects/" +
                this.props.match.params.id +
                "/" +
                this.props.match.params.JobNo +
                "/documents/retail-quote"
              }
            >
              Retail Quote <div className="available-documents">(1)</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={
                "/projects/" +
                this.props.match.params.id +
                "/" +
                this.props.match.params.JobNo +
                "/documents/wholesale-quote"
              }
            >
              Wholesale Quote <div className="available-documents">(1)</div>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={
                "/projects/" +
                this.props.match.params.id +
                "/" +
                this.props.match.params.JobNo +
                "/documents/purchase-order"
              }
            >
              Purchase Order <div className="available-documents">(1)</div>
            </NavLink>
          </li>
          <li>
            <div className="not-implemented">Commercial Invoice</div>
          </li>
          <li>
            <div className="not-implemented">Proforma Invoice</div>
          </li>
          <li>
            <div className="not-implemented">Supplier Quote</div>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(JobAccountingMenu);
