import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import JobInfo from "./JobInfo";
import JobProducts from "./JobProducts";
import { API } from "aws-amplify";
import {
  toggleMultiselect,
  toggleSelectAll,
  setJobDetails,
  setProjectDetails,
} from "../../../actions/projectsActions";
import EditJob from "../PopUps/EditJob";
import DeleteJob from "../PopUps/DeleteJob";
import JobMenu from "./JobMenu/JobMenu";
import JobQuoteWrapper from "../Documents/JobDocuments/Quote/JobQuoteWrapper";
import JobPurchaseOrder from "../Documents/JobDocuments/PurchaseOrder/JobPurchaseOrder";
import JobMarginReport from "../Documents/JobDocuments/MarginReport/JobMarginReport";
import { setLoaderState } from "../../../actions/loaderActions";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { MDBBtn } from "mdbreact";

class Jobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failed: false,
      error: undefined,
      toast: false,
      toastContent: "",
      toastTimeout: undefined,
    };
    if (
      this.props.projectDetails === undefined ||
      this.props.projectDetails === {} ||
      this.props.projectDetails.objectId !== this.props.match.params.id
    ) {
      this.getLinkProjectDetails(this.props.match.params.id);
      this.getLinkJobDetails(this.props.match.params.id, this.props.match.params.JobNo);
    }
  }
  //TODO: Make Global Toast through Redux
  displayToast = (toastContent) => {
    if (this.state.toastTimeout) {
      clearTimeout(this.state.toastTimeout);
    }
    this.setState({ toast: false }, () => {
      this.setState({ toast: true, toastContent });
    });
    this.setState({
      toastTimeout: setTimeout(() => {
        this.setState({ toast: false });
      }, 3000),
    });
  };

  getLinkProjectDetails = async (projectId) => {
    try {
      this.props.dispatch(setLoaderState(true));
      const response = await API.get("telishadeAPI", "/project/commercial/" + projectId);
      this.props.dispatch(setProjectDetails(response));
      this.props.dispatch(setLoaderState(false));
    } catch (e) {
      try {
        const response = await API.get("telishadeAPI", "/project/domestic/" + projectId);
        this.props.dispatch(setProjectDetails(response));
        this.props.dispatch(setLoaderState(false));
      } catch (error) {
        this.setState({ failed: true, error });
        this.props.dispatch(setLoaderState(false));
        console.error(error);
        console.error(e);
      }
    }
  };

  getLinkJobDetails = async (projectId, jobId) => {
    try {
      this.props.dispatch(setLoaderState(true));
      const response = await API.get(
        "telishadeAPI",
        "/project/commercial/" + projectId + "/job/" + jobId
      );
      this.props.dispatch(setJobDetails(response));
      this.props.dispatch(setLoaderState(false));
    } catch (e) {
      try {
        const response = await API.get(
          "telishadeAPI",
          "/project/domestic/" + projectId + "/job/" + jobId
        );
        this.props.dispatch(setJobDetails(response));
        this.props.dispatch(setLoaderState(false));
      } catch (error) {
        this.setState({ failed: true, error });
        this.props.dispatch(setLoaderState(false));
        console.error(error);
        console.error(e);
      }
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state !== nextState) {
      return true;
    } else {
      return false;
    }
  }

  handleDuplicateJob = async () => {
    this.props.dispatch(setLoaderState(true));
    const jobToDuplicate = this.props.jobDetails;
    const duplicateJobBody = {
      details: {
        ...jobToDuplicate.details,
        name: jobToDuplicate.details.name + " (Duplicated)",
      },
    };
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.handleDuplicateJob();
      }, 500);
    } else {
      try {
        const JobResponse = await API.post(
          "telishadeAPI",
          `/project/${this.props.projectDetails.type}/${this.props.match.params.id}`,
          { body: duplicateJobBody }
        );
        const curtainProductId = await API.get("telishadeAPI", "/product/curtain");
        const blindProductId = await API.get("telishadeAPI", "/product/blind");
        let OldJobItemBlinds = await API.get(
          "telishadeAPI",
          `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${this.props.match.params.JobNo}/items/blind`
        );
        let OldJobItemCurtain = await API.get(
          "telishadeAPI",
          `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${this.props.match.params.JobNo}/items/curtain`
        );
        let blindPromises = [];
        let curtainPromises = [];
        let chunkMultiples = 25;
        for (let i = 0; i < OldJobItemBlinds.items.length; i++) {
          let JobItemPostBody = {
            productId: blindProductId.id,
            details: { ...OldJobItemBlinds.items[i].details },
          };
          blindPromises.push(
            API.post(
              "telishadeAPI",
              `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${JobResponse.id}/item/${blindProductId.type}`,
              { body: JobItemPostBody }
            )
          );
          if ((i + 1) % chunkMultiples === 0) {
            await Promise.all(blindPromises);
          }
        }
        for (let i = 0; i < OldJobItemCurtain.items.length; i++) {
          let JobItemPostBody = {
            productId: curtainProductId.id,
            details: { ...OldJobItemCurtain.items[i].details },
          };
          curtainPromises.push(
            API.post(
              "telishadeAPI",
              `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${JobResponse.id}/item/${curtainProductId.type}`,
              { body: JobItemPostBody }
            )
          );
          if ((i + 1) % chunkMultiples === 0) {
            await Promise.all(curtainPromises);
          }
        }
        await Promise.all(blindPromises);
        await Promise.all(curtainPromises);
        this.props.history.push(`/projects/${this.props.match.params.id}`);
      } catch (e) {
        console.error(e);
      } finally {
        this.props.dispatch(setLoaderState(false));
      }
    }
  };

  get jobHeader() {
    return (
      <div className="jobHeader">
        <FontAwesomeIcon
          id="backArrow"
          icon={faArrowLeft}
          onClick={() => this.props.history.push("/projects/" + this.props.match.params.id)}
        />
        <h2>
          {this.props.jobDetails.details !== undefined
            ? this.props.jobDetails.details.name
            : "Loading..."}
        </h2>
        <Switch>
          <Route
            exact
            path="/projects/:id/:JobNo"
            component={() => (
              <Fragment>
                <MDBBtn onClick={this.handleDuplicateJob} color="blue" id="jobBtn">
                  Duplicate Job
                </MDBBtn>
              </Fragment>
            )}
          />
        </Switch>
      </div>
    );
  }

  getDocumentNumberFor(type) {
    if (
      this.props.projectDetails.details !== undefined &&
      "number" in this.props.projectDetails.details &&
      this.props.jobDetails.details !== undefined &&
      "number" in this.props.jobDetails.details
    ) {
      // Special request from Sam: "Have quote numbers start from 2000"
      if (type === "retail-quote") {
        return `QR-${2000 + this.props.projectDetails.details.number}-${
          this.props.jobDetails.details.number
        }`;
      }

      if (type === "wholesale-quote") {
        return `QW-${2000 + this.props.projectDetails.details.number}-${
          this.props.jobDetails.details.number
        }`;
      }

      if (type === "purchase-order") {
        return `PO-${2000 + this.props.projectDetails.details.number}-${
          this.props.jobDetails.details.number
        }`;
      }
    }

    return "TBC";
  }

  padNumber(num, size, qty = 1) {
    // Adds leading zeros to a passed in number
    var s = num + "";
    while (s.length < size) s = "0" + s;
    if (qty > 1) {
      s = s + "-" + this.padNumber(num + qty - 1, 3);
    }
    return s;
  }

  componentWillUnmount = () => {
    this.props.dispatch(setJobDetails({}));
  };

  HandleOnClick = () => {
    this.props.dispatch(toggleMultiselect(!this.props.multiselect));
  };

  HandleSelectAllClick = () => {
    this.props.dispatch(toggleSelectAll(!this.props.selectAll));
  };

  render() {
    if (this.state.failed) {
      return <ErrorHandler error={this.state.error} />;
    } else
      return (
        <div className="jobContainer">
          {this.state.toast && <div className="toaster">{this.state.toastContent}</div>}
          <Switch>
            <Route path="/projects/:id/:JobNo/delete" component={DeleteJob} />
            <Route
              path="/projects/:id/:JobNo/edit"
              component={() => (
                <EditJob
                  projectType={this.props.projectDetails.type}
                  details={this.props.jobDetails}
                />
              )}
            />
          </Switch>
          <div className="jobMenu">
            <JobMenu match={this.props.match} />
          </div>
          <div className="jobContent">
            <Switch>
              <Route
                path="/projects/:id/:JobNo/documents/(retail-quote)"
                component={(routeProps) => (
                  <Fragment>
                    <div className="jobContainer">{this.jobHeader}</div>
                    <JobQuoteWrapper
                      {...routeProps}
                      docNumber={this.getDocumentNumberFor("retail-quote")}
                    />
                  </Fragment>
                )}
              />
              <Route
                path="/projects/:id/:JobNo/documents/(wholesale-quote)"
                component={(routeProps) => (
                  <Fragment>
                    <div className="jobContainer">{this.jobHeader}</div>
                    <JobQuoteWrapper
                      {...routeProps}
                      docNumber={this.getDocumentNumberFor("wholesale-quote")}
                    />
                  </Fragment>
                )}
              />
              <Route
                path="/projects/:id/:JobNo/documents/purchase-order"
                component={(routeProps) => (
                  <Fragment>
                    <div className="jobContainer">{this.jobHeader}</div>
                    <JobPurchaseOrder
                      {...routeProps}
                      docNumber={this.getDocumentNumberFor("purchase-order")}
                    />
                  </Fragment>
                )}
              />
              <Route
                path="/projects/:id/:JobNo/documents/margin-report"
                component={() => (
                  <Fragment>
                    <div className="jobContainer">{this.jobHeader}</div>
                    <JobMarginReport />
                  </Fragment>
                )}
              />
              <Route
                path="/projects/:id/:JobNo"
                render={(props) => (
                  <Fragment>
                    {this.jobHeader}
                    <JobInfo {...props} jobDetails={this.props.jobDetails.details} />
                    <JobProducts displayToast={this.displayToast} />
                  </Fragment>
                )}
              />
            </Switch>
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return { ...state.projects, AlgoliaKey: state.key };
};
export default connect(mapStateToProps)(Jobs);
