import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";
import { API } from "aws-amplify";
import { setRefresh } from "../../../actions/algoliaActions";

class AddProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        {
          id: "name",
          label: "Job Name",
          type: "text",
          tooltip: "Job Name",
          required: true,
          value: ""
        },
        {
          id: "buildingUnit",
          label: "Building Unit",
          type: "text",
          tooltip: "Building Unit",
          required: false,
          value: ""
        },
        {
          id: "level",
          label: "Building Level",
          type: "text",
          tooltip: "Building Level",
          required: false,
          value: ""
        },
        {
          id: "floorPlanType",
          label: "Floor Plan Type",
          type: "text",
          tooltip: "Floor Plan Type",
          required: false,
          value: ""
        },
        {
          id: "status",
          label: "Job Status",
          type: "select",
          options: [
            { name: "Quote Requested", value: "quoteRequested" },
            { name: "Appointment Requested", value: "appointmentRequested" },
            { name: "Appointment Scheduled", value: "appointmentScheduled" },
            { name: "Measures Checked", value: "measuresChecked" },
            { name: "Quote Submitted", value: "quoteSubmitted" },
            { name: "Quote Accepted", value: "quoteAccepted" },
            { name: "Deposit Taken", value: "depositTaken" },
            { name: "Order Submitted", value: "orderSubmitted" },
            { name: "Order Confirmed", value: "orderConfirmed" },
            { name: "Products Delivered to Installer", value: "productsDeliveredToInstaller" },
            { name: "Installation Complete", value: "installationComplete" },
            { name: "Final Payment Made", value: "finalPaymentMade" },
            { name: "Job Complete", value: "jobComplete" }
          ],
          required: false,
          value: "quoteRequested"
        }
      ]
    };
  }

  APIMethod = async formData => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));

      // // Re-map form data
      // formData.options = { discount: formData.discount };
      // delete formData.discount;

      formData.options = {};
      await API.post(
        "telishadeAPI",
        "/project/" + this.props.type + "/" + this.props.match.params.id,
        {
          body: { details: formData }
        }
      );

      this.props.history.push(`/projects/${this.props.match.params.id}`);
      this.props.dispatch(setAlgoliaLoaderState(false));
    } catch (error) {
      throw error;
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.state.fields}
        apiMethod={this.APIMethod}
        header="Add New Job"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

const mapStateToProps = state => {
  return state.projects.projectDetails;
};

export default withRouter(connect(mapStateToProps)(AddProject));
