import { SET_FIRSTNAME } from "../actions/dashboardActions";

// Initial state
const initialState = {
  tenantDetails: {
    firstName: ""
  }
};

// Customers Reducer
export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case SET_FIRSTNAME:
      return {
        ...state,
        tenantDetails: {
          ...state.tenantDetails,
          firstName: action.name
        }
      };

    default:
      return state;
  }
}
