import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { populateLinks, toggleSideNav } from "../../actions/sideNavActions";
import DefaultLinks from "./SignedInLinks";
import "./SideNav.css";
import SubSideNav from "./SubSideNav";

class SideNav extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(populateLinks(DefaultLinks));
  }

  toggleNav = () => {
    this.props.dispatch(toggleSideNav());
  };

  render() {
    return (
      <div
        className={this.props.isMenuOpen ? "sideNav Open" : "sideNav Closed"}
      >
        <nav>
          <ul>
            {this.props.links.map((link, key) => {
              if (link.URL === "/")
                return (
                  <li key={key}>
                    <NavLink
                      exact
                      activeClassName="sideNavSelected"
                      to={link.URL}
                      className={link.HTMLID}
                      onClick={this.toggleNav}
                    >
                      {link.text}
                    </NavLink>
                  </li>
                );
              else if (link.URL === "/products/")
                return (
                  <li key={key}>
                    <NavLink
                      activeClassName="sideNavSelected"
                      to={link.URL}
                      className={link.HTMLID}
                    >
                      {link.text}
                    </NavLink>
                  </li>
                );
              else
                return (
                  <li key={key}>
                    <NavLink
                      activeClassName="sideNavSelected"
                      to={link.URL}
                      className={link.HTMLID}
                      onClick={this.toggleNav}
                    >
                      {link.text}
                    </NavLink>
                  </li>
                );
            })}
          </ul>
        </nav>
        {this.props.links.map((link, key) => {
          return "subMenu" in link && link.subMenu !== undefined ? (
            <SubSideNav
              key={key}
              links={link.subMenu.links}
              isSubMenuOpen={this.props.isSubMenuOpen}
              isMenuOpen={this.props.isMenuOpen}
            />
          ) : (
            ""
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state.sideNav;
};

export default connect(mapStateToProps)(SideNav);
