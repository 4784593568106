import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import "./Projects.css";
import "./Documents/Documents.css";
import ProjectDetails from "./ProjectDetails";
import Jobs from "./Jobs/Jobs";
import ProjectWorksheet from "./Worksheet/ProjectWorksheet";
import JobWorksheet from "./Worksheet/JobWorksheet";
import JobNotes from "./Notes/JobNotes";
import ProjectNotes from "./Notes/ProjectNotes";
import AddProject from "./PopUps/AddProject";
import algoliasearch from "algoliasearch";
import config from "../../config";
import BulkItemEdit from "./BulkEdit/BulkEdit";

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultRoute: undefined
    };
    this.getFirstProjectforRouting();
  }

  async getFirstProjectforRouting() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getFirstProjectforRouting();
      }, 500);
    } else {
      try {
        const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
        const projectIndex = client.initIndex(`${config.algolia.prefix}projects_created_at_desc`);
        const projects = await projectIndex.search({
          query: "",
          filters: `isDeleted:false AND type:commercial`,
          hitsPerPage: 1
        });
        const firstProject = projects.hits[0];
        this.setState({ defaultRoute: firstProject.objectID });
      } catch (e) {
        console.error(e);
      }
    }
  }

  render() {
    return (
      <div className="projects">
        <Switch>
          <Route path="/projects/add" component={AddProject} />
          <Route exact path="/projects/:id" component={ProjectDetails} />
          <Route path="/projects/:id/bulk-edit" component={BulkItemEdit} />
          <Route exact path="/projects/:id/add" component={ProjectDetails} />
          <Route exact path="/projects/:id/duplicate-job" component={ProjectDetails} />
          <Route exact path="/projects/:id/edit" component={ProjectDetails} />
          <Route exact path="/projects/:id/delete" component={ProjectDetails} />
          <Route exact path="/projects/:id/worksheet" component={ProjectWorksheet} />
          <Route exact path="/projects/:id/edit" component={ProjectDetails} />
          <Route path="/projects/:id/documents" component={ProjectDetails} />
          <Route exact path="/projects/:id/" component={ProjectDetails} />
          <Route path="/projects/:id/notes" component={ProjectNotes} />
          <Route path="/projects/:id/:JobNo/worksheet" component={JobWorksheet} />
          <Route path="/projects/:id/:JobNo/notes" component={JobNotes} />
          <Route path="/projects/:id/:JobNo/edit" component={Jobs} />
          <Route path="/projects/:id/:JobNo/delete" component={Jobs} />
          <Route path="/projects/:id/:JobNo" component={Jobs} />
          <Redirect
            to={this.state.defaultRoute ? `/projects/${this.state.defaultRoute}` : "/projects"}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.projects, AlgoliaKey: state.key };
};

export default withRouter(connect(mapStateToProps)(Projects));
