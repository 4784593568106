import React, { Component } from "react";
import { MDBAlert } from "mdbreact";

export default class Alert extends Component {
  render() {
    return (
      <MDBAlert color="danger">
        Oops! Something went wrong.
        <br /> Please refresh the page
      </MDBAlert>
    );
  }
}
