import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SearchableList from "../../Generic/Algolia/SearchableList";
import { setJobDetails } from "../../../actions/projectsActions";
import config from "../../../config";
import "../JobList/JobList.css";
import ReorderJobList from "./ReorderJobList";

class JobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reorder: false
    };
  }
  HitComponent = ({ hit }) => {
    const listItemClickHandler = () => {
      this.props.history.push("/projects/" + this.props.match.params.id + "/" + hit.objectID);
      this.props.dispatch(setJobDetails(hit));
    };

    return (
      <p className="jobListWrapper" onClick={listItemClickHandler}>
        <strong>
          <span className="jobListNumber">{`JobNo:${hit.details.number}`}</span>
          <span className="jobListName">
            {`${hit.details.name}`}
            <span className="jobListStatus">{`Status: ${hit.details.status}`}</span>
          </span>
        </strong>
      </p>
    );
  };

  render() {
    return (
      <div className="jobList">
        {this.state.reorder ? (
          <ReorderJobList onSave={() => this.setState({ reorder: false })} />
        ) : (
          <SearchableList
            appId={`${config.algolia.appId}`}
            index={`${config.algolia.prefix}projects_jobs_number_asc`}
            hitComponent={this.HitComponent}
            filters={"isDeleted:false AND projectId:" + this.props.match.params.id}
            hitsPerPage={1000}
            option={[{ text: "Reorder", onClickHandler: () => this.setState({ reorder: true }) }]}
          />
        )}
      </div>
    );
  }
}

export default withRouter(connect()(JobList));
