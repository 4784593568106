import React, { Component } from "react";
import { connect } from "react-redux";
import PopUp from "../../Generic/PopUpDialog/PopUp";
import { addressFields } from "../fields";
import { API } from "aws-amplify";
import { setRefresh } from "../../../actions/algoliaActions";
import { withRouter } from "react-router";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";

class DeleteAddress extends Component {
  get fields() {
    // Make a Deep copy of the Fields that will be passed in
    // for editing
    let fields = JSON.parse(JSON.stringify(addressFields));
    const details = { ...this.props.details };

    // AddressType and Name are stored directly in details,
    // whereas the rest of the address is stored in details.address
    for (let i = 0; i < fields.length; i++) {
      // Match the field ID with the value kept in details.address:
      fields[i].value = details.address[fields[i].id];

      // If the ID couldn't be found in details.address, search
      // one step above i.e. for name and AddressType
      if (!fields[i].value) {
        fields[i].value = details[fields[i].id];
      }
    }
    return fields;
  }

  APIMethod = async () => {
    try {
      const { staffId, addressId } = this.props.match.params;
      this.props.dispatch(setAlgoliaLoaderState(true));
      await API.del("telishadeAPI", `/staff/${staffId}/address/${addressId}`);
      this.props.history.replace(`/staff/${staffId}/addresses/`);
      this.props.dispatch(setRefresh(true));
    } catch (e) {
      throw e;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUp
        header="Delete Address?"
        body={"Are you sure you want to delete this address?"}
        button="Delete"
        buttoncolour="red"
        button2="cancel"
        buttonClickHandler={this.APIMethod}
        button2ClickHandler={undefined}
      />
    );
  }
}

const mapStateToProps = state => state.staff.address;
export default withRouter(connect(mapStateToProps)(DeleteAddress));
