import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LetterHead from "../../../TableComponents/LetterHead";
import JobHeader from "../../JobHeader";
import BlindsSummary from "./QuoteComponents/BlindsSummary";
import BlindsBillOfRates from "./QuoteComponents/BlindsBillOfRates";
import BlindsTotals from "./QuoteComponents/BlindsTotals";
import BlindsDetailedSchedule from "./QuoteComponents/BlindsDetailedSchedule";
import DiscountView from "../DiscountView/DiscountView";
import Terms from "../../../TableComponents/Terms";
import { API } from "aws-amplify";
import ErrorHandler from "../../../../../ErrorHandler/ErrorHandler";
import Modal from "../../../../PopUps/Modal";
import { changeCurrrentProduct } from "../../../../../../actions/worksheetActions";
import {
  toggleDiscountView,
  storeBlindJobQuotePayload
} from "../../../../../../actions/documentsActions";
import {
  emptyCustomer,
  emptyJobBranch,
  emptyBranchContact
} from "../../../../../Customers/CustomerModels";
import QuoteNotes from "./QuoteComponents/QuoteNotes";

class QuoteBlinds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: emptyCustomer,
      jobBranch: emptyJobBranch,
      branchContact: emptyBranchContact,
      response: undefined,
      blindsReceived: false,
      customerInfoReceived: false,
      failed: false,
      itemsMissingComponents: false,
      listOfErrors: "",
      QuoteMissingItems: false,
      errorWithPricing: false,
      error: "",
      jobDiscount: undefined
    };
    this._isMounted = false;
    this.initialiseState();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.documents.discountView !== prevProps.documents.discountView &&
      this.props.documents.discountView === false
    ) {
      if (this.props.projects.jobDetails) {
        this.setState({ jobDiscount: this.props.projects.jobDetails.details.options.discount });
      } else {
        this.setState(
          {
            customer: emptyCustomer,
            jobBranch: emptyJobBranch,
            branchContact: emptyBranchContact,
            response: undefined,
            blindsReceived: false,
            failed: false,
            itemsMissingComponents: false,
            listOfErrors: "",
            QuoteMissingItems: false,
            errorWithPricing: false,
            error: ""
          },
          () => this.initialiseState()
        );
      }
    }
  }

  async initialiseState() {
    try {
      const { jobDetails } = this.props.projects;
      if (jobDetails !== undefined && jobDetails.id !== undefined) {
        await this.retrieveBlindItems();
        await this.retrieveCustomerInfo();
        this.handleErrors();
      } else {
        setTimeout(() => {
          this.initialiseState();
        }, 500);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async retrieveBlindItems() {
    try {
      let blinds = undefined;
      const { projectDetails, jobDetails } = this.props.projects;
      blinds = await this.getFromAPI(
        `/project/${projectDetails.type}/${projectDetails.id}/job/${jobDetails.id}/quote/blind`
      );
      const floatedResponse = this.handleQuoteResponse(blinds);
      this.props.dispatch(storeBlindJobQuotePayload(floatedResponse));

      if (this._isMounted) {
        this.setState({
          response: floatedResponse,
          blindsReceived: true,
          jobDiscount: blinds.discounts
        });
      }
    } catch (error) {
      if ("response" in error) {
        if (
          error.response.data.message === "No method found matching route  for http method GET."
        ) {
          error.response.data.message = "missing customer for quote";
        }

        this.setState({
          blindsReceived: false,
          failed: true,
          error: error.response.data
        });
      }
    }
  }

  async getFromAPI(requestString) {
    try {
      const response = API.get("telishadeAPI", requestString);
      return response;
    } catch (e) {
      throw e;
    }
  }

  handleMissingComponents = response => {
    let listOfErrors = "Please fix the following errors before continuing:\n";
    let arrayOfErrors = [{}];
    response.errors.forEach(itemResponse => {
      if ("error" in itemResponse && "message" in itemResponse.error) {
        let itemMessage = itemResponse.error.message.replace(
          /ProjectJobItemMissingComponent: Item /g,
          ""
        );

        // Check for Pricelist error:
        if (itemResponse.error.message === "No matching price list") {
          if (response.quote) {
            for (var key in response.quote) {
              if (response.quote[key] === itemResponse.error.id) {
              }
            }
          }
          arrayOfErrors["unhandled"] += " " + itemResponse.error.message;
        }

        if (arrayOfErrors[itemMessage.substr(0, itemMessage.indexOf(" "))] !== undefined) {
          //                            ^^^ Captures item Number
          // DON'T APPEND IF THE MESSAGE IS ALREADY IN ARRAY:
          if (
            !arrayOfErrors[itemMessage.substr(0, itemMessage.indexOf(" "))].includes(
              itemMessage.replace(/\d+/g, "")
            )
          ) {
            arrayOfErrors[itemMessage.substr(0, itemMessage.indexOf(" "))] +=
              ", " + itemMessage.replace(/\d+/g, "");
          }
        } else {
          // subtract unneccesary item number from message
          arrayOfErrors[itemMessage.substr(0, itemMessage.indexOf(" "))] = itemMessage.replace(
            /\d+/g,
            ""
          );
        }
      }
    });

    // strip number out
    for (let i = 1; i <= arrayOfErrors.length; i++) {
      if (arrayOfErrors[i] !== undefined) {
        listOfErrors += "Item No. " + i + arrayOfErrors[i] + "\n";
      }

      // TODO: this is a temporary handling of errors that have no itemNo prop yet such as "No matching price list"
      if (arrayOfErrors["unhandled"] !== undefined) {
        listOfErrors += arrayOfErrors["unhandled"].replace(/undefined /g, "");
      }
    }

    this.setState({
      blindsReceived: true,
      failed: false,
      itemsMissingComponents: true,
      listOfErrors: listOfErrors
    });
  };

  handleNoItems = () => {
    this.setState({
      blindsReceived: true,
      failed: false,
      QuoteMissingItems: true,
      listOfErrors: "There are no items in this job."
    });
  };

  async retrieveCustomerInfo() {
    const details = this.props.projects?.projectDetails?.details
      ? this.props.projects.projectDetails.details
      : undefined;
    const customerId = details.customerId || undefined;
    const customerBranchId = details.customerBranchId || undefined;
    const customerBranchContactId = details.customerBranchContactId || undefined;
    let promises = [];

    promises.push(customerId ? this.getFromAPI(`/customer/${customerId}`) : undefined);
    promises.push(
      customerId && customerBranchId
        ? this.getFromAPI(`/customer/${customerId}/branch/${customerBranchId}`)
        : undefined
    );
    promises.push(
      customerId && customerBranchId && customerBranchContactId
        ? this.getFromAPI(
            `/customer/${customerId}/branch/${customerBranchId}/contact/${customerBranchContactId}`
          )
        : undefined
    );

    const retrievedData = await Promise.all(promises);
    if (this._isMounted) {
      this.setState({
        customer: retrievedData[0] || emptyCustomer,
        jobBranch: retrievedData[1] || emptyJobBranch,
        branchContact: retrievedData[2] || emptyBranchContact
      });
    }
  }

  handleErrors() {
    // If Quote has 0 items or if it has errors
    if ("quote" in this.state.response && !this.state.response.quote.length) {
      this.handleNoItems();
    } else if ("errors" in this.state.response && this.state.response.errors.length) {
      let listOfErrors = "Please fix the following:\n";

      this.state.response.errors.forEach(item => {
        if ("message" in item) {
          let itemMessage = item.message.replace(/ProjectJobItemMissingComponent:/g, "");
          listOfErrors += itemMessage + "\n";
        }
      });

      this.setState({
        blindsReceived: true,
        failed: false,
        itemsMissingComponents: true,
        listOfErrors: listOfErrors
      });

      // If Quote has items that are missing components
    } else if ("errors" in this.state.response && this.state.response.errors.length) {
      this.handleMissingComponents(this.state.response);
    }
  }

  handleQuoteResponse = quoteResponse => {
    quoteResponse.quote.forEach(quoteItem => {
      quoteItem.baseCost = parseFloat(quoteItem.baseCost);
      quoteItem.baseMarkup = parseFloat(quoteItem.baseMarkup);
      quoteItem.costPerMetre = parseFloat(quoteItem.costPerMetre);
      quoteItem.squareMetres = parseFloat(quoteItem.squareMetres);
      quoteItem = this.handleExtras(quoteItem);
    });
    return quoteResponse;
  };

  handleExtras = quoteItem => {
    for (let extraKey in quoteItem.extras) {
      if (quoteItem.extras.hasOwnProperty(extraKey) && quoteItem.extras[extraKey] !== null) {
        let currentExtra = quoteItem.extras[extraKey];
        if (currentExtra.details && currentExtra.details.cost && currentExtra.details.markup) {
          currentExtra.details.cost = parseFloat(currentExtra.details.cost);
          currentExtra.details.markup = parseFloat(currentExtra.details.markup);
        } else if (
          currentExtra.details &&
          currentExtra.details.costPerMetre &&
          currentExtra.details.markup
        ) {
          currentExtra.details.costPerMetre = parseFloat(currentExtra.details.costPerMetre);
          currentExtra.details.markup = parseFloat(currentExtra.details.markup);
        }
        if (extraKey === "services" && currentExtra.length > 0) {
          currentExtra = this.handleServices(currentExtra);
        }
      }
    }
    return quoteItem;
  };

  handleServices = quoteItemServices => {
    quoteItemServices.forEach(service => {
      service.details.cost = parseFloat(service.details.cost);
      service.details.markup = parseFloat(service.details.markup);
    });
    return quoteItemServices;
  };

  cancelDiscountView = () => {
    this.props.dispatch(toggleDiscountView(false));
  };
  // Prevent the following error: "Warning: Can't perform a React state update on an unmounted component."
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <Fragment>
        {this.state.failed ? (
          <ErrorHandler
            error={this.state.error}
            goBack={true}
            goBackClickHandler={() => {
              this.props.history.push(
                `/projects/${this.props.match.params.id}/${this.props.match.params.JobNo}`
              );
            }}
          />
        ) : (
          ""
        )}
        {this.state.QuoteMissingItems ? (
          <div className="no-items">
            <h1> No Blind items to display</h1>
          </div>
        ) : this.state.blindsReceived ? (
          this.state.itemsMissingComponents || this.state.errorWithPricing ? (
            <Modal
              worksheetClickHandler={() => {
                this.props.dispatch(changeCurrrentProduct("blinds"));
                this.props.history.push(
                  `/projects/${this.props.match.params.id}/${this.props.match.params.JobNo}/worksheet`
                );
              }}
              goBackClickHandler={() => {
                this.props.history.push(
                  `/projects/${this.props.match.params.id}/${this.props.match.params.JobNo}`
                );
              }}
              header="Unable to generate Quote."
              displayMessage={this.state.listOfErrors}
            />
          ) : this.props.documents.discountView ? (
            <DiscountView />
          ) : (
            <div id="Document">
              <LetterHead />

              <JobHeader
                docNumber={this.props.docNumber + "-B"}
                type="Quote"
                customer={this.state.customer}
                branch={this.state.jobBranch}
                contact={this.state.branchContact}
              />

              <BlindsSummary
                {...this.state.response}
                grouping={this.props.options.grouping}
                isRetail={this.props.isRetail}
                detailed={this.props.options.detailed}
              />
              <BlindsBillOfRates
                {...this.state.response}
                isRetail={this.props.isRetail}
                display={this.props.options.billofRates}
              />
              <BlindsTotals
                {...this.state.response}
                isRetail={this.props.isRetail}
                jobDiscount={this.state.jobDiscount}
              />
              <QuoteNotes
                display={this.props.options.itemNotes}
                jobDetails={this.props.projects.jobDetails}
                response={this.state.response}
              />
              <Terms terms={this.props.projects.projectDetails.details.options.terms} />
              <BlindsDetailedSchedule
                isRetail={this.props.isRetail}
                jobDetails={this.props.projects.jobDetails}
                response={this.state.response}
                display={this.props.options.scheduleofItems}
                detailed={this.props.options.scheduleofItemsDetailed}
              />
            </div>
          )
        ) : this.state.failed ? (
          ""
        ) : (
          <div className="loader">LOADING...</div>
        )}
      </Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return { projects: state.projects, documents: state.documents };
};

export default withRouter(connect(mapStatetoProps)(QuoteBlinds));
