import {
  CHANGE_CURRENT_PRODUCT,
  TOGGLE_DISCOUNT_VIEW,
  STORE_BLIND_JOB_QUOTE_PAYLOAD,
  STORE_CURTAIN_JOB_QUOTE_PAYLOAD
} from "../actions/documentsActions";

//Inital State
const initalState = {
  currentProduct: "blinds",
  discountView: false,
  blindJobQuote: undefined,
  curtainJobQuote: undefined
};

export default function documentsReducer(state = initalState, action) {
  switch (action.type) {
    case CHANGE_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: action.currentProduct
      };

    case TOGGLE_DISCOUNT_VIEW:
      return {
        ...state,
        discountView: action.discountView
      };

    case STORE_BLIND_JOB_QUOTE_PAYLOAD:
      return {
        ...state,
        blindJobQuote: action.payload
      };

    case STORE_CURTAIN_JOB_QUOTE_PAYLOAD:
      return {
        ...state,
        curtainJobQuote: action.payload
      };

    default:
      return state;
  }
}
