import React, { Component, Fragment } from "react";
import algoliasearch from "algoliasearch";
import config from "../../../../../config";
import { connect } from "react-redux";
import JobHeader from "../JobHeader";
import LetterHead from "../../TableComponents/LetterHead";
class PurchaseOrderComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idCache: undefined,
      headerRows: this.determineHeadings(),
    };
    this.populateCache();
  }

  determineHeadings() {
    const displayData = [
      { id: "itemNo", name: "Item No." },
      { id: "productCode", name: "Product Code" },
      { id: "location", name: "Location" },
      { id: "styleId", name: "Roller Style" },
      { id: "width", name: "Width" },
      { id: "drop", name: "Drop" },
      { id: "driveType", name: "Drive Type" },
      { id: "driveMechanismId", name: "Drive Mechanism" },
      { id: "linkSystemId", name: "Link System" },
      { id: "rollDirection", name: "Roll Direction" },
      { id: "driveSide", name: "Drive Side" },
      { id: "kassetteId", name: "Fascia" },
      { id: "kassetteColour", name: "Fascia Colour" },
      { id: "fasciaFit", name: "Fascia Fit" },
      { id: "doubleBracketSystemId", name: "Double Bracket" },
      { id: "chainDrop", name: "Chain Length" },
      { id: "railColour", name: "Rail Colour" },
      { id: "sideChannelId", name: "Side Channel" },
      { id: "baseBarId", name: "Base Bar" },
      { id: "fabricId", name: "Fabric" },
      { id: "fabricColour", name: "Fabric Colour" },
    ];
    let headings = [];
    //Filters out Columns in Purchase order that has no data
    displayData.forEach((displayData) => {
      let testString = "";
      this.props.manufacturer.lines.forEach((lineItem) => {
        testString += lineItem.details[displayData.id];
      });
      if (testString === "") {
      } else {
        headings.push(displayData);
      }
    });
    return headings;
  }

  getHeadingRow() {
    return (
      <tr className="sub-heading">
        <td>Item Code</td>
        <td>Description of Item</td>
        <td>Unit Cost</td>
        <td>Unit</td>
        <td>Total Cost</td>
      </tr>
    );
  }

  async getComponentDetails(componentId) {
    try {
      if (componentId === "" || componentId === undefined) return "";
      const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.secondKey);
      const index = client.initIndex(`${config.algolia.prefix}products_components_name_asc`);
      const response = await index.search({
        query: "",
        filters: "isDeleted:false AND objectID:" + componentId,
        hitsPerPage: 1,
      });
      return response.hits[0].details.name;
    } catch (e) {
      console.error(e);
    }
  }

  async getColourDetails(colourId) {
    try {
      if (colourId === "" || colourId === undefined) return "";
      const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.secondKey);
      const index = client.initIndex(`${config.algolia.prefix}products_colour_list_items_name_asc`);
      const response = await index.search({
        query: "",
        filters: "isDeleted:false AND objectID:" + colourId,
        hitsPerPage: 1,
      });
      return response.hits[0].details.name;
    } catch (e) {
      console.error(e);
    }
  }

  async populateCache() {
    let uniqueIdCollection = [];
    let uniqueColourIdCollection = [];
    const componentData = [
      "styleId",
      "driveMechanismId",
      "kassetteId",
      "doubleBracketSystemId",
      "sideChannelId",
      "baseBarId",
      "fabricId",
      "linkSystemId",
    ];
    const colourData = ["driveColour", "railColour", "kassetteColour", "fabricColour"];
    this.props.manufacturer.lines.forEach((lineItem, key) => {
      componentData.forEach((componentData) => {
        if (!uniqueIdCollection.includes(lineItem.details[componentData])) {
          uniqueIdCollection.push(lineItem.details[componentData]);
        }
      });
      colourData.forEach((colourData) => {
        if (!uniqueColourIdCollection.includes(lineItem.details[colourData])) {
          uniqueColourIdCollection.push(lineItem.details[colourData]);
        }
      });
    });
    let promiseCollection = uniqueIdCollection.map((id) => this.getComponentDetails(id));
    let colourPromiseCollection = uniqueColourIdCollection.map((id) => this.getColourDetails(id));
    promiseCollection = await Promise.all(promiseCollection);
    colourPromiseCollection = await Promise.all(colourPromiseCollection);
    let newCache = {};
    uniqueIdCollection.forEach((id, index) => {
      newCache = {
        ...newCache,
        [id]: promiseCollection[index],
      };
    });
    uniqueColourIdCollection.forEach((id, index) => {
      newCache = {
        ...newCache,
        colours: { ...newCache.colour, [id]: colourPromiseCollection[index] },
      };
    });
    this.setState({ idCache: newCache });
  }

  areLineItemsSame(lineItem1, lineItem2) {
    this.state.headerRows.forEach((heading) => {
      if (lineItem1.details[heading.id] !== lineItem2.details[heading.id]) {
        return false;
      }
    });
    return true;
  }

  getTotalCurtainFabric() {
    if (!this.props?.options?.showFabricTotal) {
      return;
    }

    let totalFabric = 0;
    this.props.manufacturer.lines.forEach((line) => {
      if (line.components?.fabric?.totalFabric > 0) {
        totalFabric += parseFloat(line.components.fabric.totalFabric);
      }
    });
    if (totalFabric > 0) {
      return (
        <tr className="details">
          <td colSpan="2"></td>
          <td colSpan="2">Total Fabric</td>
          <td>{parseFloat(totalFabric / 1000).toFixed(2)}LM</td>
        </tr>
      );
    }
  }

  constructFromResponse() {
    const componentData = [
      "fabric",
      "driveMechanism",
      "track",
      "tape",
      "runner",
      "pelmet",
      "bumph",
      "lining",
      "leadWeight",
      "baseBar",
      "kassette",
      "sideChannel",
      "linking",
    ];
    const componentTitle = [
      "Fabric",
      "Drive Mechanism",
      "Track",
      "Tape",
      "Runner",
      "Pelmet",
      "Bumph",
      "Lining",
      "Lead Weight",
      "Base Bar",
      "Kassette",
      "Side Channel",
      "Link System",
    ];
    let componentDisplay = {};
    this.props.manufacturer.lines.forEach((lineItem) => {
      componentData.forEach((component) => {
        if (lineItem.components[component]) {
          if (componentDisplay[component] === undefined) {
            componentDisplay[component] = [];
          }
          const description = lineItem.components[component].details.name;
          let cost = lineItem.components[component].details.cost;
          let unit = 0;
          let unitExtension = "";
          if (lineItem.components[component].details.pricePerMetre) {
            cost = lineItem.components[component].details.pricePerMetre * (lineItem.details.width / 1000);
            unit = lineItem.details.width / 1000;
            unitExtension = "m";
          }
          if (lineItem.components[component].details.costPerMetre) {
            cost = lineItem.components[component].details.costPerMetre * (lineItem.details.width / 1000);
            unit = lineItem.details.width / 1000;
            unitExtension = "m";
          }
          if (component === "fabric") {
            unit = lineItem.components[component].totalFabric / 1000;
            unitExtension = "LM";
            cost = parseFloat(lineItem.components[component].fabricCost).toFixed(2);
          } else if (component === "track") {
            unitExtension = "m";
            unit = lineItem.components[component].cutTrack / 1000;
            cost = parseFloat(lineItem.components[component].trackTotalCost).toFixed(2);
          } else if (component === "tape") {
            unitExtension = "m";
            unit = lineItem.components[component].width / 1000;
          } else if (component === "runner") {
            unit = Math.ceil(lineItem.components[component].hooksOnTape);
          } else if (component === "lining" || component === "leadWeight") {
            cost = (lineItem.components[component].details.pricePerMetre * lineItem.components[component].totalWidth) / 1000;
            unit = lineItem.components[component].totalWidth / 1000;
          } else if (unit === 0) unit = 1;
          if (cost === undefined) cost = 0;
          componentDisplay[component].push({
            description,
            unit: parseFloat(unit),
            cost: parseFloat(cost),
            itemCode: "",
            unitExtension,
          });
        }
      });
    });

    if (this.props.options.combine) {
      componentDisplay = this.combineDisplayData(componentDisplay);
    }
    return componentData.map((componentType, index) => {
      if (componentDisplay[componentType]) {
        return (
          <Fragment key={index}>
            <tr className="details jobNumber">
              <td />
              <td>{componentTitle[index]}</td>
              <td />
              <td />
              <td />
            </tr>
            {componentDisplay[componentType] &&
              componentDisplay[componentType].map((component, index) => {
                return (
                  <tr key={componentType + index} className="details">
                    <td>{component.itemCode}</td>
                    <td>{component.description}</td>
                    <td>{component.cost === 0 ? "Included" : `$${(component.cost / component.unit).toFixed(2)}`}</td>
                    <td>{`${component.unit.toFixed(2)}${component.unitExtension}`}</td>
                    <td>{component.cost === 0 ? "Included" : `$${component.cost.toFixed(2)}`}</td>
                  </tr>
                );
              })}
          </Fragment>
        );
      } else return null;
    });
  }

  combineDisplayData(displayData) {
    const componentData = [
      "fabric",
      "driveMechanism",
      "track",
      "tape",
      "runner",
      "pelmet",
      "bumph",
      "lining",
      "leadWeight",
      "baseBar",
      "kassette",
      "sideChannel",
      "linking",
    ];
    let combinedDisplayData = {};
    componentData.forEach((componentType) => {
      if (displayData[componentType]) {
        displayData[componentType].forEach((component) => {
          if (combinedDisplayData[componentType] === undefined) combinedDisplayData[componentType] = [];
          let isAddedFlag = false;
          combinedDisplayData[componentType].forEach((addedComponent) => {
            if (component.description === addedComponent.description) {
              addedComponent.cost += parseFloat(component.cost);
              addedComponent.unit += parseFloat(component.unit);
              isAddedFlag = true;
            }
          });
          if (!isAddedFlag) {
            combinedDisplayData[componentType].push({ ...component });
          }
        });
      }
    });
    return combinedDisplayData;
  }

  getSubtotal() {
    const componentData = [
      "fabric",
      "driveMechanism",
      "track",
      "tape",
      "runner",
      "pelmet",
      "bumph",
      "lining",
      "leadWeight",
      "baseBar",
      "kassette",
      "sideChannel",
      "linking",
    ];
    let subTotal = 0;
    this.props.manufacturer.lines.forEach((lineItem) => {
      componentData.forEach((component) => {
        if (lineItem.components[component]) {
          let cost = lineItem.components[component].details.cost;
          if (lineItem.components[component].details.pricePerMetre) {
            cost = lineItem.components[component].details.pricePerMetre * (lineItem.details.width / 1000);
          }
          if (lineItem.components[component].details.costPerMetre) {
            cost = lineItem.components[component].details.costPerMetre * (lineItem.details.width / 1000);
          }
          if (component === "fabric") {
            cost = parseFloat(lineItem.components[component].fabricCost);
          } else if (component === "track") {
            cost = parseFloat(lineItem.components[component].trackTotalCost);
          }
          if (cost === undefined) cost = 0;
          subTotal += parseFloat(cost);
        }
      });
    });
    return subTotal;
  }

  render() {
    if (!this.state.idCache) return "loading";
    else
      return (
        <div className="purchaseOrderDocument" id="Document">
          <div className="doc-box">
            <LetterHead />
            <table cellPadding="0" cellSpacing="0">
              <tbody>
                <tr className="heading">
                  <td colSpan="20">
                    <span className="heading-one">PURCHASE ORDER</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <JobHeader
            type="Purchase Order"
            deliveryAddress={this.props.deliveryAddress}
            deliveryPerson={
              this.props.options.deliveryAddressType === "customer"
                ? this.props.customerDetails.customer.details.name
                : "Sam Kodsi"
            }
            customDelivery={
              this.props.options.deliveryAddressType === "custom" ? this.props.options.customAddress : undefined
            }
            manufacturerName={this.props.manufacturer.details.name}
            discountNumber={this.props.options.discountNumber}
            account={this.props.options.account}
            customer={this.props.customerDetails.customer}
            jobBranch={this.props.customerDetails.jobBranch}
            contact={this.props.customerDetails.branchContact}
            docNumber={`${this.props.docNumber}-${this.props.index + 1}`}
            dateRequired={this.props.options.dateRequired}
          />
          <div className="doc-box">
            <table cellPadding="0" cellSpacing="0">
              <tbody>
                {this.getHeadingRow()}
                {this.constructFromResponse()}
                <tr className="heading">
                  <td colSpan="2"></td>
                  <td colSpan="3">Totals</td>
                </tr>
                {this.getTotalCurtainFabric()}
                <tr className="details">
                  <td colSpan="2"></td>
                  <td colSpan="2">SubTotal</td>
                  <td>${this.getSubtotal().toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return { AlgoliaKey: state.key };
};

export default connect(mapStateToProps)(PurchaseOrderComponents);
