import React, { Component } from "react";
class Terms extends Component {
  get terms() {
    if (this.props.terms && this.props.terms !== "") {
      return <div style={{ whiteSpace: "pre-line" }}>{this.props.terms}</div>;
    } else
      return (
        <div className="terms">
          STANDARD PAYMENT TERMS: NET 30 DAYS OF INVOICE DATE WITH 5% RETENTION
          <br />
          FURTHER DISCOUNTS FOR SPEEDIER PAYMENT TERMS AS INDICATED BELOW:
          <br />
          &emsp;&emsp;&emsp;&emsp;PAYMENT OPTION 1: 2% DISCOUNT FOR 20% DEPOSIT OF TOTAL CONTRACT
          VALUE DEPOSIT ON AWARD
          <br />
          &emsp;&emsp;&emsp;&emsp;PAYMENT OPTION 2: 2% EARLY SETTLEMENT DISCOUNT i.e. CLEAR FUNDS
          WITHIN 7 DAYS OF INVOICE DATE
          <br />
          PUBLIC LIABILITY COVER DETAILS:
          <br />
          &emsp;&emsp;&emsp;&emsp;$20 MILLION; RACV BUSINESS INSURANCE C/N ET 3042914/RAC
          <br />
          &emsp;&emsp;&emsp;&emsp;INCLUDING ACCIDENT INSURANCE
          <br />
          WORKCOVER POLICY DETAILS:
          <br />
          &emsp;&emsp;&emsp;&emsp;SA WORKCOVER EMPLOYER # E22626811 (WORKCOVER SA)
          <br />
          &emsp;&emsp;&emsp;&emsp;VIC WORKCOVER EMPLOYER NUMBER # 12600951 (XCHANGING INSURANCE)
          <br />
          Notes:
          <br />
          &emsp;&emsp;&emsp;&emsp;1. PRICE SUBJECT TO SITE MEASURE
          <br />
          &emsp;&emsp;&emsp;&emsp;2. PRICE INCLUDES USE OF PLATFORM LADDER
          <br />
          &emsp;&emsp;&emsp;&emsp;3. PRICE INCLUDES SITE MEASURES AS LISTED ABOVE
          <br />
          &emsp;&emsp;&emsp;&emsp;4. NO ELECTRICAL WORKS INCLUDED
          <br />
        </div>
      );
  }

  render() {
    return (
      <div className="doc-box page-break-after">
        <table cellPadding="0" cellSpacing="0" className="totals-table">
          <tbody>
            <tr className="terms-heading">
              <td>TERMS</td>
            </tr>
            <tr>
              <td className="term-details">{this.terms}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Terms;
