import React, { Component } from "react";
import { connect } from "react-redux";

class Tab extends Component {
  render() {
    let classes = ["tab", "selected"];
    if (this.props.selected) {
      classes.push("selected");
    }

    return <div className={classes.join(" ")}>{this.props.children}</div>;
  }
}

export default connect()(Tab);
