import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TabContainer from "../../Generic/TabContainer/TabContainer";
import Tab from "../../Generic/TabContainer/Tab";
import { selectSideListTab, setProjectDetails } from "../../../actions/projectsActions";
import { setRefresh } from "../../../actions/algoliaActions";

import SearchableList from "../../Generic/Algolia/SearchableList";
import config from "../../../config";

class SideList extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedId: this.props.match.params.id };
  }
  // When a list item is clicked
  HitComponent = ({ hit }) => {
    const listItemClickHandler = () => {
      this.props.dispatch(setProjectDetails(hit));
      this.setState({ selectedId: hit.objectID });
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setRefresh(false));
      this.props.history.push("/projects/" + hit.objectID);
    };

    let className = { className: "projectListElement" };
    if (this.state.selectedId === hit.objectID)
      className = { className: "selected projectListElement" };
    return (
      <p {...className} onClick={listItemClickHandler}>
        <span className="title">{hit.details.name}</span>
        <span className="status">{hit.details.status}</span>
        <span className="date">{new Date(hit.details.startDate).toLocaleDateString("en-AU")}</span>
      </p>
    );
  };

  clickHandler = clickedItem => {
    const currentURL = this.props.history.location.pathname;

    this.props.history.push(currentURL + "/" + clickedItem.itemId);
  };

  sideListTabClickHandler = tabText => {
    this.props.dispatch(selectSideListTab(tabText));
    this.props.dispatch(setRefresh(true));
    this.props.dispatch(setRefresh(false));
  };

  componentWillMount() {
    // API call for list would be done here, then pass that into populateSideList dispatch
  }

  render() {
    return (
      <div className="side-list">
        <TabContainer
          className="projectList"
          selected={this.props.selectedTabText}
          clickHandler={this.sideListTabClickHandler}
        >
          <Tab text="commercial">
            <SearchableList
              appId={`${config.algolia.appId}`}
              index={`${config.algolia.prefix}projects_created_at_desc`}
              hitComponent={this.HitComponent}
              selectedObjectId={this.state.selectedId}
              hitsPerPage={1000}
              filters="isDeleted:false AND type:commercial"
            />
          </Tab>
          <Tab text="domestic">
            <SearchableList
              appId={`${config.algolia.appId}`}
              index={`${config.algolia.prefix}projects_created_at_desc`}
              hitComponent={this.HitComponent}
              selectedObjectId={this.state.selectedId}
              hitsPerPage={1000}
              filters="isDeleted:false AND type:domestic"
            />
          </Tab>
        </TabContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state.projects.sideList;
};

export default withRouter(connect(mapStateToProps)(SideList));
