import React, { Component } from "react";
import { API } from "aws-amplify";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { setLoaderState } from "../../../actions/loaderActions";
import { connect } from "react-redux";
import config from "../../../config";

class EditBulkItemsPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = { validationTypes: undefined };
    this.getValidationTypes(this.props.productType);
  }

  async getValidationTypes(productType) {
    if (productType === "Blinds") {
      let product = await API.get("telishadeAPI", "/product/blind");
      this.setState({
        validationTypes: [
          {
            id: "productCode",
            label: "Product Code",
            type: "text",
            value: ""
          },
          {
            id: "location",
            label: "Item Location",
            type: "text",
            value: ""
          },
          {
            id: "driveType",
            label: "Drive Type",
            type: "select",
            options: [
              { name: "No Change", value: "" },
              { name: "Manual", value: "manual" },
              { name: "Motorised", value: "motorised" }
            ],
            value: ""
          },
          {
            id: "width",
            label: "Width",
            type: "number",
            value: ""
          },
          {
            id: "drop",
            label: "Drop",
            type: "number",
            value: ""
          },
          {
            id: "rollerStyle",
            label: "Roller Style",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:style AND productId:" + product.id
          },
          {
            id: "fabric",
            label: "Fabric",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:fabric AND productId:" + product.id
          },
          {
            id: "fabricColour",
            label: "Fabric Colour",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_colour_list_items_name_asc`,
            filters: "isDeleted:false",
            dependency: "fabricColourListId",
            dependencyId: "colourListId"
          },
          {
            id: "driveMechanism",
            label: "Drive Mechanism",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters:
              "isDeleted:false AND (type:drive-mechanism-manual OR type:drive-mechanism-motorised) AND productId:" +
              product.id
          },
          {
            id: "baseBar",
            label: "Base Bar",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:base-bar AND productId:" + product.id
          },
          {
            id: "sideChannel",
            label: "Side Channel",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:side-channel AND productId:" + product.id
          },
          {
            id: "kassette",
            label: "Kassette",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:kassette AND productId:" + product.id
          },
          {
            id: "doubleBracket",
            label: "Double Bracket",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:double-bracket-system AND productId:" + product.id
          }
        ]
      });
    } else if (productType === "Curtains") {
      let product = await API.get("telishadeAPI", "/product/curtain");
      this.setState({
        validationTypes: [
          {
            id: "productCode",
            label: "Product Code",
            type: "text",
            value: ""
          },
          {
            id: "location",
            label: "Item Location",
            type: "text",
            value: ""
          },
          {
            id: "driveType",
            label: "Drive Type",
            type: "select",
            options: [
              { name: "No Change", value: "" },
              { name: "Manual", value: "manual" },
              { name: "Motorised", value: "motorised" }
            ],
            value: ""
          },
          {
            id: "driveMechanism",
            label: "Drive Mechanism",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters:
              "isDeleted:false AND (type:drive-mechanism-manual OR type:drive-mechanism-motorised) AND productId:" +
              product.id
          },
          {
            id: "width",
            label: "Width",
            type: "number",
            value: ""
          },
          {
            id: "drop",
            label: "Drop",
            type: "number",
            value: ""
          },
          {
            id: "headerStyle",
            label: "Header Style",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:style AND productId:" + product.id
          },
          {
            id: "trackId",
            label: "Track Type",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:track AND productId:" + product.id,
            dependency: "headerStyle",
            dependencyId: "details.associatedStyles"
          },
          {
            id: "runnerId",
            label: "Runner Type",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:runner AND productId:" + product.id,
            dependency: "trackId",
            dependencyId: "details.associatedTracks"
          },
          {
            id: "tapeId",
            label: "Tape Type",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:tape AND productId:" + product.id,
            dependency: "runnerId",
            dependencyId: "details.associatedRunners"
          },
          {
            id: "fabric",
            label: "Fabric",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:fabric AND productId:" + product.id
          },
          {
            id: "fabricColour",
            label: "Fabric Colour",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_colour_list_items_name_asc`,
            filters: "isDeleted:false",
            dependency: "fabricColourListId",
            dependencyId: "colourListId"
          },
          {
            id: "pelmetId",
            label: "Pelmet Type",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:pelmet AND productId:" + product.id
          },
          {
            id: "leadWeightId",
            label: "Lead Weight Type",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:lead-weight AND productId:" + product.id
          },
          {
            id: "bumphId",
            label: "Bumph Type",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:bumph AND productId:" + product.id
          },
          {
            id: "liningId",
            label: "Lining Type",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}products_components_name_asc`,
            filters: "isDeleted:false AND type:lining AND productId:" + product.id
          }
        ]
      });
    }
  }

  handleCancelClick = () => {
    this.props.cancelClick();
  };

  constructBody(formData, item) {
    let body = { details: { ...item.description.allDetails } };
    if (formData.productCode.trim() !== "") {
      body.details.productCode = formData.productCode;
    }
    if (formData.location.trim() !== "") {
      body.details.location = formData.location;
    }
    if (formData.driveType.trim() !== "") {
      body.details.driveType = formData.driveType;
    }
    if (formData.width.trim() !== "") {
      body.details.width = formData.width;
    }
    if (formData.drop.trim() !== "") {
      body.details.drop = formData.drop;
    }
    if (this.props.productType === "Blinds") {
      if (formData.rollerStyle.trim() !== "") {
        body.details.styleId = formData.rollerStyle;
      }
      if (formData.fabric.trim() !== "") {
        body.details.fabricId = formData.fabric;
        body.details.fabricColourListId = formData.fabricColourListId;
        if (formData.fabricColour.trim() !== "") {
          body.details.fabricColour = formData.fabricColour;
        }
      }
      if (formData.driveMechanism.trim() !== "") {
        body.details.driveMechanismId = formData.driveMechanism;
      }
      if (formData.baseBar.trim() !== "") {
        body.details.baseBarId = formData.baseBar;
      }
      if (formData.sideChannel.trim() !== "") {
        body.details.sideChannelId = formData.sideChannel;
      }
      if (formData.kassette.trim() !== "") {
        body.details.kassetteId = formData.kassette;
      }
      if (formData.doubleBracket.trim() !== "") {
        body.details.doubleBracketId = formData.doubleBracket;
      }
    } else if (this.props.productType === "Curtains") {
      if (formData.headerStyle.trim() !== "") {
        body.details.styleId = formData.headerStyle;
      }
      if (formData.fabric.trim() !== "") {
        body.details.fabricId = formData.fabric;
        body.details.fabricColourListId = formData.fabricColourListId;
        if (formData.fabricColour.trim() !== "") {
          body.details.fabricColour = formData.fabricColour;
        }
      }
      if (formData.driveMechanism.trim() !== "") {
        body.details.driveMechanismId = formData.driveMechanism;
      }
      if (formData.trackId.trim() !== "") {
        body.details.trackId = formData.trackId;
      }
      if (formData.runnerId.trim() !== "") {
        body.details.runnerId = formData.runnerId;
      }
      if (formData.tapeId.trim() !== "") {
        body.details.tapeId = formData.tapeId;
      }
      if (formData.pelmetId.trim() !== "") {
        body.details.pelmetId = formData.pelmetId;
      }
      if (formData.leadWeightId.trim() !== "") {
        body.details.leadWeightId = formData.leadWeightId;
      }
      if (formData.bumphId.trim() !== "") {
        body.details.bumphId = formData.bumphId;
      }
      if (formData.liningId.trim() !== "") {
        body.details.liningId = formData.liningId;
      }
    }
    return body;
  }

  APIMethod = async formData => {
    let chunkSize = 100;
    try {
      this.props.dispatch(setLoaderState(true));
      let itemsToModifyBlinds = [];
      let itemsToModifyCurtains = [];
      this.props.jobList.forEach(job => {
        this.props.selectedItems.forEach(itemId => {
          if (job.blinds) {
            const filteredBlinds = job.blinds.filter(blind => blind.projectJobItemId === itemId);
            itemsToModifyBlinds.push(...filteredBlinds);
          }
          if (job.curtains) {
            const filteredCurtains = job.curtains.filter(
              curtain => curtain.projectJobItemId === itemId
            );
            itemsToModifyCurtains.push(...filteredCurtains);
          }
        });
      });
      let itemSendPromises = [];
      let chunkCounter = 0;
      for (let i = 0; i < itemsToModifyBlinds.length; i++) {
        let item = itemsToModifyBlinds[i];
        const body = this.constructBody(formData, item);
        itemSendPromises.push(
          API.put(
            "telishadeAPI",
            `/project/${this.props.project.type}/${this.props.project.id}/job/${item.projectJobId}/item/blind/${item.projectJobItemId}`,
            { body }
          )
        );
        chunkCounter++;
        if (chunkCounter >= chunkSize) {
          await Promise.all(itemSendPromises);
          chunkCounter = 0;
        }
      }

      chunkCounter = 0;

      for (let i = 0; i < itemsToModifyCurtains.length; i++) {
        let item = itemsToModifyCurtains[i];
        const body = this.constructBody(formData, item);
        itemSendPromises.push(
          API.put(
            "telishadeAPI",
            `/project/${this.props.project.type}/${this.props.project.id}/job/${item.projectJobId}/item/curtain/${item.projectJobItemId}`,
            { body }
          )
        );
        chunkCounter++;
        if (chunkCounter >= chunkSize) {
          await Promise.all(itemSendPromises);
          chunkCounter = 0;
        }
      }

      await Promise.all(itemSendPromises);
      this.props.dispatch(setLoaderState(false));
      this.props.cancelClick();
      this.props.reloadList();
    } catch (e) {
      this.props.dispatch(setLoaderState(false));
      console.error(e);
    }
  };

  render() {
    if (this.state.validationTypes) {
      return (
        <PopUpForm
          fields={this.state.validationTypes}
          apiMethod={this.APIMethod}
          header={`Bulk Edit ${this.props.productType}`}
          button="Edit"
          buttoncolour="green"
          button2="Cancel"
          button2colour="red"
          button2ClickHandler={this.handleCancelClick}
        />
      );
    } else {
      return "";
    }
  }
}

export default connect()(EditBulkItemsPopUp);
