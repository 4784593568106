import {
  SET_LOADER_STATE,
  SET_ALGOLIA_LOADER_STATE
} from "../actions/loaderActions";

const initalState = {
  loaderState: false,
  algoliaLoaderState: false
};

export default function loaderReducer(state = initalState, action) {
  switch (action.type) {
    case SET_LOADER_STATE:
      return {
        ...state,
        loaderState: action.loaderState
      };
    case SET_ALGOLIA_LOADER_STATE:
      return {
        ...state,
        algoliaLoaderState: action.algoliaLoaderState
      };
    default:
      return state;
  }
}
