import React, { Component } from "react";
import { connect } from "react-redux";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { customerFields } from "../fields";
import { API } from "aws-amplify";
import { selectCustomer, updateCustomerDetails } from "../../../actions/customersActions";
import { setRefresh } from "../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";

class EditCustomer extends Component {
  get fields() {
    let fields = JSON.parse(JSON.stringify(customerFields)); // Making a deep copy
    const details = { ...this.props.details };
    for (let i = 0; i < fields.length; i++) {
      fields[i].value = details[fields[i].id];
    }

    return fields;
  }

  APIMethod = async (formData) => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));
      const response = await API.put("telishadeAPI", `/customer/${this.props.id}`, {
        body: { details: formData },
      });
      await this.props.dispatch(updateCustomerDetails(response.details));
      await this.props.dispatch(selectCustomer(response.id));
      this.props.dispatch(setRefresh(true));
      this.props.history.goBack();
    } catch (e) {
      throw e;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.fields}
        header="Edit Customer"
        button="ACCEPT"
        buttoncolour="green"
        button2="CANCEL"
        button2colour="red"
        apiMethod={this.APIMethod}
      />
    );
  }
}
const mapStateToProps = (state) => state.customers.customer;
export default connect(mapStateToProps)(EditCustomer);
