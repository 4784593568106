import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SearchableList from "../Generic/Algolia/SearchableList";
import { selectCustomer, updateCustomerDetails } from "../../actions/customersActions";
import config from "../../config";

class CustomerList extends Component {
  displayAvailableContactDetails(details) {
    if (details.phone) {
      return (
        <span>
          <strong>ph: </strong>
          {details.phone}
        </span>
      );
    } else if (details.mobile) {
      return (
        <span>
          <strong>mob: </strong>
          {details.mobile}
        </span>
      );
    } else if (details.email) {
      return (
        <span>
          <strong>Em: </strong>
          {details.email}
        </span>
      );
    } else return "";
  }

  HitComponent = ({ hit }) => {
    const listItemClickHandler = () => {
      this.props.dispatch(updateCustomerDetails(hit.details));
      this.props.dispatch(selectCustomer(hit.objectID));
      this.props.history.push(`/customers/${hit.objectID}/projects`);
    };

    let className = { className: "customerListElement" };
    if (this.props.id === hit.objectID) className = { className: "selected customerListElement" };
    return (
      <p {...className} onClick={listItemClickHandler}>
        <span className="title">
          {hit.details.name}
          <br />
        </span>
        <span className="details">{this.displayAvailableContactDetails(hit.details)}</span>
        <br />
      </p>
    );
  };

  render() {
    return (
      <div className="side-list">
        <SearchableList
          appId={`${config.algolia.appId}`}
          index={`${config.algolia.prefix}customers_name_asc`}
          hitComponent={this.HitComponent}
          selectedObjectId={this.props.id}
          filters="isDeleted:false"
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state.customers.customer;
};

export default withRouter(connect(mapStateToProps)(CustomerList));
