import React, { Component, Fragment } from "react";
import { numberWithCommas } from "../../../../../../utilities/utils";

class CurtainsSummary extends Component {
  constructFromResponse(response) {
    let newResponse = response;
    if (this.props.grouping !== "job") {
      newResponse = this.handleGrouping(this.props.grouping, response);
    }
    if (this.props.sorting !== "default") {
      newResponse = this.handleSorting(this.props.sorting, newResponse);
    }
    const construct = newResponse.map((job) => {
      return this.constructJob(job);
    });
    return construct.map((job, jobKey) => (
      <Fragment key={jobKey}>
        <tr className="jobNumber details" key={jobKey}>
          <td>{job.number}</td>
          <td style={{ textTransform: "capitalize" }}>{job.name}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          {this.props.showDiscounts ? (
            <Fragment>
              <td></td>
              <td></td>
              <td style={{ fontWeight: "bold", fontStyle: "normal" }}>{`$${numberWithCommas(
                Math.ceil(job.jobTotalNoDiscount.toFixed(2))
              )}`}</td>
              <td style={{ fontWeight: "bold", fontStyle: "normal" }}>{`-$${numberWithCommas(
                Math.ceil(job.jobTotalNoDiscount.toFixed(2)) - Math.ceil(job.jobTotal.toFixed(2))
              )}`}</td>
            </Fragment>
          ) : (
            <Fragment />
          )}
          <td style={{ fontWeight: "bold", fontStyle: "normal" }}>{`$${numberWithCommas(
            Math.ceil(job.jobTotal.toFixed(2))
          )}`}</td>
        </tr>
        {job.items.map((item, key) => (
          <tr className="details" key={`Job:${jobKey}, Item:${key}`}>
            <td></td>
            <td></td>
            <td>{numberWithCommas(item.qty)}</td>
            <td>{numberWithCommas(item.SQM.toFixed(2))}</td>
            {this.props.showDiscounts ? (
              <td>{`$${numberWithCommas((item.totalNoDiscount / item.SQM).toFixed(2))}`}</td>
            ) : (
              <Fragment />
            )}
            {this.props.showDiscounts ? (
              <td>{(100 - (item.total / item.totalNoDiscount) * 100).toFixed(0)}%</td>
            ) : (
              <Fragment />
            )}
            <td>{`$${numberWithCommas((item.total / item.SQM).toFixed(2))}`}</td>
            <td>{`${Math.ceil(item.totalWidth / item.qty)}mm`}</td>
            <td>{`${Math.ceil(item.totalDrop / item.qty)}mm`}</td>
            <td>{this.constructItemDescription(item.item)}</td>
            {this.props.showDiscounts ? (
              <td>{`$${numberWithCommas(Math.ceil(item.totalNoDiscount.toFixed(2)))}`}‬</td>
            ) : (
              <td>{`$${numberWithCommas(Math.ceil(item.total.toFixed(2)))}`}‬</td>
            )}
            <td></td>
            {this.props.showDiscounts ? <td></td> : <Fragment />}
            {this.props.showDiscounts ? <td></td> : <Fragment />}
          </tr>
        ))}
      </Fragment>
    ));
  }

  handleGrouping(groupingKey, responseObject) {
    let newGroupedCollection = [];
    responseObject.forEach((job) => {
      job.quote.forEach((quote) => {
        let groupedFlag = false;
        newGroupedCollection.forEach((groupedItem) => {
          if (
            this.getGroupingData(groupingKey, quote) === groupedItem.name &&
            this.compareDiscountObjects(groupedItem.discount, job.discount)
          ) {
            groupedItem.quote.push(quote);
            groupedFlag = true;
          }
        });
        if (!groupedFlag) {
          newGroupedCollection.push({
            name: this.getGroupingData(groupingKey, quote),
            quote: [{ ...quote }],
            discount: job.discount,
          });
        }
      });
    });
    return newGroupedCollection;
  }

  compareDiscountObjects(object1, object2) {
    let areSame = true;
    let areAllDiscountValuesSame = true;
    if (!object1.components.length === object2.components.length) {
      areSame = false;
    }
    object1.components.forEach((componentObject) => {
      let hasSameComponentFlag = false;
      object2.components.forEach((otherComponentObject) => {
        if (otherComponentObject.id === componentObject.id) {
          if (
            componentObject.retail.percentage === otherComponentObject.retail.percentage &&
            componentObject.wholesale.percentage === otherComponentObject.wholesale.percentage
          ) {
            hasSameComponentFlag = true;
          }
        }
      });
      if (!hasSameComponentFlag) {
        areSame = false;
      }
    });
    object1.components.forEach((componentObject) => {
      object2.components.forEach((otherComponentObject) => {
        if (
          componentObject.retail.percentage !== otherComponentObject.retail.percentage ||
          componentObject.wholesale.percentage !== otherComponentObject.wholesale.percentage
        ) {
          areAllDiscountValuesSame = false;
        }
      });
    });
    if (areAllDiscountValuesSame) {
      areSame = true;
    }
    return areSame;
  }

  handleSorting(sortingKey, responseObject) {
    for (let i = 0; i < responseObject.length; i++) {
      for (let j = i; j < responseObject.length; j++) {
        if (this.getSortingMethod(sortingKey, responseObject[i], responseObject[j])) {
          const temp = responseObject[i];
          responseObject[i] = responseObject[j];
          responseObject[j] = temp;
        }
      }
    }
    return responseObject;
  }

  getSortingMethod(sortingKey, item1, item2) {
    if (sortingKey === "alphabetical") return item1.name > item2.name;
  }

  getGroupingData(groupingKey, quoteItem) {
    if (
      groupingKey === "location" ||
      groupingKey === "driveType" ||
      groupingKey === "productCode"
    ) {
      return quoteItem.description.allDetails[groupingKey];
    }
    if (groupingKey === "style") return quoteItem.description.headerStyle;
    if (groupingKey === "fabric") return quoteItem.description.fabric;
    if (groupingKey === "fabricAndColour")
      return `${quoteItem.description.fabric} - ${quoteItem.description.fabricColour}`;
    if (groupingKey === "driveMechanism") return quoteItem.description.driveMechanism;
  }

  constructItemDescription(item) {
    const { fabric, driveMechanism, headerStyle, track } = item.description;
    const { productCode } = item.description.allDetails;
    if (productCode !== "") {
      return `${productCode} / ${fabric} / ${headerStyle} / ${track} / ${driveMechanism}`;
    }
    return `${fabric} / ${headerStyle} / ${track} / ${driveMechanism}`;
  }

  constructJob(JobQuote) {
    let JobItemCollection = [];
    JobQuote.quote.forEach((quoteItem) => {
      let AddedFlag = false;
      JobItemCollection.forEach((collectionItem) => {
        if (this.componentsAreSame(quoteItem, collectionItem.item)) {
          collectionItem.qty++;
          collectionItem.total += this.calculateCurtainItemTotal(
            quoteItem,
            this.props.projectDiscount,
            JobQuote.discount
          );
          collectionItem.SQM += quoteItem.squareMetres;
          collectionItem.totalNoDiscount += this.calculateCurtainItemTotal(quoteItem);
          collectionItem.totalWidth += parseFloat(quoteItem.description.allDetails.width);
          collectionItem.totalDrop += parseFloat(quoteItem.description.allDetails.drop);
          AddedFlag = true;
        }
      });
      if (!AddedFlag) {
        JobItemCollection.push({
          item: quoteItem,
          qty: 1,
          SQM: quoteItem.squareMetres,
          total: this.calculateCurtainItemTotal(
            quoteItem,
            parseFloat(this.props.projectDiscount),
            JobQuote.discount
          ),
          totalNoDiscount: this.calculateCurtainItemTotal(quoteItem),
          totalWidth: parseFloat(quoteItem.description.allDetails.width),
          totalDrop: parseFloat(quoteItem.description.allDetails.drop),
        });
      }
    });
    let jobTotal = 0;
    let jobTotalNoDiscount = 0;
    JobItemCollection.forEach((jobItem) => {
      jobTotal += jobItem.total;
      jobTotalNoDiscount += jobItem.totalNoDiscount;
    });
    return {
      items: JobItemCollection,
      name: JobQuote.name,
      number: JobQuote.jobNo,
      discount: JobQuote.discount + parseFloat(this.props.projectDiscount),
      jobTotal,
      jobTotalNoDiscount,
    };
  }

  calculateCurtainItemTotal(quoteItem, discount = 0, discountObject) {
    if (isNaN(discount)) {
      return this.calculateCurtainItemTotal(quoteItem, 0, discountObject);
    }
    let totalforItem = 0;
    totalforItem += this.props.isRetail
      ? quoteItem.costInfo.markedUpBasePrice
      : quoteItem.costInfo.basePrice;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach((componentDiscount) => {
        if (componentDiscount.id === quoteItem.description.allDetails.fabricId) {
          totalforItem =
            (totalforItem / 100) *
            (100 -
              (this.props.isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }
    totalforItem += this.calculateExtra("bumph", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("driveMechanism", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("leadWeight", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("lining", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("pelmet", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("accessory", quoteItem, discountObject).total;
    totalforItem += this.calculateService(quoteItem, discountObject).total;
    totalforItem += this.props.isRetail
      ? this.getTrackDiscountValue(
          quoteItem.costInfo.markedUpTrackTotalCost,
          discountObject,
          quoteItem
        )
      : this.getTrackDiscountValue(quoteItem.costInfo.trackTotalCost, discountObject, quoteItem);
    totalforItem = this.props.isRetail ? (totalforItem / 100) * (100 - discount) : totalforItem;
    return totalforItem;
  }

  getTrackDiscountValue(trackCost, discountObject, quoteItem) {
    let totalforTrack = trackCost;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach((componentDiscount) => {
        if (componentDiscount.id === quoteItem.description.allDetails.trackId) {
          totalforTrack =
            (totalforTrack / 100) *
            (100 -
              (this.props.isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }
    return totalforTrack;
  }

  componentsAreSame(Component1, Component2) {
    if (
      Component1.description.allDetails.productCode ===
        Component2.description.allDetails.productCode &&
      Component1.extras.track.id === Component2.extras.track.id &&
      Component1.description.fabric === Component2.description.fabric &&
      Component1.description.headerStyle === Component2.description.headerStyle &&
      Component1.extras.driveMechanism.id === Component2.extras.driveMechanism.id
    ) {
      return true;
    } else {
      return false;
    }
  }

  calculateExtra(extraKey, item, discountObject) {
    let totalPrice = 0;
    let totalQty = 0;
    if (item.extras[extraKey]) {
      if (item.extras[extraKey].details.pricePerMetre) {
        const totalPricePerMetre =
          parseFloat(item.extras[extraKey].details.pricePerMetre) *
          (parseFloat(item.description.width) / 1000);
        const totalPricePerMetreMarkup =
          (totalPricePerMetre / 100) * (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail ? totalPricePerMetreMarkup : totalPricePerMetre;
        totalQty++;
      } else if (item.extras[extraKey].details.cost) {
        const totalCost =
          (parseFloat(item.extras[extraKey].details.cost) / 100) *
          (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail
          ? totalCost
          : parseFloat(item.extras[extraKey].details.cost);
        totalQty++;
      }
      if (discountObject && discountObject.components.length > 0) {
        discountObject.components.forEach((componentDiscount) => {
          if (item.extras[extraKey].id === componentDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (this.props.isRetail
                  ? componentDiscount.retail.percentage
                  : componentDiscount.wholesale.percentage));
          }
        });
      }
    }

    return { total: totalPrice, qty: totalQty };
  }

  calculateService(item, discountObject) {
    let totalPrice = 0;
    let totalQty = 0;
    item.extras.services.forEach((service) => {
      const totalWithMarkup =
        (parseFloat(service.details.cost) / 100) * (100 + parseFloat(service.details.markup));
      totalPrice += this.props.isRetail ? totalWithMarkup : parseFloat(service.details.cost);
      totalQty++;
      if (discountObject && discountObject.servicesAndFees.length > 0) {
        discountObject.servicesAndFees.forEach((serviceDiscount) => {
          if (service.id === serviceDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (this.props.isRetail
                  ? serviceDiscount.retail.percentage
                  : serviceDiscount.wholesale.percentage));
          }
        });
      }
    });
    return { total: totalPrice, qty: totalQty };
  }

  getGroupingTitleRow(groupingKey) {
    if (groupingKey === "job") {
      return (
        <Fragment>
          <td>Job Number</td>
          <td className="no-wrap">Job Name</td>
          <td>Qty</td>
          <td>SQM</td>
          <td>Price per SQM</td>
          {this.props.showDiscounts ? <td>Discount</td> : <Fragment />}
          {this.props.showDiscounts ? <td>Discounted Price Per SQM</td> : <Fragment />}
          <td>Average Width</td>
          <td>Average Drop</td>
          <td>Description of Items</td>
          <td>Job Cost Details</td>
          {this.props.showDiscounts ? <td>Total Cost of Job Ex.GST</td> : <Fragment />}
          {this.props.showDiscounts ? <td>Discount Amount</td> : <Fragment />}
          <td>Total Cost of Job Ex.GST</td>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <td></td>
          <td>{this.translateHeading(groupingKey)}</td>
          <td>Qty</td>
          <td>SQM</td>
          <td>Price per SQM</td>
          {this.props.showDiscounts ? <td>Discount</td> : <Fragment />}
          {this.props.showDiscounts ? <td>Discounted Price Per SQM</td> : <Fragment />}
          <td>Average Width</td>
          <td>Average Drop</td>
          <td>Description of Items</td>
          <td>Job Cost Details</td>
          {this.props.showDiscounts ? <td>Total Cost of Job Ex.GST</td> : <Fragment />}
          {this.props.showDiscounts ? <td>Discount Amount</td> : <Fragment />}
          <td>Total Cost of Job Ex.GST</td>
        </Fragment>
      );
    }
  }

  translateHeading(groupingKey) {
    if (groupingKey === "location") return "Item Location";
    if (groupingKey === "driveType") return "Drive Mechanism Type";
    if (groupingKey === "style") return "Header Style";
    if (groupingKey === "fabric") return "Fabric Name";
    if (groupingKey === "driveMechanism") return "Drive Mechanism Name";
    if (groupingKey === "fabricAndColour") return "Fabric and Colour Name";
    if (groupingKey === "productCode") return "Item Product Code";
  }

  render() {
    return (
      <div className="doc-box">
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="heading">
              <td colSpan="14">
                <span className="heading-one">PROJECT QUOTE - CURTAINS</span>
              </td>
            </tr>
            <tr className="sub-heading">{this.getGroupingTitleRow(this.props.grouping)}</tr>
            {this.constructFromResponse(this.props.response)}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CurtainsSummary;
