import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setCurrentComponent,
  setCurrentProduct,
  selectID,
  updateProductDetails
} from "../../../../actions/productsActions";
import SearchableList from "../../../Generic/Algolia/SearchableList";
import config from "../../../../config";

class BlindSideChannel extends Component {
  HitComponent = ({ hit }) => {
    const listItemClickHandler = () => {
      const currentURL = this.props.history.location.pathname;
      this.props.history.push(currentURL + "/" + hit.objectID);
      this.props.dispatch(selectID(hit.objectID));
    };

    let className = "";
    if (this.props.id === hit.objectID) className = { className: "selected" };
    return (
      <p {...className} onClick={listItemClickHandler}>
        <strong>{hit.details.name}</strong>
      </p>
    );
  };

  clickHandler = clickedItem => {
    const currentURL = this.props.history.location.pathname;
    this.props.history.push(currentURL + "/" + clickedItem.itemId);
    this.props.dispatch(selectID(clickedItem.itemId));
  };

  componentDidMount() {
    this.props.dispatch(setCurrentComponent("Side Channels"));
    this.props.dispatch(setCurrentProduct("Blinds"));
    this.props.dispatch(updateProductDetails({ name: "" }));
  }

  render() {
    return (
      <SearchableList
        appId={`${config.algolia.appId}`}
        index={`${config.algolia.prefix}products_components_name_asc`}
        hitComponent={this.HitComponent}
        selectedObjectId={this.props.id}
        filters="isDeleted:false AND type:side-channel"
      />
    );
  }
}

const mapStateToProps = state => {
  return state.products;
};

export default connect(mapStateToProps)(BlindSideChannel);
