import React, { Component } from "react";
import { connect } from "react-redux";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { staffFields } from "../fields";
import { API } from "aws-amplify";
import { selectStaff, updateStaffDetails } from "../../../actions/staffActions";
import { setRefresh } from "../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";

class EditStaff extends Component {
  get fields() {
    let fields = JSON.parse(JSON.stringify(staffFields)); // Making a deep copy
    const details = { ...this.props.details };
    for (let i = 0; i < fields.length; i++) {
      fields[i].value = details[fields[i].id];
    }

    return fields;
  }

  APIMethod = async (formData) => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));

      const response = await API.put("telishadeAPI", `/staff/${this.props.id}`, {
        body: { details: formData },
      });
      this.props.history.push(`/staff/${this.props.id}/addresses`);
      await this.props.dispatch(updateStaffDetails(response.details));
      await this.props.dispatch(selectStaff(response.id));
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setAlgoliaLoaderState(false));
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.fields}
        header="Edit Staff"
        button="ACCEPT"
        buttoncolour="green"
        button2="CANCEL"
        button2colour="red"
        apiMethod={this.APIMethod}
      />
    );
  }
}
const mapStateToProps = (state) => state.staff.staff;
export default connect(mapStateToProps)(EditStaff);
