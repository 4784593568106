import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { setLoaderState, setAlgoliaLoaderState } from "../../../actions/loaderActions";
import { API } from "aws-amplify";
import { setRefresh } from "../../../actions/algoliaActions";
import { setProjectDetails } from "../../../actions/projectsActions";
import config from "../../../config";
class EditProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        {
          id: "name",
          label: "Project Name",
          type: "text",
          tooltip: "Project Name",
          required: true
        },
        {
          id: "customerId",
          label: "Customer ID",
          type: "AlgoliaSelect",
          index: `${config.algolia.prefix}customers_name_asc`,
          filters: "isDeleted:false"
        },
        {
          id: "customerBranchId",
          label: "Customer Branch ID",
          type: "AlgoliaSelect",
          index: `${config.algolia.prefix}customers_branches_name_asc`,
          filters: "isDeleted:false",
          dependency: "customerId",
          dependencyId: "customerId"
        },
        {
          id: "customerBranchContactId",
          label: "Customer Contact",
          type: "AlgoliaSelect",
          index: `${config.algolia.prefix}customers_branches_contacts_name_asc`,
          filters: "isDeleted:false",
          dependency: "customerBranchId",
          dependencyId: "branchId"
        },
        {
          id: "startDate",
          label: "Start Date",
          type: "date"
        },
        {
          id: "estimatedDelivery",
          label: "Estimated Delivery",
          type: "date"
        },
        {
          id: "stage",
          label: "Project Stage",
          type: "select",
          options: [
            { name: "QS Budget", value: "qsBudget" },
            { name: "Pre Award Tendering", value: "preAwardTendering" },
            { name: "Builder Awarded Tendering", value: "builderAwardedTendering" },
            { name: "Project Management", value: "projectManagement" },
            { name: "Practical Completion", value: "practicalCompleteion" },
            { name: "Completed", value: "completed" }
          ],
          value: "qsBudget"
        },
        {
          id: "status",
          label: "Project Status",
          type: "select",
          options: [
            { name: "Won", value: "won" },
            { name: "Lost", value: "lost" },
            { name: "Documentation", value: "documentation" },
            { name: "Review Drawings", value: "reviewDrawings" },
            { name: "Quote ", value: "quote" }
          ],
          value: "won"
        },
        {
          id: "quoteDate",
          label: "Quote Date",
          type: "date"
        },
        {
          id: "staffId",
          label: "Staff ID",
          type: "AlgoliaSelect",
          index: `${config.algolia.prefix}staff_name_asc`,
          filters: "isDeleted:false"
        },
        {
          id: "discount",
          label: "Discount %",
          type: "number"
        },
        {
          id: "terms",
          label: "Terms",
          type: "TextArea"
        }
      ]
    };
  }

  get fields() {
    let fields = JSON.parse(JSON.stringify(this.state.fields)); // Making a deep copy
    const details = { ...this.props.details.details };

    // Re-map details
    if (details && details.options.discount && details.options.discount.retail) {
      details.discount = details.options.discount.retail.percentage;
      details.terms = details.options.terms;
      delete details.options;
    }

    for (let i = 0; i < fields.length; i++) {
      if (
        fields[i].type === "date" &&
        details[fields[i].id] !== undefined &&
        details[fields[i].id] !== "" &&
        details[fields[i].id]._seconds === undefined
      )
        fields[i].value = new Date(details[fields[i].id]);
      else fields[i].value = details[fields[i].id];
      if (fields[i].value && fields[i].value._seconds) fields[i].value = "";
    }
    return fields;
  }

  onProjectTypeChange = event => {
    this.setState({ projectType: event.target.value });
  };

  handleNextClick = () => {
    this.setState({ step: 2 });
  };

  APIMethod = async formData => {
    try {
      this.props.dispatch(setLoaderState(true));
      this.props.dispatch(setAlgoliaLoaderState(true));

      // Re-map form data
      formData.options = {
        discount: { retail: { percentage: formData.discount } },
        terms: formData.terms
      };
      delete formData.discount;
      delete formData.terms;

      if (formData.customerBranchId === undefined) {
        formData.customerBranchId = "";
      }

      if (formData.customerBranchContactId === undefined) {
        formData.customerBranchContactId = "";
      }

      const response = await API.put(
        "telishadeAPI",
        "/project/" + this.props.details.type + "/" + this.props.match.params.id,
        {
          body: { details: { ...formData, number: this.props.details.details.number } }
        }
      );
      this.props.dispatch(setProjectDetails(response));
      this.props.history.goBack();
      // Refresh any algolia components currently mounted 1 second after updating
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setLoaderState(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.fields}
        apiMethod={this.APIMethod}
        header="Edit Project"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

export default withRouter(connect()(EditProject));
