// HANDLE WITH CARE!
// Multiple files rely on these models, if altering - follow all references to ensure no new errors introduced.

export const emptyCustomer = {
  customerId: "emptyCustomer",
  details: {
    phone: "",
    fax: "",
    website: "",
    email: "",
    regNo: "",
    name: "",
    mobile: ""
  }
};

export const emptyJobBranch = {
  customerId: "emptyJobBranch",
  details: {
    addressType: "",
    name: "",
    address: {
      country: "",
      postcode: "",
      streetName: "",
      streetNumber: "",
      city: "",
      state: ""
    }
  }
};

export const emptyBranchContact = {
  customerId: "emptyBranchContact",
  branchId: "",
  details: {
    mobile: "",
    title: "",
    lastName: "",
    phone: "",
    firstName: "",
    fax: "",
    email: ""
  }
};
