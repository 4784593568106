// *** Action Type Constants ***
export const SET_FIRSTNAME = "DASHBOARD::SET_FIRSTNAME";

// *** Action Creators ***
export function setFirstName(name) {
  return {
    type: SET_FIRSTNAME,
    name
  };
}
