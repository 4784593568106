// HANDLE WITH CARE!
// Multiple files rely on these functions, don't alter any, only add new.
import { API } from "aws-amplify";

// -----------------------*
//    API FUNCTIONS
// ---------------------*

// API function configured to return Objects from Telishade API
export async function getFromAPI(requestString) {
  try {
    const response = API.get("telishadeAPI", requestString);
    return response;
  } catch (e) {
    throw e;
  }
}

// API function PUT
export async function putToAPI(requestString, body) {
  try {
    const response = API.put("telishadeAPI", requestString, { body });
    return response;
  } catch (e) {
    throw e;
  }
}

// ------------- END API FUNCTIONS ---------------------------------*

// -----------------------*
//    ALOGLIA FUNCTIONS
// ---------------------*

// This function will help determine which algolia api keys to use based on the search index
export function getAlgoliaKey(index, keys) {
  const regex = /_products_/;
  const matches = index.match(regex);
  return matches === null ? keys.key : keys.secondKey;
}

// ------------- END ALOGLI FUNCTIONS ---------------------------------*

// -----------------------*
//    MATH FUNCTIONS
// ---------------------*

// Calculate price after markup percentage is added
export function calcPriceWithMarkUp(cost, markup) {
  if (!cost) {
    cost = 0;
  }
  let result = parseFloat(cost);

  if (parseFloat(markup) > 0) {
    const markupPercent = parseFloat(parseFloat(markup) / 100);
    result += parseFloat(cost) * markupPercent;
  }
  return result;
}

// Calculate price per Metre
export function calcPricePerMetrage(costPerMetre, width) {
  return parseFloat(costPerMetre) * (parseFloat(width) / 1000);
}

// Search for 3 digit multiples and add comma:
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Adds leading zeros to a passed in number, returns a string
export function padNumber(num, size, qty = 1) {
  // num = the number to pad
  // size = the number of zeros
  var s = num + "";
  while (s.length < size) s = "0" + s;
  if (qty > 1) {
    s = s + "-" + this.padNumber(num + qty - 1, 3);
  }
  return s;
}

// returns whole number i.e. 25% comes back as a float of 25
export function getProfitMargin(costPrice, sellPrice) {
  let grossProfit = parseFloat(sellPrice) - parseFloat(costPrice);

  // protect against domain error
  if (sellPrice !== 0 && grossProfit !== 0) {
    let result = ((grossProfit / sellPrice) * 100).toFixed(2);
    if (result === "-Infinity") {
      result = "--";
    }
    return result;
  } else return 0;
}

export function getGrossProfit(costPrice, sellPrice) {
  let grossProfit = parseFloat(sellPrice) - parseFloat(costPrice);
  return grossProfit;
}

// calculate price after discount
export function getDiscountedPrice(price, discountPercent) {
  let result = parseFloat(price) - (parseFloat(discountPercent) / 100) * parseFloat(price);
  return result.toFixed(2);
}

export function AUD(amount) {
  parseFloat(amount);
  return `$${numberWithCommas(amount.toFixed(2))}`;
}

// ------------- END MATH FUNCTIONS ---------------------------------*

// -----------------------*
//    JAVASCRIPT FUNCTIONS
// ---------------------*

// Make Deep copy of an Array
export function deepCopy(array) {
  return JSON.parse(JSON.stringify(array));
}

// ------------- END JAVASCRIPT FUNCTIONS ---------------------------------*

// Search an Object's Properties for a specific value, return key
export function searchObj(obj, query) {
  for (var key in obj) {
    var value = obj[key];

    if (typeof value === "object") {
      searchObj(value, query);
    }

    if (value === query) {
      return key;
    }
  }
}

// Parse paste data
export function defaultParsePaste(str) {
  return str.split(/\r\n|\n|\r/).map((row) => row.split("\t"));
}
