import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";

class AccountingMenu extends Component {
  render() {
    return (
      <div className="subMenuLinksList-expanded">
        <ul>
          <li>
            <NavLink to={"/projects/" + this.props.match.params.id + "/documents/retail-quote"}>
              Retail Quote <div className="available-documents">(1)</div>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/projects/" + this.props.match.params.id + "/documents/wholesale-quote"}>
              Wholesale Quote <div className="available-documents">(1)</div>
            </NavLink>
          </li>
          <li>
            <NavLink to={"/projects/" + this.props.match.params.id + "/documents/purchase-order"}>
              Purchase Order <div className="available-documents">(1)</div>
            </NavLink>
          </li>
          <li>
            <div className="not-implemented">Commercial Invoice</div>
          </li>
          <li>
            <div className="not-implemented">Proforma Invoice</div>
          </li>
          <li>
            <div className="not-implemented">Supplier Quote</div>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(AccountingMenu);
