import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";

//Font Awesome Icon
import AddFAB from "../../Generic/Images/AddFab.png";

import BlindStyle from "./Components/BlindStyle";
import BlindFabric from "./Components/BlindFabric";
import BlindPricing from "./Components/BlindPricing";
import BlindDriveMechanism from "./Components/BlindDriveMechanism";
import BlindKassettes from "./Components/BlindKassettes";
import BlindSideChannel from "./Components/BlindSideChannel";
import BlindBaseBar from "./Components/BlindBaseBar";
import BlindAccessory from "./Components/BlindAccessories";
import BlindDoubleBracket from "./Components/BlindDoubleBracket";
import BlindServices from "./Components/BlindServices";
import BlindColourLists from "./Components/BlindColourLists";

class BlindsListView extends Component {
  handleAddClick = () => {
    if (this.props.currentComponent === "Style")
      this.props.history.push("/products/blinds/style/add");
    if (this.props.currentComponent === "Pricing Groups")
      this.props.history.push("/products/blinds/pricing-groups/add");
    if (this.props.currentComponent === "Fabric")
      this.props.history.push("/products/blinds/fabric/add");
    if (this.props.currentComponent === "Drive Mechanism")
      this.props.history.push("/products/blinds/drive-mechanism/add");
    if (this.props.currentComponent === "Kassettes")
      this.props.history.push("/products/blinds/kassette/add");
    if (this.props.currentComponent === "Side Channels")
      this.props.history.push("/products/blinds/side-channel/add");
    if (this.props.currentComponent === "Base Bar")
      this.props.history.push("/products/blinds/base-bar/add");
    if (this.props.currentComponent === "Accessory")
      this.props.history.push("/products/blinds/accessory/add");
    if (this.props.currentComponent === "Double Bracket")
      this.props.history.push("/products/blinds/double-bracket-system/add");
    if (this.props.currentComponent === "Services and Fees")
      this.props.history.push("/products/blinds/service-and-fee/add");
    if (this.props.currentComponent === "Colour Lists")
      this.props.history.push("/products/blinds/colour-list/add");
  };

  render() {
    return (
      <Fragment>
        <div className="productComponentHeader">
          <h2>{this.props.currentComponent}</h2>
          <img id="plusCircle" alt="Add" src={AddFAB} onClick={this.handleAddClick} />
        </div>
        <div className="productList">
          <Switch>
            <Route path="/products/blinds/style" component={BlindStyle} />
            <Route path="/products/blinds/fabric" component={BlindFabric} />
            <Route path="/products/blinds/drive-mechanism" component={BlindDriveMechanism} />
            <Route path="/products/blinds/pricing-groups" component={BlindPricing} />
            <Route path="/products/blinds/kassette" component={BlindKassettes} />
            <Route path="/products/blinds/side-channel" component={BlindSideChannel} />
            <Route path="/products/blinds/base-bar" component={BlindBaseBar} />
            <Route path="/products/blinds/accessory" component={BlindAccessory} />
            <Route path="/products/blinds/double-bracket-system" component={BlindDoubleBracket} />
            <Route path="/products/blinds/service-and-fee" component={BlindServices} />
            <Route path="/products/blinds/colour-list" component={BlindColourLists} />
            <Redirect to="/products/blinds/style" />
          </Switch>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state.products;
};

export default connect(mapStateToProps)(BlindsListView);
