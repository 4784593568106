import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDataGrid from "react-data-grid";
import Helper from "react-data-grid-multiline-header";
import { populateWorksheet, selectIndex } from "../../../actions/worksheetActions";
import { setLoaderState } from "../../../actions/loaderActions";
import { NumberEditor, CustomDropDown } from "./AdditonalEditors";
import AutoCompleteDropDown from "./AlgoliaEditors/AlgoliaAutoCompleteDropDown";
import AlgoliaFormatter from "./Formatters/AlgoliaFormatter";
import DriveMechanismDropDown from "./AlgoliaEditors/DriveMechanismDropDown";
import config from "../../../config";
import AlgoliaColourListDropDown from "./AlgoliaEditors/AlgoliaColourListDropDown";
import DependantDropDown from "./AlgoliaEditors/DependantDropDown";
import OptionsFormatter from "./Formatters/OptionsFormatter";
import { defaultParsePaste } from "../../../utilities/utils";

class BlindsWorksheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCollumn: undefined,
      issueAction: false,
    };

    document.addEventListener("paste", this.handlePaste);
  }
  //Define's the Style Selection Dropdown in the Data Grid
  BlindStyleEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:style AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );
  BlindStlyeFormatter = (
    <AlgoliaFormatter
      index={`${config.algolia.prefix}products_components_name_asc`}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
      cacheId={"components"}
    />
  );

  LinkSystemEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:drive-mechanism-link-system AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  //This is a special case editor, it will only show the drive mechanisms of the selected type
  DriveMechanismEditor = (
    <DriveMechanismDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filter={" AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );
  DriveMechanismFormatter = (
    <AlgoliaFormatter
      index={`${config.algolia.prefix}products_components_name_asc`}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
      cacheId={"components"}
    />
  );

  AccessoryEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:accessory AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  BaseBarEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:base-bar AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  SideChannelEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:side-channel AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  KassetteEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:kassette AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  FabricEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:fabric AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  DoubleBracketEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:double-bracket-system AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  FabricColourListEditor = (
    <DependantDropDown
      index={`${config.algolia.prefix}products_colour_list_items_name_asc`}
      filters={""}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
      dependantTitle="colourListId"
      dependantRow="fabricColourListId"
    />
  );

  KassetteColourListEditor = (
    <AlgoliaColourListDropDown
      index={`${config.algolia.prefix}products_colour_lists_name_asc`}
      filters={"isDeleted:false AND componentType:kassette AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  RailColourListEditor = (
    <AlgoliaColourListDropDown
      index={`${config.algolia.prefix}products_colour_lists_name_asc`}
      filters={"isDeleted:false AND componentType:rail AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  DriveColourListEditor = (
    <AlgoliaColourListDropDown
      index={`${config.algolia.prefix}products_colour_lists_name_asc`}
      filters={"isDeleted:false AND componentType:driveMechanism AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  ColourListFormatter = (
    <AlgoliaFormatter
      index={`${config.algolia.prefix}products_colour_list_items_name_asc`}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
      cacheId={"colours"}
    />
  );

  //Custom DropDowns
  TrueFalseEditor = (
    <CustomDropDown
      options={[
        { value: "true", text: "Yes" },
        { value: "false", text: "No" },
      ]}
    />
  );

  YesNoFormatter = ({ value }) => {
    if (value === "true") return "Yes";
    else return "No";
  };

  LinkingPositionEditor = (
    <CustomDropDown options={[{ value: "MotorBlind" }, { value: "IntermediateBlind" }, { value: "TailBlind" }]} />
  );
  DriveTypeEditor = (<CustomDropDown options={[{ value: "None" }, { value: "manual" }, { value: "motorised" }]} />);

  ChainTypeEditor = (
    <CustomDropDown options={[{ value: "Metal" }, { value: "Black Plastic" }, { value: "White Plastic" }]} />
  );

  ColourEditor = (
    <CustomDropDown
      options={[
        { value: "Pearl White" },
        { value: "Snow" },
        { value: "Coconut" },
        { value: "Birch White" },
        { value: "Brushed Anodised" },
        { value: "Armour" },
        { value: "River Gum" },
        { value: "Almond" },
        { value: "Latte" },
        { value: "Jute" },
        { value: "Swede" },
        { value: "Slate" },
        { value: "Esspresso" },
        { value: "Black" },
        { value: "Other/Special" },
      ]}
    />
  );

  DriveSideEditor = (<CustomDropDown options={[{ value: "Left" }, { value: "Right" }]} />);

  RollDirectionEditor = (<CustomDropDown options={[{ value: "Back" }, { value: "Forward" }]} />);

  FasciaFitEditor = (<CustomDropDown options={[{ value: "None" }, { value: "Recess" }, { value: "Face Fit" }]} />);

  //Defines the Columns for the Blinds DataGrid
  BlindsColumns = [
    { key: "options", name: "", formatter: <OptionsFormatter />, width: 80, resizable: true },
    { key: "itemNo", name: "ItemNo" },
    { key: "productCode", name: "Product Code", editable: true },
    { key: "location", name: "Location", editable: true },
    {
      key: "styleId",
      name: "Roller Style",
      editor: this.BlindStyleEditor,
      formatter: this.BlindStlyeFormatter,
    },
    { key: "width", name: "Width (mm)", editor: <NumberEditor noEmpty={true} /> },
    { key: "drop", name: "Drop (mm)", editor: <NumberEditor noEmpty={true} /> },
    {
      key: "linkSystemId",
      name: "Linking",
      editor: this.LinkSystemEditor,
      formatter: this.BlindStlyeFormatter,
    },
    { key: "linkedTo", name: "Linked To", editor: NumberEditor },
    {
      key: "linkingPosition",
      name: "Link Position",
      editor: this.LinkingPositionEditor,
    },
    {
      key: "driveType",
      name: "Drive Type",
      editor: this.DriveTypeEditor,
    },
    {
      key: "driveMechanismId",
      name: "Drive Mechanism",
      editor: this.DriveMechanismEditor,
      formatter: this.DriveMechanismFormatter,
    },
    {
      key: "driveColour",
      name: "Drive Colour",
      editor: this.DriveColourListEditor,
      formatter: this.ColourListFormatter,
    },
    { key: "driveSide", name: "Drive Side", editor: this.DriveSideEditor },
    { key: "chainDrop", name: "Chain Drop (mm)", editor: NumberEditor },
    { key: "chainType", name: "Chain Type", editor: this.ChainTypeEditor },
    {
      key: "accessoryId",
      name: "Accessory Type",
      editor: this.AccessoryEditor,
      formatter: this.BlindStlyeFormatter,
    },
    {
      key: "hasDoubleBracketSystem",
      name: "Double Bracket?",
      editor: this.TrueFalseEditor,
      formatter: this.YesNoFormatter,
    },
    {
      key: "doubleBracketSystemId",
      name: "Double Bracket System",
      editor: this.DoubleBracketEditor,
      formatter: this.BlindStlyeFormatter,
    },
    {
      key: "railColour",
      name: "Rail Colour",
      editor: this.RailColourListEditor,
      formatter: this.ColourListFormatter,
    },
    {
      key: "baseBarId",
      name: "Base Bar/Trim Type",
      editor: this.BaseBarEditor,
      formatter: this.BlindStlyeFormatter,
    },
    {
      key: "sideChannelId",
      name: "Side Channel",
      editor: this.SideChannelEditor,
      formatter: this.BlindStlyeFormatter,
    },
    { key: "rollDirection", name: "Roll Direction", editor: this.RollDirectionEditor },
    {
      key: "kassetteId",
      name: "Kassette and Fascia Options",
      editor: this.KassetteEditor,
      formatter: this.BlindStlyeFormatter,
    },
    {
      key: "kassetteColour",
      name: "Kassette Colour",
      editor: this.KassetteColourListEditor,
      formatter: this.ColourListFormatter,
    },
    { key: "fasciaFit", name: "Fascia Fit", editor: this.FasciaFitEditor },
    {
      key: "fabricId",
      name: "Fabric",
      editor: this.FabricEditor,
      formatter: this.BlindStlyeFormatter,
    },
    {
      key: "fabricColour",
      name: "Fabric Colour",
      editor: this.FabricColourListEditor,
      formatter: this.ColourListFormatter,
    },
  ];

  componentDidMount() {
    this.props.dispatch(setLoaderState(false));
  }

  componentWillUnmount() {
    document.removeEventListener("paste", this.handlePaste);
  }

  handlePaste = (e) => {
    e.preventDefault();

    const { currentCollumn } = this.state;

    const newRows = [];
    const pasteData = defaultParsePaste(e.clipboardData.getData("text/plain"));

    pasteData.forEach((row) => {
      const rowData = {};

      // Merge the values from pasting and the keys from the columns
      this.BlindsColumns.slice(currentCollumn.idx, currentCollumn.idx + row.length).forEach((col, j) => {
        // Create the key-value pair for the row
        rowData[col.key] = row[j];
      });

      // Push the new row to the changes
      newRows.push(rowData);
    });

    this.updateRows(currentCollumn.rowIdx, newRows);
  };

  updateRows = (startIdx, newRows) => {
    const rows = this.props.worksheet.items.slice();

    for (let i = 0; i < newRows.length; i++) {
      if (startIdx + i < rows.length) {
        rows[startIdx + i] = { ...rows[startIdx + i], ...newRows[i] };
      }
    }

    this.props.dispatch(populateWorksheet(rows));
    this.props.handleChanges(startIdx, newRows.length - 1);
  };

  //TODO: Implement a way to issue delete for a specific cell regardless of editor
  // componentDidUpdate() {
  //   if (this.state.issueAction) {
  //     const temp = this.props.worksheet.items;
  //     temp[this.state.currentCollumn.rowIdx] = {
  //       ...temp[this.state.currentCollumn.rowIdx],
  //       [this.BlindsColumns[this.state.currentCollumn.idx].key]: ""
  //     };
  //     this.setState({ issueAction: false });
  //     this.props.dispatch(populateWorksheet(temp));
  //   }
  // }

  //When a Row is updated do this to push the changes to the redux state
  onGridRowsUpdated = ({ fromRow, toRow, updated, action }) => {
    if (action === "COLUMN_FILL") {
    } else {
      const temp = this.props.worksheet.items;
      for (let i = fromRow; i <= toRow; i++) {
        temp[i] = { ...temp[i], ...updated };
      }
      this.props.dispatch(populateWorksheet(temp));
      this.props.handleChanges(fromRow, toRow);
    }
  };
  //TODO: Implement a way to issue delete for a specific cell regardless of editor
  // onGridKeyDown = e => {
  //   if (e.keyCode == 46) {
  //     e.preventDefault();
  //     this.setState({ issueAction: true });
  //   }
  // };

  onRowsSelected = (rows) => {
    const temp = this.props.worksheet.selectedIndexs.concat(rows.map((r) => r.rowIdx));
    this.props.dispatch(selectIndex(temp));
  };

  onRowsDeselected = (rows) => {
    let rowIndexs = rows.map((r) => r.rowIdx);
    const temp = this.props.worksheet.selectedIndexs.filter((i) => rowIndexs.indexOf(i) === -1);
    this.props.dispatch(selectIndex(temp));
  };

  onCellSelected = (cell) => {
    const temp = cell.rowIdx;
    if (!this.props.worksheet.selectedIndexs.includes(temp)) this.props.dispatch(selectIndex([temp]));
    this.setState({ currentCollumn: cell });
  };

  onCellDeselected = (cell) => {
    let temp = cell.rowIdx;
    if (!this.props.worksheet.selectedIndexs.includes(temp)) this.props.dispatch(selectIndex([]));
  };

  //the Helper class just ensures that the headers will all display correctly
  blindsHelper = new Helper(this.BlindsColumns);

  render() {
    return (
      <div className="react-grid-multiline-header">
        {this.props.currentProduct === "blinds" && this.props.productID !== undefined ? (
          <ReactDataGrid
            rowKey="id"
            columns={this.blindsHelper.columns}
            rowGetter={(i) => this.props.worksheet.items[i]}
            rowsCount={this.props.worksheet.items.length}
            //minColumnWidth={100}
            headerRowHeight={this.blindsHelper.headerRowHeight}
            onGridRowsUpdated={this.onGridRowsUpdated}
            enableCellSelect={true}
            onCellSelected={this.onCellSelected}
            onCellDeSelected={this.onCellDeselected}
            rowHeight={50}
            rowSelection={{
              showCheckbox: false,
              enableShiftSelect: true,
              onRowsSelected: this.onRowsSelected,
              onRowsDeselected: this.onRowsDeselected,
              selectBy: {
                indexes: this.props.worksheet.selectedIndexs,
              },
            }}
            //TODO: Implement a way to issue delete for a specific cell regardless of editor
            //onGridKeyDown={this.onGridKeyDown}
          />
        ) : (
          "WRONG"
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.worksheet, AlgoliaKey: state.key };
};

export default connect(mapStateToProps)(BlindsWorksheet);
