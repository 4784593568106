import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class LegalMenu extends Component {
  render() {
    return (
      <div className="subMenuLinksList-expanded">
        <ul>
          <li>
            <div className="not-implemented">Insurance</div>
          </li>
          <li>
            <div className="not-implemented">Main Contract</div>
          </li>
          <li>
            <div className="not-implemented">Sub-Contract</div>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(LegalMenu);
