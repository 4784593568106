import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { branchFields } from "../fields";
import { API } from "aws-amplify";
import { selectBranch, updateBranchDetails } from "../../../actions/manufacturersActions";
import { setRefresh } from "../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";

class EditBranch extends Component {
  get fields() {
    // Make a Deep copy of the Fields that will be passed in
    // for editing
    let fields = JSON.parse(JSON.stringify(branchFields));
    const details = { ...this.props.details };

    // AddressType and Name are stored directly in details,
    // whereas the rest of the address is stored in details.address
    for (let i = 0; i < fields.length; i++) {
      // Match the field ID with the value kept in details.address:
      fields[i].value = details.address[fields[i].id];

      // If the ID couldn't be found in details.address, search
      // one step above i.e. for name and AddressType
      if (!fields[i].value) {
        fields[i].value = details[fields[i].id];
      }
    }
    return fields;
  }

  APIMethod = async formData => {
    try {
      const { manufacturerId, branchId } = this.props.match.params;
      this.props.dispatch(setAlgoliaLoaderState(true));
      const response = await API.put(
        "telishadeAPI",
        `/manufacturer/${manufacturerId}/branch/${branchId}`,
        {
          body: {
            details: {
              name: formData.name,
              addressType: formData.addressType,
              address: {
                streetNumber: formData.streetNumber,
                streetName: formData.streetName,
                city: formData.city,
                postcode: formData.postcode,
                state: formData.state,
                country: formData.country
              }
            }
          }
        }
      );
      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.dispatch(setRefresh(true));
      await this.props.dispatch(updateBranchDetails(response.details));
      await this.props.dispatch(selectBranch(response.id));
      this.props.history.push(`/manufacturers/${manufacturerId}/branches/${branchId}`);
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.fields}
        header="Edit Branch Details"
        button="ACCEPT"
        buttoncolour="green"
        button2="CANCEL"
        button2colour="red"
        apiMethod={this.APIMethod}
      />
    );
  }
}

const mapStateToProps = state => state.manufacturers.branch;
export default withRouter(connect(mapStateToProps)(EditBranch));
