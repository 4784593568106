import {
  SELECT_CUSTOMER,
  SELECT_BRANCH,
  SELECT_PROJECT,
  SELECT_CONTACT,
  UPDATE_CUSTOMER_DETAILS,
  UPDATE_BRANCH_DETAILS,
  UPDATE_PROJECT_DETAILS,
  UPDATE_CONTACT_DETAILS,
  SET_CUSTOMERLIST_LOADING_STATE,
  SET_BRANCHLIST_LOADING_STATE,
  SET_PROJECTLIST_LOADING_STATE,
  SET_CONTACTLIST_LOADING_STATE
} from "../actions/customersActions";

// Initial state
const initialState = {
  customer: {
    id: null,
    details: {
      name: "",
      regNo: "",
      email: "",
      website: "",
      phone: "",
      mobile: "",
      fax: ""
    }
  },

  customerList: {
    stillLoading: true
  },

  branch: {
    id: "",
    details: {
      name: "",
      addressType: "",
      address: {
        streetNumber: "",
        streetName: "",
        city: "",
        postcode: "",
        state: "",
        country: ""
      }
    }
  },

  branchList: {
    stillLoading: true
  },

  project: {},

  projectList: {
    stillLoading: true
  },

  contact: {
    id: "",
    details: {
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      fax: "",
      mobile: ""
    }
  },

  contactList: {
    stillLoading: true
  }
};

// Customers Reducer
export default function customersReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_CUSTOMER:
      return {
        ...state,
        customer: {
          ...state.customer,
          id: action.id
        }
      };

    case SELECT_BRANCH:
      return {
        ...state,
        branch: {
          ...state.branch,
          id: action.id
        }
      };

    case SELECT_PROJECT:
      return {
        ...state,
        project: {
          ...state.project,
          id: action.id
        }
      };

    case UPDATE_CUSTOMER_DETAILS:
      return {
        ...state,
        customer: {
          ...state.customer,
          details: action.details
        }
      };

    case UPDATE_BRANCH_DETAILS:
      return {
        ...state,
        branch: {
          ...state.branch,
          details: action.details
        }
      };

    case UPDATE_PROJECT_DETAILS:
      return {
        ...state,
        project: {
          ...state.project,
          details: action.details
        }
      };

    case UPDATE_CONTACT_DETAILS:
      return {
        ...state,
        contact: {
          ...state.contact,
          details: action.details
        }
      };

    case SELECT_CONTACT:
      return {
        ...state,
        contact: {
          ...state.contact,
          id: action.id
        }
      };

    case SET_CUSTOMERLIST_LOADING_STATE:
      return {
        ...state,
        customerList: {
          ...state.customerList,
          stillLoading: action.bool
        }
      };

    case SET_BRANCHLIST_LOADING_STATE:
      return {
        ...state,
        branchList: {
          ...state.branchList,
          stillLoading: action.bool
        }
      };

    case SET_PROJECTLIST_LOADING_STATE:
      return {
        ...state,
        projectList: {
          ...state.projectList,
          stillLoading: action.bool
        }
      };

    case SET_CONTACTLIST_LOADING_STATE:
      return {
        ...state,
        contactList: {
          ...state.contactList,
          stillLoading: action.bool
        }
      };

    default:
      return state;
  }
}
