import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { MDBBtn } from "mdbreact";

class JobInfo extends Component {
  handleEditClick = () => {
    const currentURL = this.props.history.location.pathname;
    this.props.history.push(currentURL + "/edit");
  };

  handleDeleteClick = () => {
    const currentURL = this.props.history.location.pathname;
    this.props.history.push(currentURL + "/delete");
  };

  render() {
    const CurrentJob = this.props.jobDetails;
    if (CurrentJob !== undefined && CurrentJob !== {}) {
      return (
        <div className="projectDetails">
          <div>
            <strong>Job Details</strong>
          </div>
          <div>
            <strong>Job Name:</strong> {CurrentJob.name}
          </div>
          <div>
            <strong>Job status:</strong> {CurrentJob.status}
          </div>
          <div>
            <strong>Building Unit:</strong> {CurrentJob.buildingUnit}
          </div>
          <div>
            <strong>Level:</strong>
            {CurrentJob.level}
          </div>
          <div>
            <strong>Floor Plan Type:</strong> {CurrentJob.floorPlanType}
          </div>
          <div id="acceptButton">
            <MDBBtn onClick={this.handleEditClick} color="blue" id="editBtn">
              Edit
            </MDBBtn>
            <MDBBtn onClick={this.handleDeleteClick} color="red" id="editBtn">
              Delete
            </MDBBtn>
          </div>
        </div>
      );
    } else {
      return <div className="projectDetails">Loading...</div>;
    }
  }
}

export default withRouter(connect()(JobInfo));
