import React, { Component } from "react";
import { connect } from "react-redux";
import SearchableList from "./SearchableList";
import config from "../../../config.js";
class MultiSelectList extends Component {
  constructor(props) {
    super(props);
    this.state = { ListFilterString: "", SelectedFilterString: "" };
  }

  componentDidMount() {}

  HitComponent = ({ hit }) => {
    let className = "";
    if (this.props.id === hit.objectID) className = { className: "selected" };
    return (
      <p {...className} onClick={i => this.props.listItemClickHandler(i, hit, this.props.id)}>
        <strong>{hit.details.name}</strong>
      </p>
    );
  };

  SelectedComponent = ({ hit }) => {
    let className = "SelectedPriceList";
    return (
      <div className={className}>
        {hit.details.name}
        <div onClick={i => this.props.removeClickHandler(i, hit, this.props.id)} className="Remove">
          X
        </div>
      </div>
    );
  };

  getListFilterString() {
    let filterString = " AND productId:" + this.props.currentProductID;
    if (this.props.selectedValues !== "" && this.props.selectedValues !== undefined) {
      filterString = " AND (NOT objectID:" + this.props.selectedValues[0];
      this.props.selectedValues.map(i => {
        if (i !== this.props.selectedValues[0])
          filterString = filterString + " AND NOT objectID:" + i;
        return "";
      });
      filterString = this.props.productsMulti
        ? filterString + ") AND productId:" + this.props.currentProductID
        : filterString + ")";
    }
    return filterString;
  }

  getSelectedFilterString() {
    let filterString = "";
    if (this.props.selectedValues !== "" && this.props.selectedValues !== undefined) {
      filterString = " OR objectID:" + this.props.selectedValues[0];
      this.props.selectedValues.map(i => {
        if (i !== this.props.selectedValues[0]) filterString = filterString + " OR objectID:" + i;
        return "";
      });
    }
    return filterString;
  }

  render() {
    let ListFilterString = this.getListFilterString();
    let SelectedFilterString = this.getSelectedFilterString();
    return (
      <div className="AlgoliaMultiSelect">
        <div className="AlgoliaMultiSelectList">
          <SearchableList
            appId={`${config.algolia.appId}`}
            index={this.props.index}
            hitComponent={this.HitComponent}
            selectedObjectId={this.props.id}
            filters={"isDeleted:false" + ListFilterString + this.props.filter}
            hideSearchBox={true}
            hitsPerPage={1000}
          />
        </div>
        <div className="AlgoliaMultiSelectListSelected">
          <label>Selected Items</label>
          <SearchableList
            appId={`${config.algolia.appId}`}
            index={this.props.index}
            hitComponent={this.SelectedComponent}
            selectedObjectId={this.props.id}
            hitsPerPage={1000}
            filters={
              this.props.productsMulti
                ? "isDeleted:false AND ( objectId: idPlaceholder" + // idPlaceholder is used otherwise Algolia would ignore objectId field
                  SelectedFilterString +
                  ")" +
                  " AND productId:" +
                  this.props.currentProductID +
                  this.props.filter
                : "isDeleted:false AND ( objectId: idPlaceholder" + // idPlaceholder is used otherwise Algolia would ignore objectId field
                  SelectedFilterString +
                  ")" +
                  this.props.filter
            }
            hideSearchBox={true}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state.products;
};

export default connect(mapStateToProps)(MultiSelectList);
