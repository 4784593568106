import React, { Component } from "react";
import PopUpForm from "../../../Generic/PopUpDialog/PopUpForm";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { setRefresh } from "../../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../../actions/loaderActions";

class CreateBlindColourList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationTypes: [
        {
          id: "name",
          label: "Colour List Name",
          type: "text",
          tooltip: "Please Enter Colour List Name",
          required: true,
          value: ""
        },
        {
          id: "componentType",
          label: "Associated Component",
          type: "select",
          options: [
            { name: "Drive Mechanism", value: "driveMechanism" },
            { name: "Rail", value: "rail" },
            { name: "Kassette", value: "kassette" }
          ],
          value: "driveMechanism"
        }
      ]
    };
  }
  handleCancelClick = () => {
    this.props.history.goBack();
  };

  APIMethod = async formData => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));
      const body = {
        componentType: formData.componentType,
        details: {
          name: formData.name,
          list: []
        }
      };
      await API.post("telishadeAPI", "/product/blind/colour-list", {
        body
      });
      this.props.history.push(`/products/blinds/colour-list`);
      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.dispatch(setRefresh(true));
    } catch (e) {
      this.props.dispatch(setAlgoliaLoaderState(false));
      throw e;
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.state.validationTypes}
        apiMethod={this.APIMethod}
        header="Add New Blind Base Bar"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

export default connect()(CreateBlindColourList);
