import {
  SELECT_MANUFACTURER,
  SELECT_BRANCH,
  SELECT_CONTACT,
  UPDATE_MANUFACTURER_DETAILS,
  UPDATE_BRANCH_DETAILS,
  UPDATE_CONTACT_DETAILS
} from "../actions/manufacturersActions";

// Initial state
const initialState = {
  manufacturer: {
    id: null,
    details: {
      name: "",
      regNo: "",
      email: "",
      website: "",
      phone: "",
      mobile: "",
      fax: ""
    }
  },

  branch: {
    id: "",
    details: {
      name: "",
      addressType: "",
      address: {
        streetNumber: "",
        streetName: "",
        city: "",
        postcode: "",
        state: "",
        country: ""
      }
    }
  },

  contact: {
    id: "",
    details: {
      title: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      fax: "",
      mobile: ""
    }
  }
};

// Manufacturers Reducer
export default function manufacturersReducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_MANUFACTURER:
      return {
        ...state,
        manufacturer: {
          ...state.manufacturer,
          id: action.id
        },
        branch: {},
        contact: {}
      };

    case SELECT_BRANCH:
      return {
        ...state,
        branch: {
          ...state.branch,
          id: action.id
        },
        contact: {}
      };

    case UPDATE_MANUFACTURER_DETAILS:
      return {
        ...state,
        manufacturer: {
          ...state.manufacturer,
          details: action.details
        }
      };

    case UPDATE_BRANCH_DETAILS:
      return {
        ...state,
        branch: {
          ...state.branch,
          details: action.details
        }
      };

    case UPDATE_CONTACT_DETAILS:
      return {
        ...state,
        contact: {
          ...state.contact,
          details: action.details
        }
      };

    case SELECT_CONTACT:
      return {
        ...state,
        contact: {
          ...state.contact,
          id: action.id
        }
      };

    default:
      return state;
  }
}
