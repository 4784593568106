import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { MDBBtn, MDBIcon } from "mdbreact";
import { API } from "aws-amplify";
import { selectCustomer, updateCustomerDetails } from "../../actions/customersActions";
import { setLoaderState } from "../../actions/loaderActions";

class CustomerDetailsSelected extends Component {
  async componentDidMount() {
    try {
      this.props.dispatch(setLoaderState(true));
      const { customerId } = this.props.match.params;
      if (!this.props.id) {
        const response = await API.get("telishadeAPI", "/customer/" + customerId);
        this.props.dispatch(updateCustomerDetails(response.details));
        this.props.dispatch(selectCustomer(customerId));
      }
    } catch (error) {
      // If the customer didn't exist, then redirect back
      if (error.response && error.response.status === 404) {
        this.props.history.replace("/customers/");
      }
    } finally {
      this.props.dispatch(setLoaderState(false));
    }
  }

  render() {
    return (
      <div className="customer-details-panel">
        <div className="customer-header">
          <h2>{this.props.details.name}</h2>
        </div>
        {!this.props.details.name ? (
          ""
        ) : (
          <span>
            <strong>Company Registration No.: </strong> {this.props.details.regNo}
            <br />
            <strong>Email:</strong> {this.props.details.email}
            <br />
            <strong>Phone: </strong> {this.props.details.phone}
            <br />
            <strong>Website: </strong> {this.props.details.website}
            <br />
            <strong>fax: </strong> {this.props.details.fax}
            <br />
            <strong>Mobile: </strong> {this.props.details.mobile}
            <br />
            <div id="editButton">
              <MDBBtn
                onClick={() =>
                  this.props.history.push(`/customers/${this.props.match.params.customerId}/edit`)
                }
                color="blue"
                id="custEditBtn"
              >
                Edit
              </MDBBtn>
              <MDBBtn
                onClick={() =>
                  this.props.history.push(`/customers/${this.props.match.params.customerId}/delete`)
                }
                color="red"
                id="custDeleteBtn"
              >
                <MDBIcon icon="trash" />
              </MDBBtn>
            </div>
          </span>
        )}
      </div>
    );
  }
}

class CustomerDetails extends Component {
  render() {
    return (
      <Switch>
        <Route path="/customers/add" />
        <Route
          path="/customers/:customerId"
          render={routeProps => <CustomerDetailsSelected {...this.props} {...routeProps} />}
        />
        <Route path="/customers/">
          <div className="customer-details-panel">
            <div className="no-customer-selected">
              <h2>select a customer</h2>
            </div>
          </div>
        </Route>
      </Switch>
    );
  }
}

const mapStateToProps = state => {
  return state.customers.customer;
};

export default withRouter(connect(mapStateToProps)(CustomerDetails));
