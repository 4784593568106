const data = {
  labels: [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    36,
    37,
    38,
    39,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57,
    58,
    59,
    60,
    61,
    62,
    63,
    64,
    65,
    66,
    67,
    68,
    69,
    70,
    71,
    72,
    73,
    74,
    75,
    76
  ],
  datasets: [
    {
      data: [
        48,
        37,
        52,
        42,
        21,
        26,
        31,
        25,
        6,
        8,
        15,
        9,
        6,
        10,
        4,
        9,
        6,
        2,
        10,
        4,
        9,
        6,
        2,
        14,
        8,
        14,
        7,
        3,
        12,
        1,
        2,
        14,
        8,
        1,
        13,
        11,
        5,
        7,
        3,
        13,
        11,
        5,
        7,
        3,
        12,
        10,
        4,
        9,
        6,
        2,
        14,
        8,
        1,
        13,
        11,
        5,
        8,
        1,
        12,
        13,
        11,
        5,
        7,
        3,
        12,
        10,
        4
      ],
      label: "Weeks",
      borderColor: "#B4BBFA",
      backgroundColor: "#C7DFFF",
      fill: true
    }
  ]
};

export default data;
