import {
  UPDATE_PRODUCT_DETAILS,
  POPULATE_PRODUCT_LIST,
  SELECT_PRODUCT_LIST_ITEM,
  SET_CURRENT_COMPONENT,
  SET_CURRENT_PRODUCT,
  SELECT_ID,
  SET_PRODUCT_ID
} from "../actions/productsActions";

// Initial state
const initialState = {
  productList: {
    items: [],
    selected: null
  },
  productDetails: {},
  currentComponent: "style",
  currentProduct: "blinds",
  selectedID: "1",
  currentProductID: undefined
};

// Products Reducer
export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_PRODUCT_DETAILS:
      return {
        ...state,
        productDetails: action.productDetails
      };

    case POPULATE_PRODUCT_LIST:
      return {
        ...state,
        productList: {
          ...state.productList,
          items: action.productList.items
        }
      };

    case SELECT_PRODUCT_LIST_ITEM:
      return {
        ...state,
        productList: {
          ...state.productList,
          selected: action.productList.selected
        }
      };

    case SET_CURRENT_COMPONENT:
      return {
        ...state,
        currentComponent: action.currentComponent
      };

    case SET_CURRENT_PRODUCT:
      return {
        ...state,
        currentProduct: action.currentProduct
      };

    case SELECT_ID:
      return {
        ...state,
        selectedID: action.selectedID
      };

    case SET_PRODUCT_ID:
      return {
        ...state,
        currentProductID: action.currentProductID
      };

    default:
      return state;
  }
}
