import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { MDBBtn } from "mdbreact";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class PopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      failed: false,
      error: ""
    };
  }

  handleCancelClick = () => {
    this.props.history.goBack();
  };

  handleSubmit = async event => {
    if (this.props.buttonClickHandler !== undefined) {
      try {
        await this.props.buttonClickHandler();
      } catch (e) {
        this.setState({ failed: true, error: e });
      }
    }
  };

  render() {
    return (
      <div className="popupContainer">
        {this.state.failed ? (
          <ErrorHandler
            error={this.state.error}
            buttonClickHandler={() => {
              this.setState({ failed: false });
            }}
            button2ClickHandler={async () => {
              this.setState({ failed: false });
              if (this.props.buttonClickHandler !== undefined) {
                try {
                  const response = await this.props.buttonClickHandler;
                  if (this.props.updateMethod !== undefined) {
                    this.props.updateMethod(response);
                  }
                } catch (e) {
                  this.setState({ failed: true, error: e });
                }
              }
            }}
          />
        ) : (
          <div className="popupWindow">
            <div className="popupHeader" style={{ backgroundColor: this.props.headerColour }}>
              {this.props.header === undefined ? "" : this.props.header}
            </div>
            <div className="popUpTextContainer">
              {this.props.body === undefined ? "" : this.props.body}
            </div>
            <div className="popUpButtonContainer">
              {this.props.button === undefined ? (
                ""
              ) : (
                <MDBBtn
                  onClick={
                    this.props.buttonClickHandler === undefined
                      ? this.handleCancelClick
                      : this.handleSubmit
                  }
                  color={this.props.buttoncolour === undefined ? "blue" : this.props.buttoncolour}
                  id="PopUpButton"
                >
                  {this.props.button}
                </MDBBtn>
              )}

              {this.props.button2 === undefined ? (
                ""
              ) : (
                <MDBBtn
                  onClick={
                    this.props.button2ClickHandler === undefined
                      ? this.handleCancelClick
                      : this.props.button2ClickHandler
                  }
                  color={this.props.button2colour === undefined ? "blue" : this.props.button2colour}
                  id="PopUpButton2"
                >
                  {this.props.button2}
                </MDBBtn>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(PopUp);
