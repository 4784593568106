import React from "react";

export default function ComponentType(props) {
  let { title, setValue, fields, recalculateRow } = props;
  let { tableCellWidth, readyToRender } = props.config;

  const rows = props.rows.map((item, key) => {
    return (
      <tr key={key}>
        <td>{item.name}</td>
        <td>{item.manufacturer}</td>
        <td>{item.qty}</td>
        <td>
          <input
            onChange={(event) =>
              recalculateRow("retail", item.type, item.id, fields, item, event.target.value === "" ? 0 : event.target.value)
            }
            type="number"
            className="form-control  form-control-sm"
            step="0.5"
            id={`${item.type}-${item.id}-retail`}
            value={parseFloat(fields[`${item.type}-${item.id}-retail`]) || ""}
          />
        </td>
        <td>
          <input
            onChange={(event) =>
              recalculateRow(
                "wholesale",
                item.type,
                item.id,
                fields,
                item,
                event.target.value === "" ? 0 : event.target.value
              )
            }
            type="number"
            className="form-control form-control-sm"
            step="0.5"
            id={`${item.type}-${key}-wholesale`}
            value={
              isNaN(parseFloat(fields[`${item.type}-${item.id}-wholesale`]))
                ? ""
                : parseFloat(fields[`${item.type}-${item.id}-wholesale`]) || ""
            }
          />
        </td>
        <td>
          <input
            onChange={(event) => setValue(`${item.type}-${key}-discount-number`, event.target.value)}
            type="text"
            className="form-control form-control-sm expand-with-keystroke"
            id={`${item.type}-${key}-discount-number`}
            value={parseFloat(fields[`${item.type}-${item.id}-discount-number`]) || "--"}
            width="2000"
          />
        </td>
        <td
          className={
            parseFloat(fields[`${item.type}-${item.id}-margin`]) < 15
              ? parseFloat(fields[`${item.type}-${item.id}-margin`]) < 12.5
                ? parseFloat(fields[`${item.type}-${item.id}-margin`]) < 10 ||
                  parseFloat(fields[`${item.type}-${item.id}-margin`]) < 0
                  ? parseFloat(fields[`${item.type}-${item.id}-margin`]) === 0
                    ? "white-cell"
                    : "red-cell"
                  : "orange-cell"
                : "yellow-cell"
              : "white-cell"
          }
        >
          {isNaN(parseFloat(fields[`${item.type}-${item.id}-margin`]))
            ? ""
            : parseFloat(fields[`${item.type}-${item.id}-margin`]).toFixed(2) || "--"}
          %
        </td>
        <td>
          $
          {isNaN(parseFloat(fields[`${item.type}-${item.id}-grossProfit`]))
            ? ""
            : parseFloat(fields[`${item.type}-${item.id}-grossProfit`]).toFixed(2) || "--"}
        </td>
      </tr>
    );
  });

  return (
    <table id="discount-view-table">
      <thead>
        <tr>
          <th className="grow">{title}</th>
          <th>Manufacturer</th>
          <th>Qty</th>
          <th className="number-cell">Retail Discount (%)</th>
          <th className="number-cell">Wholesale Discount (%)</th>
          <th className="wide">Discount Number</th>
          <th>Margin</th>
          <th>Gross Profit</th>
        </tr>
      </thead>
      <tbody>
        {readyToRender ? (
          rows
        ) : (
          <tr>
            <td colSpan={tableCellWidth}>LOADING...</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
