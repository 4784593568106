import React, { Component } from "react";
import PopUpForm from "../../../Generic/PopUpDialog/PopUpForm";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { setRefresh } from "../../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../../actions/loaderActions";

class CreateCurtainBumphPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationTypes: [
        {
          id: "name",
          label: "Fabric Surcharge Name",
          type: "text",
          tooltip: "Please Enter Curtain Fabric Surcharge Name",
          required: true,
          value: ""
        },
        {
          id: "costPerSquareMetre",
          label: "Cost Per Square Metre",
          type: "number",
          tooltip: "Please Enter Price Per Square Metre",
          value: 0
        },
        {
          id: "markup",
          label: "Markup",
          type: "number",
          tooltip: "Please Enter Markup",
          value: 0
        },
        {
          id: "adjustWidthFullness",
          label: "Adjust Width Fullness?",
          type: "select",
          options: [{ name: "True", value: true }, { name: "False", value: false }],
          value: true
        },
        {
          id: "adjustWidthSymbol",
          label: "Adjust Width Symbol",
          type: "select",
          options: [{ name: "<", value: "<" }, { name: ">", value: ">" }],
          value: "<"
        },
        {
          id: "adjustWidth",
          label: "Adjust Width",
          type: "number",
          tooltip: "Please Enter Adjust Width",
          value: 0
        },
        {
          id: "adjustWidthAndOr",
          label: "Adjust Width And Or",
          type: "select",
          options: [{ name: "And", value: "AND" }, { name: "Or", value: "OR" }],
          value: "AND"
        },
        {
          id: "adjustWidthDropSymbol",
          label: "Adjust Width Drop Symbol",
          type: "select",
          options: [{ name: "<", value: "<" }, { name: ">", value: ">" }],
          value: "<"
        },
        {
          id: "adjustDrop",
          label: "Adjust Drop",
          type: "number",
          tooltip: "Please Enter Adjust Drop",
          value: 0
        }
      ]
    };
  }

  handleCancelClick = () => {
    this.props.history.goBack();
  };

  handleSubmitClick = formData => {
    const body = {
      details: {
        name: formData.name,
        costPerSquareMetre: formData.costPerSquareMetre,
        markup: formData.markup,
        adjustWidthFullness: formData.adjustWidthFullness,
        adjustWidthSymbol: formData.adjustWidthSymbol,
        adjustWidth: formData.adjustWidth,
        adjustWidthAndOr: formData.adjustWidthAndOr,
        adjustWidthDropSymbol: formData.adjustWidthDropSymbol,
        adjustDrop: formData.adjustDrop
      }
    };
    return API.post("telishadeAPI", "/product/curtain/fabric-surcharge", {
      body
    });
  };

  APIMethod = async formData => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));
      const body = {
        details: {
          name: formData.name,
          costPerSquareMetre: formData.costPerSquareMetre,
          markup: formData.markup,
          adjustWidthFullness: formData.adjustWidthFullness,
          adjustWidthSymbol: formData.adjustWidthSymbol,
          adjustWidth: formData.adjustWidth,
          adjustWidthAndOr: formData.adjustWidthAndOr,
          adjustWidthDropSymbol: formData.adjustWidthDropSymbol,
          adjustDrop: formData.adjustDrop
        }
      };
      await API.post("telishadeAPI", "/product/curtain/fabric-surcharge", {
        body
      });
      this.props.history.push(`/products/curtains/fabric-surcharge`);
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setAlgoliaLoaderState(false));
    } catch (e) {
      this.props.dispatch(setAlgoliaLoaderState(false));
      throw e;
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.state.validationTypes}
        apiMethod={this.APIMethod}
        header="Add New Fabric Surcharge"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

export default connect()(CreateCurtainBumphPopUp);
