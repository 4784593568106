//Action Types
export const SEND_TOAST = "SEND_TOAST";
export const FINISH_TOAST = "FINISH_TOAST";
//Action functions
export function sendToast(type, message, position = "left") {
  return {
    type: SEND_TOAST,
    payload: { type, message, position }
  };
}

export function finishToast(index) {
  return {
    type: FINISH_TOAST,
    payload: { index }
  };
}
