import React, { Component } from "react";
import AddFAB from "../Images/AddFab.png";
import { MDBBtn } from "mdbreact";
class TwoStageFab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: false
    };
  }

  getOption1Style() {
    if (this.state.stage) {
      return { transform: "translateY(-70px)", zIndex: 1 };
    } else {
      return { transform: "translateY(0px)", opacity: 0, zIndex: 0 };
    }
  }

  getOption2Style() {
    if (this.state.stage) {
      return { transform: "translateY(-140px)", zIndex: 1 };
    } else {
      return { transform: "translateY(0px)", opacity: 0, zIndex: 0 };
    }
  }

  getFabStyle() {
    if (this.state.stage) {
      return { transform: "rotate(45deg)", transition: "all 1.5 ease" };
    } else {
      return { transform: "rotate(0deg)", transition: "all 1.5 ease" };
    }
  }

  render() {
    return (
      <div className="custom2stagebtnWrapper">
        <img
          className={"fabButton"}
          alt="Add"
          onClick={() => {
            this.setState({ stage: !this.state.stage });
          }}
          id="addJobButton"
          src={AddFAB}
          style={this.getFabStyle()}
        />
        <MDBBtn
          color="blue"
          onClick={
            this.state.stage
              ? () => {
                  this.props.handleButton1();
                  this.setState({ stage: !this.state.stage });
                }
              : undefined
          }
          className="customFabOption1"
          style={this.getOption1Style()}
        >
          Duplicate Job
        </MDBBtn>
        <MDBBtn
          color="blue"
          onClick={
            this.state.stage
              ? () => {
                  this.props.handleButton2();
                  this.setState({ stage: !this.state.stage });
                }
              : undefined
          }
          className="customFabOption2"
          style={this.getOption2Style()}
        >
          New Job
        </MDBBtn>
        {this.state.stage ? (
          <div
            className="ClickAwayDiv"
            onClick={() => {
              this.setState({ stage: !this.state.stage });
            }}
            style={{
              height: "100vh",
              width: "100vw",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 0
            }}
          ></div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default TwoStageFab;
