import {
  POPULATE_WORKSHEET,
  CHANGE_CURRENT_PRODUCT,
  ADD_ROW,
  SELECT_INDEX,
  WSTOGGLE_MULTISELECT,
  DUPLICATE_WORKSHEET_ITEM,
  SET_WORKSHEET_PRODUCT_ID,
  SET_WORKSHEET_PROJECT_DETAILS,
  SET_FORMATTER_CACHE,
  SET_WORKSHEET_ROW_DATA
} from "../actions/worksheetActions";

//Inital State
const initalState = {
  worksheet: {
    items: [],
    selectedIndexs: [],
    multiselect: false
  },
  formatterCache: { components: {}, colours: {} },
  currentProduct: "blinds",
  productId: undefined,
  projectDetails: undefined
};

export default function worksheetReducer(state = initalState, action) {
  switch (action.type) {
    case POPULATE_WORKSHEET:
      return {
        ...state,
        worksheet: {
          ...state.worksheet,
          items: action.worksheet.items
        }
      };

    case CHANGE_CURRENT_PRODUCT:
      return {
        ...state,
        worksheet: {
          items: [],
          selectedIndexs: []
        },
        currentProduct: action.currentProduct
      };

    case ADD_ROW:
      return {
        ...state,
        worksheet: {
          ...state.worksheet,
          items: state.worksheet.items.concat({
            ...action.payload
          })
        }
      };

    case SELECT_INDEX:
      return {
        ...state,
        worksheet: {
          ...state.worksheet,
          selectedIndexs: action.worksheet.selectedIndexs
        }
      };

    case WSTOGGLE_MULTISELECT:
      return {
        ...state,
        worksheet: {
          ...state.worksheet,
          wsmultiselect: action.worksheet.multiselect
        }
      };

    case DUPLICATE_WORKSHEET_ITEM:
      return {
        ...state,
        worksheet: {
          ...state.worksheet,
          items: state.worksheet.items.concat(action.row)
        }
      };

    case SET_WORKSHEET_PRODUCT_ID:
      return {
        ...state,
        productId: action.productId
      };

    case SET_WORKSHEET_PROJECT_DETAILS:
      return {
        ...state,
        projectDetails: action.projectDetails
      };

    case SET_FORMATTER_CACHE:
      return {
        ...state,
        formatterCache: action.payload
      };

    case SET_WORKSHEET_ROW_DATA:
      let newWorksheetData = JSON.parse(JSON.stringify(state.worksheet.items));
      newWorksheetData[action.payload.rowNumber - 1] = action.payload.rowData;
      return {
        ...state,
        worksheet: {
          ...state.worksheet,
          items: newWorksheetData
        }
      };

    default:
      return state;
  }
}
