// Action Type Constants
export const POPULATE_SIDE_LIST = "POPULATE_SIDE_LIST";
export const SELECT_SIDE_LIST_ITEM = "SELECT_SIDE_LIST_ITEM";
export const SELECT_SIDE_LIST_TAB = "SELECT_SIDE_LIST_TAB";
export const TOGGLE_MULTISELECT = "TOGGLE_MULTISELECT";
export const TOGGLE_SELECTALL = "TOGGLE_SELECTALL";
export const SET_PROJECT_DETAILS = "SET_PROJECT_DETAILS";
export const SET_JOB_DETAILS = "SET_JOB_DETAILS";
export const TOGGLE_MENU = "PROJECTS::TOGGLE_MENU";
export const TOGGLE_SUBMENU = "PROJECTS::TOGGLE_SUBMENU";
//Action Creators
export function populateSideList(items) {
  return {
    type: POPULATE_SIDE_LIST,
    sideList: {
      items
    }
  };
}

export function setProjectDetails(details) {
  return {
    type: SET_PROJECT_DETAILS,
    projectDetails: details
  };
}

export function selectSideListItem(key) {
  return {
    type: SELECT_SIDE_LIST_ITEM,
    sideList: {
      selected: key
    }
  };
}

export function selectSideListTab(tabText) {
  return {
    type: SELECT_SIDE_LIST_TAB,
    selectedTabText: tabText
  };
}

export function toggleMultiselect(multiselect) {
  return {
    type: TOGGLE_MULTISELECT,
    multiselect: multiselect
  };
}

export function toggleSelectAll(selectAll) {
  return {
    type: TOGGLE_SELECTALL,
    selectAll: selectAll
  };
}

export function setJobDetails(details) {
  return {
    type: SET_JOB_DETAILS,
    jobDetails: details
  };
}

export function toggleMenu(menu) {
  return {
    type: TOGGLE_MENU,
    menu
  };
}

export function toggleSubMenu(menu) {
  return {
    type: TOGGLE_SUBMENU,
    menu
  };
}
