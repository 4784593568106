import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { MDBBtn } from "mdbreact";

class HeaderRoutes extends Component {
  CustomerHeader = () => {
    const handleAddNewClick = () => {
      this.props.history.push("/customers/add");
    };

    return (
      <div>
        <h2>Customers</h2>
        <MDBBtn color="white" id="HeaderButton" onClick={handleAddNewClick}>
          Add Customer
        </MDBBtn>
      </div>
    );
  };

  ManufacturerHeader = () => {
    const handleAddNewClick = () => {
      this.props.history.push("/manufacturers/add");
    };

    return (
      <div>
        <h2>Manufacturers</h2>
        <MDBBtn color="white" id="HeaderButton" onClick={handleAddNewClick}>
          Add Manufacturer
        </MDBBtn>
      </div>
    );
  };

  StaffHeader = () => {
    const handleAddNewClick = () => {
      this.props.history.push("/staff/add");
    };
    return (
      <div>
        <h2>Staff</h2>
        <MDBBtn color="white" id="HeaderButton" onClick={handleAddNewClick}>
          New Staff Member
        </MDBBtn>
      </div>
    );
  };

  ProjectHeader = () => {
    const handleAddNewClick = () => {
      this.props.history.push("/projects/add");
    };
    return (
      <div>
        <h2>Projects</h2>
        <MDBBtn color="white" id="HeaderButton" onClick={handleAddNewClick}>
          New Project
        </MDBBtn>
      </div>
    );
  };

  DefaultHeader = () => {
    return (
      <div>
        <h2>&nbsp;</h2>
      </div>
    );
  };

  render() {
    return (
      <Switch>
        <Route exact path="/" render={props => <h2>Dashboard</h2>} />
        <Route path="/customers" render={this.CustomerHeader} />
        <Route exact path="/projects/:id" render={this.ProjectHeader} />
        <Route path="/projects/:id/:id" render={props => <h2>Projects</h2>} />
        <Route path="/staff" render={this.StaffHeader} />
        <Route exact path="/products" render={props => <h2>Products</h2>} />
        <Route path="/products/blinds" render={props => <h2>Blinds</h2>} />
        <Route path="/products/curtains" render={props => <h2>Curtains</h2>} />
        <Route path="/manufacturers" render={this.ManufacturerHeader} />
        <Route path="/my-account" render={props => <h2>My Account</h2>} />
        <Route render={this.DefaultHeader} />
      </Switch>
    );
  }
}

export default withRouter(HeaderRoutes);
