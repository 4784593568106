import React, { Component } from "react";
import "./BulkEdit.css";
import BulkItemList from "./BulkItemList";
import { connect } from "react-redux";
import config from "../../../config";
import algoliasearch from "algoliasearch";

//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { API } from "aws-amplify";

class BulkEdit extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, jobs: [], headerText: "Loading..." };
    this.getProjectItemsbyJob(this.props.match.params.id);
  }

  reloadList = () => {
    this.setState({ loading: true, jobs: [], headerText: "Loading..." });
    this.getProjectItemsbyJob(this.props.match.params.id);
  };

  async getProjectItemsbyJob(ProjectId) {
    let jobItemCollection = [];
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getProjectItemsbyJob(ProjectId);
      }, 500);
    } else {
      try {
        let project = undefined;
        try {
          project = await API.get("telishadeAPI", `/project/commercial/${ProjectId}`);
        } catch {
          project = await API.get("telishadeAPI", `/project/domestic/${ProjectId}`);
        }
        const client = await algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
        const index = await client.initIndex(`${config.algolia.prefix}projects_jobs_number_asc`);
        const response = await index.search({
          query: "",
          filters: "isDeleted:false AND projectId:" + ProjectId,
          hitsPerPage: 1000
        });
        response.hits.forEach(job => {
          jobItemCollection.push({ name: job.details.name, id: job.objectID });
        });
        let blindItemPromises = [];
        let curtainItemPromies = [];
        jobItemCollection.forEach(job => {
          blindItemPromises.push(
            API.get(
              "telishadeAPI",
              `/project/${project.type}/${ProjectId}/job/${job.id}/quote/blind`
            )
          );
          curtainItemPromies.push(
            API.get(
              "telishadeAPI",
              `/project/${project.type}/${ProjectId}/job/${job.id}/quote/curtain`
            )
          );
        });
        blindItemPromises = await Promise.all(blindItemPromises);
        curtainItemPromies = await Promise.all(curtainItemPromies);

        for (let i = 0; i < jobItemCollection.length; i++) {
          if (blindItemPromises[i].quote.length > 0) {
            jobItemCollection[i].blinds = blindItemPromises[i].quote;
          }
          if (curtainItemPromies[i].quote.length > 0) {
            jobItemCollection[i].curtains = curtainItemPromies[i].quote;
          }
        }
        this.setState({
          jobs: jobItemCollection,
          loading: false,
          headerText: project.details.name,
          project: project
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  render() {
    return (
      <div className="bulkEditContainer">
        <div className="projectHeader">
          <FontAwesomeIcon
            id="backArrowBulkEdit"
            icon={faArrowLeft}
            onClick={() => this.props.history.goBack()}
          />
          <h2>{this.state.loading ? "Loading..." : this.state.headerText}</h2>
        </div>
        {this.state.loading ? (
          <div className="loader">LOADING...</div>
        ) : (
          <BulkItemList
            reloadList={this.reloadList}
            project={this.state.project}
            jobs={this.state.jobs}
          />
        )}
      </div>
    );
  }
}

const mapStatetoProps = state => {
  return { AlgoliaKey: state.key, projectDetails: state.projects.projectDetails };
};

export default connect(mapStatetoProps)(BulkEdit);
