import { combineReducers } from "redux";
import authenticationReducer from "./authenticationReducer";
import sideNavReducer from "./sideNavReducer";
import customersReducer from "./customersReducer";
import productsReducer from "./productsReducer";
import projectsReducer from "./projectsReducer";
import manufacturersReducer from "./manufacturersReducer";
import staffReducer from "./staffReducer";
import worksheetReducer from "./worksheetReducer";
import accountReducer from "./accountReducer";
import loaderReducer from "./loaderReducer";
import algoliaReducer from "./algoliaReducer";
import dashboardReducer from "./dashboardReducer";
import documentsReducer from "./documentsReducer";
import keyReducer from "./keyReducer";
import toastReducer from "./toastReducer";

const appReducer = combineReducers({
  authentication: authenticationReducer,
  sideNav: sideNavReducer,
  customers: customersReducer,
  products: productsReducer,
  projects: projectsReducer,
  manufacturers: manufacturersReducer,
  staff: staffReducer,
  worksheet: worksheetReducer,
  account: accountReducer,
  loader: loaderReducer,
  algolia: algoliaReducer,
  dashboard: dashboardReducer,
  documents: documentsReducer,
  toast: toastReducer,
  key: keyReducer
});

const rootReducer = (state, action) => {
  // Wraps appReducer to detect when a user logs out.
  // When LOGOUT is received, replaces the state with 'undefined'.
  // This causes the state to re-populate with the initial state of each reducer.
  if (action.type === "LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
