import React, { Component } from "react";
import PopUpForm from "../../../Generic/PopUpDialog/PopUpForm";
import { connect } from "react-redux";
import { API } from "aws-amplify";

import { setRefresh } from "../../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../../actions/loaderActions";
import config from "../../../../config";

class CreateCurtainAccessoryPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationTypes: [
        {
          id: "name",
          label: "Accessory Name",
          type: "text",
          tooltip: "Please Enter Blind Accessory Name",
          required: true,
          value: ""
        },
        {
          id: "cost",
          label: "Accessory Cost",
          type: "number",
          tooltip: "Please Enter Blind Accessory Cost",
          required: true,
          value: 0
        },
        {
          id: "markup",
          label: "Markup (%)",
          type: "number",
          tooltip: "Please Enter Blind Accessory Markup",
          required: true,
          value: 0
        },
        {
          id: "technicalInfo",
          label: "Technical Info",
          type: "text",
          tooltip: "Please Enter Blind Accessory technicalInfo",
          required: false,
          value: ""
        },
        {
          id: "manufacturerId",
          label: "Manufacturer ID",
          type: "AlgoliaSelect",
          index: `${config.algolia.prefix}manufacturers_name_asc`,
          filters: "isDeleted:false",
          value: ""
        },
        {
          id: "associatedServices",
          label: "Associated Services",
          type: "multiSelect",
          index: `${config.algolia.prefix}products_services_fees_name_asc`,
          value: []
        }
      ]
    };
  }
  handleCancelClick = () => {
    this.props.history.goBack();
  };

  APIMethod = async formData => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));
      const body = {
        details: {
          name: formData.name,
          cost: formData.cost,
          markup: formData.markup,
          technicalInfo: formData.technicalInfo,
          manufacturerId: formData.manufacturerId,
          associatedServices: formData.associatedServices
        }
      };
      await API.post("telishadeAPI", "/product/curtain/component/accessory", {
        body
      });
      this.props.history.push(`/products/curtains/accessory`);
      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.dispatch(setRefresh(true));
    } catch (e) {
      this.props.dispatch(setAlgoliaLoaderState(false));
      throw e;
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.state.validationTypes}
        apiMethod={this.APIMethod}
        header="Add New Curtain Accessory"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

export default connect()(CreateCurtainAccessoryPopUp);
