import React, { Component, Fragment } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import TabBar from "./TabBar";
import ProjectsTab from "./ProjectsTab/ProjectsTab";
import ContactBranchList from "./ContactsTab/BranchList";
import ContactList from "./ContactsTab/ContactList";
import DocumentTab from "./DocumentsTab/DocumentTab";
import MessageTab from "./MessageTab";

class CustomerTabs extends Component {
  tabNames = ["projects", "contacts", "documents", "messages"];

  render() {
    return (
      <Fragment>
        <Switch>
          {/* PROJECTS TAB */}
          <Route
            path="/customers/:customerId/projects"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} selected="projects" />
                <ProjectsTab {...this.props} {...routeProps} />
              </div>
            )}
          />
          {/* CONTACTS TAB */}
          <Route
            path="/customers/:customerId/contacts/bid/:branchId"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} selected="contacts" />
                <div className="branch-tab">
                  <ContactBranchList {...this.props} {...routeProps} />
                  <ContactList {...this.props} {...routeProps} />
                </div>
              </div>
            )}
          />
          <Route
            path="/customers/:customerId/contacts"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} selected="contacts" />
                <div className="branch-tab">
                  <ContactBranchList {...this.props} {...routeProps} />
                </div>
              </div>
            )}
          />
          {/* DOCUMENTS TAB */}
          <Route
            path="/customers/:customerId/documents"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} selected="documents" />
                <DocumentTab {...this.props} {...routeProps} />
              </div>
            )}
          />
          <Route
            path="/customers/:customerId/messages"
            render={routeProps => (
              <div className="tab-window">
                <TabBar tabNames={this.tabNames} selected="messages" />
                <MessageTab {...this.props} {...routeProps} />
              </div>
            )}
          />
        </Switch>
      </Fragment>
    );
  }
}

export default withRouter(CustomerTabs);
