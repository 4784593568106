import React, { Component } from "react";
import PopUpForm from "../../../Generic/PopUpDialog/PopUpForm";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { setRefresh } from "../../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../../actions/loaderActions";
import config from "../../../../config";

class CreateCurtainBumphPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationTypes: [
        {
          id: "name",
          label: "Lead Weight name",
          type: "text",
          tooltip: "Please Enter Lead Weight Name",
          required: true,
          value: ""
        },
        {
          id: "description",
          label: "Description",
          type: "text",
          tooltip: "Please Enter Track Description",
          value: ""
        },
        {
          id: "pricePerMetre",
          label: "Price Per Metre",
          type: "number",
          tooltip: "Please Enter Price Per Metre",
          value: 0
        },
        {
          id: "markup",
          label: "Markup",
          type: "number",
          tooltip: "Please Enter Markup",
          value: 0
        },
        {
          id: "technicalInfo",
          label: "Technical Info",
          type: "text",
          tooltip: "Please Enter Technical Info",
          value: ""
        },
        {
          id: "manufacturerId",
          label: "Manufacturer ID",
          type: "AlgoliaSelect",
          index: `${config.algolia.prefix}manufacturers_name_asc`,
          filters: "isDeleted:false",
          value: ""
        },
        {
          id: "associatedServices",
          label: "Associated Services",
          type: "multiSelect",
          index: `${config.algolia.prefix}products_services_fees_name_asc`,
          value: []
        }
      ]
    };
  }

  handleCancelClick = () => {
    this.props.history.goBack();
  };

  APIMethod = async formData => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));
      const body = {
        details: {
          name: formData.name,
          description: formData.description,
          basePrice: formData.basePrice,
          pricePerMetre: formData.pricePerMetre,
          technicalInfo: formData.technicalInfo,
          markup: formData.markup,
          associatedServices: formData.associatedServices,
          manufacturerId: formData.manufacturerId
        }
      };
      await API.post("telishadeAPI", "/product/curtain/component/lead-weight", {
        body
      });
      this.props.history.push(`/products/curtains/lead-weight`);
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setAlgoliaLoaderState(false));
    } catch (e) {
      this.props.dispatch(setAlgoliaLoaderState(false));
      throw e;
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.state.validationTypes}
        apiMethod={this.APIMethod}
        header="Add New Curtain Lead Weight"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

export default connect()(CreateCurtainBumphPopUp);
