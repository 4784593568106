import React, { Component, Fragment } from "react";
import { numberWithCommas } from "../../../../../../../utilities/utils";

class CurtainsSummary extends Component {
  constructFromResponse(response) {
    let newResponse = response;
    if (this.props.grouping !== "job") {
      newResponse = this.handleGrouping(this.props.grouping, response);
    }
    const construct = newResponse.map(job => {
      return this.constructJob(job);
    });
    return construct.map((job, jobKey) => (
      <Fragment key={jobKey}>
        {this.props.grouping !== "job" ? (
          <tr className="details jobNumber">
            <td style={{ textTransform: "capitalize" }}>{job.name}</td>
          </tr>
        ) : (
          <Fragment />
        )}
        {job.items.map((item, key) => (
          <Fragment key={key}>
            <tr className="details jobNumber" key={`Job:${jobKey}, Item:${key}`}>
              {this.props.grouping !== "job" ? <td></td> : <Fragment />}
              <td>{item.item.itemNo}</td>
              <td>{item.qty}</td>
              <td>{item.location}</td>
              <td>{this.constructItemDescription(item.item)}</td>
              <td>{/*`$${(item.total / item.SQM).toFixed(2)}`*/}</td>
              <td>{}</td>
              <td>{}</td>
              <td></td>
              <td></td>
              <td>
                {this.props.detailed ? (
                  <Fragment />
                ) : (
                  `$${numberWithCommas(item.total.toFixed(2))}‬`
                )}
              </td>
            </tr>
            {this.props.detailed ? (
              <Fragment key={key}>
                <tr className="details">
                  {this.props.grouping !== "job" ? <td></td> : <Fragment />}
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Fabric</td>
                  <td>{(item.extras.Fabric.qty / 1000).toFixed(2)}LM</td>
                  <td>${item.extras.Fabric.cost.toFixed(2)}</td>
                  {item.qty > 1 ? (
                    <Fragment>
                      <td>{((item.extras.Fabric.qty * item.qty) / 1000).toFixed(2)}LM</td>
                      <td>${numberWithCommas((item.extras.Fabric.cost * item.qty).toFixed(2))}</td>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <td />
                      <td />
                    </Fragment>
                  )}
                  <td />
                </tr>
                <tr className="details">
                  {this.props.grouping !== "job" ? <td></td> : <Fragment />}
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Makeup</td>
                  <td>{(item.extras.Makeup.qty / 1000).toFixed(2)}m</td>
                  <td>${item.extras.Makeup.cost.toFixed(2)}</td>
                  {item.qty > 1 ? (
                    <Fragment>
                      <td>{((item.extras.Makeup.qty * item.qty) / 1000).toFixed(2)}m</td>
                      <td>${numberWithCommas((item.extras.Makeup.cost * item.qty).toFixed(2))}</td>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <td />
                      <td />
                    </Fragment>
                  )}
                  <td />
                </tr>
                <tr className="details">
                  {this.props.grouping !== "job" ? <td></td> : <Fragment />}
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Track</td>
                  <td>{(item.extras.Track.qty / 1000).toFixed(2)}m</td>
                  <td>${item.extras.Track.cost.toFixed(2)}</td>
                  {item.qty > 1 ? (
                    <Fragment>
                      <td>{((item.extras.Track.qty * item.qty) / 1000).toFixed(2)}m</td>
                      <td>${numberWithCommas((item.extras.Track.cost * item.qty).toFixed(2))}</td>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <td />
                      <td />
                    </Fragment>
                  )}
                  <td />
                </tr>
                {item.extras.Components.map((component, key) => (
                  <tr className="details" key={key}>
                    {this.props.grouping !== "job" ? <td></td> : <Fragment />}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{component.name}</td>
                    <td>{component.qty}</td>
                    <td>${component.cost.toFixed(2)}</td>
                    {item.qty > 1 ? (
                      <Fragment>
                        <td>{component.qty * item.qty}</td>
                        <td>${numberWithCommas((component.cost * item.qty).toFixed(2))}</td>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <td />
                        <td />
                      </Fragment>
                    )}
                    <td />
                  </tr>
                ))}
                {item.extras.Services.map((service, key) => (
                  <tr className="details" key={key}>
                    {this.props.grouping !== "job" ? <td></td> : <Fragment />}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{service.name}</td>
                    <td>{1}</td>
                    <td>${numberWithCommas(service.cost.toFixed(2))}</td>
                    {item.qty > 1 ? (
                      <Fragment>
                        <td>{1 * item.qty}</td>
                        <td>${(service.cost * item.qty).toFixed(2)}</td>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <td />
                        <td />
                      </Fragment>
                    )}

                    <td />
                  </tr>
                ))}
                <tr className="details jobNumber">
                  {this.props.grouping !== "job" ? <td></td> : <Fragment />}
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>

                  <td></td>
                  <td></td>
                  <td>${numberWithCommas(item.total.toFixed(2))}</td>
                </tr>
              </Fragment>
            ) : (
              <Fragment />
            )}
          </Fragment>
        ))}
      </Fragment>
    ));
  }

  handleGrouping(groupingKey, responseObject) {
    let newGroupedCollection = [];
    responseObject.forEach(job => {
      job.quote.forEach(quote => {
        let groupedFlag = false;
        newGroupedCollection.forEach(groupedItem => {
          if (this.getGroupingData(groupingKey, quote) === groupedItem.name) {
            groupedItem.quote.push(quote);
            groupedFlag = true;
          }
        });
        if (!groupedFlag) {
          newGroupedCollection.push({
            name: this.getGroupingData(groupingKey, quote),
            quote: [{ ...quote }]
          });
        }
      });
    });
    return newGroupedCollection;
  }

  getGroupingData(groupingKey, quoteItem) {
    if (
      groupingKey === "location" ||
      groupingKey === "driveType" ||
      groupingKey === "productCode"
    ) {
      return quoteItem.description.allDetails[groupingKey];
    }
    if (groupingKey === "style") return quoteItem.description.headerStyle;
    if (groupingKey === "fabric") return quoteItem.description.fabric;
    if (groupingKey === "fabricAndColour")
      return `${quoteItem.description.fabric} - ${quoteItem.description.fabricColour}`;
    if (groupingKey === "driveMechanism") return quoteItem.description.driveMechanism;
  }

  constructItemDescription(item) {
    const { fabric, driveMechanism, headerStyle, track, width, drop } = item.description;
    const { productCode } = item.description.allDetails;
    if (productCode !== "") {
      return `${productCode} / ${fabric} / ${headerStyle} / ${track} / ${driveMechanism} / Width: ${width}mm, Drop: ${drop}mm`;
    }
    return `${fabric} / ${headerStyle} / ${track} / ${driveMechanism} / Width: ${width}mm, Drop: ${drop}mm`;
  }

  constructJob(JobQuote) {
    let JobItemCollection = [];
    JobQuote.quote.forEach(quoteItem => {
      let AddedFlag = false;
      JobItemCollection.forEach(collectionItem => {
        if (this.componentsAreSame(quoteItem, collectionItem.item)) {
          collectionItem.qty++;
          collectionItem.total += this.calculateCurtainItemTotal(quoteItem, 0, JobQuote.discount);
          collectionItem.SQM += quoteItem.squareMetres;
          AddedFlag = true;
        }
      });
      if (!AddedFlag) {
        JobItemCollection.push({
          item: quoteItem,
          qty: 1,
          SQM: quoteItem.squareMetres,
          total: this.calculateCurtainItemTotal(quoteItem, 0, JobQuote.discount),
          location: quoteItem.description.allDetails.location,
          extras: this.constructExtraDetails(quoteItem, JobQuote.discount)
        });
      }
    });
    let jobTotal = 0;
    JobItemCollection.forEach(jobItem => {
      jobTotal += jobItem.total;
    });
    return {
      items: JobItemCollection,
      name: JobQuote.name,
      number: JobQuote.jobNo,
      discount: JobQuote.discount,
      jobTotal
    };
  }

  constructExtraDetails(quoteItem, discountObject) {
    let extras = { Fabric: {}, Makeup: {}, Track: {}, Components: [], Services: [] };
    if (quoteItem.costInfo.right) {
      extras.Fabric = {
        cost: this.props.isRetail
          ? quoteItem.costInfo.right.markedUpFabricCost + quoteItem.costInfo.left.markedUpFabricCost
          : quoteItem.costInfo.right.fabricCost + quoteItem.costInfo.left.fabricCost,
        qty: quoteItem.costInfo.right.totalFabric + quoteItem.costInfo.left.totalFabric
      };
      if (quoteItem.costInfo.right.makeUpCost) {
        extras.Makeup = {
          cost: quoteItem.costInfo.right.makeUpCost + quoteItem.costInfo.left.makeUpCost,

          qty: quoteItem.costInfo.right.totalFabric + quoteItem.costInfo.left.totalFabric
        };
      }
      if (quoteItem.costInfo.right.makeUpCost) {
        extras.Makeup = {
          cost: this.props.isRetail
            ? quoteItem.costInfo.right.markedUpMakeUpCost +
              quoteItem.costInfo.left.markedUpMakeUpCost
            : quoteItem.costInfo.right.makeUpCost + quoteItem.costInfo.left.makeUpCost,
          qty: quoteItem.costInfo.right.totalFabric + quoteItem.costInfo.left.totalFabric
        };
      }
    } else {
      extras.Fabric = {
        cost: this.props.isRetail
          ? quoteItem.costInfo.markedUpFabricCost
          : quoteItem.costInfo.fabricCost,
        qty: quoteItem.costInfo.totalFabric
      };
      extras.Makeup = {
        cost: this.props.isRetail
          ? quoteItem.costInfo.markedUpMakeUpCost
          : quoteItem.costInfo.makeUpCost,
        qty: quoteItem.costInfo.totalFabric
      };
    }
    extras.Track = {
      cost: this.props.isRetail
        ? quoteItem.costInfo.markedUpTrackTotalCost
        : quoteItem.costInfo.trackTotalCost,
      name: quoteItem.description.track,
      qty:
        quoteItem.costInfo.cutTrack ||
        quoteItem.costInfo.left.cutTrack + quoteItem.costInfo.right.cutTrack
    };
    extras.Components = this.constructExtraComponents(quoteItem, discountObject);
    extras.Services = quoteItem.extras.services.map(service => {
      const totalWithMarkup =
        (parseFloat(service.details.cost) / 100) * (100 + parseFloat(service.details.markup));
      return {
        name: service.details.name,
        cost: this.props.isRetail ? totalWithMarkup : parseFloat(service.details.cost)
      };
    });
    return extras;
  }

  constructExtraComponents(quoteItem, discountObject) {
    const componentDefinition = [
      { name: "Bumph", key: "bumph", qty: quoteItem.description.width / 1000 + "/m" },
      { name: "Drive Mechanism", key: "driveMechanism", qty: 1 },
      { name: "Lining", key: "lining", qty: quoteItem.description.width / 1000 + "/m" },
      { name: "Lead Weight", key: "leadWeight", qty: quoteItem.description.width / 1000 + "/m" },
      { name: "Pelmet", key: "pelmet", qty: quoteItem.description.width / 1000 + "/m" },
      { name: "Accessory", key: "accessory", qty: 1 }
    ];
    let componentArray = [];
    componentDefinition.forEach(({ name, key, qty }) => {
      let tempValue = this.calculateExtra(key, quoteItem, discountObject).total;
      if (tempValue !== 0) {
        componentArray.push({ name, cost: tempValue, qty });
      }
    });
    return componentArray;
  }

  calculateCurtainItemTotal(quoteItem, discount = 0, discountObject) {
    if (isNaN(discount)) {
      return this.calculateCurtainItemTotal(quoteItem, 0, discountObject);
    }
    let totalforItem = 0;
    totalforItem += this.props.isRetail
      ? quoteItem.costInfo.markedUpBasePrice
      : quoteItem.costInfo.basePrice;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach(componentDiscount => {
        if (componentDiscount.id === quoteItem.description.allDetails.fabricId) {
          totalforItem =
            (totalforItem / 100) *
            (100 -
              (this.props.isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }
    totalforItem += this.calculateExtra("bumph", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("driveMechanism", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("leadWeight", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("lining", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("pelmet", quoteItem, discountObject).total;
    totalforItem += this.calculateExtra("accessory", quoteItem, discountObject).total;
    totalforItem += this.calculateService(quoteItem, discountObject).total;
    totalforItem += this.props.isRetail
      ? this.getTrackDiscountValue(
          quoteItem.costInfo.markedUpTrackTotalCost,
          discountObject,
          quoteItem
        )
      : this.getTrackDiscountValue(quoteItem.costInfo.trackTotalCost, discountObject, quoteItem);
    totalforItem = this.props.isRetail ? (totalforItem / 100) * (100 - discount) : totalforItem;
    return totalforItem;
  }

  getTrackDiscountValue(trackCost, discountObject, quoteItem) {
    let totalforTrack = trackCost;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach(componentDiscount => {
        if (componentDiscount.id === quoteItem.description.allDetails.trackId) {
          totalforTrack =
            (totalforTrack / 100) *
            (100 -
              (this.props.isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }
    return totalforTrack;
  }

  componentsAreSame(Component1, Component2) {
    if (
      Component1.description.allDetails.productCode ===
        Component2.description.allDetails.productCode &&
      Component1.extras.track.id === Component2.extras.track.id &&
      Component1.description.fabric === Component2.description.fabric &&
      Component1.description.headerStyle === Component2.description.headerStyle &&
      Component1.extras.driveMechanism.id === Component2.extras.driveMechanism.id &&
      Component1.description.allDetails.location === Component2.description.allDetails.location &&
      this.calculateCurtainItemTotal(Component1) === this.calculateCurtainItemTotal(Component2)
    ) {
      return true;
    } else {
      return false;
    }
  }

  calculateExtra(extraKey, item, discountObject) {
    let totalPrice = 0;
    let totalQty = 0;
    if (item.extras[extraKey]) {
      if (item.extras[extraKey].details.pricePerMetre) {
        const totalPricePerMetre =
          parseFloat(item.extras[extraKey].details.pricePerMetre) *
          (parseFloat(item.description.width) / 1000);
        const totalPricePerMetreMarkup =
          (totalPricePerMetre / 100) * (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail ? totalPricePerMetreMarkup : totalPricePerMetre;
        totalQty++;
      } else if (item.extras[extraKey].details.cost) {
        const totalCost =
          (parseFloat(item.extras[extraKey].details.cost) / 100) *
          (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail
          ? totalCost
          : parseFloat(item.extras[extraKey].details.cost);
        totalQty++;
      }
      if (discountObject && discountObject.components.length > 0) {
        discountObject.components.forEach(componentDiscount => {
          if (item.extras[extraKey].id === componentDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (this.props.isRetail
                  ? componentDiscount.retail.percentage
                  : componentDiscount.wholesale.percentage));
          }
        });
      }
    }

    return { total: totalPrice, qty: totalQty };
  }

  calculateService(item, discountObject) {
    let totalPrice = 0;
    let totalQty = 0;
    item.extras.services.forEach(service => {
      const totalWithMarkup =
        (parseFloat(service.details.cost) / 100) * (100 + parseFloat(service.details.markup));
      totalPrice += this.props.isRetail ? totalWithMarkup : parseFloat(service.details.cost);
      totalQty++;
      if (discountObject && discountObject.servicesAndFees.length > 0) {
        discountObject.servicesAndFees.forEach(serviceDiscount => {
          if (service.id === serviceDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (this.props.isRetail
                  ? serviceDiscount.retail.percentage
                  : serviceDiscount.wholesale.percentage));
          }
        });
      }
    });
    return { total: totalPrice, qty: totalQty };
  }

  getGroupingTitleRow(groupingKey) {
    if (groupingKey === "job") {
      return (
        <Fragment>
          <td>Item Number</td>
          <td>Qty</td>
          <td>Location</td>
          <td>Description</td>
          <td>Details of Item</td>
          <td>Unit Qty</td>
          <td>Cost of Unit</td>
          <td>Total Unit Qty</td>
          <td>Total Cost of Unit</td>
          <td>Total Cost of Item</td>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <td>{this.translateHeading(groupingKey)}</td>
          <td>Item Number</td>
          <td>Qty</td>
          <td>Location</td>
          <td>Description</td>
          <td>Details of Item</td>
          <td>Unit Qty</td>
          <td>Cost of Unit</td>
          <td>Total Unit Qty</td>
          <td>Total Cost of Unit</td>
          <td>Total Cost of Item</td>
        </Fragment>
      );
    }
  }

  translateHeading(groupingKey) {
    if (groupingKey === "location") return "Item Location";
    if (groupingKey === "driveType") return "Drive Mechanism Type";
    if (groupingKey === "style") return "Header Style";
    if (groupingKey === "fabric") return "Fabric Name";
    if (groupingKey === "driveMechanism") return "Drive Mechanism Name";
    if (groupingKey === "fabricAndColour") return "Fabric and Colour Name";
    if (groupingKey === "productCode") return "Item Product Code";
  }

  render() {
    return (
      <div className="doc-box">
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="heading">
              <td colSpan="11">
                <span className="heading-one">Q U O T E - S U M M A R Y</span>
              </td>
            </tr>
            <tr className="sub-heading">{this.getGroupingTitleRow(this.props.grouping)}</tr>
            {this.constructFromResponse(this.props.response)}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CurtainsSummary;
