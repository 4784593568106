import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import "./Account.css";

import { Switch, Route } from "react-router-dom";
import { MDBBtn } from "mdbreact";
import ChangePassword from "./ChangePassword";
import PopUpForm from "../Generic/PopUpDialog/PopUpForm";
import { populateAccountDetails } from "../../actions/accountActions";
import { setLoaderState } from "../../actions/loaderActions";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import Alert from "../ErrorHandler/Alert";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = { error: "", failed: false };
    // Example get
    this.props.dispatch(setLoaderState(true));
    API.get("telishadeAPI", "/account")
      .then(response => {
        const { firstName, lastName, email, phoneNumber } = response.details;
        this.props.dispatch(
          populateAccountDetails({
            firstName,
            lastName,
            email,
            phoneNumber
          })
        );
        this.props.dispatch(setLoaderState(false));
      })
      .catch(error => {
        this.props.dispatch(setLoaderState(false));
        this.setState({ failed: true, error: error });
      });
  }

  UpdateDetails = response => {
    const { firstName, lastName, email, phoneNumber } = response.details;
    this.props.dispatch(populateAccountDetails({ firstName, lastName, email, phoneNumber }));
  };

  HandleChangePasswordClick = () => {
    this.props.history.push("/account/change-password");
  };
  HandleEditDetailsClick = () => {
    this.props.history.push("/account/edit");
  };

  editFields = [
    {
      id: "firstName",
      label: "First Name",
      type: "text",
      tooltip: "Please Enter First Name",
      required: false,
      value: ""
    },
    {
      id: "lastName",
      label: "Last Name",
      type: "text",
      tooltip: "Please Enter Last Name",
      required: false,
      value: ""
    },
    {
      id: "email",
      label: "Email",
      type: "email",
      tooltip: "Please Enter Valid Email",
      required: true,
      value: ""
    },
    {
      id: "phoneNumber",
      label: "Phone Number",
      type: "tel",
      tooltip: "Please Enter Valid Phone Number",
      required: false,
      pattern:
        "(^1300\\d{6}$)|(^1800|1900|1902\\d{6}$)|(^0[2|3|7|8]{1}[0-9]{8}$)|(^13\\d{4}$)|(^04\\d{2,3}\\d{6}$)",
      value: ""
    }
  ];

  handlePost = async formData => {
    try {
      this.props.dispatch(setLoaderState(true));
      return await API.put("telishadeAPI", "/account", {
        body: { details: formData }
      });
    } catch (error) {
      this.props.dispatch(setLoaderState(false));
      this.setState({ failed: true, error: error });
    } finally {
      this.props.dispatch(setLoaderState(false));
      this.props.history.push("/account");
    }
  };

  get fields() {
    const fields = [...this.editFields];
    for (let i = 0; i < fields.length; i++) {
      fields[i].value = this.props.accountDetails[fields[i].id];
    }
    return fields;
  }

  render() {
    return (
      <Fragment>
        {this.state.failed ? (
          <ErrorHandler
            error={this.state.error}
            buttonClickHandler={() => {
              this.setState({ failed: false });
            }}
            button2ClickHandler={async () => {
              this.setState({ failed: false });
              try {
                this.props.dispatch(setLoaderState(true));
                const { firstName, lastName, email, phoneNumber } = await API.get(
                  "telishadeAPI",
                  "/account"
                );
                this.props.dispatch(
                  populateAccountDetails({
                    firstName,
                    lastName,
                    email,
                    phoneNumber
                  })
                );
                this.props.dispatch(setLoaderState(false));
              } catch (err) {
                this.props.dispatch(setLoaderState(false));
                this.setState({ failed: true, error: err });
              }
            }}
          />
        ) : (
          ""
        )}
        <Switch>
          <Route path="/account/change-password" component={ChangePassword} />
          <Route path="/account">
            <div className="myaccount-container">
              <div className="myaccount-card card">
                <h2>My Account</h2>
                {this.state.error ? (
                  <Alert />
                ) : (
                  <Fragment>
                    <div className="myaccount-details">
                      <h5>
                        Full Name: {this.props.accountDetails.firstName}{" "}
                        {this.props.accountDetails.lastName}
                      </h5>
                      <h5>Email Address: {this.props.accountDetails.email}</h5>
                      <h5>Phone Number: {this.props.accountDetails.phoneNumber}</h5>
                    </div>
                    <MDBBtn onClick={this.HandleEditDetailsClick} color="blue" className="editBtn">
                      Edit details
                    </MDBBtn>
                    <MDBBtn
                      onClick={this.HandleChangePasswordClick}
                      color="blue"
                      className="editBtn"
                    >
                      Change Password
                    </MDBBtn>
                  </Fragment>
                )}
              </div>
            </div>
            <Switch>
              <Route path="/account/edit">
                <PopUpForm
                  header="Edit Account Details"
                  button="Accept"
                  buttoncolour="green"
                  button2="Cancel"
                  button2colour="red"
                  fields={this.fields}
                  apiMethod={this.handlePost}
                  updateMethod={this.UpdateDetails}
                />
              </Route>
            </Switch>
          </Route>
        </Switch>
      </Fragment>
    );
  }
}

const mapStatetoProps = state => {
  return state.account;
};

export default connect(mapStatetoProps)(Account);
