import React, { Component } from "react";
import { connect } from "react-redux";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { contactFields } from "../fields";
import { API } from "aws-amplify";
import { setRefresh } from "../../../actions/algoliaActions";
import { setLoaderState, setAlgoliaLoaderState } from "../../../actions/loaderActions";
import { updateContactDetails, selectContact } from "../../../actions/customersActions";

class AddContact extends Component {
  customerId = this.props.match.params.customerId;
  branchId = this.props.match.params.branchId;

  async APIMethod(formData) {
    this.props.dispatch(setAlgoliaLoaderState(true));
    try {
      this.props.dispatch(setLoaderState(true));

      const response = await API.post(
        "telishadeAPI",
        `/customer/${this.customerId}/branch/${this.branchId}/contact`,
        {
          body: {
            details: {
              firstName: formData.firstName,
              lastName: formData.lastName,
              title: formData.title,
              email: formData.email,
              phone: formData.phone,
              fax: formData.fax,
              mobile: formData.mobile
            }
          }
        }
      );
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setLoaderState(false));
      await this.props.dispatch(updateContactDetails(response.details));
      await this.props.dispatch(selectContact(response.id));
      this.props.history.goBack();
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }

    // Refresh any algolia components currently mounted 1 second after updating
  }

  render() {
    const fields = [...contactFields];

    return (
      <PopUpForm
        fields={fields}
        header="Add New Contact"
        button="ACCEPT"
        buttoncolour="green"
        button2="CANCEL"
        button2colour="red"
        buttonClickHandler={undefined}
        button2ClickHandler={undefined}
        apiMethod={details => this.APIMethod(details)}
      />
    );
  }
}
const mapStateToProps = state => {
  return state.customers.contact;
};

export default connect(mapStateToProps)(AddContact);
