import React, { Component } from "react";
import { connect } from "react-redux";
import ReactDataGrid from "react-data-grid";
import Helper from "react-data-grid-multiline-header";
import { NumberEditor, CustomDropDown } from "./AdditonalEditors";
import AutoCompleteDropDown from "./AlgoliaEditors/AlgoliaAutoCompleteDropDown";
import AlgoliaFormatter from "./Formatters/AlgoliaFormatter";
import DriveMechanismDropDown from "./AlgoliaEditors/DriveMechanismDropDown";
import DependantDropDown from "./AlgoliaEditors/DependantDropDown";
import config from "../../../config";

import { populateWorksheet, selectIndex } from "../../../actions/worksheetActions";
import AlgoliaColourListDropDown from "./AlgoliaEditors/AlgoliaColourListDropDown";
import OptionsFormatter from "./Formatters/OptionsFormatter";

class BlindsWorksheet extends Component {
  //Alogolia Editors
  CurtainStyleEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:style AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  ComponentFormatter = (
    <AlgoliaFormatter
      index={`${config.algolia.prefix}products_components_name_asc`}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
      cacheId={"components"}
    />
  );

  DriveMechanismEditor = (
    <DriveMechanismDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filter={" AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  TrackDependantEditor = (
    <DependantDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={" AND type:track AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
      dependantTitle="details.associatedStyles"
      dependantRow="styleId"
    />
  );

  RunnerDependantEditor = (
    <DependantDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={" AND type:runner AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
      dependantTitle="details.associatedTracks"
      dependantRow="trackId"
    />
  );

  TapeDependantEditor = (
    <DependantDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={" AND type:tape AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
      dependantTitle="details.associatedRunners"
      dependantRow="runnerId"
    />
  );

  PelmetEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:pelmet AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  LeadWeightEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:lead-weight AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  BumphEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:bumph AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  LiningEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:lining AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  FabricEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:fabric AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  AccessoryEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_components_name_asc`}
      filters={"isDeleted:false AND type:accessory AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  FabricSurchargeEditor = (
    <AutoCompleteDropDown
      index={`${config.algolia.prefix}products_fabric_surcharges_name_asc`}
      filters={"isDeleted:false AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  FabricSurchargeFormatter = (
    <AlgoliaFormatter
      index={`${config.algolia.prefix}products_fabric_surcharges_name_asc`}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
      cacheId={"components"}
    />
  );

  FabricColourListEditor = (
    <DependantDropDown
      index={`${config.algolia.prefix}products_colour_list_items_name_asc`}
      filters={""}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
      dependantTitle="colourListId"
      dependantRow="fabricColourListId"
    />
  );

  PelmetColourListEditor = (
    <AlgoliaColourListDropDown
      index={`${config.algolia.prefix}products_colour_lists_name_asc`}
      filters={"isDeleted:false AND componentType:pelmet AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  TrackColourListEditor = (
    <AlgoliaColourListDropDown
      index={`${config.algolia.prefix}products_colour_lists_name_asc`}
      filters={"isDeleted:false AND componentType:track AND productId:" + this.props.productID}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
    />
  );

  ColourListFormatter = (
    <AlgoliaFormatter
      index={`${config.algolia.prefix}products_colour_list_items_name_asc`}
      API={{ id: `${config.algolia.appId}`, key: this.props.AlgoliaKey.secondKey }}
      cacheId={"colours"}
    />
  );

  //Custom DropDowns
  DriveTypeEditor = (<CustomDropDown options={[{ value: "manual" }, { value: "motorised" }]} />);

  ControlSideEditor = (
    <CustomDropDown options={[{ value: "Right Hand" }, { value: "Left Hand" }, { value: "Dual Motors" }]} />
  );

  OpeningEditor = (
    <CustomDropDown options={[{ value: "Center" }, { value: "Left" }, { value: "Right" }, { value: "None" }]} />
  );

  TrackTypeEditor = (
    <CustomDropDown
      options={[{ value: "Manual Cord Track" }, { value: "Hand Drawn Track" }, { value: "Motorised Track" }]}
    />
  );

  CordTypeEditor = (
    <CustomDropDown
      options={[{ value: "Clear Stick" }, { value: "Metal Stick" }, { value: "White Cord" }, { value: "Black Cord" }]}
    />
  );

  PelmetTypeEditor = (<CustomDropDown options={[{ value: "Fabric" }, { value: "Metal" }, { value: "Timber" }]} />);

  ColourEditor = (
    <CustomDropDown
      options={[
        { value: "Pearl White" },
        { value: "Snow" },
        { value: "Coconut" },
        { value: "Birch White" },
        { value: "Brushed Anodised" },
        { value: "Armour" },
        { value: "River Gum" },
        { value: "Almond" },
        { value: "Latte" },
        { value: "Jute" },
        { value: "Swede" },
        { value: "Slate" },
        { value: "Esspresso" },
        { value: "Black" },
        { value: "Other/Special" },
      ]}
    />
  );

  FixTypeEditor = (<CustomDropDown options={[{ value: "Recess" }, { value: "Face Fix" }, { value: "Top Fix" }]} />);

  CurtainColumns = [
    { key: "options", name: "", formatter: <OptionsFormatter />, width: 80, resizable: true },
    { key: "itemNo", name: "ItemNo" },
    { key: "productCode", name: "Product Code", editable: true },
    { key: "location", name: "Location", editable: true },
    {
      key: "styleId",
      name: "Header Style",
      editor: this.CurtainStyleEditor,
      formatter: this.ComponentFormatter,
    },
    //{ key: "fullness", name: "Fullness", editor: NumberEditor },
    {
      key: "trackId",
      name: "Track System",
      editor: this.TrackDependantEditor,
      formatter: this.ComponentFormatter,
    },
    {
      key: "runnerId",
      name: "Track Runner",
      editor: this.RunnerDependantEditor,
      formatter: this.ComponentFormatter,
    },
    {
      key: "tapeId",
      name: "Tape",
      editor: this.TapeDependantEditor,
      formatter: this.ComponentFormatter,
    },
    { key: "width", name: "Width (mm)", editor: <NumberEditor noEmpty={true} /> },
    { key: "drop", name: "Drop (mm)", editor: <NumberEditor noEmpty={true} /> },
    { key: "widthAllowance", name: "Width Allowance", editor: <NumberEditor noEmpty /> },
    { key: "aboveFloorAllowance", name: "Above Floor Allowance", editor: <NumberEditor noEmpty /> },
    { key: "ceilingAllowance", name: "Ceiling Allowance", editor: <NumberEditor noEmpty /> },
    { key: "hemAllowance", name: "Hem Allowance", editor: <NumberEditor noEmpty /> },
    { key: "driveType", name: "Drive Type", editor: this.DriveTypeEditor },
    {
      key: "driveMechanismId",
      name: "Drive Mechanism",
      editor: this.DriveMechanismEditor,
      formatter: this.ComponentFormatter,
    },
    { key: "controlSide", name: "Control Side", editor: this.ControlSideEditor },
    { key: "opening", name: "Opening", editor: this.OpeningEditor },
    { key: "trackType", name: "Track Type", editor: this.TrackTypeEditor },

    {
      key: "trackColour",
      name: "Track Colour",
      editor: this.TrackColourListEditor,
      formatter: this.ColourListFormatter,
    },
    { key: "cordStickLength", name: "Cord/Stick Length (mm)", editor: NumberEditor },
    { key: "cordStickType", name: "Cord/Stick Type", editor: this.CordTypeEditor },
    {
      key: "pelmetId",
      name: "Pelmet",
      editor: this.PelmetEditor,
      formatter: this.ComponentFormatter,
    },
    { key: "pelmetType", name: "Pelmet Type", editor: this.PelmetTypeEditor },
    {
      key: "pelmetHeadboxColour",
      name: "Pelmet Headbox Colour",
      editor: this.PelmetColourListEditor,
      formatter: this.ColourListFormatter,
    },
    {
      key: "leadWeightId",
      name: "Lead Weight",
      editor: this.LeadWeightEditor,
      formatter: this.ComponentFormatter,
    },
    { key: "fit", name: "Fit", editor: this.FixTypeEditor },
    { key: "bumphId", name: "Bumph", editor: this.BumphEditor, formatter: this.ComponentFormatter },
    {
      key: "liningId",
      name: "Lining",
      editor: this.LiningEditor,
      formatter: this.ComponentFormatter,
    },
    {
      key: "fabricId",
      name: "Fabric",
      editor: this.FabricEditor,
      formatter: this.ComponentFormatter,
    },
    {
      key: "fabricColour",
      name: "Fabric Colour",
      editor: this.FabricColourListEditor,
      formatter: this.ColourListFormatter,
    },
    {
      key: "accessoryId",
      name: "Accessory Type",
      editor: this.AccessoryEditor,
      formatter: this.ComponentFormatter,
    },
    {
      key: "fabricSurchargeId",
      name: "Fabric Surcharge",
      editor: this.FabricSurchargeEditor,
      formatter: this.FabricSurchargeFormatter,
    },
  ];
  componentDidMount() {}

  onGridRowsUpdated = ({ fromRow, toRow, updated, action }) => {
    if (action === "COLUMN_FILL") {
    } else {
      const temp = this.props.worksheet.items;
      for (let i = fromRow; i <= toRow; i++) {
        temp[i] = { ...temp[i], ...updated };
        this.props.handleChanges(i, i);
      }
      for (const i of this.props.worksheet.selectedIndexs) {
        temp[i] = { ...temp[i], ...updated };
        this.props.handleChanges(i, i);
      }
      this.props.dispatch(populateWorksheet(temp));
      this.props.handleChanges(fromRow, toRow);
    }
  };

  onRowsSelected = (rows) => {
    const temp = this.props.worksheet.selectedIndexs.concat(rows.map((r) => r.rowIdx));
    this.props.dispatch(selectIndex(temp));
  };

  onRowsDeselected = (rows) => {
    let rowIndexs = rows.map((r) => r.rowIdx);
    const temp = this.props.worksheet.selectedIndexs.filter((i) => rowIndexs.indexOf(i) === -1);
    this.props.dispatch(selectIndex(temp));
  };

  //There are some functionality quirks with this that need to be worked out, but for now this is an ok fix
  onCellSelected = (cell) => {
    const temp = cell.rowIdx;
    //const tempArray = this.props.worksheet.selectedIndexs;
    if (!this.props.worksheet.selectedIndexs.includes(temp))
      this.props.dispatch(selectIndex([...this.props.worksheet.selectedIndexs, temp]));
    else if (this.props.worksheet.selectedIndexs.includes(temp)) {
      // tempArray.splice(this.props.worksheet.selectedIndexs.indexOf(temp), 1);
      // this.props.dispatch(selectIndex(tempArray));
    }
  };

  onCellDeselected = (cell) => {
    let temp = cell.rowIdx;
    if (!this.props.worksheet.selectedIndexs.includes(temp)) this.props.dispatch(selectIndex([]));
  };

  Helper = new Helper(this.CurtainColumns);

  render() {
    return (
      <div className="react-grid-multiline-header">
        {this.props.currentProduct === "curtains" ? (
          <ReactDataGrid
            columns={this.Helper.columns}
            rowGetter={(i) => this.props.worksheet.items[i]}
            rowsCount={this.props.worksheet.items.length}
            //minColumnWidth={100}
            headerRowHeight={this.Helper.headerRowHeight}
            onGridRowsUpdated={this.onGridRowsUpdated}
            enableCellSelect={true}
            onCellSelected={this.onCellSelected}
            onCellDeSelected={this.onCellDeselected}
            rowHeight={50}
            rowSelection={{
              showCheckbox: true,
              enableShiftSelect: true,
              onRowsSelected: this.onRowsSelected,
              onRowsDeselected: this.onRowsDeselected,
              selectBy: {
                indexes: this.props.worksheet.selectedIndexs,
              },
            }}
          />
        ) : (
          "WRONG"
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.worksheet, AlgoliaKey: state.key };
};

export default connect(mapStateToProps)(BlindsWorksheet);
