import { POPULATE_ACCOUNT_DETAILS } from "../actions/accountActions";

//Inital State
const initialState = {
  accountDetails: undefined
};

//Account Reducers
export default function accountReducer(state = initialState, action) {
  switch (action.type) {
    case POPULATE_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: action.details
      };

    default:
      return state;
  }
}
