import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";

import { toggleSideNav } from "../../actions/sideNavActions";

class SubSideNav extends Component {
  toggleNav = () => {
    this.props.dispatch(toggleSideNav());
  };
  render() {
    return (
      <div
        className={
          this.props.isMenuOpen && this.props.isSubMenuOpen
            ? "subSideNav Open"
            : "subSideNav Closed"
        }
      >
        <nav>
          <ul>
            {this.props.links.map((link, key) => {
              return (
                <li key={key}>
                  <NavLink
                    activeClassName="sideNavSelected"
                    className="sideNavItem"
                    to={link.URL}
                    onClick={this.toggleNav}
                  >
                    {link.text}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    );
  }
}

export default connect()(SubSideNav);
