import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";
import { API } from "aws-amplify";
import config from "../../config";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
//MDB
import { MDBBtn } from "mdbreact";
import SearchableList from "../Generic/Algolia/SearchableList";
import { updateProductDetails } from "../../actions/productsActions";
import PopUpForm from "../Generic/PopUpDialog/PopUpForm";
import { setRefresh } from "../../actions/algoliaActions";
import { setLoaderState } from "../../actions/loaderActions";

const ColourListColourBuilder = [
  {
    id: "name",
    label: "Colour Name",
    type: "text",
    required: true
  }
];

const detailsDisplayDefinition = [
  "serviceFeeType",
  "minCost",
  "cost",
  "basePrice",
  "costPerSquareMetre",
  "costPerMetre",
  "pricePerMetre",
  "makeupCost",
  "manualFabrication",
  "markup",
  "fabricWidth",
  "height",
  "width",
  "drop",
  "maxLength",
  "power",
  "lift",
  "speed",
  "maxAngle",
  "rollWidth",
  "vPatternRepeat",
  "hPatternRepeat",
  "endAllowance",
  "hookWidth",
  "extraEnd",
  "manufacturerId",
  "description",
  "technicalInfo",
  "adjustWidthFullness",
  "adjustWidthSymbol",
  "adjustWidth",
  "adjustWidthAndOr",
  "adjustWidthDropSymbol",
  "adjustDrop"
];

class ProductDetails extends Component {
  constructor(props) {
    super(props);

    this.state = { hasDetails: false, currentColourID: undefined };
  }
  handleEditClick = () => {
    const currentURL = this.props.history.location.pathname;
    this.props.history.push(currentURL + "/edit");
  };

  componentDidMount = () => {
    Object.keys(this.props.productDetails.details).map(i => {
      if (
        i !== "createdAt" &&
        i !== "name" &&
        i !== "id" &&
        i !== "photo" &&
        i !== "associatedServices" &&
        i !== "fabricSurcharges" &&
        i !== "priceLists"
      ) {
        this.setState({ hasDetails: true });
      }
      return "";
    });
  };

  componentWillUnmount() {
    this.props.dispatch(updateProductDetails({}));
  }

  ManufacturerHitComponent = ({ hit }) => {
    return (
      <p
        className="manufacturer"
        onClick={() => this.props.history.push("/manufacturers/" + hit.objectID)}
      >
        {hit.details.name}
      </p>
    );
  };

  HitComponent = ({ hit }) => {
    return (
      <p>
        <strong>{hit.details.name}</strong>
      </p>
    );
  };

  getPriceListFilterString() {
    let filterString = "";
    if (
      this.props.productDetails.details.priceLists !== "" &&
      this.props.productDetails.details.priceLists !== undefined
    ) {
      filterString = " OR objectID:" + this.props.productDetails.details.priceLists[0];
      this.props.productDetails.details.priceLists.map(i => {
        if (i !== this.props.productDetails.details.priceLists[0])
          filterString = filterString + " OR objectID:" + i;
        return "";
      });
    }
    return filterString;
  }

  getAssociatedServiceString() {
    let filterString = "";
    if (
      this.props.productDetails.details.associatedServices !== "" &&
      this.props.productDetails.details.associatedServices !== undefined
    ) {
      filterString = " OR objectID:" + this.props.productDetails.details.associatedServices[0];
      this.props.productDetails.details.associatedServices.map(i => {
        if (i !== this.props.productDetails.details.associatedServices[0])
          filterString = filterString + " OR objectID:" + i;
        return "";
      });
    }
    return filterString;
  }

  getFabricSurchargeString() {
    let filterString = "";
    if (
      this.props.productDetails.details.fabricSurcharges !== "" &&
      this.props.productDetails.details.fabricSurcharges !== undefined
    ) {
      filterString = " OR objectID:" + this.props.productDetails.details.fabricSurcharges[0];
      this.props.productDetails.details.fabricSurcharges.map(i => {
        if (i !== this.props.productDetails.details.fabricSurcharges[0])
          filterString = filterString + " OR objectID:" + i;
        return "";
      });
    }
    return filterString;
  }

  getDetailsFilterString(detailsKey) {
    let filterString = "";
    if (
      this.props.productDetails.details[detailsKey] !== "" &&
      this.props.productDetails.details[detailsKey] !== undefined
    ) {
      filterString = " OR objectID:" + this.props.productDetails.details[detailsKey][0];
      this.props.productDetails.details[detailsKey].map(i => {
        if (i !== this.props.productDetails.details[detailsKey][0])
          filterString = filterString + " OR objectID:" + i;
        return "";
      });
    }
    return filterString;
  }

  handleCancelClick = () => {
    this.props.history.goBack();
  };

  handleDeleteClick = () => {
    const currentURL = this.props.history.location.pathname;
    this.props.history.push(currentURL + "/delete");
  };

  //Colour List Functions

  addColourtoList = async formData => {
    try {
      this.props.dispatch(setLoaderState(true));
      const body = {
        details: {
          name: formData.name
        }
      };
      if (this.props.match.params.product === "blinds") {
        await API.post(
          "telishadeAPI",
          `/product/blind/colour-list/${this.props.productDetails.colourListId}/item`,
          { body }
        );
      } else if (this.props.match.params.product === "curtains") {
        await API.post(
          "telishadeAPI",
          `/product/curtain/colour-list/${this.props.productDetails.colourListId}/item`,
          { body }
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.history.replace(
        `/products/${this.props.match.params.product}/${this.props.match.params.component}/${this.props.match.params.id}`
      );
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setRefresh(false));
      this.props.dispatch(setLoaderState(false));
    }
  };

  editColourinList = async formData => {
    try {
      this.props.dispatch(setLoaderState(true));
      const body = {
        details: {
          name: formData.name
        }
      };
      if (this.props.match.params.product === "blinds") {
        await API.put(
          "telishadeAPI",
          `/product/blind/colour-list/${this.props.productDetails.colourListId}/item/${this.state.currentColourID}`,
          { body }
        );
      } else if (this.props.match.params.product === "curtains") {
        await API.put(
          "telishadeAPI",
          `/product/curtain/colour-list/${this.props.productDetails.colourListId}/item/${this.state.currentColourID}`,
          { body }
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.history.replace(
        `/products/${this.props.match.params.product}/${this.props.match.params.component}/${this.props.match.params.id}`
      );
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setRefresh(false));
      this.props.dispatch(setLoaderState(false));
    }
  };

  deleteColourinList = async id => {
    try {
      this.props.dispatch(setLoaderState(true));
      if (this.props.match.params.product === "blinds") {
        await API.del(
          "telishadeAPI",
          `/product/blind/colour-list/${this.props.productDetails.colourListId}/item/${id}`
        );
      } else if (this.props.match.params.product === "curtains") {
        await API.del(
          "telishadeAPI",
          `/product/curtain/colour-list/${this.props.productDetails.colourListId}/item/${id}`
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.history.replace(
        `/products/${this.props.match.params.product}/${this.props.match.params.component}/${this.props.match.params.id}`
      );
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setRefresh(false));
      this.props.dispatch(setLoaderState(false));
    }
  };

  handleEditColourClick = (id, value) => {
    this.setState({ currentColourID: id });
    ColourListColourBuilder[0].value = value;
    this.props.history.push(
      `/products/${this.props.match.params.product}/${this.props.match.params.component}/${this.props.match.params.id}/colour/edit`
    );
  };

  handleDeleteColourClick = id => {
    this.setState({ currentColourID: id });
    this.deleteColourinList(id);
  };

  ColourHitComponent = ({ hit }) => {
    const handleEditButton = () => {
      this.handleEditColourClick(hit.objectID, hit.details.name);
    };

    const handleDeleteButton = () => {
      this.handleDeleteColourClick(hit.objectID);
    };

    return (
      <div className="coloursListElement">
        <div className="colourSquareElement" />
        {hit.details.name}
        <div className="colourListButtons">
          <MDBBtn onClick={handleEditButton} color="blue" id="ColoursListEdit">
            Edit
          </MDBBtn>
          <MDBBtn onClick={handleDeleteButton} color="red" id="ColoursListRemove">
            Remove
          </MDBBtn>
        </div>
      </div>
    );
  };

  ColourListComponent = (
    <Fragment>
      <Switch>
        <Route path="/products/:product/:component/:id/colour/add">
          <PopUpForm
            fields={ColourListColourBuilder}
            header="Add Colour"
            apiMethod={this.addColourtoList}
            button="Add"
            button2="Cancel"
            buttoncolour="green"
            button2colour="red"
          />
        </Route>
        <Route path="/products/:product/:component/:id/colour/edit">
          <PopUpForm
            fields={ColourListColourBuilder}
            header="Edit Colour"
            apiMethod={this.editColourinList}
            button="Edit"
            button2="Cancel"
            buttoncolour="green"
            button2colour="red"
          />
        </Route>
      </Switch>
      <div className="detailsContainer">
        <div className="detailsLeft">
          <div className="coloursList card">
            <h3>Colours</h3>
            <MDBBtn
              onClick={() => {
                ColourListColourBuilder.value = "";
                this.props.history.push(
                  `/products/${this.props.match.params.product}/${this.props.match.params.component}/${this.props.match.params.id}/colour/add`
                );
              }}
              color="green"
              id="ColoursListAdd"
            >
              Add
            </MDBBtn>
            <SearchableList
              appId={`${config.algolia.appId}`}
              index={`${config.algolia.prefix}products_colour_list_items_name_asc`}
              hitComponent={this.ColourHitComponent}
              filters={`isDeleted:false AND colourListId:${this.props.productDetails.colourListId}`}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );

  //End Colour List Functions

  render() {
    const SelectedPriceLists = this.getPriceListFilterString();
    const SelectedAssociatedServices = this.getAssociatedServiceString();
    const SelectedFabricSurcharges = this.getFabricSurchargeString();
    const SelectedAssociatedStyles = this.getDetailsFilterString("associatedStyles");
    const SelectedAssociatedTracks = this.getDetailsFilterString("associatedTracks");
    const SelectedAssociatedRunners = this.getDetailsFilterString("associatedRunners");
    return (
      <Fragment>
        <div className="productDetailHeader">
          <FontAwesomeIcon
            id="backArrow"
            icon={faArrowLeft}
            onClick={() => {
              if (
                this.props.match.params.component === "drive-mechanism-manual" ||
                this.props.match.params.component === "drive-mechanism-motorised" ||
                this.props.match.params.component === "drive-mechanism-link-system"
              ) {
                this.props.history.push(
                  `/products/${this.props.match.params.product}/drive-mechanism`
                );
              } else {
                this.props.history.push(
                  `/products/${this.props.match.params.product}/${this.props.match.params.component}`
                );
              }
            }}
          />
          <h2>{this.props.name}</h2>
          <MDBBtn onClick={this.handleDeleteClick} color="red" id="jobBtn">
            Delete
          </MDBBtn>
          <MDBBtn onClick={this.handleEditClick} color="blue" id="jobBtn2">
            Edit
          </MDBBtn>
        </div>
        <div className="detailsContainer">
          <div className="detailsLeft">
            <div className="card detailsName">
              {this.props.productDetails.details === undefined ? (
                ""
              ) : (
                <h2>Name: {this.props.productDetails.details.name}</h2>
              )}
            </div>
            {this.props.productDetails.details === undefined ? (
              ""
            ) : this.props.productDetails.details.photo === "" ||
              this.props.productDetails.details.photo === undefined ? (
              ""
            ) : (
              <img className="detailsPhoto" src={this.props.productDetails.details.photo} alt="" />
            )}
            {this.props.productDetails.details !== undefined ? (
              this.props.productDetails.details.name !== undefined ? (
                <div
                  className="card detailsPricing"
                  style={this.state.hasDetails ? {} : { display: "none" }}
                >
                  Details:
                  {detailsDisplayDefinition.map(i => {
                    if (
                      this.props.productDetails.details[i] !== undefined &&
                      this.props.productDetails.details[i] !== ""
                    ) {
                      if (i === "manufacturerId") {
                        return (
                          <h5 key={i}>
                            Manufacturer:
                            <SearchableList
                              noLoader
                              appId={`${config.algolia.appId}`}
                              index={`${config.algolia.prefix}manufacturers_name_asc`}
                              hitComponent={this.ManufacturerHitComponent}
                              filters={
                                "isDeleted:false AND objectID:" +
                                this.props.productDetails.details[i]
                              }
                              hideSearchBox={true}
                            />
                          </h5>
                        );
                      } else
                        return (
                          <h5 key={i}>
                            {i}: {this.props.productDetails.details[i]}
                          </h5>
                        );
                    } else return "";
                  })}
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {this.props.productDetails.details.priceLists !== undefined ? (
              <div className="card detailsPricing">
                Associated Pricing Groups
                <SearchableList
                  appId={`${config.algolia.appId}`}
                  index={`${config.algolia.prefix}products_price_lists_name_asc`}
                  hitComponent={this.HitComponent}
                  filters={
                    "isDeleted:false AND ( objectId: idPlaceholder" + SelectedPriceLists + ")" // idPlaceholder is used otherwise Algolia would ignore objectId field
                  }
                  hideSearchBox={true}
                />
              </div>
            ) : (
              ""
            )}
            {this.props.productDetails.details.associatedServices !== undefined ? (
              <div className="card detailsPricing">
                Associated Services
                <SearchableList
                  appId={`${config.algolia.appId}`}
                  index={`${config.algolia.prefix}products_services_fees_name_asc`}
                  hitComponent={this.HitComponent}
                  filters={
                    "isDeleted:false AND ( objectId: idPlaceholder" + // idPlaceholder is used otherwise Algolia would ignore objectId field
                    SelectedAssociatedServices +
                    ")"
                  }
                  hideSearchBox={true}
                />
              </div>
            ) : (
              ""
            )}
            {this.props.productDetails.details.fabricSurcharges !== undefined ? (
              <div className="card detailsPricing">
                Fabric Surcharges
                <SearchableList
                  appId={`${config.algolia.appId}`}
                  index={`${config.algolia.prefix}products_fabric_surcharges_name_asc`}
                  hitComponent={this.HitComponent}
                  filters={
                    "isDeleted:false AND ( objectId: idPlaceholder" + SelectedFabricSurcharges + ")" // idPlaceholder is used otherwise Algolia would ignore objectId field
                  }
                  hideSearchBox={true}
                />
              </div>
            ) : (
              ""
            )}
            {this.props.productDetails.details.associatedStyles !== undefined ? (
              <div className="card detailsPricing">
                Associated Styles
                <SearchableList
                  appId={`${config.algolia.appId}`}
                  index={`${config.algolia.prefix}products_components_name_asc`}
                  hitComponent={this.HitComponent}
                  filters={
                    "isDeleted:false AND type:style AND ( objectId: idPlaceholder" +
                    SelectedAssociatedStyles +
                    ")" // idPlaceholder is used otherwise Algolia would ignore objectId field
                  }
                  hideSearchBox={true}
                />
              </div>
            ) : (
              ""
            )}
            {this.props.productDetails.details.associatedTracks !== undefined ? (
              <div className="card detailsPricing">
                Associated Tracks
                <SearchableList
                  appId={`${config.algolia.appId}`}
                  index={`${config.algolia.prefix}products_components_name_asc`}
                  hitComponent={this.HitComponent}
                  filters={
                    "isDeleted:false AND type:track AND ( objectId: idPlaceholder" +
                    SelectedAssociatedTracks +
                    ")" // idPlaceholder is used otherwise Algolia would ignore objectId field
                  }
                  hideSearchBox={true}
                />
              </div>
            ) : (
              ""
            )}
            {this.props.productDetails.details.associatedRunners !== undefined ? (
              <div className="card detailsPricing">
                Associated Runners
                <SearchableList
                  appId={`${config.algolia.appId}`}
                  index={`${config.algolia.prefix}products_components_name_asc`}
                  hitComponent={this.HitComponent}
                  filters={
                    "isDeleted:false AND type:runner AND ( objectId: idPlaceholder" +
                    SelectedAssociatedRunners +
                    ")" // idPlaceholder is used otherwise Algolia would ignore objectId field
                  }
                  hideSearchBox={true}
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="card detailsDatasheets">DataSheets</div>
        </div>
        {"colourListId" in this.props.productDetails ? this.ColourListComponent : ""}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state.products;
};

export default withRouter(connect(mapStateToProps)(ProductDetails));
