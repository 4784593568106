import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Route } from "react-router-dom";
import { API } from "aws-amplify";
//Iconrs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
//MDB
import { MDBBtn } from "mdbreact";
import { updateProductDetails } from "../../../actions/productsActions";
import { setLoaderState } from "../../../actions/loaderActions";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import SearchableList from "../../Generic/Algolia/SearchableList";
import config from "../../../config";
import { setRefresh } from "../../../actions/algoliaActions";

const ColourListColourBuilder = [
  {
    id: "name",
    label: "Colour Name",
    type: "text",
    required: true
  }
];

const ColourListBuilderBlinds = [
  {
    id: "name",
    label: "Colour List Name",
    type: "text",
    tooltip: "Please Enter Colour List Name",
    required: true,
    value: ""
  },
  {
    id: "componentType",
    label: "Associated Component",
    type: "select",
    options: [
      { name: "Drive Mechanism", value: "driveMechanism" },
      { name: "Rail", value: "rail" },
      { name: "Kassette", value: "kassette" }
    ],
    value: "fabric"
  }
];

const ColourListBuilderCurtains = [
  {
    id: "name",
    label: "Colour List Name",
    type: "text",
    tooltip: "Please Enter Colour List Name",
    required: true,
    value: ""
  },
  {
    id: "componentType",
    label: "Associated Component",
    type: "select",
    options: [
      { name: "Track", value: "track" },
      { name: "Pelmet Headbox", value: "pelmet" }
    ],
    value: "fabric"
  }
];

class ColourList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coloursList: this.props.productDetails.details
        ? this.props.productDetails.details.list
        : [{ details: { name: "Colours" } }, { details: { name: "Colours" } }],
      currentColourID: undefined
    };
    if (this.props.productDetails === undefined || this.props.currentComponent === "style") {
      this.getLinkColourList(this.props.match.params.product);
    }
  }

  editColourList = async formData => {
    try {
      this.props.dispatch(setLoaderState(false));
      const body = {
        componentType: formData.componentType,
        details: {
          name: formData.name
        }
      };
      if (this.props.match.params.product === "blinds") {
        const response = await API.put(
          "telishadeAPI",
          `/product/blind/colour-list/${this.props.match.params.id}`,
          { body }
        );
        this.props.dispatch(updateProductDetails(response));
      } else if (this.props.match.params.product === "curtains") {
        const response = await API.put(
          "telishadeAPI",
          `/product/curtain/colour-list/${this.props.match.params.id}`,
          { body }
        );
        this.props.dispatch(updateProductDetails(response));
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.history.replace(
        `/products/${this.props.match.params.product}/colour-list/${this.props.match.params.id}`
      );
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setRefresh(false));
      this.props.dispatch(setLoaderState(false));
    }
  };

  deleteColourList = async () => {
    try {
      if (this.props.match.params.product === "blinds") {
        await API.del("telishadeAPI", `/product/blind/colour-list/${this.props.match.params.id}`);
      } else if (this.props.match.params.product === "curtains") {
        await API.del("telishadeAPI", `/product/blind/colour-list/${this.props.match.params.id}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.history.push(`/products/blinds/colour-list`);
    }
  };

  async getLinkColourList(productType) {
    try {
      this.props.dispatch(setLoaderState(true));
      if (productType === "blinds") {
        const response = await API.get(
          "telishadeAPI",
          `/product/blind/colour-list/${this.props.match.params.id}`
        );
        this.props.dispatch(updateProductDetails(response));
        this.props.dispatch(setLoaderState(false));
        //this.setState({ coloursList: response.details.list });
      } else if (productType === "curtains") {
        const response = await API.get(
          "telishadeAPI",
          `/product/curtain/colour-list/${this.props.match.params.id}`
        );
        this.props.dispatch(updateProductDetails(response));
        this.props.dispatch(setLoaderState(false));
        //this.setState({ coloursList: response.details.list });
      }
    } catch (e) {
      console.error(e);
    }
  }

  addColourtoList = async formData => {
    try {
      this.props.dispatch(setLoaderState(true));
      const body = {
        details: {
          name: formData.name
        }
      };
      if (this.props.match.params.product === "blinds") {
        await API.post(
          "telishadeAPI",
          `/product/blind/colour-list/${this.props.match.params.id}/item`,
          { body }
        );
      } else if (this.props.match.params.product === "curtains") {
        await API.post(
          "telishadeAPI",
          `/product/curtain/colour-list/${this.props.match.params.id}/item`,
          { body }
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.history.replace(
        `/products/${this.props.match.params.product}/colour-list/${this.props.match.params.id}`
      );
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setRefresh(false));
      this.props.dispatch(setLoaderState(false));
    }
  };

  editColourinList = async formData => {
    try {
      this.props.dispatch(setLoaderState(true));
      const body = {
        details: {
          name: formData.name
        }
      };
      if (this.props.match.params.product === "blinds") {
        await API.put(
          "telishadeAPI",
          `/product/blind/colour-list/${this.props.match.params.id}/item/${this.state.currentColourID}`,
          { body }
        );
      } else if (this.props.match.params.product === "curtains") {
        await API.put(
          "telishadeAPI",
          `/product/curtain/colour-list/${this.props.match.params.id}/item/${this.state.currentColourID}`,
          { body }
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.history.replace(
        `/products/${this.props.match.params.product}/colour-list/${this.props.match.params.id}`
      );
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setRefresh(false));
      this.props.dispatch(setLoaderState(false));
    }
  };

  deleteColourinList = async id => {
    try {
      this.props.dispatch(setLoaderState(true));
      if (this.props.match.params.product === "blinds") {
        await API.del(
          "telishadeAPI",
          `/product/blind/colour-list/${this.props.match.params.id}/item/${id}`
        );
      } else if (this.props.match.params.product === "curtains") {
        await API.del(
          "telishadeAPI",
          `/product/curtain/colour-list/${this.props.match.params.id}/item/${id}`
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.props.history.replace(
        `/products/${this.props.match.params.product}/colour-list/${this.props.match.params.id}`
      );
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setRefresh(false));
      this.props.dispatch(setLoaderState(false));
    }
  };

  handleEditColourClick = (id, value) => {
    this.setState({ currentColourID: id });
    ColourListColourBuilder[0].value = value;
    this.props.history.push(
      `/products/${this.props.match.params.product}/colour-list/${this.props.match.params.id}/colour/edit`
    );
  };

  handleDeleteColourClick = id => {
    this.setState({ currentColourID: id });
    this.deleteColourinList(id);
  };
  handleEditClick = value => {
    if (this.props.match.params.product === "blinds") {
      ColourListBuilderBlinds[0].value = this.props.productDetails.details.name;
      ColourListBuilderBlinds[1].value = this.props.productDetails.componentType;
    } else {
      ColourListBuilderCurtains[0].value = this.props.productDetails.details.name;
      ColourListBuilderCurtains[1].value = this.props.productDetails.componentType;
    }

    this.props.history.push(
      `/products/${this.props.match.params.product}/colour-list/${this.props.match.params.id}/edit`
    );
  };

  handleDeleteClick = () => {
    this.props.history.push(
      `/products/${this.props.match.params.product}/colour-list/${this.props.match.params.id}/delete`
    );
  };

  HitComponent = ({ hit }) => {
    const handleEditButton = () => {
      this.handleEditColourClick(hit.objectID, hit.details.name);
    };

    const handleDeleteButton = () => {
      this.handleDeleteColourClick(hit.objectID);
    };

    return (
      <div className="coloursListElement">
        <div className="colourSquareElement" />
        {hit.details.name}
        <div className="colourListButtons">
          <MDBBtn onClick={handleEditButton} color="blue" id="ColoursListEdit">
            Edit
          </MDBBtn>
          <MDBBtn onClick={handleDeleteButton} color="red" id="ColoursListRemove">
            Remove
          </MDBBtn>
        </div>
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <Switch>
          <Route path="/products/:product/colour-list/:id/edit">
            <PopUpForm
              fields={
                this.props.match.params.product === "blinds"
                  ? ColourListBuilderBlinds
                  : ColourListBuilderCurtains
              }
              apiMethod={this.editColourList}
              header="Edit Colour List"
              button="Accept"
              buttoncolour="green"
              button2="Cancel"
              button2colour="red"
            />
          </Route>
          <Route path="/products/:product/colour-list/:id/delete">
            <PopUpForm
              fields={[]}
              apiMethod={this.deleteColourList}
              header="Delete Colour List"
              button="Accept"
              buttoncolour="red"
              button2="Cancel"
              button2colour="blue"
              body="Are you sure you want to delete?"
            />
          </Route>
          <Route path="/products/:product/colour-list/:id/colour/add">
            <PopUpForm
              fields={ColourListColourBuilder}
              header="Add Colour"
              apiMethod={this.addColourtoList}
              button="Add"
              button2="Cancel"
              buttoncolour="green"
              button2colour="red"
            />
          </Route>
          <Route path="/products/:product/colour-list/:id/colour/edit">
            <PopUpForm
              fields={ColourListColourBuilder}
              header="Edit Colour"
              apiMethod={this.editColourinList}
              button="Edit"
              button2="Cancel"
              buttoncolour="green"
              button2colour="red"
            />
          </Route>
        </Switch>
        <div className="productDetailHeader">
          <FontAwesomeIcon
            id="backArrow"
            icon={faArrowLeft}
            onClick={() => {
              this.props.history.push(`/products/${this.props.match.params.product}/colour-list`);
            }}
          />
          <h2>{this.props.name}</h2>
          <MDBBtn onClick={this.handleDeleteClick} color="red" id="jobBtn">
            Delete
          </MDBBtn>
          <MDBBtn onClick={this.handleEditClick} color="blue" id="jobBtn2">
            Edit
          </MDBBtn>
        </div>
        <div className="detailsContainer">
          <div className="detailsLeft">
            <div className="card detailsName">
              {this.props.productDetails.details === undefined ? (
                ""
              ) : (
                <h2>Name: {this.props.productDetails.details.name}</h2>
              )}
            </div>
            <div className="coloursList card">
              <h3>Colours</h3>
              <MDBBtn
                onClick={() => {
                  ColourListColourBuilder.value = "";
                  this.props.history.push(
                    `/products/${this.props.match.params.product}/colour-list/${this.props.match.params.id}/colour/add`
                  );
                }}
                color="green"
                id="ColoursListAdd"
              >
                Add
              </MDBBtn>
              <SearchableList
                appId={`${config.algolia.appId}`}
                index={`${config.algolia.prefix}products_colour_list_items_name_asc`}
                hitComponent={this.HitComponent}
                filters={`isDeleted:false AND colourListId:${this.props.match.params.id}`}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state.products;
};
export default withRouter(connect(mapStateToProps)(ColourList));
