import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SearchableList from "../Generic/Algolia/SearchableList";
import { selectManufacturer, updateManufacturerDetails } from "../../actions/manufacturersActions";
import config from "../../config";

class ManufacturerList extends Component {
  displayAvailableContactDetails(details) {
    if (details.phone) {
      return (
        <span>
          <strong>ph: </strong>
          {details.phone}
        </span>
      );
    } else if (details.mobile) {
      return (
        <span>
          <strong>mob: </strong>
          {details.mobile}
        </span>
      );
    } else if (details.email) {
      return (
        <span>
          email: <strong />
          {details.email}
        </span>
      );
    } else return "";
  }

  HitComponent = ({ hit }) => {
    const listItemClickHandler = () => {
      this.props.dispatch(updateManufacturerDetails(hit.details));
      this.props.dispatch(selectManufacturer(hit.objectID));
      this.props.history.push(`/manufacturers/${hit.objectID}/branches`);
    };

    let className = "";

    if (this.props.match.params.manufacturerId === hit.objectID) {
      className = { className: "selected" };
      if (this.props.id !== this.props.match.params.manufacturerId) {
        this.props.dispatch(updateManufacturerDetails(hit.details));
        this.props.dispatch(selectManufacturer(hit.objectID));
      }
    }

    return (
      <p {...className} onClick={listItemClickHandler}>
        <strong>{hit.details.name}</strong>
        <br />
        {this.displayAvailableContactDetails(hit.details)}
        <br />
      </p>
    );
  };

  render() {
    return (
      <div className="side-list">
        <SearchableList
          appId={`${config.algolia.appId}`}
          index={`${config.algolia.prefix}manufacturers_name_asc`}
          hitComponent={this.HitComponent}
          selectedObjectId={this.props.id}
          filters="isDeleted:false"
          noResultsComponent={"No Results"}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state.manufacturers.manufacturer;
};

export default withRouter(connect(mapStateToProps)(ManufacturerList));
