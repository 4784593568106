//Action Type
export const SET_ALGOLIA_KEY = "SET_ALGOLIA_KEY";
export const SET_ALGOLIA_SECOND_KEY = "SET_ALGOLIA_SECOND_KEY";
export const REQUEST_KEY = "REQUEST_KEY";

//Action Creators
export function setAlgoliaKey(key, secondKey) {
  return {
    type: SET_ALGOLIA_KEY,
    payload: { key: key, secondKey: secondKey },
  };
}

export function requestKey() {
  return {
    type: REQUEST_KEY,
  };
}
