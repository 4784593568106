import React, { Component } from "react";
import { connect } from "react-redux";
import PopUp from "../../Generic/PopUpDialog/PopUp";
import { API } from "aws-amplify";
import { setRefresh } from "../../../actions/algoliaActions";
import { withRouter } from "react-router";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";

class DeleteStaff extends Component {
  APIMethod = async () => {
    this.props.dispatch(setAlgoliaLoaderState(true));
    try {
      await API.del("telishadeAPI", `/staff/${this.props.id}`);
      this.props.history.push("/staff");
      this.props.dispatch(setRefresh(true));
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUp
        header="Delete Staff Member?"
        body={"Are you sure you want to delete this staff member?"}
        button="Delete"
        buttoncolour="red"
        button2="cancel"
        buttonClickHandler={this.APIMethod}
        button2ClickHandler={undefined}
      />
    );
  }
}
const mapStateToProps = state => state.staff.staff;
export default withRouter(connect(mapStateToProps)(DeleteStaff));
