import React, { Component, Fragment } from "react";
import { numberWithCommas } from "../../../../../../utilities/utils";

class CurtainsBillOfRates extends Component {
  constructBillofRates(jobQuotes) {
    const billofRates = this.calculateBillofRate(jobQuotes);
    return (
      <Fragment>
        <tr className="details">
          <td>Curtains</td>
          <td>{`${numberWithCommas(billofRates.totalM2.toFixed(2))}`}</td>
          <td>{`$${numberWithCommas((billofRates.total / billofRates.totalM2).toFixed(2))}`}</td>
          <td>{`$${numberWithCommas((billofRates.total / billofRates.qty).toFixed(2))}`}</td>
          <td>{billofRates.qty}</td>
          <td>{`$${numberWithCommas(billofRates.total.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td>Curtains + Extras</td>
          <td>{`${billofRates.totalM2.toFixed(2)}`}</td>
          <td>{`$${numberWithCommas(
            (billofRates.totalWithExtras / billofRates.totalM2).toFixed(2)
          )}`}</td>
          <td>{`$${numberWithCommas(
            (billofRates.totalWithExtras / billofRates.qty).toFixed(2)
          )}`}</td>
          <td>{billofRates.qty}</td>
          <td>{`$${numberWithCommas(billofRates.totalWithExtras.toFixed(2))}`}</td>
        </tr>
      </Fragment>
    );
  }

  calculateBillofRate(jobQuotes) {
    let total = 0;
    let totalWithExtras = 0;
    let qty = 0;
    let totalM2 = 0;
    jobQuotes.forEach(job => {
      job.quote.forEach(quoteItem => {
        total += this.calculateCurtainItemTotal(quoteItem, 0, job.discount);
        totalWithExtras += this.calculateCurtainItemTotal(quoteItem, 0, job.discount, true);
        qty++;
        totalM2 += quoteItem.squareMetres;
      });
    });
    return { total, totalWithExtras, qty, totalM2 };
  }

  calculateCurtainItemTotal(quoteItem, discount = 0, discountObject, extras = false) {
    if (isNaN(discount)) {
      return this.calculateCurtainItemTotal(quoteItem, 0, discountObject);
    }
    let totalforItem = 0;
    totalforItem += this.props.isRetail
      ? quoteItem.costInfo.markedUpBasePrice
      : quoteItem.costInfo.basePrice;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach(componentDiscount => {
        if (componentDiscount.id === quoteItem.description.allDetails.fabricId) {
          totalforItem =
            (totalforItem / 100) *
            (100 -
              (this.props.isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }
    if (extras) {
      totalforItem += this.calculateExtra("bumph", quoteItem, discountObject).total;
      totalforItem += this.calculateExtra("driveMechanism", quoteItem, discountObject).total;
      totalforItem += this.calculateExtra("leadWeight", quoteItem, discountObject).total;
      totalforItem += this.calculateExtra("lining", quoteItem, discountObject).total;
      totalforItem += this.calculateExtra("pelmet", quoteItem, discountObject).total;
      totalforItem += this.calculateExtra("accessory", quoteItem, discountObject).total;
      totalforItem += this.calculateService(quoteItem, discountObject).total;
    }
    totalforItem += this.props.isRetail
      ? this.getTrackDiscountValue(
          quoteItem.costInfo.markedUpTrackTotalCost,
          discountObject,
          quoteItem
        )
      : this.getTrackDiscountValue(quoteItem.costInfo.trackTotalCost, discountObject, quoteItem);
    totalforItem = this.props.isRetail ? (totalforItem / 100) * (100 - discount) : totalforItem;
    return totalforItem;
  }

  getTrackDiscountValue(trackCost, discountObject, quoteItem) {
    let totalforTrack = trackCost;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach(componentDiscount => {
        if (componentDiscount.id === quoteItem.description.allDetails.trackId) {
          totalforTrack =
            (totalforTrack / 100) *
            (100 -
              (this.props.isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }
    return totalforTrack;
  }

  calculateExtra(extraKey, item, discountObject) {
    let totalPrice = 0;
    let totalQty = 0;
    if (item.extras[extraKey]) {
      if (item.extras[extraKey].details.pricePerMetre) {
        const totalPricePerMetre =
          parseFloat(item.extras[extraKey].details.pricePerMetre) *
          (parseFloat(item.description.width) / 1000);
        const totalPricePerMetreMarkup =
          (totalPricePerMetre / 100) * (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail ? totalPricePerMetreMarkup : totalPricePerMetre;
        totalQty++;
      } else if (item.extras[extraKey].details.cost) {
        const totalCost =
          (parseFloat(item.extras[extraKey].details.cost) / 100) *
          (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail
          ? totalCost
          : parseFloat(item.extras[extraKey].details.cost);
        totalQty++;
      }
      if (discountObject && discountObject.components.length > 0) {
        discountObject.components.forEach(componentDiscount => {
          if (item.extras[extraKey].id === componentDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (this.props.isRetail
                  ? componentDiscount.retail.percentage
                  : componentDiscount.wholesale.percentage));
          }
        });
      }
    }

    return { total: totalPrice, qty: totalQty };
  }

  calculateService(item, discountObject) {
    let totalPrice = 0;
    let totalQty = 0;
    item.extras.services.forEach(service => {
      const totalWithMarkup =
        (parseFloat(service.details.cost) / 100) * (100 + parseFloat(service.details.markup));
      totalPrice += this.props.isRetail ? totalWithMarkup : parseFloat(service.details.cost);
      totalQty++;
      if (discountObject && discountObject.servicesAndFees.length > 0) {
        discountObject.servicesAndFees.forEach(serviceDiscount => {
          if (service.id === serviceDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (this.props.isRetail
                  ? serviceDiscount.retail.percentage
                  : serviceDiscount.wholesale.percentage));
          }
        });
      }
    });
    return { total: totalPrice, qty: totalQty };
  }

  render() {
    return (
      <div className="doc-box" style={{ display: this.props.display ? "" : "None" }}>
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="heading">
              <td colSpan="6">
                <span className="heading-one">PROJECT QUOTE - BILL OF RATES</span>
              </td>
            </tr>
            <tr className="sub-heading">
              <td>Description</td>
              <td>Total m²</td>
              <td>$/m²</td>
              <td>Average Price</td>
              <td>QTY</td>
              <td>Total*</td>
            </tr>
            {this.constructBillofRates(this.props.response)}
            <tr className="bottom">
              <td colSpan="6">* Does not include GST</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default CurtainsBillOfRates;
