import React, { Component } from "react";
import { connect } from "react-redux";
import { MDBBtn } from "mdbreact";
import "./Account.css";
import { Auth } from "aws-amplify";
import { setLoaderState } from "../../actions/loaderActions";

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errormsg: "",
      currentPassword: "",
      newPassword: "",
      confirmNewPW: ""
    };
  }

  handleChange = event => {
    // Handles editing of the form
    this.setState({ [event.target.id]: event.target.value });
    if (event.target.value.trim() === "")
      this.setState({ [event.target.id]: "" });
  };

  handleSubmit = async event => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (
      event.target.checkValidity() &&
      this.state.newPassword === this.state.confirmNewPW
    ) {
      this.props.dispatch(setLoaderState(true));
      Auth.currentAuthenticatedUser()
        .then(user => {
          return Auth.changePassword(
            user,
            this.state.currentPassword,
            this.state.newPassword
          );
        })
        .then(data => {
          this.props.dispatch(setLoaderState(false));
          this.props.history.push("/account");
        })
        .catch(err => {
          this.props.dispatch(setLoaderState(false));
          console.error(err);
          if (err.code === "NotAuthorizedException")
            this.setState({ errormsg: "Incorrect Password" });
          if (err.code === "LimitExceededException")
            this.setState({
              errormsg: "Password Changed Too Frequently, Try Again Later"
            });
          if (err.code === "InvalidParameterException")
            this.setState({
              errormsg:
                "Invalid Password. \n Password must contain atleast one Uppercase, Special Character and Number"
            });
          if (err.code === "NetworkError") {
            this.setState({
              errormsg: "Error, Disconnected from Internet"
            });
          }
        });
    } else {
      this.setState({ errormsg: "Password Mismatch" });
    }
  };

  handleCancelClick = () => {
    this.props.history.push("/account");
  };

  render() {
    return (
      <div className="myaccount-container">
        <div className="myaccount-card card">
          <h2>Change Password</h2>
          <div className="errorMessage">
            {this.state.errormsg === "" ? "" : this.state.errormsg}
          </div>
          <div className="myaccount-details">
            <form
              className="needs-validation"
              noValidate
              onSubmit={this.handleSubmit}
            >
              <div className="passwordFields">
                <label className="passwordLabel">Current Password</label>
                <input
                  id="currentPassword"
                  autoComplete="current-password"
                  size="sm"
                  className="projectInput form-control"
                  type="password"
                  onChange={this.handleChange}
                  value={this.state.currentPassword}
                  required
                />
                <div className="invalid-tooltip">Please Enter Old Password</div>
              </div>
              <div className="passwordFields">
                <label className="passwordLabel">New Password</label>
                <input
                  id="newPassword"
                  autoComplete="new-password"
                  size="sm"
                  className="projectInput form-control"
                  type="password"
                  pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$"
                  onChange={this.handleChange}
                  value={this.state.newPassword}
                  required
                />
                <div className="invalid-tooltip">
                  Invalid Password. Password must contain atleast one Uppercase,
                  Special Character and Number
                </div>
              </div>
              <div className="passwordFields">
                <label className="passwordLabel">Confirm New Password</label>
                <input
                  id="confirmNewPW"
                  autoComplete="new-password"
                  size="sm"
                  className="projectInput form-control"
                  type="password"
                  onChange={this.handleChange}
                  value={this.state.confirmNewPW}
                  required
                />
                <div className="invalid-tooltip">
                  Please Confirm New Password
                </div>
              </div>
              <MDBBtn color="blue" type="submit" className="editBtn">
                Submit
              </MDBBtn>
              <MDBBtn
                color="red"
                onClick={this.handleCancelClick}
                className="editBtn"
              >
                Cancel
              </MDBBtn>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(ChangePassword);
