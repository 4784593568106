export function handleSearchString(searchString) {
  let stringArray = searchString.split(",");
  let searchObject = {};
  stringArray.forEach(searchQuery => {
    if (searchQuery.toLowerCase().includes("location:")) {
      searchObject.location = searchQuery
        .toLowerCase()
        .replace("location:", "")
        .trim();
    }
    if (searchQuery.toLowerCase().includes("drivetype:")) {
      searchObject.driveType = searchQuery
        .toLowerCase()
        .replace("drivetype:", "")
        .trim();
    }
    if (searchQuery.toLowerCase().includes("style:")) {
      searchObject.style = searchQuery
        .toLowerCase()
        .replace("style:", "")
        .trim();
    }
    if (searchQuery.toLowerCase().includes("drivemechanism:")) {
      searchObject.driveMechanism = searchQuery
        .toLowerCase()
        .replace("drivemechanism:", "")
        .trim();
    }
    if (searchQuery.toLowerCase().includes("fabric:")) {
      searchObject.fabric = searchQuery
        .toLowerCase()
        .replace("fabric:", "")
        .trim();
    }
    if (searchQuery.toLowerCase().includes("productcode:")) {
      searchObject.productCode = searchQuery
        .toLowerCase()
        .replace("productcode:", "")
        .trim();
    }
  });
  if (Object.keys(searchObject).length > 0) {
    return searchObject;
  } else return undefined;
}

export function handleSearchQuery(item, searchObject) {
  let matchingFlag = true;
  if (searchObject) {
    if (searchObject.location) {
      if (
        !item.description.allDetails.location
          .toLowerCase()
          .trim()
          .includes(searchObject.location)
      )
        return false;
    }
    if (searchObject.driveType) {
      if (
        !item.description.allDetails.driveType
          .toLowerCase()
          .trim()
          .includes(searchObject.driveType)
      )
        return false;
    }
    if (searchObject.style) {
      if (item.description.headerStyle) {
        if (
          !item.description.headerStyle
            .toLowerCase()
            .trim()
            .includes(searchObject.style)
        )
          return false;
      }
      if (item.description.rollerStyle) {
        if (
          !item.description.rollerStyle
            .toLowerCase()
            .trim()
            .includes(searchObject.style)
        )
          return false;
      }
    }
    if (searchObject.driveMechanism) {
      if (
        !item.description.driveMechanism
          .toLowerCase()
          .trim()
          .includes(searchObject.driveMechanism)
      )
        return false;
    }
    if (searchObject.fabric) {
      if (
        !item.description.fabric
          .toLowerCase()
          .trim()
          .includes(searchObject.fabric)
      )
        return false;
    }
    if (searchObject.productCode) {
      if (
        !item.productCode
          .toLowerCase()
          .trim()
          .includes(searchObject.productCode)
      )
        return false;
    }
  }
  return matchingFlag;
}
