import React, { Component } from "react";
import PopUpForm from "../../../Generic/PopUpDialog/PopUpForm";
import { connect } from "react-redux";
import { API } from "aws-amplify";

//Actions
import { setRefresh } from "../../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../../actions/loaderActions";

class CreateBlindPricingGroup extends Component {
  handleCancelClick = () => {
    this.props.history.goBack();
  };

  handleSubmitClick = formData => {
    const body = {
      details: {
        name: formData.name,
        markup: formData.markup,
        x: { start: formData.xStartValue, increment: formData.xIncrementValue },
        y: { start: formData.yStartValue, increment: formData.yIncrementValue },
        data: []
      }
    };
    return API.post("telishadeAPI", "/product/blind/price-list", { body });
  };

  APIMethod = async formData => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));
      const body = {
        details: {
          name: formData.name,
          markup: formData.markup,
          x: { start: formData.xStartValue, increment: formData.xIncrementValue },
          y: { start: formData.yStartValue, increment: formData.yIncrementValue },
          data: []
        }
      };
      this.props.history.push(`/products/blinds/pricing-groups`);
      await API.post("telishadeAPI", "/product/blind/price-list", { body });
      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.dispatch(setRefresh(true));
    } catch (e) {
      this.props.dispatch(setAlgoliaLoaderState(false));
      throw e;
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  fields = [
    {
      id: "name",
      label: "Price List Name",
      type: "text",
      tooltip: "Please Enter Price List Name",
      required: true,
      value: ""
    },
    {
      id: "markup",
      label: "Price List Markup",
      type: "number",
      tooltip: "Please Enter Markup Value",
      required: true,
      value: 0
    },
    {
      id: "xStartValue",
      label: "X Axis Starting Value",
      type: "number",
      tooltip: "Please Enter X Axis Starting Value",
      required: true,
      value: 0
    },
    {
      id: "xIncrementValue",
      label: "X Axis Increment Value",
      type: "number",
      tooltip: "Please Enter X Axis Increment Value",
      required: true,
      value: 0
    },
    {
      id: "yStartValue",
      label: "Y Axis Starting Value",
      type: "number",
      tooltip: "Please Enter Y Axis Starting Value",
      required: true,
      value: 0
    },
    {
      id: "yIncrementValue",
      label: "Y Axis Increment Value",
      type: "number",
      tooltip: "Please Enter Y Axis Increment Value",
      required: true,
      value: 0
    }
  ];

  render() {
    return (
      <PopUpForm
        fields={this.fields}
        apiMethod={this.APIMethod}
        header="Add New Pricing Group"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

export default connect()(CreateBlindPricingGroup);
