import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { contactFields } from "../fields";
import { API } from "aws-amplify";
import { selectContact, updateContactDetails } from "../../../actions/customersActions";
import { setRefresh } from "../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";

class EditContact extends Component {
  get fields() {
    // Make a Deep copy of the Fields that will be passed in
    // for editing
    let fields = JSON.parse(JSON.stringify(contactFields));
    const details = { ...this.props.details };

    for (let i = 0; i < fields.length; i++) {
      // Match the field ID with the value kept in details:
      fields[i].value = details[fields[i].id];
    }
    return fields;
  }

  APIMethod = async formData => {
    try {
      const { customerId, branchId, contactId } = this.props.match.params;
      this.props.dispatch(setAlgoliaLoaderState(true));
      const response = await API.put(
        "telishadeAPI",
        `/customer/${customerId}/branch/${branchId}/contact/${contactId}`,
        {
          body: {
            details: {
              title: formData.title,
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
              phone: formData.phone,
              fax: formData.fax,
              mobile: formData.mobile
            }
          }
        }
      );
      await this.props.dispatch(updateContactDetails(response.details));
      await this.props.dispatch(selectContact(response.id));
      this.props.history.goBack();
    } catch (e) {
      throw e;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.fields}
        header="Edit Contact Details"
        button="ACCEPT"
        buttoncolour="green"
        button2="CANCEL"
        button2colour="red"
        apiMethod={this.APIMethod}
      />
    );
  }
}

const mapStateToProps = state => state.customers.contact;
export default withRouter(connect(mapStateToProps)(EditContact));
