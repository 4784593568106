import React from "react";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Theme } from "../Generic/Theme";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2)
    }
  }
}));

const DeterminateLoader = ({ maxValue, currentValue }) => {
  const classes = useStyles();
  const [completed, setCompleted] = React.useState(0);

  React.useEffect(() => {
    const progress = (currentValue / maxValue) * 100;
    if (completed !== progress) {
      setCompleted(progress);
    }
  }, [currentValue, maxValue, completed]);

  return (
    <div className={classes.root}>
      <ThemeProvider theme={Theme}>
        <LinearProgress variant="determinate" value={completed} />
      </ThemeProvider>
    </div>
  );
};

export default DeterminateLoader;
