import React, { Component } from "react";
import { connect } from "react-redux";
// import Bootstrap from "react-bootstrap";
import { Auth } from "aws-amplify";
import { logout, loginSuccessRedirect } from "../actions/authenticationActions";

class Logout extends Component {
  async componentDidMount() {
    try {
      await Auth.signOut();
      await this.props.dispatch(logout());
      await this.props.dispatch(loginSuccessRedirect("/"));
      this.props.history.push("/login");
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    return <div className="Logout" />;
  }
}
const mapStateToProps = state => ({
  authentication: state.authentication
});
export default connect(mapStateToProps)(Logout);
