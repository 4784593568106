import React, { Component } from "react";
import { API } from "aws-amplify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Theme } from "../../../../Generic/Theme";
//Material UI
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { ThemeProvider, IconButton } from "@material-ui/core";
import PurchaseOrderDocument from "./PurchaseOrderDocument";
import PurchaseOrderComponents from "./PurchaseOrderComponents";
import "./PurchaseOrder.css";
import PopUpForm from "../../../../Generic/PopUpDialog/PopUpForm";
import config from "../../../../../config";
import algoliasearch from "algoliasearch";
import { savePDF } from "@progress/kendo-react-pdf";
import SettingsIcon from "@material-ui/icons/Settings";
import SaveIcon from "@material-ui/icons/Save";

function pageTemplate({ pageNum, totalPages }) {
  return (
    <div className="pageTemplate">
      <div className="pageNumber">
        Pg. {pageNum} of {totalPages}
      </div>
    </div>
  );
}

class PurchaseOrderPerSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseOrder: undefined,
      hasBlinds: false,
      hasCurtains: false,
      customerDetails: undefined,
      currentTabSelection: 0,
      optionsPopUp: false,
      jobInfo: undefined,
      deliveryAddress: undefined,
      contactAddress: undefined,
      customManufacturer: undefined,
      options: {
        discountNumber: "",
        account: "",
        deliveryAddressType: "customer",
        deliveryAddress: "",
        dateRequired: "",
        combine: false,
        sortingKey: "itemNo",
        contactAddressType: "default",
        customContactAddress: "",
        contactAddress: "",
        customAddress: undefined,
        customManufacturer: undefined,
        showFabricTotal: true,
      },
    };
    this.getPurchaseOrder();
  }

  async getPurchaseOrder() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getPurchaseOrder();
      }, 500);
    } else {
      try {
        const customerDetails = await this.getCustomerInformation(
          this.props.projectDetails.details
        );
        const purchaseOrderBlind = await this.getJobPurchaseOrder(
          this.props.match.params.JobNo,
          "blind"
        );
        const purchaseOrderCurtain = await this.getJobPurchaseOrder(
          this.props.match.params.JobNo,
          "curtain"
        );
        let newOptions = {
          ...this.state.options,
          deliveryAddress: this.props.projectDetails.details.customerBranchId,
        };
        if (purchaseOrderBlind) {
          if (purchaseOrderBlind.details && purchaseOrderBlind.details.options.purchaseOrder) {
            newOptions = {
              ...newOptions,
              ...purchaseOrderBlind.details.options.purchaseOrder,
            };
            if (purchaseOrderBlind.details.options.purchaseOrder.dateRequired) {
              newOptions.dateRequired = new Date(
                purchaseOrderBlind.details.options.purchaseOrder.dateRequired
              );
            }
          }
        } else if (purchaseOrderCurtain) {
          if (purchaseOrderCurtain.details && purchaseOrderCurtain.details.options.purchaseOrder) {
            newOptions = {
              ...newOptions,
              ...purchaseOrderCurtain.details.options.purchaseOrder,
            };
            if (purchaseOrderCurtain.details.options.purchaseOrder.dateRequired) {
              newOptions.dateRequired = new Date(
                purchaseOrderCurtain.details.options.purchaseOrder.dateRequired
              );
            }
          }
        }
        if (purchaseOrderBlind || purchaseOrderCurtain) {
          this.setState({
            purchaseOrder: { blind: purchaseOrderBlind, curtain: purchaseOrderCurtain },
            customerDetails,
            deliveryAddress: customerDetails.jobBranch,
            options: newOptions,
            hasBlinds: purchaseOrderBlind && purchaseOrderBlind.id !== undefined,
            hasCurtains: purchaseOrderCurtain && purchaseOrderCurtain.id !== undefined,
            jobInfo: purchaseOrderBlind?.details || purchaseOrderCurtain?.details,
          });
        }
        if (newOptions.contactAddress) {
          this.handleContactAddressChange(newOptions.contactAddress);
        }
        if (newOptions.deliveryAddress) {
          this.handleDeliveryAddressChange(newOptions.deliveryAddress);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  async getCustomerInformation(details) {
    const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
    const customerIndex = client.initIndex(`${config.algolia.prefix}customers_name_asc`);
    const branchIndex = client.initIndex(`${config.algolia.prefix}customers_branches_name_asc`);
    const contactIndex = client.initIndex(
      `${config.algolia.prefix}customers_branches_contacts_name_asc`
    );
    const customer = await customerIndex.getObject(details.customerId);
    const branch = await branchIndex.getObject(details.customerBranchId);
    const contact = await contactIndex.getObject(details.customerBranchContactId);

    return { jobBranch: branch, branchContact: contact, customer };
  }

  async getJobPurchaseOrder(jobId, itemType) {
    try {
      const response = await API.get(
        "telishadeAPI",
        `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${jobId}/purchase-order/${itemType}`
      );
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  handleTabSelect = (event, newValue) => {
    this.setState({ currentTabSelection: newValue });
  };

  handleAddressTypeChange = (event) => {
    this.setState({ options: { ...this.state.options, deliveryAddressType: event.target.value } });
  };

  handleContactAddressTypeChange = (event) => {
    this.setState({ options: { ...this.state.options, contactAddressType: event.target.value } });
  };

  getValidationTypes = () => {
    return [
      {
        id: "discountNumber",
        label: "Discount Number",
        type: "text",
        value: this.state.options.discountNumber,
      },
      {
        id: "account",
        label: "Account Number",
        type: "text",
        value: this.state.options.account,
      },
      {
        id: "deliveryAddressType",
        label: "Delivery Address Type",
        type: "select",
        options: [
          { name: "Customer Address", value: "customer" },
          { name: "Staff Address", value: "staff" },
          { name: "Custom Address", value: "custom" },
        ],
        onChange: this.handleAddressTypeChange,
        value: this.state.options.deliveryAddressType,
      },
      this.state.options.deliveryAddressType === "customer"
        ? {
            id: "deliveryAddress",
            label: "Delivery  Address",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}customers_branches_name_asc`,
            filters: `isDeleted:false AND customerId:${this.props.projectDetails.details.customerId}`,
            value: this.state.options.deliveryAddress,
          }
        : this.state.options.deliveryAddressType === "staff"
        ? {
            id: "deliveryAddress",
            label: "Delivery  Address",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}staff_addresses_name_asc`,
            filters: `isDeleted:false`,
            value: this.state.options.deliveryAddress,
          }
        : {
            id: "customAddress",
            label: "Custom Delivery Address",
            type: "TextArea",
            value: this.state.options.customAddress,
          },
      {
        id: "contactAddressType",
        label: "Work Order Addressed",
        type: "select",
        options: [
          { name: "default", value: "default" },
          { name: "Manufacturer", value: "manufacturer" },
          { name: "Staff", value: "staff" },
          { name: "Custom", value: "custom" },
        ],
        onChange: this.handleContactAddressTypeChange,
        value: this.state.options.contactAddressType,
      },
      this.state.options.contactAddressType === "manufacturer"
        ? {
            id: "contactAddress",
            label: "Custom Work Order Manu",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}manufacturers_name_asc`,
            filters: `isDeleted:false`,
            value: this.state.options.contactAddress,
          }
        : this.state.options.contactAddressType === "staff"
        ? {
            id: "contactAddress",
            label: "Custom Work Order Manu",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}staff_name_asc`,
            filters: `isDeleted:false`,
            value: this.state.options.contactAddress,
          }
        : {
            id: "customManufacturer",
            label: "Custom Work Order Manu",
            type: "TextArea",
            value: this.state.options.customManufacturer,
          },
      {
        id: "dateRequired",
        label: "Date Required",
        type: "date",
        value: this.state.options.dateRequired,
      },
      {
        id: "combine",
        label: "Combine Same Items?",
        type: "select",
        options: [
          { value: true, name: "Yes" },
          { value: false, name: "No" },
        ],
        required: true,
        value: this.state.options.combine.toString(),
      },
      {
        id: "sortingKey",
        label: "Sort Items By",
        type: "select",
        options: [
          { value: "itemNo", name: "Item Number (Default)" },
          { value: "productCode", name: "Product Code" },
        ],
        required: true,
        value: this.state.options.sortingKey.toString(),
      },
      {
        id: "showFabricTotal",
        label: "Show Total Fabric?",
        type: "select",
        options: [
          { value: true, name: "Yes" },
          { value: false, name: "No" },
        ],
        required: true,
        value: this.state.options.showFabricTotal.toString(),
      },
    ];
  };

  handleOptionsPopUp = (formData) => {
    if (this.state.options.deliveryAddress !== formData.deliveryAddress) {
      this.handleDeliveryAddressChange(formData.deliveryAddress);
    }
    if (this.state.options.contactAddress !== formData.contactAddress) {
      this.handleContactAddressChange(formData.contactAddress);
    }
    if (this.state.customManufacturer === undefined && formData.contactAddress) {
      this.handleContactAddressChange(formData.contactAddress);
    }
    this.setState({
      options: {
        ...this.state.options,
        discountNumber: formData.discountNumber,
        account: formData.account,
        deliveryAddress: formData.deliveryAddress,
        dateRequired: formData.dateRequired,
        combine: formData.combine === "true",
        sortingKey: formData.sortingKey,
        contactAddressType: formData.contactAddressType,
        contactAddress: formData.contactAddress,
        customAddress: formData.customAddress,
        customManufacturer: formData.customManufacturer,
        showFabricTotal: formData.showFabricTotal === "true",
      },
      optionsPopUp: false,
    });
    this.sendOptionsToApi({
      ...this.state.options,
      discountNumber: formData.discountNumber,
      account: formData.account,
      deliveryAddress: formData.deliveryAddress,
      dateRequired: formData.dateRequired,
      combine: formData.combine === "true",
      sortingKey: formData.sortingKey,
      contactAddressType: formData.contactAddressType,
      contactAddress: formData.contactAddress,
      customAddress: formData.customAddress,
      customManufacturer: formData.customManufacturer,
    });
  };

  async sendOptionsToApi(options) {
    let body = {
      details: {
        ...this.state.jobInfo,
        options: {
          ...this.state.jobInfo.options,
          purchaseOrder: options,
        },
      },
    };
    await API.put(
      "telishadeAPI",
      `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${this.props.match.params.JobNo}`,
      { body }
    );
  }

  async handleDeliveryAddressChange(newAddressId) {
    const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
    const contactIndex = client.initIndex(`${config.algolia.prefix}customers_branches_name_asc`);
    const staffContactIndex = client.initIndex(`${config.algolia.prefix}staff_addresses_name_asc`);
    if (this.state.options.deliveryAddressType === "customer") {
      const contact = await contactIndex.getObject(newAddressId);
      this.setState({ deliveryAddress: contact });
    } else if (this.state.options.deliveryAddressType === "staff") {
      const contact = await staffContactIndex.getObject(newAddressId);
      this.setState({ deliveryAddress: contact });
    }
  }

  async handleContactAddressChange(newAddressId) {
    const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
    const contactIndex = client.initIndex(`${config.algolia.prefix}manufacturers_name_asc`);
    const staffContactIndex = client.initIndex(`${config.algolia.prefix}staff_name_asc`);
    if (this.state.options.contactAddressType === "manufacturer") {
      const contact = await contactIndex.getObject(newAddressId);
      this.setState({ customManufacturer: contact });
      // this.handleManufacturerChange(contact.manufacturerId);
    } else if (this.state.options.contactAddressType === "staff") {
      const contact = await staffContactIndex.getObject(newAddressId);
      this.setState({ customManufacturer: contact });
      // this.handleManufacturerChange();
    }
  }

  // async handleManufacturerChange(newManuId) {
  //   const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
  //   const manuIndex = client.initIndex(`${config.algolia.prefix}manufacturers_name_asc`);
  //   if (this.state.options.contactAddressType === "manufacturer") {
  //     const customManufacturer = await manuIndex.getObject(newManuId);
  //     this.setState({ customManufacturer });
  //   } else {
  //     this.setState({ customManufacturer: undefined });
  //   }
  // }

  getCurtainIndexing(index) {
    let newIndex = index;
    if (this.state.hasBlinds) {
      newIndex = newIndex + this.state.purchaseOrder.blind.manufacturers.length + 1;
      if (this.state.purchaseOrder.blind.noManufacturers) newIndex = newIndex + 1;
    }
    return newIndex;
  }

  handleOptionsClick = () => {
    this.setState({ optionsPopUp: true });
  };

  render() {
    if (!this.state.purchaseOrder) {
      return <div className="loader">LOADING...</div>;
    } else
      return (
        <div className="PurchaseOrderWrapper">
          {this.state.optionsPopUp ? (
            <PopUpForm
              fields={this.getValidationTypes()}
              apiMethod={this.handleOptionsPopUp}
              header="Purchase Order Options"
              button="Accept"
              buttoncolour="green"
              button2="Cancel"
              button2colour="red"
              button2ClickHandler={() => this.setState({ optionsPopUp: false })}
            />
          ) : null}
          <ThemeProvider theme={Theme}>
            <AppBar className="manufactuerTabBar">
              <Tabs value={this.state.currentTabSelection} onChange={this.handleTabSelect}>
                {this.state.hasBlinds && this.state.purchaseOrder.blind.workOrder ? (
                  <Tab label={"Work Order (Blinds)"} />
                ) : null}
                {this.state.hasBlinds &&
                  this.state.purchaseOrder.blind.manufacturers.map((manu, index) => (
                    <Tab key={index} label={manu.details.name} />
                  ))}
                {this.state.hasBlinds && this.state.purchaseOrder.blind.noManufacturers ? (
                  <Tab label={"No Manufacturer (Blinds)"} />
                ) : null}
                {this.state.hasCurtains && this.state.purchaseOrder.curtain.workOrder ? (
                  <Tab label={"Work Order (Curtains)"} />
                ) : null}
                {this.state.hasCurtains &&
                  this.state.purchaseOrder.curtain.manufacturers.map((manu, index) => (
                    <Tab key={this.getCurtainIndexing(index)} label={manu.details.name} />
                  ))}
                {this.state.hasCurtains && this.state.purchaseOrder.curtain.noManufacturers ? (
                  <Tab label={"No Manufacturer (Curtains)"} />
                ) : null}
              </Tabs>
              <IconButton className="optionsButton" onClick={this.handleOptionsClick}>
                <SettingsIcon />
              </IconButton>
              <IconButton
                className="exportButton"
                onClick={() => {
                  try {
                    savePDF(document.getElementById("Document"), {
                      fileName: `${this.props.docNumber}-${this.state.currentTabSelection + 1}.pdf`,
                      repeatHeaders: true,
                      paperSize: "A4",
                      landscape: true,
                      scale: 0.4,
                      pageTemplate: pageTemplate,
                    });
                  } catch (e) {
                    console.error(e);
                  }
                }}
              >
                <SaveIcon />
              </IconButton>
            </AppBar>
          </ThemeProvider>
          {this.state.hasBlinds && 0 === this.state.currentTabSelection ? (
            <PurchaseOrderDocument
              docNumber={this.props.docNumber}
              key={1}
              customerDetails={this.state.customerDetails}
              deliveryAddress={
                this.state.options.deliveryAddressType === "custom"
                  ? this.state.options.customAddress
                  : this.state.deliveryAddress
              }
              manufacturer={{
                lines: this.state.purchaseOrder.blind.workOrder,
                details: { name: "N/A" },
              }}
              contactAddress={
                this.state.options.contactAddressType === "custom"
                  ? this.state.options.customAddress
                  : this.state.contactAddress
              }
              customManufacturer={
                this.state.options.contactAddressType === "custom"
                  ? { details: { name: this.state.options.customManufacturer } }
                  : this.state.options.contactAddressType === "default"
                  ? { details: { name: "N/A" } }
                  : this.state.customManufacturer
              }
              purchaseOrder={this.state.purchaseOrder}
              index={1}
              options={this.state.options}
            />
          ) : null}
          {this.state.hasBlinds &&
            this.state.purchaseOrder.blind.manufacturers.map((manu, index) => {
              if (index + 1 === this.state.currentTabSelection) {
                return (
                  <PurchaseOrderDocument
                    docNumber={this.props.docNumber}
                    key={index + 1}
                    customerDetails={this.state.customerDetails}
                    deliveryAddress={
                      this.state.options.deliveryAddressType === "custom"
                        ? this.state.options.customAddress
                        : this.state.deliveryAddress
                    }
                    manufacturer={manu}
                    purchaseOrder={this.state.purchaseOrder}
                    index={index + 1}
                    options={this.state.options}
                  />
                );
              } else return null;
            })}
          {this.state.hasBlinds &&
          this.state.purchaseOrder.blind.manufacturers.length + 1 ===
            this.state.currentTabSelection ? (
            <PurchaseOrderComponents
              docNumber={this.props.docNumber}
              key={this.state.purchaseOrder.blind.manufacturers.length + 1}
              customerDetails={this.state.customerDetails}
              manufacturer={{
                lines: this.state.purchaseOrder.blind.noManufacturers,
                details: { name: "N/A" },
              }}
              deliveryAddress={
                this.state.options.deliveryAddressType === "custom"
                  ? this.state.options.customAddress
                  : this.state.deliveryAddress
              }
              purchaseOrder={this.state.purchaseOrder}
              index={this.state.purchaseOrder.blind.manufacturers.length + 1}
              options={this.state.options}
            />
          ) : null}
          {this.state.hasCurtains &&
          this.getCurtainIndexing(0) === this.state.currentTabSelection ? (
            <PurchaseOrderDocument
              extraLines
              docNumber={this.props.docNumber}
              key={this.getCurtainIndexing(0)}
              customerDetails={this.state.customerDetails}
              deliveryAddress={
                this.state.options.deliveryAddressType === "custom"
                  ? this.state.options.customAddress
                  : this.state.deliveryAddress
              }
              manufacturer={{
                lines: this.state.purchaseOrder.curtain.workOrder,
                details: { name: "N/A" },
              }}
              purchaseOrder={this.state.purchaseOrder}
              index={this.getCurtainIndexing(0)}
              options={this.state.options}
              customManufacturer={
                this.state.options.contactAddressType === "custom"
                  ? { details: { name: this.state.options.customManufacturer } }
                  : this.state.options.contactAddressType === "default"
                  ? { details: { name: "N/A" } }
                  : this.state.customManufacturer
              }
            />
          ) : null}
          {this.state.hasCurtains &&
            this.state.purchaseOrder.curtain.manufacturers.map((manu, index) => {
              if (this.getCurtainIndexing(index + 1) === this.state.currentTabSelection) {
                return (
                  <PurchaseOrderComponents
                    docNumber={this.props.docNumber}
                    key={this.getCurtainIndexing(index + 1)}
                    customerDetails={this.state.customerDetails}
                    deliveryAddress={
                      this.state.options.deliveryAddressType === "custom"
                        ? this.state.options.customAddress
                        : this.state.deliveryAddress
                    }
                    manufacturer={manu}
                    purchaseOrder={this.state.purchaseOrder}
                    index={this.getCurtainIndexing(index + 1)}
                    options={this.state.options}
                  />
                );
              } else return null;
            })}
          {this.state.hasCurtains &&
          this.getCurtainIndexing(this.state.purchaseOrder.curtain.manufacturers.length + 1) ===
            this.state.currentTabSelection ? (
            <PurchaseOrderComponents
              docNumber={this.props.docNumber}
              key={this.getCurtainIndexing(
                this.state.purchaseOrder.curtain.manufacturers.length + 1
              )}
              customerDetails={this.state.customerDetails}
              manufacturer={{
                lines: this.state.purchaseOrder.curtain.noManufacturers,
                details: { name: "N/A" },
              }}
              deliveryAddress={
                this.state.options.deliveryAddressType === "custom"
                  ? this.state.options.customAddress
                  : this.state.deliveryAddress
              }
              purchaseOrder={this.state.purchaseOrder}
              index={this.getCurtainIndexing(
                this.state.purchaseOrder.curtain.manufacturers.length + 1
              )}
              options={this.state.options}
            />
          ) : null}
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return { AlgoliaKey: state.key, projectDetails: state.projects.projectDetails };
};

export default withRouter(connect(mapStateToProps)(PurchaseOrderPerSupplier));
