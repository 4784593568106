import React, { Component } from "react";
import PopUpForm from "../../../Generic/PopUpDialog/PopUpForm";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import * as VALIDATIONTYPES from "../../../Generic/validationTypes";
import { setRefresh } from "../../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../../actions/loaderActions";

class CreateBlindBaseBarPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationTypes: VALIDATIONTYPES.blindBaseBar
    };
  }
  handleCancelClick = () => {
    this.props.history.goBack();
  };

  APIMethod = async formData => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));
      const body = {
        details: {
          name: formData.name,
          height: formData.height,
          costPerMetre: formData.costPerMetre,
          markup: formData.markup,
          associatedServices: formData.associatedServices,
          manufacturerId: formData.manufacturerId
        }
      };
      await API.post("telishadeAPI", "/product/blind/component/base-bar", {
        body
      });
      this.props.history.push(`/products/blinds/base-bar`);
      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.dispatch(setRefresh(true));
    } catch (e) {
      this.props.dispatch(setAlgoliaLoaderState(false));
      throw e;
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.state.validationTypes}
        apiMethod={this.APIMethod}
        header="Add New Blind Base Bar"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

export default connect()(CreateBlindBaseBarPopUp);
