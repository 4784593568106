import { SET_REFRESH } from "../actions/algoliaActions";

const initalState = {
  refresh: false
};

export default function algoliaReducer(state = initalState, action) {
  switch (action.type) {
    case SET_REFRESH:
      return {
        ...state,
        refresh: action.refresh
      };
    default:
      return state;
  }
}
