import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LetterHead from "../../../TableComponents/LetterHead";
import JobHeader from "../../JobHeader";
import CurtainsSummary from "./QuoteComponents/CurtainsSummary";
import CurtainsBillOfRates from "./QuoteComponents/CurtainsBillOfRates";
import CurtainsTotals from "./QuoteComponents/CurtainsTotals";
import Terms from "../../../TableComponents/Terms";
import CurtainsScheduleWithExtras from "./QuoteComponents/CurtainsDetailedScheduleWithExtras";
import DiscountView from "../DiscountView/DiscountView";
import { API } from "aws-amplify";
import config from "../../../../../../config";
import algoliasearch from "algoliasearch";
import Modal from "../../../../PopUps/Modal";
import {
  emptyBranchContact,
  emptyCustomer,
  emptyJobBranch
} from "../../../../../Customers/CustomerModels";
import { storeCurtainJobQuotePayload } from "../../../../../../actions/documentsActions";
import QuoteNotes from "./QuoteComponents/QuoteNotes";

class QuoteCurtains extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: undefined,
      customerDetails: undefined,
      error: undefined
    };
    this.getJobQuote();
  }

  componentDidUpdate(prevProps) {
    //This Reloads the quote when the discount view switches
    if (
      this.props.documents.discountView !== prevProps.documents.discountView &&
      this.props.documents.discountView === false
    ) {
      this.setState({
        quote: undefined,
        customerDetails: undefined,
        error: undefined
      });
      this.getJobQuote();
    }
  }

  async getJobQuote() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getJobQuote();
      }, 500);
    } else {
      let quoteResponseCollection = [];
      let quoteCollection = [];
      let quoteErrorCollection = [];
      //Get Quote for Every Job in Project
      const quote = this.getQuoteForJob(this.props.match.params.JobNo);
      quoteResponseCollection.push(quote);
      const customerDetails = await this.getCustomerInformation();
      quoteResponseCollection = await Promise.all(quoteResponseCollection);
      //Filter Out Blind only Jobs, Format for Display
      await this.props.dispatch(storeCurtainJobQuotePayload(quoteResponseCollection[0]));
      quoteResponseCollection.forEach((response, key) => {
        if (response.quote.length > 0) {
          quoteCollection.push({
            name: this.props.jobDetails.details.name,
            jobNo: this.props.jobDetails.details.number,
            id: response.id,
            quote: this.handleQuoteResponse(response).quote,
            discount: response.discounts
          });
        }
        if (response.errors.length > 0) {
          response.errors.forEach(error => {
            quoteErrorCollection.push({ ...error.error });
          });
        }
      });
      if (quoteErrorCollection.length > 0) {
        this.handleErrors(quoteErrorCollection);
      }
      this.setState({ quote: quoteCollection, customerDetails });
    }
  }

  handleErrors(errorCollection) {
    let ErrorDialog = "";
    errorCollection.forEach(error => {
      ErrorDialog += this.formatErrorMessage(error);
    });
    console.error(ErrorDialog);
    this.setState({ error: ErrorDialog });
  }

  formatErrorMessage(errorObject) {
    let errorMessage = "";
    errorMessage = errorObject.message;
    errorMessage += "\n";
    return errorMessage;
  }

  async getCustomerInformation() {
    const details = this.props.projectDetails.details
      ? this.props.projectDetails.details
      : undefined;
    const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
    const customerIndex = client.initIndex(`${config.algolia.prefix}customers_name_asc`);
    const branchIndex = client.initIndex(`${config.algolia.prefix}customers_branches_name_asc`);
    const contactIndex = client.initIndex(
      `${config.algolia.prefix}customers_branches_contacts_name_asc`
    );
    let customer = emptyCustomer;
    let branch = emptyJobBranch;
    let contact = emptyBranchContact;

    if (details.customerId) {
      customer = await customerIndex.getObject(details.customerId);
    }

    if (details.customerBranchId) {
      branch = await branchIndex.getObject(details.customerBranchId);
    }

    if (details.customerBranchContactId) {
      contact = await contactIndex.getObject(details.customerBranchContactId);
    }

    return { jobBranch: branch, branchContact: contact, customer };
  }

  async getJobsForProject() {
    try {
      const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
      const index = client.initIndex(`${config.algolia.prefix}projects_jobs_number_asc`);
      const response = await index.search({
        query: "",
        filters: "isDeleted:false AND projectId:" + this.props.match.params.id,
        hitsPerPage: 1000
      });
      return response.hits;
    } catch (e) {
      console.error(e);
    }
  }

  async getQuoteForJob(JobId) {
    try {
      const response = API.get(
        "telishadeAPI",
        `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${JobId}/quote/curtain`
      );

      return response;
    } catch (e) {
      console.error(e);
    }
  }

  handleQuoteResponse = quoteResponse => {
    quoteResponse.quote.forEach(quoteItem => {
      quoteItem = this.handleExtras(quoteItem);
    });
    return quoteResponse;
  };

  handleExtras = quoteItem => {
    for (let extraKey in quoteItem.extras) {
      if (quoteItem.extras.hasOwnProperty(extraKey) && quoteItem.extras[extraKey] !== null) {
        let currentExtra = quoteItem.extras[extraKey];
        if (currentExtra.details && currentExtra.details.cost && currentExtra.details.markup) {
          currentExtra.details.cost = parseFloat(currentExtra.details.cost);
          currentExtra.details.markup = parseFloat(currentExtra.details.markup);
        } else if (
          currentExtra.details &&
          currentExtra.details.costPerMetre &&
          currentExtra.details.markup
        ) {
          currentExtra.details.costPerMetre = parseFloat(currentExtra.details.costPerMetre);
          currentExtra.details.markup = parseFloat(currentExtra.details.markup);
        }
        if (currentExtra.details && currentExtra.details.fabricWidth) {
          currentExtra.details.fabricWidth = parseFloat(currentExtra.details.fabricWidth);
        }
        if (currentExtra.details && currentExtra.details.pricePerMetre) {
          currentExtra.details.pricePerMetre = parseFloat(currentExtra.details.pricePerMetre);
        }
        if (currentExtra.details && currentExtra.details.markup) {
          currentExtra.details.markup = parseFloat(currentExtra.details.markup);
        }
        if (currentExtra.details && currentExtra.details.basePrice) {
          currentExtra.details.basePrice = parseFloat(currentExtra.details.basePrice);
        }
        if (extraKey === "services" && currentExtra.length > 0) {
          currentExtra = this.handleServices(currentExtra);
        }
      }
    }
    return quoteItem;
  };

  handleServices = quoteItemServices => {
    quoteItemServices.forEach(service => {
      service.details.cost = parseFloat(service.details.cost);
      service.details.markup = parseFloat(service.details.markup);
    });
    return quoteItemServices;
  };

  // Prevent the following error: "Warning: Can't perform a React state update on an unmounted component."
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if (!this.state.quote) {
      return <div className="loader">LOADING...</div>;
    } else {
      return (
        <Fragment>
          {this.state.error ? (
            <Modal header={"Curtain Quote Errors"} displayMessage={this.state.error} />
          ) : (
            ""
          )}
          {this.props.documents.discountView ? (
            <DiscountView />
          ) : (
            <div id="Document">
              <LetterHead />
              <JobHeader
                type="Quote"
                docNumber={this.props.docNumber + "-C"}
                customer={this.state.customerDetails.customer}
                branch={this.state.customerDetails.jobBranch}
                contact={this.state.customerDetails.branchContact}
              />
              <CurtainsSummary
                grouping={this.props.options.grouping}
                detailed={this.props.options.detailed}
                isRetail={this.props.isRetail}
                response={this.state.quote}
              />
              <CurtainsBillOfRates
                display={this.props.options.billofRates}
                isRetail={this.props.isRetail}
                response={this.state.quote}
              />
              <CurtainsTotals
                isRetail={this.props.isRetail}
                response={this.state.quote}
                discount={this.props.jobDetails.details.options.discount}
              />
              <QuoteNotes response={this.state.quote} display={this.props.options.itemNotes} />
              <Terms terms={this.props.projectDetails.details.options.terms} />
              <CurtainsScheduleWithExtras
                display={this.props.options.scheduleofItems}
                detailed={this.props.options.scheduleofItemsDetailed}
                isRetail={this.props.isRetail}
                response={this.state.quote}
              />
            </div>
          )}
        </Fragment>
      );
    }
  }
}

const mapStatetoProps = state => {
  return {
    AlgoliaKey: state.key,
    projectDetails: state.projects.projectDetails,
    jobDetails: state.projects.jobDetails,
    documents: state.documents
  };
};
export default withRouter(connect(mapStatetoProps)(QuoteCurtains));
