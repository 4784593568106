// Conditional config

const REACT_APP_STAGE =
  process.env.REACT_APP_STAGE !== undefined ? process.env.REACT_APP_STAGE : "dev";

const dev = {
  REACT_APP_STAGE,
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://dev-api.telishade.com"
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_VguAbqZEr",
    APP_CLIENT_ID: "768na1pcujv424sd44pmit4j29",
    IDENTITY_POOL_ID: "ap-southeast-2:cbaeb66a-b3e7-4ca1-889f-73ae791a762f"
  },
  algolia: {
    prefix: "dev_",
    appId: "6VMDRWKB0K"
  }
};

const staging = {
  REACT_APP_STAGE,
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://api.telishade.com"
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_L0teeOH5X",
    APP_CLIENT_ID: "6vbmnjdqaq7ps5c7iouu9r1p0n",
    IDENTITY_POOL_ID: "ap-southeast-2:c340e2af-4d97-4e46-89e8-cb7c0ae86926"
  },
  algolia: {
    prefix: "prod_",
    appId: "6VMDRWKB0K"
  }
};

const prod = {
  REACT_APP_STAGE,
  apiGateway: {
    REGION: "ap-southeast-2",
    URL: "https://api.telishade.com"
  },
  cognito: {
    REGION: "ap-southeast-2",
    USER_POOL_ID: "ap-southeast-2_L0teeOH5X",
    APP_CLIENT_ID: "6vbmnjdqaq7ps5c7iouu9r1p0n",
    IDENTITY_POOL_ID: "ap-southeast-2:c340e2af-4d97-4e46-89e8-cb7c0ae86926"
  },
  algolia: {
    prefix: "prod_",
    appId: "6VMDRWKB0K"
  }
};

let config;
switch (REACT_APP_STAGE) {
  case "prod":
    config = prod;
    break;
  case "staging":
    config = staging;
    break;
  case "dev":
  default:
    config = dev;
}

export default {
  ...config
};
