// VALIDATION TYPES, these map to Input type to a Pop Up Form
const branchFields = [
  {
    id: "name",
    label: "Name of Branch",
    type: "text",
    tooltip: "Name of Branch must not be empty",
    required: false,
    value: ""
  },
  {
    id: "addressType",
    label: "Address Type",
    type: "text",
    tooltip: "Address Type eg. Pick Up or Office",
    required: false,
    value: ""
  },
  {
    id: "streetNumber",
    label: "Street Number",
    type: "text",
    tooltip: "Must be a number",
    required: false,
    value: ""
  },
  {
    id: "streetName",
    label: "Street Name",
    type: "text",
    tooltip: "Street Name must not be empty",
    required: false,
    value: ""
  },
  {
    id: "city",
    label: "City",
    type: "text",
    tooltip: "City must not be empty",
    required: false,
    value: ""
  },
  {
    id: "postcode",
    label: "Postcode",
    type: "text",
    tooltip: "Postcode must not be empty",
    required: false,
    value: ""
  },
  {
    id: "state",
    label: "State",
    type: "text",
    tooltip: "State must not be empty",
    required: false,
    value: ""
  },
  {
    id: "country",
    label: "Country",
    type: "text",
    tooltip: "Country must not be empty",
    required: false,
    value: ""
  }
];

const contactFields = [
  {
    id: "firstName",
    label: "First Name",
    type: "text",
    tooltip: "First Name must not be empty",
    required: false,
    value: ""
  },
  {
    id: "lastName",
    label: "Last Name",
    type: "text",
    tooltip: "Lastname must not be empty",
    required: false,
    value: ""
  },
  {
    id: "title",
    label: "Role in Company",
    type: "text",
    tooltip: "Please enter a title",
    required: false,
    value: ""
  },
  {
    id: "email",
    label: "Email Address",
    type: "email",
    tooltip: "Please enter a valid email address",
    required: false,
    value: ""
  },
  {
    id: "phone",
    label: "Phone Number",
    type: "text",
    tooltip: "Please enter a valid phone number",
    required: false,
    value: ""
  },
  {
    id: "fax",
    label: "Fax Number",
    type: "text",
    tooltip: "Please enter a valid fax number",
    required: false,
    value: ""
  },
  {
    id: "mobile",
    label: "Mobile",
    type: "text",
    tooltip: "Please enter a valid mobile number",
    required: false,
    value: ""
  }
];

const customerFields = [
  {
    id: "name",
    label: "Customer Name",
    type: "text",
    tooltip: "Customer Name must not be empty",
    required: true,
    value: ""
  },
  {
    id: "regNo",
    label: "Registration No.",
    type: "text",
    tooltip: "Please enter a company registration number",
    required: false,
    value: ""
  },
  {
    id: "email",
    label: "Email Address",
    type: "email",
    tooltip: "Please enter a valid email address",
    required: false,
    value: ""
  },
  {
    id: "website",
    label: "Website",
    type: "text",
    tooltip: "Please enter a website",
    required: false,
    value: ""
  },
  {
    id: "phone",
    label: "Phone Number",
    type: "text",
    tooltip: "Please enter a valid contact number",
    required: false,
    value: ""
  },
  {
    id: "fax",
    label: "Fax",
    type: "text",
    tooltip: "Please enter a fax number",
    required: false,
    value: ""
  },
  {
    id: "mobile",
    label: "Mobile",
    type: "text",
    tooltip: "Please enter a valid mobile number",
    required: false,
    value: ""
  }
];

export { branchFields, contactFields, customerFields };
