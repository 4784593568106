import React, { Component, Fragment } from "react";

class ProjectHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docNumber: "", // DocNumber can be either Quote, PO, Invoice number etc
      docType: this.documentReference ? this.documentReference : "--"
    };
  }

  get FormattedAddress() {
    const streetNumber = this.props.customerDetails?.jobBranch?.details?.address?.streetNumber
      ? this.props.customerDetails.jobBranch.details.address.streetNumber
      : "";
    const streetName = this.props.customerDetails?.jobBranch?.details?.address?.streetName
      ? this.props.customerDetails.jobBranch.details.address.streetName
      : "";
    const city = this.props.customerDetails?.jobBranch?.details?.address?.city
      ? this.props.customerDetails.jobBranch.details.address.city
      : "";

    return (
      <Fragment>
        {streetNumber} {streetName} - {city}
      </Fragment>
    );
  }

  get DateNow() {
    var tempDate = new Date();
    return `${tempDate.toLocaleDateString("en-AU")}`;
  }

  get documentReference() {
    return this.props.type === "Project"
      ? "PROJECT PO #"
      : this.props.type === "Job"
      ? "PO #"
      : this.props.type === "Quote"
      ? "Project Quotation"
      : this.props.type === "Margin Report"
      ? "Project Margin Report #"
      : "Ref No.";
  }

  get showDeliveryInformation() {
    return (
      <div id="JobDetails">
        <span id="title">
          <span id="grey-text">
            Delivery Information
            <br />
            To:
          </span>
          {this.props.customerDetails?.jobBranch?.details?.address?.city || ""}
        </span>
        <br />
        Address: {this.FormattedAddress}
        <br />
        State: {this.props.customerDetails?.jobBranch?.details?.address?.state || ""}
        <br />
        Postal Code: {this.props.customerDetails?.jobBranch?.details?.address?.postcode || ""}
        <br />
      </div>
    );
  }

  render() {
    const customerDetails = this.props.customerDetails;
    const project = this.props.projectDetails.details;

    return (
      <Fragment>
        <div id="ProjectHeader">
          <div id="ProjectDetails">
            <span id="title">
              <span id="grey-text">{this.props.type} Name: </span> {project.name}
            </span>
            <br />
            <span id="title">
              <span id="grey-text">Client Name: </span>
              {customerDetails?.customer?.details?.name || ""}
            </span>
            <br />
            <span id="title">
              <span id="grey-text">Contact Name: </span>
              {customerDetails?.branchContact?.details?.firstName || ""}{" "}
              {customerDetails?.branchContact?.details?.lastName || ""}
            </span>
            <br />
            Client Address: {this.FormattedAddress}
            <br />
            Phone: {customerDetails?.branchContact?.details?.phone || ""}
            <br />
            Mobile: {customerDetails?.branchContact?.details?.mobile || ""}
            <br />
            Email: {customerDetails?.branchContact?.details?.email || ""}
            <br />
            Discount Number:
            <br />
            Account:
          </div>
          {this.props.type === "Job" ? this.showDeliveryInformation : ""}

          <span>
            <span id="title">{this.state.docType}</span> <br />
            <span id="title">Document Date</span> <br />
            {this.props.type === "Job" ? <span id="title">Required Date</span> : ""}
          </span>
          <span id="QuoteNumberAndDate">
            <span id="title">{this.props.docNumber}</span>
            <span id="title">{this.DateNow}</span>
            {this.props.type === "Job" ? <span id="title">{project.startDate}</span> : ""}
          </span>
        </div>
      </Fragment>
    );
  }
}

export default ProjectHeader;
