import React, { Component } from "react";
import config from "../../../config";
import algoliasearch from "algoliasearch";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { API } from "aws-amplify";

//MDB
import { MDBBtn } from "mdbreact";

//Atlassian DND
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { setLoaderState } from "../../../actions/loaderActions";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: "13px",
  height: "50px",
  margin: `0 0 ${grid}px 0`,
  position: "relative",

  // change background colour if dragging
  background: isDragging ? "rgba(0, 187, 255, 0.578)" : "white",
  boxShadow: isDragging ? "0px 0px 15px rgba(0, 0, 0, 0.16)" : "",
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "white" : "white",
  padding: grid,
  width: "100%"
});

class ReorderJobList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: undefined,
      selectedIndex: undefined
    };
    this.getJobsForProject();
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    this.MoveItems(result.source.index, result.destination.index);
  }

  async getJobsForProject() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getJobsForProject();
      }, 500);
    } else {
      try {
        const client = await algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
        const index = await client.initIndex(`${config.algolia.prefix}projects_jobs_number_asc`);
        const response = await index.search({
          query: "",
          filters: "isDeleted:false AND projectId:" + this.props.match.params.id,
          hitsPerPage: 1000
        });
        this.setState({ jobs: response.hits });
      } catch (e) {
        console.error(e);
      }
    }
  }

  MoveItems(moveFrom, moveTo) {
    let newJobList = JSON.parse(JSON.stringify(this.state.jobs));
    newJobList.splice(moveTo, 0, newJobList.splice(moveFrom, 1)[0]);
    this.ReNumberItems(newJobList);
    this.setState({ jobs: newJobList, selectedIndex: undefined });
  }

  ReNumberItems(JobArray) {
    JobArray.forEach((job, key) => {
      job.details.number = key + 1;
    });
  }

  async SaveItems(JobArray) {
    try {
      this.props.dispatch(setLoaderState(true));
      let JobPromises = [];
      JobArray.forEach(job => {
        JobPromises.push(
          API.put(
            "telishadeAPI",
            "/project/" +
              this.props.projectDetails.type +
              "/" +
              this.props.match.params.id +
              "/job/" +
              job.objectID,
            { body: { details: { ...job.details } } }
          )
        );
      });
      await Promise.all(JobPromises);
      this.props.dispatch(setLoaderState(false));
      this.props.onSave();
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (this.state.jobs) {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <MDBBtn color="green" onClick={() => this.SaveItems(this.state.jobs)}>
            Save
          </MDBBtn>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className="reorderJobList"
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {this.state.jobs.map((job, key) => {
                  return (
                    <Draggable draggableId={key.toString()} key={key + 1} index={key}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          className={"reorderJobListItem"}
                        >
                          <p className="jobListWrapper">
                            <strong>
                              <span className="jobListNumber">{`JobNo:${job.details.number}`}</span>
                              <span className="jobListName">
                                {`${job.details.name}`}
                                <span className="jobListStatus">{`Status: ${job.details.status}`}</span>
                              </span>
                            </strong>
                          </p>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    } else return <div>Loading...</div>;
  }
}

const mapStatetoProps = state => {
  return { AlgoliaKey: state.key, projectDetails: state.projects.projectDetails };
};

export default withRouter(connect(mapStatetoProps)(ReorderJobList));
