import React, { Component } from "react";

class BlindsBillOfRates extends Component {
  calcAverage = (total, qty) => {
    if (qty > 0 && total > 0) {
      return total / qty;
    } else return 0;
  };

  constructor(props) {
    super(props);

    const baseSystems = this.tallyBaseSystems();
    const withExtras = this.tallyExtras();

    this.state = {
      billOfRatesItems: [
        {
          description: "Roller Blinds Systems",
          totalm2: baseSystems.totalm2, // ( ALL WIDTHS ) * ( ALL DROPS )
          $m2: baseSystems.$m2, // ( ALL base + markup for all LineItems ) / totalm2
          averagePrice: baseSystems.averagePrice, // Line Item total divided by quantity
          qty: baseSystems.qty, // Total Qty
          total: baseSystems.total // Total price of all line items
        },

        {
          description: "Roller Blinds + Extras",
          totalm2: withExtras.totalm2, // ... SAME BUT WITH EXTRAS ...
          $m2: withExtras.$m2,
          averagePrice: withExtras.averagePrice,
          qty: withExtras.qty,
          total: withExtras.total
        }
      ]
    };
  }

  // Search for 3 digit multiples and add comma:
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  tallyExtras() {
    let withExtras = {
      totalm2: 0,
      $m2: 0,
      averagePrice: 0,
      qty: 0,
      total: 0
    };

    this.props.quote.forEach(lineItem => {
      let price = Math.ceil(
        this.calcPriceWithMarkUp(parseFloat(lineItem.baseCost), parseFloat(lineItem.baseMarkup))
      );

      withExtras.totalm2 += parseFloat(lineItem.squareMetres);
      withExtras.total += price + Math.ceil(this.calcPriceOfExtras(lineItem));
      withExtras.qty++;
    });

    // Calc average
    withExtras.averagePrice = withExtras.total / withExtras.qty;

    // Calc cost per square metre
    withExtras.$m2 = withExtras.total / withExtras.totalm2;

    return withExtras;
  }

  tallyArrayItemsOf(extra) {
    let result = 0;
    extra.forEach(subItem => {
      if ("details" in subItem) {
        let cost = 0;
        if ("cost" in subItem.details) {
          cost = parseFloat(subItem.details.cost);
        } else {
          console.error("No cost found in lineItem extras array");
        }
        let totalCost = this.calcPriceWithMarkUp(cost, parseFloat(subItem.details.markup));
        result += totalCost;
      }
    });
    return result;
  }

  calcPriceOfExtras(lineItem) {
    let result = 0;
    // For each extra that exists
    if (lineItem.extras) {
      // calc cost + markup for extra
      for (var extraType in lineItem.extras) {
        // skip loop if the property is from prototype (meaning its not an object)
        if (!lineItem.extras.hasOwnProperty(extraType)) continue;
        let extra = lineItem.extras[extraType];
        if (extra !== null) {
          // if extra is an array
          if (Array.isArray(extra)) {
            result += this.tallyArrayItemsOf(extra);
          } else if ("details" in extra) {
            // if extra is an object
            let cost = 0;
            if ("cost" in extra.details) {
              cost = parseFloat(extra.details.cost);
            } else if ("costPerMetre" in extra.details) {
              if (lineItem.description && lineItem.description.width) {
                cost =
                  parseFloat(extra.details.costPerMetre) *
                  (parseFloat(lineItem.description.width) / 1000);
              } else
                console.error(
                  "LineItem " + lineItem.itemNo + " has a cost per metre, but no width is provided."
                );
            }
            let totalCost = this.calcPriceWithMarkUp(cost, parseFloat(extra.details.markup));
            result += totalCost;
          }
        }
      }
    }
    return result;
  }

  tallyBaseSystems() {
    let baseSystems = {
      totalm2: 0.0, // ( ALL WIDTHS ) * ( ALL DROPS )
      $m2: 0.0, // ( ALL base + markup for all LineItems ) / totalm2
      qty: 0.0,
      total: 0.0,
      averagePrice: 0.0 // Line Item total divided by quantity
    };

    this.props.quote.forEach(lineItem => {
      let price = this.calcPriceWithMarkUp(
        parseFloat(lineItem.baseCost),
        parseFloat(lineItem.baseMarkup)
      );

      baseSystems.totalm2 += lineItem.squareMetres;
      baseSystems.total += price;
      baseSystems.qty++;
    });

    // Calc cost per square metre
    baseSystems.$m2 = parseFloat(baseSystems.total) / parseFloat(baseSystems.totalm2);

    // Calc average
    baseSystems.averagePrice = parseFloat(baseSystems.total) / parseFloat(baseSystems.qty);

    return baseSystems;
  }

  calcBaseSystemsTotal = () => {};

  calcPriceWithMarkUp(cost, markup) {
    if (!cost) {
      cost = 0;
    }
    let result = parseFloat(cost);
    if (this.props.isRetail) {
      if (parseFloat(markup) > 0) {
        const markupPercent = parseFloat(parseFloat(markup) / 100);
        result += parseFloat(cost) * markupPercent;
      }
    }
    return result;
  }

  render() {
    return (
      <div className="doc-box" style={{ display: this.props.display ? "" : "None" }}>
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="heading">
              <td colSpan="6">
                <span className="heading-one">
                  P R O J E C T &nbsp; Q U O T E &nbsp;-&nbsp; B I L L &nbsp; O F &nbsp; R A T E S
                </span>
              </td>
            </tr>
            <tr className="sub-heading">
              <td>Description</td>
              <td>Total m²</td>
              <td>$/m²</td>
              <td>Average Price</td>
              <td>QTY</td>
              <td>Total*</td>
            </tr>
            {this.state.billOfRatesItems.map((lineItem, key) => {
              return (
                <tr className="details" key={key}>
                  <td>{lineItem.description}</td>
                  <td>{lineItem.totalm2.toFixed(2)}</td>
                  <td>
                    {"$"}
                    {this.numberWithCommas(lineItem.$m2.toFixed(2))}
                  </td>
                  <td>
                    {"$"}
                    {this.numberWithCommas(lineItem.averagePrice.toFixed(2))}
                  </td>
                  <td>{lineItem.qty}</td>
                  <td className="bold">
                    {this.numberWithCommas(Math.ceil(lineItem.total)) === 0
                      ? "included"
                      : `$${this.numberWithCommas(Math.ceil(lineItem.total))}`}
                  </td>
                </tr>
              );
            })}

            <tr className="job-bottom">
              <td colSpan="6">* Does not include GST. Applicable discounts are applied.</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default BlindsBillOfRates;
