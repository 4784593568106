import { createMuiTheme } from "@material-ui/core";

export const Theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2699fb",
      light: "#72c9ff",
      dark: "#006cc7",
      contrastText: "#fff"
    },
    secondary: {
      light: "#ffffff",
      main: "#ffffff",
      dark: "#cccccc",
      contrastText: "#000000"
    }
  }
});
