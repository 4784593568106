import React, { Component } from "react";
import { connect } from "react-redux";
import { setFirstName } from "../../actions/dashboardActions";
import { API } from "aws-amplify";
import "./Dashboard.css";
import Chart from "chart.js";
import PieGraphCard from "./PieGraphCard";
import LineGraphCard from "./LineGraphCard";
import { MDBCardGroup } from "mdbreact";
import algoliasearch from "algoliasearch";
import dummyLineData from "./DummyLineData";
import config from "../../config";
import { Fab, ThemeProvider } from "@material-ui/core";
import { Theme } from "../Generic/Theme";

//icons
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ContactsIcon from "@material-ui/icons/Contacts";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import BuildIcon from "@material-ui/icons/Build";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      commercialDomestic: undefined,
      stage: undefined,
      projectTotal: undefined,
    };

    this.getDomestic();
    this.getProjectStage();
    API.get("telishadeAPI", "/account")
      .then((response) => {
        this.props.dispatch(setFirstName(response.details.firstName));
      })
      .catch((error) => {
        console.error(error);
      });

    this.chartRef = React.createRef();
  }

  async getProjectStage() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getProjectStage();
      }, 500);
    } else {
      try {
        while (!this.props.AlgoliaKey) {}
        const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
        const index = client.initIndex(`${config.algolia.prefix}projects_name_asc`);
        const qsBudget = await index.search({
          query: "",
          filters: `isDeleted:false AND details.stage:qsBudget`,
          hitsPerPage: 1000,
        });
        const preAwardTendering = await index.search({
          query: "",
          filters: `isDeleted:false AND details.stage:preAwardTendering`,
          hitsPerPage: 1000,
        });
        const builderAwardedTendering = await index.search({
          query: "",
          filters: `isDeleted:false AND details.stage:builderAwardedTendering`,
          hitsPerPage: 1000,
        });
        const projectManagement = await index.search({
          query: "",
          filters: `isDeleted:false AND details.stage:projectManagement`,
          hitsPerPage: 1000,
        });
        const practicalCompleteion = await index.search({
          query: "",
          filters: `isDeleted:false AND details.stage:practicalCompleteion`,
          hitsPerPage: 1000,
        });
        const completed = await index.search({
          query: "",
          filters: `isDeleted:false AND details.stage:completed`,
          hitsPerPage: 1000,
        });
        this.setState({
          stage: {
            labels: [
              "QS Budget",
              "Pre-Award Tendering",
              "Builder Awarded Tendering",
              "Project Management",
              "Practical Completion",
              "Completed",
            ],
            datasets: [
              {
                data: [
                  qsBudget.hits.length,
                  preAwardTendering.hits.length,
                  builderAwardedTendering.hits.length,
                  projectManagement.hits.length,
                  practicalCompleteion.hits.length,
                  completed.hits.length,
                ],
                backgroundColor: ["#ff6384", "#58508d", "#003f5c", "#bc5090", "#ff6361", "#ffa600"],
              },
            ],
          },
          projectTotal:
            qsBudget.hits.length +
            preAwardTendering.hits.length +
            builderAwardedTendering.hits.length +
            projectManagement.hits.length +
            practicalCompleteion.hits.length +
            completed.hits.length,
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  async getDomestic() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getDomestic();
      }, 500);
    } else {
      try {
        const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
        const index = client.initIndex(`${config.algolia.prefix}projects_name_asc`);
        const domestic = await index.search({
          query: "",
          filters: "isDeleted:false AND type:domestic",
        });
        const commercial = await index.search({
          query: "",
          filters: "isDeleted:false AND type:commercial",
        });
        this.setState({
          commercialDomestic: {
            labels: ["Commercial", "Domestic"],
            datasets: [
              {
                data: [commercial.hits.length, domestic.hits.length],
                backgroundColor: ["#6200EE", "#03DAC5"],
              },
            ],
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  }

  componentDidMount() {
    if (this.chartRef.current !== null) {
      const myChartRef = this.chartRef.current.getContext("2d");
      new Chart(myChartRef, { type: "doughnut", data: this.state });
    }
  }

  handleFabClick = (redirect) => {
    this.props.history.push(redirect);
  };

  render() {
    return (
      <div id="Dashboard">
        <div className="greeting">
          <h4>
            {this.props.dashboard.tenantDetails.firstName !== ""
              ? `Welcome, ${this.props.dashboard.tenantDetails.firstName}`
              : ""}
          </h4>
        </div>
        <div className="dashboardFabRow">
          <ThemeProvider theme={Theme}>
            <Fab color="primary" variant="extended" onClick={() => this.handleFabClick("/projects/add")}>
              Add Project
              <AccountTreeIcon />
            </Fab>
            <Fab color="primary" variant="extended" onClick={() => this.handleFabClick("/customers/add")}>
              Add Customer
              <ContactsIcon />
            </Fab>
            <Fab color="primary" variant="extended" onClick={() => this.handleFabClick("/staff/add")}>
              Add Staff
              <SupervisorAccountIcon />
            </Fab>
            <Fab color="primary" variant="extended" onClick={() => this.handleFabClick("/manufacturers/add")}>
              Add Manufacturer
              <BuildIcon />
            </Fab>
            <Fab color="secondary" variant="extended" onClick={() => this.handleFabClick("/projects")}>
              Go To Projects
            </Fab>
            <Fab color="secondary" variant="extended" onClick={() => this.handleFabClick("/products")}>
              Go To Products
            </Fab>
          </ThemeProvider>
        </div>
        <MDBCardGroup deck>
          <PieGraphCard
            title={this.state.projectTotal ? `Projects (${this.state.projectTotal})` : ""}
            id="Pie1"
            data={this.state.stage}
          />
          <PieGraphCard title="Commercial VS Domestic" id="Pie1" data={this.state.commercialDomestic} />
          <LineGraphCard title="Average Project Duration (Weeks)" id="Line1" data={dummyLineData} />
        </MDBCardGroup>
        {/* <MDBCardGroup deck>
          <BarGraphCard title="Average Project Duration (Weeks)" id="Line1" data={dummyLineData} />
        </MDBCardGroup> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  account: state.account,
  dashboard: state.dashboard,
  AlgoliaKey: state.key,
});

export default connect(mapStateToProps)(Dashboard);
