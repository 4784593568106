import React, { Component } from "react";
import { connect } from "react-redux";
import {
  setCurrentComponent,
  setCurrentProduct,
  selectID
} from "../../../../actions/productsActions";
import SearchableList from "../../../Generic/Algolia/SearchableList";
import config from "../../../../config";

class CurtainDriveMechanism extends Component {
  HitComponent = ({ hit }) => {
    const listItemClickHandler = () => {
      this.props.history.push("/products/curtains/" + hit.type + "/" + hit.objectID);
      this.props.dispatch(selectID(hit.objectID));
    };

    let className = "";
    if (this.props.id === hit.objectID) className = { className: "selected" };
    return (
      <p {...className} onClick={listItemClickHandler}>
        <strong>{hit.details.name}</strong>
        {hit.type === "drive-mechanism-manual" ? (
          <span>Manual</span>
        ) : hit.type === "drive-mechanism-motorised" ? (
          <span>Motorised</span>
        ) : (
          ""
        )}
      </p>
    );
  };

  clickHandler = clickedItem => {
    const currentURL = this.props.history.location.pathname;
    this.props.history.push(currentURL + "/" + clickedItem.itemId);
    this.props.dispatch(selectID(clickedItem.itemId));
  };

  componentDidMount() {
    this.props.dispatch(setCurrentProduct("Curtains"));
    this.props.dispatch(setCurrentComponent("Drive Mechanism"));
  }

  render() {
    return (
      <SearchableList
        appId={`${config.algolia.appId}`}
        index={`${config.algolia.prefix}products_components_name_asc`}
        hitComponent={this.HitComponent}
        selectedObjectId={this.props.id}
        filters={
          "isDeleted:false AND (type:drive-mechanism-manual OR type:drive-mechanism-motorised OR type:drive-mechanism-link-system) AND productId:" +
          this.props.currentProductID
        }
      />
    );
  }
}

const mapStateToProps = state => {
  return state.products;
};

export default connect(mapStateToProps)(CurtainDriveMechanism);
