import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { getFromAPI, putToAPI } from "../../../../../../utilities/utils";
import { MDBBtn } from "mdbreact";
import {
  toggleDiscountView,
  storeBlindJobQuotePayload,
  storeCurtainJobQuotePayload,
} from "../../../../../../actions/documentsActions";
import { setLoaderState } from "../../../../../../actions/loaderActions";
import {
  getGrossProfit,
  getProfitMargin,
  getDiscountedPrice,
  calcPriceWithMarkUp,
  calcPricePerMetrage,
  searchObj,
} from "../../../../../../utilities/utils";
import ComponentType from "./ComponentType";
import { setJobDetails } from "../../../../../../actions/projectsActions";

class DiscountView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      components: [],
      fields: {},
      jobDetails: {},

      config: {
        tableCellWidth: "8",
        readyToRender: false,
      },
    };
    this._isMounted = false;
    this.initialiseState();
  }
  async initialiseState() {
    let components = [];
    let fields = {};
    try {
      const { jobDetails } = this.props.projects;
      if (jobDetails !== undefined && jobDetails.id !== undefined) {
        let response = await this.getJobDiscountsFromAPI();
        components = this.mapDiscountsToComponents(response);
        await this.addDetailsFromQuote(components);
        fields = this.setFieldValues(components);

        if (this._isMounted) {
          this.setState({
            jobDetails: response.details,
            components,
            fields,
            config: { ...this.state.config, readyToRender: true },
          });
        }
      } else {
        setTimeout(() => {
          this.initialiseState();
        }, 500);
      }
    } catch (err) {
      console.error(err);
    } finally {
      this.props.dispatch(setLoaderState(false));
    }
  }

  // if wrong details in redux, perform new Quote GET for blind/curtain
  async validateReduxDetails() {
    if (
      this.props.documents.blindJobQuote === undefined ||
      this.props.documents.blindJobQuote.id !== this.props.projects.jobDetails.id
    ) {
      try {
        let response = await getFromAPI(
          `/project/${this.props.projects.projectDetails.type}/${this.props.projects.projectDetails.id}/job/${this.props.projects.jobDetails.id}/quote/blind`
        );
        await this.props.dispatch(storeBlindJobQuotePayload(response));
      } catch (e) {
        console.error(e);
      }
    }
    if (
      this.props.documents.curtainJobQuote === undefined ||
      this.props.documents.curtainJobQuote.id !== this.props.projects.jobDetails.id
    ) {
      // perform new Quote get
      try {
        let response = await getFromAPI(
          `/project/${this.props.projects.projectDetails.type}/${this.props.projects.projectDetails.id}/job/${this.props.projects.jobDetails.id}/quote/curtain`
        );
        await this.props.dispatch(storeCurtainJobQuotePayload(response));
      } catch (e) {
        console.error(e);
      }
    }
  }

  async addDetailsFromQuote(components) {
    await this.validateReduxDetails();

    let { quote } = this.props.documents.blindJobQuote;
    let curtainQuote = this.props.documents.curtainJobQuote.quote;
    let componentDetails = [];
    let keys = Object.keys(components);

    // NOTE: allComponents array is an array of references to the passed in components, changes
    //       made here will be reflected in the components object (back in initialiseState())
    let allComponents = [];

    keys.forEach((key) => {
      components[key].forEach((component) => {
        allComponents.push(component);
      });
    });

    keys = Object.keys(allComponents);
    keys.forEach((key) => {
      // ADD DETAILS FROM BLIND QUOTE
      quote.forEach((lineItem) => {
        if (lineItem.description?.allDetails) {
          let idKey = searchObj(lineItem.description.allDetails, allComponents[key].id);

          if (idKey !== undefined) {
            if (idKey === "fabricId") {
              this.getFabric("fabric", lineItem, allComponents[key]);
            }
            if (idKey === "driveMechanismId") {
              this.getDetail("driveMechanism", lineItem, allComponents[key]);
            }
            if (idKey === "doubleBracketSystemId") {
              this.getDetail("doubleBracket", lineItem, allComponents[key]);
            }
            if (idKey === "linkSystemId") {
              this.getDetail("linking", lineItem, allComponents[key]);
            }
            if (idKey === "sideChannelId") {
              this.getDetail("sideChannel", lineItem, allComponents[key]);
            }
            if (idKey === "kassetteId") {
              this.getDetail("kassette", lineItem, allComponents[key]);
            }
            if (idKey === "baseBarId") {
              this.getDetail("baseBar", lineItem, allComponents[key]);
            }
            if (idKey === "accessoryId") {
              this.getDetail("accessory", lineItem, allComponents[key]);
            }
          } else {
            if (lineItem.extras?.services && lineItem.extras.services.length > 0) {
              lineItem.extras.services.forEach((service) => {
                if (service.id === allComponents[key].id) {
                  this.getServiceDetail(service, allComponents[key]);
                }
              });
            }
          }
        }
      });

      // ADD DETAILS FROM CURTAIN QUOTE
      curtainQuote.forEach((lineItem) => {
        if (lineItem.description?.allDetails) {
          let idKey = searchObj(lineItem.description.allDetails, allComponents[key].id);

          if (idKey !== undefined) {
            if (idKey === "fabricId") {
              this.getCurtainFabricDetail("fabric", lineItem, allComponents[key]);
            }
            if (idKey === "bumphId") {
              this.getCurtainExtraDetail("bumph", lineItem, allComponents[key]);
            }
            if (idKey === "driveMechanismId") {
              this.getDetail("driveMechanism", lineItem, allComponents[key]);
            }
            if (idKey === "leadWeightId") {
              this.getCurtainExtraDetail("leadWeight", lineItem, allComponents[key]);
            }
            if (idKey === "liningId") {
              this.getCurtainExtraDetail("lining", lineItem, allComponents[key]);
            }
            if (idKey === "pelmetId") {
              this.getCurtainExtraDetail("pelmet", lineItem, allComponents[key]);
            }
            if (idKey === "trackId") {
              this.getTrackDetail("track", lineItem, allComponents[key]);
            }
            if (idKey === "accessoryId") {
              this.getCurtainExtraDetail("accessory", lineItem, allComponents[key]);
            }
          } else {
            if (lineItem.extras?.services && lineItem.extras.services.length > 0) {
              lineItem.extras.services.forEach((service) => {
                if (service.id === allComponents[key].id) {
                  this.getServiceDetail(service, allComponents[key]);
                }
              });
            }
          }
        }
      });
    });
    return componentDetails;
  }

  getServiceDetail(service, component) {
    component.name = service.details.name;
    component.qty += 1;
    component.cost += parseFloat(service.details.cost);
    component.price += calcPriceWithMarkUp(parseFloat(service.details.cost), parseFloat(service.details.markup) || 0);
  }

  getCurtainExtraDetail(objId, lineItem, component) {
    if (lineItem.extras[objId]) {
      let cost = 0;
      let price = 0;

      if (lineItem.extras[objId].details?.pricePerMetre) {
        cost = calcPricePerMetrage(
          lineItem.extras[objId].details.pricePerMetre || 0,
          lineItem.extras[objId].details.fabricWidth
            ? lineItem.extras[objId].details.fabricWidth
            : lineItem.description?.width
            ? lineItem.description?.width
            : 0
        );
        price = calcPriceWithMarkUp(cost, lineItem.extras[objId].details?.markup || 0);
      } else if (lineItem.extras[objId].details?.cost) {
        cost += parseFloat(lineItem.extras[objId].details.cost);
        price += calcPriceWithMarkUp(lineItem.extras[objId].details.cost || 0, lineItem.extras[objId].details.markup || 0);
      } else {
        // console.throw(e);
      }

      component.name = lineItem.extras[objId].details?.name || "...";
      component.qty += 1;
      component.cost += cost;
      component.price += price;
    }
  }

  getDetail(objId, lineItem, component) {
    if (lineItem.extras[objId]) {
      let cost = 0;
      let price = 0;

      if (lineItem.extras[objId].details?.costPerMetre) {
        cost = calcPricePerMetrage(lineItem.extras[objId].details?.costPerMetre || 0, lineItem.description?.width || 0);
        price = calcPriceWithMarkUp(cost, lineItem.extras[objId].details?.markup || 0);
      } else if (lineItem.extras[objId].details?.cost) {
        cost += parseFloat(lineItem.extras[objId].details.cost);
        price += calcPriceWithMarkUp(lineItem.extras[objId].details.cost || 0, lineItem.extras[objId].details.markup || 0);
      } else {
        // console.throw(e);
      }

      component.name = lineItem.extras[objId].details?.name || "...";
      component.qty += 1;
      component.cost += cost;
      component.price += price;
    } else {
      console.error("Error trying to access", objId);
    }
  }

  getCurtainFabricDetail(objId, lineItem, component) {
    component.name = lineItem.description[objId] || "...";
    component.qty = parseFloat(component.qty) + 1;
    component.cost += parseFloat(lineItem.costInfo.fabricCost);
    component.price += parseFloat(lineItem.costInfo.markedUpFabricCost);
  }

  getTrackDetail(objId, lineItem, component) {
    component.name = lineItem.description[objId] || "...";
    component.qty = parseFloat(component.qty) + 1;
    component.cost += parseFloat(lineItem.costInfo.trackTotalCost);
    component.price += parseFloat(lineItem.costInfo.markedUpTrackTotalCost);
  }

  getFabric(objId, lineItem, component) {
    component.name = lineItem.description[objId] || "...";
    component.qty = parseFloat(component.qty) + 1;
    component.cost += parseFloat(lineItem.baseCost);
    component.price += calcPriceWithMarkUp(parseFloat(lineItem.baseCost), parseFloat(lineItem.baseMarkup));
  }

  getJobDiscountsFromAPI = async () => {
    const { projectDetails, jobDetails } = this.props.projects;
    if (jobDetails) {
      if (this._isMounted) {
        this.setState({
          jobDetails: jobDetails.details,
        });
      }
      return jobDetails;
    } else {
      let response = await getFromAPI(`/project/${projectDetails.type}/${projectDetails.id}/job/${jobDetails.id}`);
      if (this._isMounted) {
        this.setState({
          jobDetails: response.details,
        });
      }
      return response;
    }
  };

  mapDiscountsToComponents(response) {
    let components = [];
    components["Fabrics"] = this.getComponent(response, "fabric");
    components["Manual Drive Mechanisms"] = this.getComponent(response, "drive-mechanism-manual");
    components["Motorised Drive Mechanisms"] = this.getComponent(response, "drive-mechanism-motorised");
    components["Double Brackets"] = this.getComponent(response, "double-bracket-system");
    components["Linking Systems"] = this.getComponent(response, "drive-mechanism-link-system");
    components["Side Channels"] = this.getComponent(response, "side-channel");
    components["Kassettes"] = this.getComponent(response, "kassette");
    components["Base Bars"] = this.getComponent(response, "base-bar");
    components["Tracks"] = this.getComponent(response, "track");
    // components["Tapes"] = this.getComponent(response, "tape");
    // components["Runners"] = this.getComponent(response, "runner");
    components["Bumphs"] = this.getComponent(response, "bumph");
    components["Lead Weights"] = this.getComponent(response, "lead-weight");
    components["Linings"] = this.getComponent(response, "lining");
    components["Pelmets"] = this.getComponent(response, "pelmet");
    components["Accessories"] = this.getComponent(response, "accessory");
    components["Services"] = this.getServices(response, "servicesAndFees");

    return components;
  }

  getServices(response, type) {
    return response.details.options.discount.servicesAndFees.map(function (service) {
      return {
        type: "service",
        id: service.id,
        name: "...",
        manufacturer: "-",
        qty: 0,
        margin: 0,
        cost: 0,
        price: 0,
        discount: {
          retail: service.retail.percentage,
          wholesale: service.wholesale.percentage,
          number: "-",
        },
      };
    });
  }

  getComponent(response, type) {
    // Iterate through discounts, push relative ones into array
    let items = response.details.options.discount.components
      .filter((component) => component.type === type)
      .map(function (component) {
        return {
          type: component.type,
          id: component.id,
          name: "...",
          manufacturer: "-",
          qty: 0,
          margin: 0,
          cost: 0,
          price: 0,
          discount: {
            retail: component.retail.percentage,
            wholesale: component.wholesale.percentage,
            number: "-",
          },
        };
      });
    return items;
  }

  setFieldValues(componentCollection) {
    let fields = {};
    let keys = Object.keys(componentCollection);
    keys.forEach((key) => {
      componentCollection[key].forEach((component) => {
        const wholesalePrice = getDiscountedPrice(component.cost, component.discount.wholesale);
        const retailPrice = getDiscountedPrice(component.price, component.discount.retail);
        const grossProfit = getGrossProfit(wholesalePrice, retailPrice);
        const margin = getProfitMargin(wholesalePrice, retailPrice);

        fields = {
          ...fields,
          [`${component.type}-${component.id}-retail`]: component.discount.retail,
          [`${component.type}-${component.id}-wholesale`]: component.discount.wholesale,
          [`${component.type}-${component.id}-cost`]: component.cost,
          [`${component.type}-${component.id}-price`]: component.price,
          [`${component.type}-${component.id}-grossProfit`]: grossProfit,
          [`${component.type}-${component.id}-margin`]: margin,
          [`${component.type}-${component.id}-discountNumber`]: component.discount.number,
        };
      });
    });
    return fields;
  }

  handleSubmit = async (event) => {
    this.props.dispatch(setLoaderState(true));
    const { projectDetails, jobDetails } = this.props.projects;
    const components = this.mapComponentsToDiscounts();
    const servicesAndFees = this.mapServicesToDiscounts();

    const details = {
      ...this.state.jobDetails,
      options: {
        ...this.state.jobDetails.options,
        discount: {
          ...this.state.jobDetails.options.discount,
          components: components,
          servicesAndFees: servicesAndFees,
        },
      },
    };

    try {
      const response = await putToAPI(`/project/${projectDetails.type}/${projectDetails.id}/job/${jobDetails.id}`, {
        details,
      });
      this.props.dispatch(setJobDetails(response));
      this.props.dispatch(toggleDiscountView(false));
    } catch (e) {
      console.error("!! Error with Form Submission: ", e);
      this.setState({ failed: true, error: e });
    } finally {
      this.props.dispatch(setLoaderState(false));
    }
  };

  mapComponentsToDiscounts() {
    const fields = this.state.fields;
    const discountComponents = [];
    let keys = Object.keys(this.state.components);

    keys.forEach((key) => {
      this.state.components[key].forEach((subComponent) => {
        if (subComponent.type !== "service") {
          discountComponents.push({
            id: subComponent.id,
            type: subComponent.type,
            retail: {
              percentage: parseFloat(fields[`${subComponent.type}-${subComponent.id}-retail`]),
            },
            wholesale: {
              percentage: parseFloat(fields[`${subComponent.type}-${subComponent.id}-wholesale`]),
            },
          });
        }
      });
    });
    return discountComponents;
  }

  mapServicesToDiscounts() {
    const fields = this.state.fields;
    const discountServices = [];
    let keys = Object.keys(this.state.components);

    keys.forEach((key) => {
      this.state.components[key].forEach((subComponent) => {
        if (subComponent.type === "service") {
          discountServices.push({
            id: subComponent.id,
            retail: {
              percentage: parseFloat(fields[`${subComponent.type}-${subComponent.id}-retail`]),
            },
            wholesale: {
              percentage: parseFloat(fields[`${subComponent.type}-${subComponent.id}-wholesale`]),
            },
          });
        }
      });
    });
    return discountServices;
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  recalculateRow = (type, title, key, fields, item, value) => {
    let id = `${title}-${key}`;

    if (!isNaN(value) || value === "") {
      if (value === "") {
        value = 0;
      }

      let retailDiscount = fields[`${id}-retail`];
      let wholesaleDiscount = fields[`${id}-wholesale`];

      if (type === "retail") {
        retailDiscount = parseFloat(value);
      } else if (type === "wholesale") {
        wholesaleDiscount = parseFloat(value);
      }

      let newRetailPrice = getDiscountedPrice(item.price, retailDiscount);
      let newWholesalePrice = getDiscountedPrice(item.cost, wholesaleDiscount);

      let newMargin = getProfitMargin(newWholesalePrice, newRetailPrice);
      let grossProfit = getGrossProfit(newWholesalePrice, newRetailPrice);

      if (this._isMounted) {
        this.setState({
          fields: {
            ...this.state.fields,
            [`${id}-retail`]: retailDiscount,
            [`${id}-wholesale`]: wholesaleDiscount,
            [`${id}-grossProfit`]: grossProfit,
            [`${id}-margin`]: newMargin,
          },
        });
      }
    }
  };

  setValue = (id, value) => {
    if (this._isMounted) {
      this.setState({
        fields: { ...this.state.fields, [id]: value },
      });
    }
  };

  getComponentsForDisplay() {
    let keys = Object.keys(this.state.components);
    const rows = keys.map((key) =>
      this.state.components[key].length > 0 ? (
        <ComponentType
          key={key}
          title={key}
          fields={this.state.fields}
          setValue={this.setValue}
          recalculateRow={this.recalculateRow}
          config={this.state.config}
          rows={this.state.components[key]}
        />
      ) : (
        <Fragment key={key} />
      )
    );
    return rows;
  }

  render() {
    let { config } = this.state;

    return config.readyToRender ? (
      <div id="discount-view-window">
        <form noValidate>
          {this.getComponentsForDisplay()}
          <MDBBtn
            color="blue"
            id="discount-view-cancel-button"
            onClick={() => this.props.dispatch(toggleDiscountView(false))}
          >
            CANCEL
          </MDBBtn>
          <MDBBtn color="red" id="discount-view-save-button" onClick={() => this.handleSubmit()}>
            SAVE
          </MDBBtn>
        </form>
      </div>
    ) : (
      <div className="loader">LOADING...</div>
    );
  }
}

const mapStatetoProps = (state) => {
  return { projects: state.projects, documents: state.documents };
};

export default connect(mapStatetoProps)(DiscountView);
