import React, { Component } from "react";
import Doughtnut from "chart.js";
import { MDBCard, MDBCardBody, MDBCardTitle } from "mdbreact";

let myPieChart;
//--Chart Style Options--//
Doughtnut.defaults.global.defaultFontFamily = "'PT Sans', sans-serif";
Doughtnut.defaults.global.legend.display = true;
//--Chart Style Options--//

export default class PieGraphCard extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    if (this.props.data && this.chartRef) this.buildChart();
  }

  componentDidUpdate(nextProps) {
    if (this.props.data !== nextProps.data && this.chartRef) this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    const { data, options } = this.props;
    myPieChart = new Doughtnut(myChartRef, {
      type: "doughnut",
      data: data,
      options: options
    });

    if (typeof myPieChart == undefined) {
      myPieChart.destroy();
    }
  };

  render() {
    if (this.props.data) {
      return (
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>{this.props.title}</MDBCardTitle>
            <canvas id={this.props.id} ref={this.chartRef} />
          </MDBCardBody>
        </MDBCard>
      );
    } else {
      return (
        <MDBCard>
          <MDBCardBody>
            <MDBCardTitle>{this.props.title}</MDBCardTitle>
            <div className="centered-loader">
              <div className="loader">LOADING...</div>
            </div>
          </MDBCardBody>
        </MDBCard>
      );
    }
  }
}
