import React, { Fragment, Component } from "react";
import { Route, Switch } from "react-router-dom";
import CreateBlindStyle from "./CreateBlindStyle";
import CreateBlindPricingGroup from "./CreateBlindPricingGroup";
import CreateBlindFabric from "./CreateBlindFabric";
import CreateDriveMechanism from "./CreateDriveMechanism";
import CreateBlindKassette from "./CreateBlindKassette";
import CreateBlindSideChannel from "./CreateBlindSideChannel";
import CreateBlindBaseBar from "./CreateBlindBaseBar";
import CreateBlindAccessory from "./CreateBlindAccessory";
import CreateBlindDoubleBracket from "./CreateBlindDoubleBracket";
import CreateBlindService from "./CreateBlindService";
import CreateBlindColourList from "./CreateBlindColourList";

class CreateBlindComponentRoutes extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route path="/products/blinds/style/add" component={CreateBlindStyle} />
          <Route exact path="/products/blinds/fabric/add" component={CreateBlindFabric} />
          <Route
            exact
            path="/products/blinds/pricing-groups/add"
            component={CreateBlindPricingGroup}
          />
          <Route
            exact
            path="/products/blinds/drive-mechanism/add"
            component={CreateDriveMechanism}
          />
          <Route exact path="/products/blinds/kassette/add" component={CreateBlindKassette} />
          <Route
            exact
            path="/products/blinds/side-channel/add"
            component={CreateBlindSideChannel}
          />
          <Route exact path="/products/blinds/base-bar/add" component={CreateBlindBaseBar} />
          <Route exact path="/products/blinds/accessory/add" component={CreateBlindAccessory} />
          <Route
            exact
            path="/products/blinds/double-bracket-system/add"
            component={CreateBlindDoubleBracket}
          />
          <Route exact path="/products/blinds/service-and-fee/add" component={CreateBlindService} />
          <Route exact path="/products/blinds/colour-list/add" component={CreateBlindColourList} />
        </Switch>
      </Fragment>
    );
  }
}

export default CreateBlindComponentRoutes;
