import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";

//Font Awesome Icon
import AddFAB from "../../Generic/Images/AddFab.png";

import CurtainStyle from "./Components/CurtainStyle";
import CurtainFabric from "./Components/CurtainFabric";
import CurtainDriveMechanism from "./Components/CurtainDriveMechanism";
import CurtainBumph from "./Components/CurtainBumph";
import CurtainLining from "./Components/CurtainLining";
import CurtainPelmet from "./Components/CurtainPelmet";
import CurtainTrack from "./Components/CurtainTrack";
import CurtainLeadWeight from "./Components/CurtainLeadWeight";
import CurtainFabricSurcharge from "./Components/CurtainFabricSurcharge";
import CurtainPriceGroup from "./Components/CurtainPriceGroup";
import CurtainService from "./Components/CurtainService";
import CurtainTape from "./Components/CurtainTape";
import CurtainRunner from "./Components/CurtainRunner";
import CurtainColourLists from "./Components/CurtainColourLists";
import CurtainAccessories from "./Components/CurtainAccessories";

class CurtainListView extends Component {
  handleAddClick = () => {
    if (this.props.currentComponent === "Style")
      this.props.history.push("/products/curtains/style/add");
    if (this.props.currentComponent === "Fabric")
      this.props.history.push("/products/curtains/fabric/add");
    if (this.props.currentComponent === "Tape")
      this.props.history.push("/products/curtains/tape/add");
    if (this.props.currentComponent === "Runner")
      this.props.history.push("/products/curtains/runner/add");
    if (this.props.currentComponent === "Drive Mechanism")
      this.props.history.push("/products/curtains/drive-mechanism/add");
    if (this.props.currentComponent === "Pricing Group")
      this.props.history.push("/products/curtains/pricing-group/add");
    if (this.props.currentComponent === "Bumph")
      this.props.history.push("/products/curtains/bumph/add");
    if (this.props.currentComponent === "Lining")
      this.props.history.push("/products/curtains/lining/add");
    if (this.props.currentComponent === "Pelmet")
      this.props.history.push("/products/curtains/pelmet/add");
    if (this.props.currentComponent === "Track")
      this.props.history.push("/products/curtains/track/add");
    if (this.props.currentComponent === "Lead Weight")
      this.props.history.push("/products/curtains/lead-weight/add");
    if (this.props.currentComponent === "Fabric Surcharge")
      this.props.history.push("/products/curtains/fabric-surcharge/add");
    if (this.props.currentComponent === "Services and Fees")
      this.props.history.push("/products/curtains/service-and-fee/add");
    if (this.props.currentComponent === "Colour Lists")
      this.props.history.push("/products/curtains/colour-list/add");
    if (this.props.currentComponent === "Accessory")
      this.props.history.push("/products/curtains/accessory/add");
  };

  render() {
    return (
      <Fragment>
        <div className="productComponentHeader">
          <h2>{this.props.currentComponent}</h2>
          <img id="plusCircle" alt="Add" src={AddFAB} onClick={this.handleAddClick} />
        </div>
        <div className="productList">
          <Switch>
            <Route path="/products/curtains/style" component={CurtainStyle} />
            <Route path="/products/curtains/fabric" component={CurtainFabric} />
            <Route path="/products/curtains/drive-mechanism" component={CurtainDriveMechanism} />
            <Route path="/products/curtains/pricing-group" component={CurtainPriceGroup} />
            <Route path="/products/curtains/bumph" component={CurtainBumph} />
            <Route path="/products/curtains/lining" component={CurtainLining} />
            <Route path="/products/curtains/pelmet" component={CurtainPelmet} />
            <Route path="/products/curtains/track" component={CurtainTrack} />
            <Route path="/products/curtains/lead-weight" component={CurtainLeadWeight} />
            <Route path="/products/curtains/fabric-surcharge" component={CurtainFabricSurcharge} />
            <Route path="/products/curtains/service-and-fee" component={CurtainService} />
            <Route path="/products/curtains/tape" component={CurtainTape} />
            <Route path="/products/curtains/runner" component={CurtainRunner} />
            <Route path="/products/curtains/accessory" component={CurtainAccessories} />
            <Route path="/products/curtains/colour-list" component={CurtainColourLists} />
            <Redirect to="/products/curtains/style" />
          </Switch>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state.products;
};

export default withRouter(connect(mapStateToProps)(CurtainListView));
