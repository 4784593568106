import React, { Component } from "react";

class QuoteNotes extends Component {
  constructQuotesNotes(jobArray) {
    let itemNotesCollection = [];
    jobArray.forEach((job, index) => {
      itemNotesCollection.push({ jobName: job.name, notes: [] });
      job.quote.forEach(quote => {
        if (quote.description.allDetails.options && quote.description.allDetails.options.note) {
          const itemNo = quote.description.allDetails.itemNo;
          const note = quote.description.allDetails.options.note;
          itemNotesCollection[index].notes.push({ note, itemNo });
        }
      });
    });
    let notesString = "";
    itemNotesCollection.forEach(job => {
      if (job.notes.length > 0) {
        notesString += `${job.jobName} Notes:\n`;
        job.notes.forEach(note => {
          notesString += `Item Number ${note.itemNo}: ${note.note}\n`;
        });
      }
    });
    return notesString;
  }

  render() {
    if (!this.props.display) return "";
    else
      return (
        <div className="doc-box page-break-after">
          <table cellPadding="0" cellSpacing="0" className="totals-table">
            <tbody>
              <tr className="terms-heading">
                <td>Notes</td>
              </tr>
              <tr>
                <td style={{ whiteSpace: "pre-wrap" }} className="term-details">
                  {this.constructQuotesNotes(this.props.response)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
  }
}

export default QuoteNotes;
