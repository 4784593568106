import React, { Component } from "react";
import { connect } from "react-redux";

import "./TabContainer.css";

class TabContainer extends Component {
  renderTabBar = () => {
    let tabList = [];
    for (let i = 0; i < this.props.children.length; i++) {
      tabList.push(this.props.children[i].props.text);
    }
    return tabList.map((tabText, key) => {
      return (
        <li
          key={key}
          className={this.props.selected === tabText ? "selected" : ""}
          onClick={() => this.props.clickHandler(tabText)}
        >
          {tabText}
        </li>
      );
    });
  };

  renderTab = selectedTabText => {
    const selectedTab = selectedTabText !== undefined ? selectedTabText : null;
    for (let i = 0; i < this.props.children.length; i++) {
      if (selectedTab === this.props.children[i].props.text) {
        return this.props.children[i];
      }
    }
  };

  render() {
    return (
      <div className={`tab-container ${this.props.className}`}>
        <ul className="tab-bar">{this.renderTabBar()}</ul>
        {this.renderTab(this.props.selected)}
      </div>
    );
  }
}

export default connect()(TabContainer);
