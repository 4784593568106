import React from "react";
import { NavLink } from "react-router-dom";
const BlindComponentList = () => {
  return (
    <ul>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/blinds/style">
          Style
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/blinds/fabric">
          Fabric
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/blinds/drive-mechanism">
          Drive Mechanisms
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/blinds/pricing-groups">
          Pricing Groups
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/blinds/kassette">
          Kassettes
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/blinds/side-channel">
          Side Channels
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/blinds/base-bar">
          Base Bars
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/blinds/accessory">
          Accessories
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/blinds/double-bracket-system">
          Double Bracket Systems
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/blinds/service-and-fee">
          Services and Fees
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="sideNavSelected" to="/products/blinds/colour-list">
          Colour Lists
        </NavLink>
      </li>
    </ul>
  );
};

export default BlindComponentList;
