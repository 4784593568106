import React, { Component } from "react";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";
// import { logout } from "../actions/authenticationActions";

//MDB
import { MDBBtn } from "mdbreact";

class SignUp extends Component {
  constructor(props) {
    super(props);

    // Using just regular React state for the login page...
    // don't see a reason to use redux store
    this.state = {
      email: "",
      password: "",
      password2: "",
      error: null
    };
  }

  handleChange = event => {
    // Handles editing of the form
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (
      this.state.password === this.state.password2 &&
      event.target.checkValidity()
    ) {
      try {
        await Auth.signUp({
          username: this.state.email,
          password: this.state.password
        });
        this.setState({
          error: `Confirmation code sent to ${this.state.email}`
        });
        // Redirect after 4 seconds to allow message to show... not the best UX
        setTimeout(() => {
          this.props.history.push("/sign-up-confirm");
        }, 4000);
      } catch (e) {
        if (e.code === "UsernameExistsException") {
          // Try resend verification email
          try {
            await Auth.resendSignUp(this.state.email);
            this.setState({
              error: `Confirmation code re-sent to ${this.state.email}`
            });
            // Redirect after 4 seconds to allow message to show... not the best UX
            setTimeout(() => {
              this.props.history.push("/sign-up-confirm");
            }, 4000);
          } catch (e2) {
            if (
              e2.code === "InvalidParameterException" &&
              e2.message === "User is already confirmed."
            ) {
              this.setState({
                error: `A user with email ${this.state.email} already exists`
              });
            } else {
              // Something else went wrong
              console.error(e);
              this.setState({
                error: "Unable to sign up, please try again later"
              });
            }
          }
        } else {
          // Something else went wrong...
          console.error(e);
          this.setState({
            error: "Unable to sign up, please try again later"
          });
        }
      }
    } else {
      this.setState({ error: "Passwords Do Not Match" });
    }
  };

  render() {
    // TODO: Change form elements to use what has been already used,
    // or change other forms to use react-bootstrap like this
    return (
      <div className="loginContainer">
        <div className="filler">
          <h1>Telishade</h1>
        </div>
        <div className="Login card">
          <form
            onSubmit={this.handleSubmit}
            className="needs-validation login-form"
            noValidate
          >
            <div className={this.state.error ? "loginError" : "loginErrorNone"}>
              {this.state.error}
            </div>
            <div className="formRow">
              <label>Email</label>
              <input
                id="email"
                placeholder="Enter Email Address"
                className="form-control"
                value={this.state.email}
                onChange={this.handleChange}
                autoComplete="username"
                type="email"
                required
              />
              <div className="invalid-tooltip">
                Please provide a valid email.
              </div>
            </div>
            <div className="formRow">
              <label>Password</label>
              <input
                id="password"
                placeholder="Enter Password"
                className="form-control"
                autoComplete="password"
                value={this.state.password}
                onChange={this.handleChange}
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$"
                type="password"
                required
              />
              <div className="invalid-tooltip">
                Password must contain at least one uppercase letter,
                <br /> one lowercase letter, one number and one special
                character.
              </div>
            </div>
            <div className="formRow">
              <label>Confirm Password</label>
              <input
                id="password2"
                placeholder="Confirm Password"
                className="form-control"
                autoComplete="password"
                value={this.state.password2}
                onChange={this.handleChange}
                type="password"
                required
              />
              <div className="invalid-tooltip">Please Enter Password.</div>
            </div>
            <div className="loginBtn">
              <MDBBtn color="blue" type="submit">
                Sign Up
              </MDBBtn>
              <a className="forgotPWLink" href="/login">
                Login
              </a>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  authentication: state.authentication
});
export default connect(mapStateToProps)(SignUp);
