import config from "../../config";

// VALIDATION TYPES, these map to Input type to a Pop Up Form

const projectValidationTypes = [
  {
    id: "projectName",
    label: "Project Name",
    type: "text",
    tooltip: "Please Enter Project Name",
    required: true,
  },
  {
    id: "projectStage",
    label: "Project Stage",
    type: "select",
    tooltip: "Please Enter Last Name",
    required: true,
    options: [
      { name: "Stage 1", value: "1" },
      { name: "Stage 2", value: "2" },
    ],
  },
  {
    id: "projectStartDate",
    label: "Project Start Date",
    type: "date",
    required: true,
  },
  {
    id: "projectEstimatedDeliveryDate",
    label: "Project Estimated Delivery Date",
    type: "date",
    required: true,
  },
  {
    id: "projectStatus",
    label: "Project Status",
    type: "select",
    tooltip: "Please Enter Last Name",
    required: true,
    options: [
      { name: "Status 1", value: "1" },
      { name: "Status 2", value: "2" },
    ],
  },
  {
    id: "projectEmail",
    label: "Project Email",
    type: "email",
    tooltip: "Please Enter Project Email",
    required: true,
  },
  {
    id: "projectQuoteDate",
    label: "Project Quote Date",
    type: "date",
    required: true,
  },
  {
    id: "projectAddress",
    label: "Project Address",
    type: "text",
    tooltip: "Please Enter Project Address",
    required: true,
  },
  {
    id: "projectProjectManager",
    label: "Project Manager",
    type: "text",
    tooltip: "Please Enter Project Manager",
    required: true,
  },
  {
    id: "projectPhone",
    label: "Phone Number",
    type: "tel",
    tooltip: "Please Enter Valid Phone Number",
    required: true,
    pattern:
      "(^1300\\d{6}$)|(^1800|1900|1902\\d{6}$)|(^0[2|3|7|8]{1}[0-9]{8}$)|(^13\\d{4}$)|(^04\\d{2,3}\\d{6}$)",
  },
  {
    id: "projectMobile",
    label: "Mobile Number",
    type: "tel",
    tooltip: "Please Enter Valid Phone Number",
    required: true,
    pattern:
      "(^1300\\d{6}$)|(^1800|1900|1902\\d{6}$)|(^0[2|3|7|8]{1}[0-9]{8}$)|(^13\\d{4}$)|(^04\\d{2,3}\\d{6}$)",
  },
];

//Product Validaton Types

//Drive Mechanism

const manualDrive = [
  {
    id: "name",
    label: "Drive Mechanism Name",
    type: "text",
    tooltip: "Please Enter Drive Name",
    required: true,
    value: "",
  },
  {
    id: "cost",
    label: "Drive Cost",
    type: "number",
    tooltip: "Please Enter Cost",
    required: true,
    value: 0,
  },
  {
    id: "markup",
    label: "Drive Markup",
    type: "number",
    tooltip: "Please Enter Markup",
    required: true,
    value: 0,
  },
  {
    id: "manufacturerId",
    label: "Manufacturer ID",
    type: "AlgoliaSelect",
    index: `${config.algolia.prefix}manufacturers_name_asc`,
    filters: "isDeleted:false",
    value: "",
  },
  {
    id: "associatedServices",
    label: "Associated Services",
    type: "multiSelect",
    index: `${config.algolia.prefix}products_services_fees_name_asc`,
    value: [],
  },
];

const motorisedDrive = [
  {
    id: "name",
    label: "Drive Mechanism Name",
    type: "text",
    tooltip: "Please Enter Drive Name",
    required: true,
    value: "",
  },
  {
    id: "cost",
    label: "Drive Cost",
    type: "number",
    tooltip: "Please Enter Cost",
    required: true,
    value: 0,
  },
  {
    id: "markup",
    label: "Drive Markup",
    type: "number",
    tooltip: "Please Enter Markup",
    required: true,
    value: 0,
  },
  {
    id: "power",
    label: "Drive Power",
    type: "number",
    tooltip: "Please Enter Power",
    required: true,
    value: 0,
  },
  {
    id: "speed",
    label: "Drive Speed",
    type: "number",
    tooltip: "Please Enter Speed",
    required: true,
    value: 0,
  },
  {
    id: "lift",
    label: "Drive Lift",
    type: "number",
    tooltip: "Please Enter Lift",
    required: true,
    value: 0,
  },
  {
    id: "manufacturerId",
    label: "Manufacturer ID",
    type: "AlgoliaSelect",
    index: `${config.algolia.prefix}manufacturers_name_asc`,
    filters: "isDeleted:false",
    value: "",
  },
  {
    id: "associatedServices",
    label: "Associated Services",
    type: "multiSelect",
    index: `${config.algolia.prefix}products_services_fees_name_asc`,
    value: [],
  },
];

const linkSystemDrive = [
  {
    id: "name",
    label: "Link System name",
    type: "text",
    tooltip: "Please Enter Drive Name",
    required: true,
    value: "",
  },
  {
    id: "cost",
    label: "Link System Cost",
    type: "number",
    tooltip: "Please Enter Cost",
    required: true,
    value: 0,
  },
  {
    id: "markup",
    label: "Link System Markup",
    type: "number",
    tooltip: "Please Enter Markup",
    required: true,
    value: 0,
  },
  {
    id: "maxAngle",
    label: "Link System Max Angle",
    type: "number",
    tooltip: "Please Enter Max Angle",
    required: true,
    value: 0,
  },
  {
    id: "manufacturerId",
    label: "Manufacturer ID",
    type: "AlgoliaSelect",
    index: `${config.algolia.prefix}manufacturers_name_asc`,
    filters: "isDeleted:false",
    value: "",
  },
  {
    id: "associatedServices",
    label: "Associated Services",
    type: "multiSelect",
    index: `${config.algolia.prefix}products_services_fees_name_asc`,
    value: [],
  },
];

const blindStyle = [
  {
    id: "name",
    label: "Style Name",
    type: "text",
    tooltip: "Please Enter Blind Style Name",
    required: true,
    value: "",
  },
  {
    id: "photo",
    label: "Photo URL",
    type: "text",
    tooltip: "Please Enter Photo URL",
    required: false,
    value: "",
  },
  {
    id: "priceLists",
    label: "Associated Price Lists",
    type: "multiSelect",
    index: `${config.algolia.prefix}products_price_lists_name_asc`,
    value: [],
  },
  {
    id: "associatedServices",
    label: "Associated Services",
    type: "multiSelect",
    index: `${config.algolia.prefix}products_services_fees_name_asc`,
    value: [],
  },
];

const blindFabric = [
  {
    id: "name",
    label: "Style Name",
    type: "text",
    tooltip: "Please Enter Blind Style Name",
    required: true,
    value: "",
  },
  {
    id: "photo",
    label: "Photo URL",
    type: "text",
    tooltip: "Please Enter Photo URL",
    required: false,
    value: "",
  },
  {
    id: "manufacturerId",
    label: "Manufacturer ID",
    type: "AlgoliaSelect",
    index: `${config.algolia.prefix}manufacturers_name_asc`,
    filters: "isDeleted:false",
    value: "",
  },
  {
    id: "priceLists",
    label: "Associated Price Lists",
    type: "multiSelect",
    index: `${config.algolia.prefix}products_price_lists_name_asc`,
    value: [],
  },
  {
    id: "associatedServices",
    label: "Associated Services",
    type: "multiSelect",
    index: `${config.algolia.prefix}products_services_fees_name_asc`,
    value: [],
  },
];

const blindKassette = [
  {
    id: "name",
    label: "Kassette Name",
    type: "text",
    tooltip: "Please Enter Blind Style Name",
    required: true,
    value: "",
  },
  {
    id: "height",
    label: "Kassette Height",
    type: "number",
    tooltip: "Please Enter Kassette Height",
    required: false,
    value: 0,
  },
  {
    id: "costPerMetre",
    label: "Cost Per Metre",
    type: "number",
    tooltip: "Please Enter Kassette Cost per Metre",
    required: true,
    value: 0,
  },
  {
    id: "markup",
    label: "Markup (%)",
    type: "number",
    tooltip: "Please Enter Kassette Markup",
    required: true,
    value: 0,
  },
  {
    id: "manufacturerId",
    label: "Manufacturer ID",
    type: "AlgoliaSelect",
    index: `${config.algolia.prefix}manufacturers_name_asc`,
    filters: "isDeleted:false",
  },
  {
    id: "associatedServices",
    label: "Associated Services",
    type: "multiSelect",
    index: `${config.algolia.prefix}products_services_fees_name_asc`,
    value: [],
  },
];

const blindSideChannel = [
  {
    id: "name",
    label: "Side Channel Name",
    type: "text",
    tooltip: "Please Enter Blind Style Name",
    required: true,
    value: "",
  },
  {
    id: "height",
    label: "Side Channel Height",
    type: "number",
    tooltip: "Please Enter Kassette Height",
    required: false,
    value: 0,
  },
  {
    id: "costPerMetre",
    label: "Side Channel Cost per Metre",
    type: "number",
    tooltip: "Please Enter Side Channel Cost per Metre",
    required: true,
    value: 0,
  },
  {
    id: "markup",
    label: "Markup (%)",
    type: "number",
    tooltip: "Please Enter Side Channel Markup",
    required: true,
    value: 0,
  },
  {
    id: "manufacturerId",
    label: "Manufacturer ID",
    type: "AlgoliaSelect",
    index: `${config.algolia.prefix}manufacturers_name_asc`,
    filters: "isDeleted:false",
  },
  {
    id: "associatedServices",
    label: "Associated Services",
    type: "multiSelect",
    index: `${config.algolia.prefix}products_services_fees_name_asc`,
    value: [],
  },
];

const blindBaseBar = [
  {
    id: "name",
    label: "Base Bar Name",
    type: "text",
    tooltip: "Please Enter Blind Base Bar Name",
    required: true,
    value: "",
  },
  {
    id: "height",
    label: "Base Bar Height",
    type: "number",
    tooltip: "Please Enter Base Bar Height",
    required: false,
    value: 0,
  },
  {
    id: "costPerMetre",
    label: "Base Bar Cost per Metre",
    type: "number",
    tooltip: "Please Enter Base Bar Cost per Metre",
    required: true,
    value: 0,
  },
  {
    id: "markup",
    label: "Markup (%)",
    type: "number",
    tooltip: "Please Enter Base Bar Markup",
    required: true,
    value: 0,
  },
  {
    id: "manufacturerId",
    label: "Manufacturer ID",
    type: "AlgoliaSelect",
    index: `${config.algolia.prefix}manufacturers_name_asc`,
    filters: "isDeleted:false",
    value: "",
  },
  {
    id: "associatedServices",
    label: "Associated Services",
    type: "multiSelect",
    index: `${config.algolia.prefix}products_services_fees_name_asc`,
    value: [],
  },
];

const blindAccessory = [
  {
    id: "name",
    label: "Accessory Name",
    type: "text",
    tooltip: "Please Enter Blind Accessory Name",
    required: true,
    value: "",
  },
  {
    id: "cost",
    label: "Accessory Cost",
    type: "number",
    tooltip: "Please Enter Blind Accessory Cost",
    required: true,
    value: 0,
  },
  {
    id: "markup",
    label: "Markup (%)",
    type: "number",
    tooltip: "Please Enter Blind Accessory Markup",
    required: true,
    value: 0,
  },
  {
    id: "manufacturerId",
    label: "Manufacturer ID",
    type: "AlgoliaSelect",
    index: `${config.algolia.prefix}manufacturers_name_asc`,
    filters: "isDeleted:false",
    value: "",
  },
];

const blindDoubleBracketSystem = [
  {
    id: "name",
    label: "Double Bracket Name",
    type: "text",
    tooltip: "Please Enter Double Bracket Name",
    required: true,
    value: "",
  },
  {
    id: "cost",
    label: "Double Bracket Cost",
    type: "number",
    tooltip: "Please Enter Double Bracket Cost",
    required: true,
    value: 0,
  },
  {
    id: "markup",
    label: "Double Bracket Markup",
    type: "number",
    tooltip: "Please Enter Double Bracket Markup",
    required: true,
    value: 0,
  },
  {
    id: "manufacturerId",
    label: "Manufacturer ID",
    type: "AlgoliaSelect",
    index: `${config.algolia.prefix}manufacturers_name_asc`,
    filters: "isDeleted:false",
    value: "",
  },
  {
    id: "associatedServices",
    label: "Associated Services",
    type: "multiSelect",
    index: `${config.algolia.prefix}products_services_fees_name_asc`,
    value: [],
  },
];

const blindService = [
  {
    id: "name",
    label: "Service / Fee Name",
    type: "text",
    tooltip: "Please Enter Service/Fee Name",
    required: true,
    value: "",
  },
  {
    id: "type",
    label: "Service / Fee type",
    type: "select",
    tooltip: "Please Enter Service/Fee Name",
    options: [
      { name: "Consultation", value: "consultation" },
      { name: "Installation", value: "installation" },
      { name: "Maintenance", value: "maintenance" },
      { name: "Surcharge", value: "surcharge" },
    ],
    value: "consultation",
  },
  {
    id: "cost",
    label: "Service / Fee Cost",
    type: "number",
    tooltip: "Please Enter Service / Fee Cost",
    required: true,
    value: 0,
  },
  {
    id: "markup",
    label: "Service / Fee Markup",
    type: "number",
    tooltip: "Please Enter Service / Fee Markup",
    required: true,
    value: "",
  },
];

export {
  projectValidationTypes,
  linkSystemDrive,
  manualDrive,
  motorisedDrive,
  blindStyle,
  blindFabric,
  blindKassette,
  blindSideChannel,
  blindBaseBar,
  blindAccessory,
  blindDoubleBracketSystem,
  blindService,
};
