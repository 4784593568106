import React, { Component, Fragment } from "react";
import PopUpForm from "../../../Generic/PopUpDialog/PopUpForm";
import { connect } from "react-redux";
import { API } from "aws-amplify";

//Actions
import { setLoaderState } from "../../../../actions/loaderActions";
import { populateProductList } from "../../../../actions/productsActions";
import PopUp from "../../../Generic/PopUpDialog/PopUp";
import { setRefresh } from "../../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../../actions/loaderActions";
import config from "../../../../config";

class CreateBlindDriveMechanismPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationTypes: [
        {
          id: "name",
          label: "Drive Mechanism Name",
          type: "text",
          tooltip: "Please Enter Drive Name",
          required: true,
          value: "",
        },
        {
          id: "cost",
          label: "Drive Cost",
          type: "number",
          tooltip: "Please Enter Cost",
          required: true,
          value: "",
        },
        {
          id: "markup",
          label: "Drive Markup",
          type: "number",
          tooltip: "Please Enter Markup",
          required: true,
          value: "",
        },
        {
          id: "manufacturerId",
          label: "Manufacturer ID",
          type: "AlgoliaSelect",
          index: `${config.algolia.prefix}manufacturers_name_asc`,
          filters: "isDeleted:false",
          value: "",
        },
        {
          id: "associatedServices",
          label: "Associated Services",
          type: "multiSelect",
          index: `${config.algolia.prefix}products_services_fees_name_asc`,
          value: [],
        },
      ],
      fields: { name: "", cost: 0, markup: 0 },
      step: 1,
      mechanisimType: "manual",
    };
  }
  handleCancelClick = () => {
    this.props.history.goBack();
  };

  onDriveTypeChange = (event) => {
    this.setState({ mechanisimType: event.target.value });
    if (event.target.value === "manual") {
      this.setState({
        validationTypes: [
          {
            id: "name",
            label: "Style Name",
            type: "text",
            tooltip: "Please Enter Drive Name",
            required: true,
            value: "",
          },
          {
            id: "cost",
            label: "Drive Cost",
            type: "number",
            tooltip: "Please Enter Cost",
            required: true,
            value: "",
          },
          {
            id: "markup",
            label: "Drive Markup",
            type: "number",
            tooltip: "Please Enter Markup",
            required: true,
            value: "",
          },
          {
            id: "manufacturerId",
            label: "Manufacturer ID",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}manufacturers_name_asc`,
            filters: "isDeleted:false",
            value: "",
          },
          {
            id: "associatedServices",
            label: "Associated Services",
            type: "multiSelect",
            index: `${config.algolia.prefix}products_services_fees_name_asc`,
            value: "",
          },
        ],
        fields: { name: "", cost: 0, markup: 0 },
      });
    }
    if (event.target.value === "motorised") {
      this.setState({
        validationTypes: [
          {
            id: "name",
            label: "Drive Mechanism Name",
            type: "text",
            tooltip: "Please Enter Drive Name",
            required: true,
            value: "",
          },
          {
            id: "cost",
            label: "Drive Cost",
            type: "number",
            tooltip: "Please Enter Cost",
            required: true,
            value: "",
          },
          {
            id: "markup",
            label: "Drive Markup",
            type: "number",
            tooltip: "Please Enter Markup",
            required: true,
            value: "",
          },
          {
            id: "power",
            label: "Drive Power",
            type: "number",
            tooltip: "Please Enter Power",
            required: true,
            value: "",
          },
          {
            id: "speed",
            label: "Drive Speed",
            type: "number",
            tooltip: "Please Enter Speed",
            required: true,
            value: "",
          },
          {
            id: "lift",
            label: "Drive Lift",
            type: "number",
            tooltip: "Please Enter Lift",
            required: true,
            value: "",
          },
          {
            id: "manufacturerId",
            label: "Manufacturer ID",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}manufacturers_name_asc`,
            filters: "isDeleted:false",
            value: "",
          },
          {
            id: "associatedServices",
            label: "Associated Services",
            type: "multiSelect",
            index: `${config.algolia.prefix}products_services_fees_name_asc`,
            value: "",
          },
        ],
        fields: { name: "", cost: 0, markup: 0, power: 0, speed: 0, lift: 0 },
      });
    }
    if (event.target.value === "link-system") {
      this.setState({
        validationTypes: [
          {
            id: "name",
            label: "Drive Mechanism Name",
            type: "text",
            tooltip: "Please Enter Drive Name",
            required: true,
            value: "",
          },
          {
            id: "cost",
            label: "Drive Cost",
            type: "number",
            tooltip: "Please Enter Cost",
            required: true,
            value: "",
          },
          {
            id: "markup",
            label: "Drive Markup",
            type: "number",
            tooltip: "Please Enter Markup",
            required: true,
            value: "",
          },
          {
            id: "maxAngle",
            label: "Link System Max Angle",
            type: "number",
            tooltip: "Please Enter Max Angle",
            required: true,
            value: "",
          },
          {
            id: "manufacturerId",
            label: "Manufacturer ID",
            type: "AlgoliaSelect",
            index: `${config.algolia.prefix}manufacturers_name_asc`,
            filters: "isDeleted:false",
            value: "",
          },
          {
            id: "associatedServices",
            label: "Associated Services",
            type: "multiSelect",
            index: `${config.algolia.prefix}products_services_fees_name_asc`,
            value: "",
          },
        ],
        fields: { name: "", cost: 0, markup: 0, maxAngle: 0 },
      });
    }
  };

  handleNextClick = () => {
    this.setState({ step: 2 });
  };

  APIMethod = async (formData) => {
    try {
      let body = {};
      if (this.state.mechanisimType === "link-system")
        body = {
          details: {
            name: formData.name,
            cost: formData.cost,
            markup: formData.markup,
            maxAngle: formData.maxAngle,
            manufacturerId: formData.manufacturerId,
            associatedServices: formData.associatedServices,
          },
        };
      if (this.state.mechanisimType === "manual")
        body = {
          details: {
            name: formData.name,
            cost: formData.cost,
            markup: formData.markup,
            manufacturerId: formData.manufacturerId,
            associatedServices: formData.associatedServices,
          },
        };
      if (this.state.mechanisimType === "motorised")
        body = {
          details: {
            name: formData.name,
            cost: formData.cost,
            markup: formData.markup,
            power: formData.power,
            speed: formData.speed,
            lift: formData.lift,
            manufacturerId: formData.manufacturerId,
            associatedServices: formData.associatedServices,
          },
        };
      this.props.dispatch(setAlgoliaLoaderState(true));
      await API.post(
        "telishadeAPI",
        "/product/blind/component/drive-mechanism-" + this.state.mechanisimType,
        { body }
      );
      this.props.history.push(`/products/blinds/drive-mechanism`);
      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.dispatch(setRefresh(true));
    } catch (e) {
      this.props.dispatch(setAlgoliaLoaderState(false));
      throw e;
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  updateMethod = async (response) => {
    try {
      this.props.dispatch(setLoaderState(true));
      const newList = await API.get("telishadeAPI", "/product/blind/component/drive-mechanism");
      this.props.dispatch(populateProductList(newList.components));
      this.props.dispatch(setLoaderState(false));
    } catch (err) {
      console.error(err);
      this.props.dispatch(setLoaderState(false));
    }
  };

  render() {
    return this.state.step === 1 ? (
      <PopUp
        header="Add New Drive Mechanism"
        body={
          <Fragment>
            <label className="datefieldLabel">Mechanism Type</label>
            <select
              value={this.state.mechanisimType}
              onChange={this.onDriveTypeChange}
              className="browser-default custom-select form-control"
            >
              <option value="manual" label="Manual" />
              <option value="motorised" label="Motorised" />
              <option value="link-system" label="Link System" />
            </select>
          </Fragment>
        }
        button="Next"
        buttoncolour="blue"
        buttonClickHandler={this.handleNextClick}
        button2="Cancel"
        button2colour="red"
      />
    ) : this.state.step === 2 ? (
      <PopUpForm
        fields={this.state.validationTypes}
        apiMethod={this.APIMethod}
        header="Add New Drive Mechanism"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    ) : (
      ""
    );
  }
}

export default connect()(CreateBlindDriveMechanismPopUp);
