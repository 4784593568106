import React, { Component } from "react";
import "./Loading.css";
import { connect } from "react-redux";

class Loading extends Component {
  render() {
    return this.props.loaderState || this.props.algoliaLoaderState ? (
      <div className="loadingContainer">
        <div className="loader">LOADING...</div>
      </div>
    ) : (
      ""
    );
  }
}

const mapStateToProps = state => {
  return state.loader;
};

export default connect(mapStateToProps)(Loading);
