// HANDLE WITH CARE!
// Multiple files rely on these functions, don't alter any, only add new.

import { calcPriceWithMarkUp } from "../../../../utilities/utils";

export const blindExtraTypes = [
  { key: "driveMechanism", title: "Drive Mechanisms" },
  { key: "baseBar", title: "Base Bars" },
  { key: "kassette", title: "Kassettes" },
  { key: "linking", title: "Linkings" },
  { key: "sideChannel", title: "Side Channels" },
  { key: "doubleBracket", title: "Double Brackets" },
  { key: "accessory", title: "Accessories" }
  // Services is an Array
];

// Get Blind Fabric after considering markups and discounts
export function getBlindFabricPrice(quoteItem, isRetail, discountObj) {
  let totalFabricPrice = 0;

  // CHECK IF RETAIL
  totalFabricPrice += quoteItem.baseCost
    ? isRetail
      ? calcPriceWithMarkUp(quoteItem.baseCost, quoteItem.baseMarkup || 0) //parsed as Floats inside func
      : parseFloat(quoteItem.baseCost)
    : 0;

  // CHECK FOR DISCOUNT
  if (discountObj.components?.length > 0) {
    discountObj.components.forEach(componentDiscount => {
      if (componentDiscount.id === quoteItem.description?.allDetails?.fabricId) {
        totalFabricPrice =
          (totalFabricPrice / 100) *
          (100 -
            (isRetail
              ? parseFloat(componentDiscount.retail?.percentage)
              : parseFloat(componentDiscount.wholesale?.percentage)));
      }
    });
  }

  return totalFabricPrice;
}

// Get Blind Extra price after condering markups and discounts
export function getBlindExtraPrice(extraKey, quoteItem, isRetail, discountObj) {
  let totalExtraPrice = 0;

  if (quoteItem.extras && quoteItem.extras[extraKey] && quoteItem.extras[extraKey].details) {
    let cost = 0;
    if (quoteItem.extras[extraKey].details.costPerMetre) {
      cost =
        parseFloat(quoteItem.extras[extraKey].details.costPerMetre) *
        (parseFloat(quoteItem.description?.width || 0) / 1000);
    } else if (quoteItem.extras[extraKey].details.cost) {
      cost = parseFloat(quoteItem.extras[extraKey].details.cost);
    }
    totalExtraPrice = isRetail
      ? calcPriceWithMarkUp(cost, quoteItem.extras[extraKey].details.markup || 0)
      : cost;

    if (discountObj.components?.length > 0) {
      discountObj.components.forEach(componentDiscount => {
        if (componentDiscount.id === quoteItem.extras[extraKey].id) {
          totalExtraPrice =
            (totalExtraPrice / 100) *
            (100 -
              (isRetail
                ? parseFloat(componentDiscount.retail?.percentage || 0)
                : parseFloat(componentDiscount.wholesale?.percentage || 0)));
        }
      });
    }
  }

  return totalExtraPrice;
}

// Get Blind Service price after markups and discounts
export function getBlindServicePrice(service, isRetail, discountObj) {
  let servicePrice = service.details?.cost
    ? isRetail
      ? calcPriceWithMarkUp(service.details.cost, service.details.markup || 0)
      : parseFloat(service.details.cost)
    : 0;

  if (discountObj?.servicesAndFees?.length > 0) {
    discountObj.servicesAndFees.forEach(serviceDiscount => {
      if (service.id && service.id === serviceDiscount.id) {
        servicePrice =
          (servicePrice / 100) *
          (100 -
            (isRetail
              ? parseFloat(serviceDiscount.retail?.percentage || 0)
              : parseFloat(serviceDiscount.wholesale?.percentage || 0)));
      }
    });
  }

  return servicePrice;
}
