import React, { Component } from "react";
import { connect } from "react-redux";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { branchFields } from "../fields";
import { API } from "aws-amplify";
import { setRefresh } from "../../../actions/algoliaActions";
import { setLoaderState, setAlgoliaLoaderState } from "../../../actions/loaderActions";
import { updateBranchDetails, selectBranch } from "../../../actions/customersActions";

class AddBranch extends Component {
  customerId = this.props.match.params.customerId;

  async APIMethod(formData) {
    this.props.dispatch(setAlgoliaLoaderState(true));
    try {
      this.props.dispatch(setLoaderState(true));

      const response = await API.post("telishadeAPI", "/customer/" + this.customerId + "/branch", {
        body: {
          details: {
            name: formData.name ? formData.name : "Untitled Address",
            addressType: formData.addressType,
            address: {
              streetNumber: formData.streetNumber,
              streetName: formData.streetName,
              city: formData.city,
              postcode: formData.postcode,
              state: formData.state,
              country: formData.country
            }
          }
        }
      });
      this.props.dispatch(setRefresh(true));
      await this.props.dispatch(updateBranchDetails(response.details));
      await this.props.dispatch(selectBranch(response.id));
      this.props.history.goBack();
    } catch (error) {
      throw error;
    } finally {
      this.props.dispatch(setLoaderState(false));
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  }

  render() {
    const fields = [...branchFields];

    return (
      <PopUpForm
        fields={fields}
        header="Add New Branch"
        button="ACCEPT"
        buttoncolour="green"
        button2="CANCEL"
        button2colour="red"
        buttonClickHandler={undefined}
        button2ClickHandler={undefined}
        apiMethod={details => this.APIMethod(details)}
      />
    );
  }
}
const mapStateToProps = state => {
  return state.customers.branch;
};

export default connect(mapStateToProps)(AddBranch);
