import React, { Component } from "react";
import { withRouter, NavLink } from "react-router-dom";

class ReportsMenu extends Component {
  render() {
    return (
      <div className="subMenuLinksList-expanded">
        <ul>
          <li>
            <NavLink
              to={
                "/projects/" +
                this.props.match.params.id +
                "/" +
                this.props.match.params.JobNo +
                "/documents/margin-report"
              }
            >
              Margin Report <div className="available-documents">(1)</div>
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default withRouter(ReportsMenu);
