import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { withRouter } from "react-router-dom";
import BlindsWorksheet from "./BlindsWorksheet";
import { MDBBtn } from "mdbreact";
import {
  addRow,
  populateWorksheet,
  changeCurrrentProduct,
  toggleMultiselect,
  duplicateWorksheetItem,
  selectIndex,
  setWorksheetProductID,
  setWorksheetProjectDetails,
  setFormatterCache
} from "../../../actions/worksheetActions";

import CurtainsWorksheet from "./CurtainsWorksheet";
import BlindsWorksheetMulti from "./BlindsWorksheetMulti";
import CurtainsWorksheetMulti from "./CurtainsWorksheetMulti";
import { setLoaderState } from "../../../actions/loaderActions";
import algoliasearch from "algoliasearch";
import config from "../../../config";
//Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PopUp from "../../Generic/PopUpDialog/PopUp";
import ReorderWorksheet from "./ReorderWorksheet";

const blindEmpty = {
  productCode: "",
  location: "",
  styleId: "",
  width: 0,
  drop: 0,
  linkSystemId: "",
  linkedTo: "",
  linkingPosition: "",
  driveType: "",
  driveMechanismId: "",
  driveColour: "",
  driveSide: "",
  chainDrop: 0,
  chainType: "",
  accessoryId: "",
  hasDoubleBracketSystem: "",
  doubleBracketSystemId: "",
  railColour: "",
  baseBarId: "",
  sideChannelId: "",
  rollDirection: "",
  kassetteId: "",
  kassetteColour: "",
  fasciaFit: "",
  fabricId: "",
  fabricColour: "",
  fabricColourListId: "",
  options: { note: "" }
};

const curtainEmpty = {
  productCode: "",
  styleId: "",
  trackId: "",
  runnerId: "",
  tapeId: "",
  location: "",
  width: 0,
  drop: 0,
  widthAllowance: 0,
  aboveFloorAllowance: 0,
  ceilingAllowance: 0,
  hemAllowance: 0,
  driveType: "",
  driveMechanismId: "",
  controlSide: "",
  opening: "",
  trackType: "",
  trackColour: "",
  cordStickLength: 0,
  cordStickType: "",
  pelmetId: "",
  pelmetType: "",
  pelmetHeadboxColour: "",
  leadWeightId: "",
  fit: "",
  bumphId: "",
  liningId: "",
  fabricId: "",
  fabricColour: "",
  fabricColourListId: "",
  accessoryId: "",
  fabricSurchargeId: "",
  options: { note: "" }
};

class JobWorksheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedJob: this.props.match.params.JobNo,
      canAdd: true,
      productIdExists: false,
      duplicatePopUp: false,
      deletePopUp: false,
      unsavedChanges: false,
      saving: false,
      unsavedRows: [],
      retrievedItems: false,
      reorder: false,
      duplicateValue: 1,
      savingDialog: false
    };
    this.props.dispatch(setLoaderState(true));
    this.getCurrentProductType(this.props.currentProduct);
    if (
      this.props.projectDetails === undefined ||
      this.props.projectDetails === {} ||
      this.props.projectDetails.objectId !== this.props.match.params.id
    ) {
      this.getLinkProjectDetails(this.props.match.params.id);
      this.getJobsForProject();
    } else this.getJobsForProject();
    this._isMounted = true;
  }

  savingInterval;

  componentWillUnmount() {
    this.props.dispatch(setFormatterCache({ components: {}, colours: {} }));
    this._isMounted = false;
    clearInterval(this.savingInterval);
  }

  handleUnsavedChanges = () => {
    if (this.state.unsavedChanges) {
      this.setState({ savingDialog: true });
    } else {
      this.handleExitClick();
    }
  };

  componentDidMount() {
    if (this.props.projectDetails !== undefined && this.props.currentProduct !== undefined) {
      this.getJobItems();
    }

    window.addEventListener("beforeunload", ev => {
      ev.preventDefault();
      return (ev.returnValue = "Are you sure you want to close?");
    });
    //TESTING, Interval Worksheeting Saving?
    // this.savingInterval = setInterval(() => {
    //   this.sendWorksheetToDatabase(this.props.worksheet.items);
    // }, 5000);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.worksheet.selectedIndexs !== this.props.worksheet.selectedIndexs &&
      prevProps !== undefined &&
      prevProps.worksheet.selectedIndexs[0] !== undefined &&
      this.props.worksheet.items[prevProps.worksheet.selectedIndexs[0]] !== undefined
    ) {
      this.updateItem(this.props.worksheet.items[prevProps.worksheet.selectedIndexs[0]]);
      for (let i = 0; i < this.state.unsavedRows.length; i++) {
        if (this.state.unsavedRows[i] === prevProps.worksheet.selectedIndexs[0]) {
          this.state.unsavedRows.splice(i, 1);
        }
      }
    }
  }

  sendItemsfromAPItoRedux(response) {
    let temp = response.map(i => {
      return { ...i.details, jobItemId: i.id };
    });
    this.props.dispatch(populateWorksheet(temp));
  }

  async getJobItems() {
    this.setState({ retrievedItems: false });
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getJobItems();
      }, 500);
    } else {
      try {
        this.props.dispatch(setLoaderState(true));
        let productType;
        if (this.props.currentProduct === "blinds") {
          productType = "blind";
        }
        if (this.props.currentProduct === "curtains") {
          productType = "curtain";
        }
        const response = await API.get(
          "telishadeAPI",
          `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${this.state.SelectedJob}/items/${productType}`
        );
        this.sendItemsfromAPItoRedux(response.items);
        this.props.dispatch(setLoaderState(false));
        this.setState({ retrievedItems: true });
      } catch (e) {}
    }
  }

  async postJobItem() {
    this.setState({ canAdd: false });
    let body = {};
    let productType;
    if (this.props.currentProduct === "blinds") {
      productType = "blind";
      body = {
        productId: this.props.productId,
        details: { itemNo: this.props.worksheet.items.length + 1, ...blindEmpty }
      };
    }
    if (this.props.currentProduct === "curtains") {
      productType = "curtain";
      body = {
        productId: this.props.productId,
        details: { itemNo: this.props.worksheet.items.length + 1, ...curtainEmpty }
      };
    }
    try {
      const response = await API.post(
        "telishadeAPI",
        "/project/" +
          this.props.projectDetails.type +
          "/" +
          this.props.match.params.id +
          "/job/" +
          this.props.match.params.JobNo +
          "/item/" +
          productType,
        { body }
      );
      await this.props.dispatch(addRow({ ...response.details, jobItemId: response.id }));
      var element = document.getElementsByClassName("react-grid-Canvas");
      element.scrollTop = element.scrollHeight;
      if (this._isMounted) {
        this.setState({ canAdd: true });
      }
    } catch (e) {
      console.error(e);
      if (this._isMounted) {
        this.setState({ canAdd: true });
      }
    }
  }

  getLinkProjectDetails = async id => {
    try {
      const response = await API.get("telishadeAPI", "/project/commercial/" + id);
      this.props.dispatch(setWorksheetProjectDetails(response));
    } catch (e) {
      try {
        const response = await API.get("telishadeAPI", "/project/domestic/" + id);
        this.props.dispatch(setWorksheetProjectDetails(response));
      } catch (error) {
        console.error(error);
        console.error(e);
      }
    }
    this.getJobItems();
  };

  async getCurrentProductType(Product) {
    let product = "";
    if (Product === "blinds") product = "blind";
    if (Product === "curtains") product = "curtain";
    try {
      this.props.dispatch(setLoaderState(true));
      const response = await API.get("telishadeAPI", "/product/" + product);
      await this.props.dispatch(setWorksheetProductID(response.id));
      this.setState({ productIdExists: true });
      this.props.dispatch(setLoaderState(false));
    } catch (err) {
      console.error(err);
      this.props.dispatch(setLoaderState(false));
    }
  }

  async getJobsForProject() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getJobsForProject();
      }, 500);
    } else {
      try {
        const client = await algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
        const index = await client.initIndex(`${config.algolia.prefix}projects_jobs_number_asc`);
        const response = await index.search({
          query: "",
          filters: "isDeleted:false AND projectId:" + this.props.match.params.id,
          hitsPerPage: 1000
        });
        if (this._isMounted) {
          this.setState({ jobs: response.hits });
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  duplicateItemWrapper = async (indexs, duplicateAmmount) => {
    let duplicationPromises = [];
    let itemNoTracker = this.props.worksheet.items.length + 1;
    this.props.dispatch(setLoaderState(true));
    for (let i = 0; i < duplicateAmmount; i++) {
      for (let index = 0; index < indexs.length; index++) {
        duplicationPromises.push(
          this.duplicateItem(this.props.worksheet.items[indexs[index]], itemNoTracker)
        );
        itemNoTracker++;
      }
    }
    const duplicationResponses = await Promise.all(duplicationPromises);
    duplicationResponses.forEach(response => {
      this.props.dispatch(duplicateWorksheetItem({ ...response.details, jobItemId: response.id }));
    });
    this.props.dispatch(setLoaderState(false));
    if (this._isMounted) {
      this.setState({ canAdd: true, duplicatePopUp: false });
    }
  };

  duplicateItem = async (lineToDuplicate, itemNo) => {
    try {
      let temp = Object.assign({}, lineToDuplicate);
      temp.itemNo = itemNo;
      let productType = "";
      let body = { details: { ...temp, jobItemId: undefined } };
      if (this.props.currentProduct === "blinds") {
        productType = "blind";
        body = { ...body, productId: this.props.productId };
      }
      if (this.props.currentProduct === "curtains") {
        productType = "curtain";
        body = { ...body, productId: this.props.productId };
      }
      this.props.dispatch(setLoaderState(true));
      const response = await API.post(
        "telishadeAPI",
        "/project/" +
          this.props.projectDetails.type +
          "/" +
          this.props.match.params.id +
          "/job/" +
          this.props.match.params.JobNo +
          "/item/" +
          productType,
        { body }
      );
      return response;
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  handleDeleteItem = async () => {
    this.props.dispatch(setLoaderState(true));
    if (this.props.worksheet.selectedIndexs.length >= 1) {
      let temp = this.props.worksheet.items;
      let previous = undefined;
      let iterator = 0;
      let deletePromises = [];
      for (let i = 0; i < this.props.worksheet.selectedIndexs.length; i++) {
        if (previous !== undefined && previous < this.props.worksheet.selectedIndexs[i]) {
          iterator++;
          deletePromises.push(
            this.DeleteItem(temp[this.props.worksheet.selectedIndexs[i - iterator]].jobItemId)
          );
          temp.splice(this.props.worksheet.selectedIndexs[i - iterator], 1);
        } else {
          deletePromises.push(
            this.DeleteItem(temp[this.props.worksheet.selectedIndexs[i]].jobItemId)
          );
          temp.splice(this.props.worksheet.selectedIndexs[i], 1);
        }
        previous = this.props.worksheet.selectedIndexs[i];
      }
      for (let i = 0; i < temp.length; i++) {
        temp[i].itemNo = i + 1;
      }
      await Promise.all(deletePromises);
      this.props.dispatch(selectIndex([]));
      this.props.dispatch(populateWorksheet(temp));
      this.sendWorksheetToDatabase(temp);
      this.props.dispatch(setLoaderState(false));
    }
    if (this._isMounted) {
      this.setState({ deletePopUp: false });
    }
  };

  async DeleteItem(JobItemId) {
    let productType = "";
    if (this.props.currentProduct === "blinds") {
      productType = "blind";
    }
    if (this.props.currentProduct === "curtains") {
      productType = "curtain";
    }
    try {
      await API.del(
        "telishadeAPI",
        "/project/" +
          this.props.projectDetails.type +
          "/" +
          this.props.match.params.id +
          "/job/" +
          this.props.match.params.JobNo +
          "/item/" +
          productType +
          "/" +
          JobItemId
      );
    } catch (e) {
      console.error(e);
    }
  }

  onDuplicateValueChange = e => {
    this.setState({ duplicateValue: e.target.value });
  };

  async sendWorksheetToDatabase(worksheetObject) {
    // for (let i = 0; i < worksheetObject.length; i++) {
    //   try {
    //     this.updateItem(worksheetObject[i]);
    //   } catch (e) {
    //     console.error(e);
    //   }
    // }
    //The Above function has been refactored to allow parallel updating of multiple different job items
    await Promise.all(
      worksheetObject.map(async w => {
        try {
          await this.updateItem(w);
        } catch (e) {
          console.error(e);
        }
      })
    );
  }

  async updateItem(newItem) {
    if (this._isMounted) {
      this.setState({ unsavedChanges: false });
      this.setState({ saving: true });
    }
    let productType = "";
    if (this.props.currentProduct === "blinds") {
      productType = "blind";
    }
    if (this.props.currentProduct === "curtains") {
      productType = "curtain";
    }
    try {
      const body = { details: { ...newItem, jobItemId: undefined } };
      const response = await API.put(
        "telishadeAPI",
        "/project/" +
          this.props.projectDetails.type +
          "/" +
          this.props.match.params.id +
          "/job/" +
          this.props.match.params.JobNo +
          "/item/" +
          productType +
          "/" +
          newItem.jobItemId,
        { body }
      );
      if (this.state.unsavedRows.length > 0) {
        this.setState({ unsavedChanges: true });
      }
      return response;
    } catch (e) {
      if (this._isMounted) {
        this.setState({ unsavedChanges: true });
      }
      console.error(e);
      throw e;
    } finally {
      if (this._isMounted) {
        this.setState({ saving: false });
      }
    }
  }

  handleChanges = (fromRow, toRow) => {
    if (fromRow) {
      const modifiedRows = this.state.unsavedRows;
      for (let i = fromRow; i <= toRow; i++) {
        modifiedRows.push(i);
      }
      this.setState({ unsavedRows: modifiedRows });
    }
    this.setState({ unsavedChanges: true });
  };

  handleAddItem = () => {
    if (this.state.canAdd && this.props.worksheet.items.length < 300) this.postJobItem();
  };

  handleDuplicateItemClick = () => {
    if (this.state.canAdd && this.props.worksheet.items.length < 300) {
      this.duplicateItemWrapper(this.props.worksheet.selectedIndexs, this.state.duplicateValue);
    }
  };

  handleDuplicatePopUp = () => {
    this.setState({ duplicatePopUp: true, deletePopUp: false });
  };

  handleDeletePopUp = () => {
    this.setState({ duplicatePopUp: false, deletePopUp: true });
  };

  handlePopUpCancel = () => {
    this.setState({ duplicatePopUp: false, deletePopUp: false });
  };

  handleProductChange = event => {
    //this.setState({ productIdExists: false });
    this.sendWorksheetToDatabase(this.props.worksheet.items);
    const temp = event.target.value;
    this.getCurrentProductType(event.target.value).then(async () => {
      await this.props.dispatch(changeCurrrentProduct(temp));
      this.getJobItems();
    });
  };

  handleMultiSelect = () => {
    this.props.dispatch(toggleMultiselect(!this.props.worksheet.wsmultiselect));
    this.props.dispatch(selectIndex([]));
  };

  handleJobSelectChange = event => {
    this.sendWorksheetToDatabase(this.props.worksheet.items);
    this.props.history.push(
      "/projects/" + this.props.match.params.id + "/" + event.target.value + "/worksheet"
    );
    this.setState({ SelectedJob: event.target.value }, () => this.getJobItems());
  };

  handleSave = async () => {
    this.setState({ unsavedRows: [] });
    this.props.dispatch(setLoaderState(true));
    await this.sendWorksheetToDatabase(this.props.worksheet.items);
    this.props.dispatch(setLoaderState(false));
  };

  handleSaveExitClick = async () => {
    try {
      await this.handleSave();
      this.handleExitClick();
    } catch (e) {
      console.error(e);
    }
  };

  handleExitClick = () => {
    this.props.history.push(
      `/projects/${this.props.match.params.id}/${this.props.match.params.JobNo}`
    );
    this.props.dispatch(toggleMultiselect(false));
    this.props.dispatch(selectIndex([]));
  };

  render() {
    return (
      <div className="worksheetContainer">
        {this.state.savingDialog ? (
          <PopUp
            header="Unsaved Changes?"
            body={"You Have Unsaved Changes Would you Like to Save?"}
            button2="Save"
            button2colour="green"
            button="Exit without Saving"
            buttoncolour="red"
            button2ClickHandler={this.handleSaveExitClick}
            buttonClickHandler={this.handleExitClick}
          />
        ) : (
          ""
        )}
        {this.state.duplicatePopUp ? (
          <PopUp
            header="Duplicate?"
            body={
              <Fragment>
                <div>
                  {"Are you sure you want to duplicate " +
                    this.props.worksheet.selectedIndexs.length +
                    " items "}
                  <input
                    onChange={this.onDuplicateValueChange}
                    type="number"
                    value={this.state.duplicateValue}
                  />
                  Times
                </div>
              </Fragment>
            }
            button="Duplicate"
            button2="Cancel"
            button2colour="red"
            buttonClickHandler={this.handleDuplicateItemClick}
            button2ClickHandler={this.handlePopUpCancel}
          />
        ) : this.state.deletePopUp ? (
          <PopUp
            header="Delete?"
            body={
              "Are you sure you want to delete " +
              this.props.worksheet.selectedIndexs.length +
              " items?"
            }
            button="Delete"
            button2="Cancel"
            headerColour="red"
            buttoncolour="red"
            buttonClickHandler={this.handleDeleteItem}
            button2ClickHandler={this.handlePopUpCancel}
          />
        ) : (
          ""
        )}
        <div className="worksheetHeader">
          <FontAwesomeIcon
            id="backArrowWorksheet"
            icon={faArrowLeft}
            onClick={() => this.handleUnsavedChanges()}
          />
          <MDBBtn
            color="blue"
            id="ReorderBtn"
            onClick={() => {
              if (this.state.reorder) {
                this.handleSave();
              }
              this.setState({ reorder: !this.state.reorder });
            }}
          >
            {this.state.reorder ? "Done" : "Reorder"}
          </MDBBtn>
          <MDBBtn color="white" id="AddItemBtn" onClick={this.handleAddItem}>
            {this.state.canAdd ? "Add Item" : "Loading..."}
          </MDBBtn>
          <MDBBtn color="white" id="DuplicateItemBtn" onClick={this.handleDuplicatePopUp}>
            {this.state.canAdd ? "Duplicate Item" : "Loading..."}
          </MDBBtn>
          {!this.props.worksheet.wsmultiselect ? (
            <MDBBtn color="Blue" id="MultiSelectBtn" onClick={this.handleMultiSelect}>
              MultiSelect
            </MDBBtn>
          ) : (
            <MDBBtn color="Blue" id="CancelBtn" onClick={this.handleMultiSelect}>
              Cancel
            </MDBBtn>
          )}
          <MDBBtn color="red" id="DeleteBtn" onClick={this.handleDeletePopUp}>
            Delete
          </MDBBtn>
          <MDBBtn color="green" id="SaveBtn" onClick={this.handleSave}>
            Save
          </MDBBtn>
          {this.props.worksheet.wsmultiselect
            ? ` Selected(${this.props.worksheet.selectedIndexs.length})`
            : ""}
          {this.state.jobs !== undefined ? (
            <select onChange={this.handleJobSelectChange} value={this.props.match.params.JobNo}>
              {this.state.jobs.map(job => (
                <option key={job.objectID} value={job.objectID}>
                  {job.details.name}
                </option>
              ))}
            </select>
          ) : (
            ""
          )}
          <select
            className="currentProduct"
            value={this.props.currentProduct}
            onChange={this.handleProductChange}
          >
            <option value="blinds">Blinds</option>
            <option value="curtains">Curtains</option>
          </select>
          {this.state.unsavedChanges ? (
            <span className="unsaved">Unsaved Changes</span>
          ) : this.state.saving ? (
            <span className="saving">Saving....</span>
          ) : (
            <span className="saved">Saved</span>
          )}
        </div>
        {this.props.productId !== undefined &&
        this.state.productIdExists &&
        this.state.retrievedItems ? (
          this.state.reorder ? (
            <ReorderWorksheet />
          ) : (
            <div className="worksheet">
              {this.props.currentProduct === "blinds" ? (
                this.props.worksheet.wsmultiselect ? (
                  <BlindsWorksheetMulti
                    productID={this.props.productId}
                    handleChanges={this.handleChanges}
                  />
                ) : (
                  <BlindsWorksheet
                    productID={this.props.productId}
                    handleChanges={this.handleChanges}
                  />
                )
              ) : this.props.worksheet.wsmultiselect ? (
                <CurtainsWorksheetMulti
                  productID={this.props.productId}
                  handleChanges={this.handleChanges}
                />
              ) : (
                <CurtainsWorksheet
                  productID={this.props.productId}
                  handleChanges={this.handleChanges}
                />
              )}
            </div>
          )
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.worksheet, AlgoliaKey: state.key, documents: state.documents };
};

export default withRouter(connect(mapStateToProps)(JobWorksheet));
