import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Auth, API } from "aws-amplify";
import { logout, loginSuccessRedirect } from "./actions/authenticationActions";

import SideNav from "./components/SideNav/SideNav";
import Header from "./components/Header/Header";
import Dashboard from "./components/Dashboard/Dashboard";
import Customers from "./components/Customers/Customers";
import Products from "./components/Products/Products";
import Manufacturers from "./components/Manufacturers/Manufacturers";
import Projects from "./components/Projects/Projects";
import Staff from "./components/Staff/Staff";
import Login from "./components/Login";
import Logout from "./components/Logout";
import { toggleSideNav } from "./actions/sideNavActions";
import Account from "./components/Account/Account";
import SignUpConfirm from "./components/SignUpConfirm";
import SignUp from "./components/SignUp";
import ForgotPassword from "./components/ForgotPassword";
import NewPassword from "./components/NewPassword";
import Loading from "./components/Loader/Loading";
import { setAlgoliaKey } from "./actions/keyActions";
import Toast from "./components/Generic/Toast/Toast";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { algoliaKeyTimeOut: undefined };
  }
  async componentDidMount() {
    await this.authentication();
    this.getAlgoliaKey();
  }

  async componentDidUpdate() {
    await this.authentication();
    if (this.props.AlgoliaKey.needNewKey) {
      this.getAlgoliaKey();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.state.algoliaKeyTimeOut);
  }

  // User authentication
  async authentication() {
    const { pathname } = this.props.location;
    const { isAuthenticated } = this.props.authentication;

    try {
      // If not authenticated, an exception will be raised
      await Auth.currentSession();
    } catch (e) {
      if (e !== "No current user") {
        // Error logging if the error is other than not logged in
        console.error(e);
      }

      // Make sure the user is logged out
      if (isAuthenticated) {
        await this.props.dispatch(logout());
      }

      if (
        pathname !== "/login" &&
        pathname !== "/logout" &&
        pathname !== "/sign-up" &&
        pathname !== "/sign-up-confirm" &&
        pathname !== "/forgot-password" &&
        pathname !== "/new-password"
      ) {
        // Update authentication.successRedirect to be current path
        await this.props.dispatch(loginSuccessRedirect(pathname));
        // Redirect to login page
        this.props.history.push("/login");
      }
    }
  }

  getAlgoliaKey = async () => {
    try {
      clearTimeout(this.state.algoliaKeyTimeOut);
      const { key, productKey } = await API.get("telishadeAPI", "/algolia/key");
      this.props.dispatch(setAlgoliaKey(key, productKey));
      const AlgoliaTimeOut = setTimeout(() => {
        this.getAlgoliaKey();
      }, 600000);
      this.setState({ algoliaKeyTimeOut: AlgoliaTimeOut });
    } catch (e) {
      console.error(e);
      setTimeout(() => {
        this.getAlgoliaKey();
      }, 1000);
    }
  };

  contentStyle = () => {
    if (this.props.sideNav.isMenuOpen && this.props.sideNav.isSubMenuOpen) {
      return "content SubNav";
    } else if (this.props.sideNav.isMenuOpen) {
      return "content SideNav";
    }
    return "content Closed";
  };

  closeSideNav = () => {
    if (this.props.sideNav.isMenuOpen) this.props.dispatch(toggleSideNav());
  };

  render() {
    return (
      <div className="App">
        <Loading />
        {/* If the User is not logged in, hide the SideNav bar */}
        <Switch>
          <Route path="/(login|sign-up|sign-up-confirm|forgot-password|new-password)" />
          <Route path="/" component={SideNav} />
        </Switch>
        <Switch>
          <Route path="/(login|sign-up|sign-up-confirm|forgot-password|new-password)" />
          <Route path="/" component={Header} />
        </Switch>
        <div id="content" className={this.contentStyle()} onClick={this.closeSideNav}>
          {/* If the User is not logged in, hide the Header bar */}

          <div id="panel">
            <Switch location={this.props.location}>
              <Route exact path="/login" component={Login} />
              <Route exact path="/sign-up" component={SignUp} />
              <Route exact path="/sign-up-confirm" component={SignUpConfirm} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/new-password" component={NewPassword} />
              <Route exact path="/" component={Dashboard} />
              <Route path="/customers" component={Customers} />
              <Route path="/projects" component={Projects} />
              <Route path="/products" component={Products} />
              <Route path="/manufacturers" component={Manufacturers} />
              <Route path="/staff" component={Staff} />
              <Route path="/account" component={Account} />
              <Route path="/logout" component={Logout} />
            </Switch>
          </div>
          <Toast />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authentication: state.authentication,
  sideNav: state.sideNav,
  AlgoliaKey: state.key,
});

export default withRouter(connect(mapStateToProps)(App));
