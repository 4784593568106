import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SearchableList from "../../Generic/Algolia/SearchableList";
import config from "../../../config";

class ProjectList extends Component {
  HitComponent = ({ hit }) => {
    const listItemClickHandler = () => {
      this.props.history.push(`/projects/${hit.objectID}`);
    };

    let className = "";
    if (this.props.id === hit.objectID) {
      className = { className: "selected" };
    }

    return (
      <Fragment>
        <p {...className} onClick={listItemClickHandler}>
          <strong>{hit.details.name}</strong>
          <br />
          <strong>Status: </strong>
          {hit.details.status}
          <br />
          <strong>Start Date: </strong>
          {hit.details.startDate}
        </p>
        {className.className === "selected" ? <Fragment></Fragment> : ""}
      </Fragment>
    );
  };

  render() {
    return (
      <div className="project-container">
        <div className="project-header">
          <h6>Projects</h6>
        </div>
        <div className="project-list">
          <SearchableList
            appId={`${config.algolia.appId}`}
            index={`${config.algolia.prefix}projects_name_asc`}
            hitComponent={this.HitComponent}
            selectedObjectId={this.props.id}
            filters={`isDeleted:false AND details.customerBranchId:${this.props.match.params.branchId}`}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return state.customers.branch;
};

export default withRouter(connect(mapStateToProps)(ProjectList));
