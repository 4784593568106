import React, { Component } from "react";
class MessageTab extends Component {
  render() {
    return (
      <div className="empty-tab">
        <h2>Messages section coming soon</h2>
      </div>
    );
  }
}
export default MessageTab;
