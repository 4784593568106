import { API } from "aws-amplify";
import React, { Component } from "react";

class TenantLetterHead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      businessNumber: "",
      number: "",
      address: "",
      email: "",
      website: "",
    };

    API.get("telishadeAPI", "/account")
      .then((response) => {
        this.setState({
          name: response.tenantDetails.tenantName,
          businessNumber: response.tenantDetails.businessNumber,
          number: response.tenantDetails.phoneNumber,
          address: response.tenantDetails.address,
          email: response.tenantDetails.email,
          website: response.tenantDetails.website,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div id="LetterHead">
        <span id="title">{this.state.name}</span>
        ABN: {this.state.businessNumber}
        <br />
        MAILING ADDRESS: {this.state.address}
        <br />
        PH: {this.state.number}
        <br />
        Email: {this.state.email}
        <br />
        web: {this.state.website}
        <br />
      </div>
    );
  }
}

export default TenantLetterHead;
