import React, { Component } from "react";
import { connect } from "react-redux";
import { connectAutoComplete, InstantSearch, Configure } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch";
import config from "../../../config.js";
import { getAlgoliaKey } from "../../../utilities/utils.js";

class CustomAutocomplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: this.props.inputValue,
      focus: false,
    };
  }

  onFocus = () => {
    this.setState({ focus: true });
    this.setState({ inputValue: "" });
  };
  offFocus = () => {
    this.setState({ focus: false });
  };

  onInputChange = (event) => {
    this.props.setInput(event.target.value);

    if (event.target.value === "") {
      this.props.refine();
    } else {
      this.props.refine(this.props.inputValue);
    }
  };

  handleClick = (value) => {
    this.props.onHitClick(value);
    if (value.details.name !== undefined) {
      this.setState({ inputValue: value.details.name });
      this.props.setInput(value.details.name);
    } else {
      this.setState({ inputValue: value.details.firstName + " " + value.details.lastName });
      this.props.setInput(value.details.firstName + " " + value.details.lastName);
    }
    this.offFocus();
  };

  render() {
    return (
      <div className="SelectAutoComplete">
        <input
          className="projectInput form-control AutoCompleteSelectInput"
          type="text"
          value={this.props.inputValue}
          onChange={(event) => this.onInputChange(event)}
          onFocus={this.onFocus}
          onBlur={this.offFocus}
        />
        {this.state.focus ? (
          <ul>
            {this.props.hits.map((hit) => (
              <li onMouseDown={(event) => this.handleClick(hit)} key={hit.objectID}>
                {hit.details.name || hit.details.firstName + " " + hit.details.lastName}
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const MyCustomAutocomplete = connectAutoComplete(CustomAutocomplete);

class SelectAutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: undefined,
      focus: false,
      key: getAlgoliaKey(this.props.index, this.props.AlgoliaKey),
      currentDependency: this.props.dependency,
    };
  }

  async getStringOnLoad(objectID) {
    const client = algoliasearch(`${config.algolia.appId}`, this.state.key);
    const currentIndex = client.initIndex(this.props.index);
    currentIndex.getObject(objectID, (error, result) => {
      if (error) {
        this.setState({ inputValue: "" });
      }
      if (result && result.hits === undefined) {
        this.setState({
          inputValue: result.details.name || result.details.firstName + " " + result.details.lastName,
        });
      }
    });
    if (objectID === undefined || objectID === "") {
      this.setState({ inputValue: "" });
    }
  }

  componentDidUpdate() {
    if (this.props.dependency !== this.state.currentDependency) {
      this.props.handleSelectAutoComplete(this.props.id, "");
      this.setState({ currentDependency: this.props.dependency, inputValue: "" });
    }
  }

  componentDidMount() {
    if (this.props.value && this.props.value !== "") {
      this.getStringOnLoad(this.props.value);
    } else {
      if (this.props.value === undefined || this.props.value === "") {
        this.setState({ inputValue: "" });
      }
    }
  }

  setInputValue = (input) => {
    if (input === "") {
      this.props.handleSelectAutoComplete(this.props.id, "");
      this.setState({ currentDependency: this.props.dependency, inputValue: "" });
    }
    this.setState({ inputValue: input });
  };

  render() {
    let filterString = this.props.filters || "";
    if (this.props.dependency !== undefined && this.props.dependency !== "") {
      filterString += " AND " + this.props.dependencyId + ":" + this.props.dependency;
    }
    return this.props.AlgoliaKey.key && (this.state.inputValue !== undefined || this.state.inputValue === "") ? (
      <InstantSearch indexName={this.props.index} apiKey={this.state.key} appId={`${config.algolia.appId}`}>
        <Configure filters={filterString} distinct />
        <MyCustomAutocomplete
          id={this.props.id}
          onHitClick={(value) => this.props.handleSelectAutoComplete(this.props.id, value)}
          value={this.props.value || ""}
          inputValue={this.state.inputValue}
          setInput={this.setInputValue}
        />
      </InstantSearch>
    ) : (
      ""
    );
  }
}
const mapStateToProps = (state) => {
  return { AlgoliaKey: state.key };
};
export default connect(mapStateToProps)(SelectAutoComplete);
