import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { setLoaderState, setAlgoliaLoaderState } from "../../../actions/loaderActions";
import { API } from "aws-amplify";
import { setRefresh } from "../../../actions/algoliaActions";
import { setJobDetails } from "../../../actions/projectsActions";

class AddProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: [
        {
          id: "name",
          label: "Job Name",
          type: "text",
          tooltip: "Job Name",
          required: true,
          value: ""
        },
        {
          id: "buildingUnit",
          label: "Building Unit",
          type: "text",
          tooltip: "Building Unit",
          required: false,
          value: ""
        },
        {
          id: "level",
          label: "Building Level",
          type: "text",
          tooltip: "Building Level",
          required: false,
          value: ""
        },
        {
          id: "floorPlanType",
          label: "Floor Plan Type",
          type: "text",
          tooltip: "Floor Plan Type",
          required: false,
          value: ""
        },
        {
          id: "status",
          label: "Job Status",
          type: "select",
          options: [
            { name: "Quote Requested", value: "quoteRequested" },
            { name: "Appointment Requested", value: "appointmentRequested" },
            { name: "Appointment Scheduled", value: "appointmentScheduled" },
            { name: "Measures Checked", value: "measuresChecked" },
            { name: "Quote Submitted", value: "quoteSubmitted" },
            { name: "Quote Accepted", value: "quoteAccepted" },
            { name: "Deposit Taken", value: "depositTaken" },
            { name: "Order Submitted", value: "orderSubmitted" },
            { name: "Order Confirmed", value: "orderConfirmed" },
            { name: "Products Delivered to Installer", value: "productsDeliveredToInstaller" },
            { name: "Installation Complete", value: "installationComplete" },
            { name: "Final Payment Made", value: "finalPaymentMade" },
            { name: "Job Complete", value: "jobComplete" }
          ],
          required: false,
          value: "quoteRequested"
        }
      ]
    };
  }

  get fields() {
    let fields = JSON.parse(JSON.stringify(this.state.fields)); // Making a deep copy
    const details = { ...this.props.details.details };
    // // Re-map details
    // if (details && details.options.discount) {
    //   details.discount = details.options.discount;
    //  details.options;
    // }

    for (let i = 0; i < fields.length; i++) {
      if (fields[i].type === "date") fields[i].value = new Date(details[fields[i].id]);
      else fields[i].value = details[fields[i].id];
    }
    return fields;
  }

  onProjectTypeChange = event => {
    this.setState({ projectType: event.target.value });
  };

  APIMethod = async formData => {
    try {
      this.props.dispatch(setLoaderState(true));
      this.props.dispatch(setAlgoliaLoaderState(true));
      // // Re-map form data
      // formData.options = { discount: formData.discount };
      // delete formData.discount;
      formData.options = this.props.details.details.options;
      const response = await API.put(
        "telishadeAPI",
        "/project/" +
          this.props.projectType +
          "/" +
          this.props.match.params.id +
          "/job/" +
          this.props.match.params.JobNo,
        {
          body: { details: { ...formData, number: this.props.details.details.number } }
        }
      );
      this.props.dispatch(setJobDetails(response));

      this.props.history.goBack();
      // Refresh any algolia components currently mounted 1 second after updating
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
        this.props.dispatch(setLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.fields}
        apiMethod={this.APIMethod}
        header="Edit Job"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

export default withRouter(connect()(AddProject));
