import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import LetterHead from "../../../TableComponents/LetterHead";
import ProjectHeader from "../../ProjectHeader";
import BlindsSummary from "./BlindsSummary";
import BlindsBillOfRates from "./BlindsBillOfRates";
import BlindsTotals from "./BlindsTotals";
import Terms from "../../../TableComponents/Terms";
import BlindsExtras from "./BlindsExtras";
import algoliasearch from "algoliasearch";
import config from "../../../../../../config";
import { API } from "aws-amplify";
import Modal from "../../../../PopUps/Modal";
import QuoteNotes from "./QuoteNotes";
import { sendToast } from "../../../../../../actions/toastActions";
import DeterminateLoader from "../../../../../Loader/DeterminateLoader";
import DiscountView from "../DiscountView/DiscountView";
import { storeBlindJobQuotePayload } from "../../../../../../actions/documentsActions";
import {
  emptyCustomer,
  emptyJobBranch,
  emptyBranchContact,
} from "../../../../../Customers/CustomerModels";

class ProjectQuoteBlinds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quote: undefined,
      customerDetails: undefined,
      error: undefined,
      completedRequests: 0,
      maxRequests: undefined,
    };
    this.getProjectQuote();
  }

  componentDidUpdate(prevProps) {
    //This Reloads the quote when the discount view switches
    if (
      this.props.documents.discountView !== prevProps.documents.discountView &&
      this.props.documents.discountView === false
    ) {
      this.setState({
        quote: undefined,
        customerDetails: undefined,
        error: undefined,
        completedRequests: 0,
        maxRequests: undefined,
      });
      this.getProjectQuote();
    }
  }

  async getProjectQuote() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getProjectQuote();
      }, 500);
    } else {
      const jobs = await this.getJobsForProject();
      let quoteResponseCollection = [];
      let quoteErrorCollection = [];
      let quoteCollection = [];

      this.setState({ maxRequests: jobs.length });
      //Get Quote for Every Job in Project
      jobs.forEach(async (job) => {
        const quote = this.getQuoteForJob(job.objectID);
        quoteResponseCollection.push(quote);
      });

      const customerDetails = await this.getCustomerInformation(this.props.projectDetails.details);
      quoteResponseCollection = await Promise.all(quoteResponseCollection);
      //Filter Out Blind only Jobs, Format for Display
      quoteResponseCollection.forEach((response, key) => {
        if (response && response.quote.length > 0) {
          quoteCollection.push({
            name: jobs[key].details.name,
            jobNo: jobs[key].details.number,
            id: response.id,
            quote: response.quote,
            discount: response.discounts,
          });
        }
        if (response?.errors?.length > 0) {
          response.errors.forEach((error) => {
            quoteErrorCollection.push({ ...error.error });
          });
        }
      });
      if (quoteErrorCollection.length > 0) {
        this.handleErrors(quoteErrorCollection);
      }

      const combinedQuoteCollection = this.convertQuoteForDiscount(quoteCollection);
      this.props.dispatch(storeBlindJobQuotePayload(combinedQuoteCollection));
      this.props.dispatch(sendToast("success", "Quote Generated", "center"));
      this.setState({ quote: quoteCollection, customerDetails });
    }
  }

  convertQuoteForDiscount(quoteCollection) {
    if (quoteCollection.length === 0) return { quote: [] };
    let combinedquote = JSON.parse(JSON.stringify(quoteCollection[0]));
    for (let i = 1; i < quoteCollection.length; i++) {
      combinedquote.quote.push(...quoteCollection[i].quote);
    }
    return combinedquote;
  }

  handleErrors(errorCollection) {
    let ErrorDialog = "";
    errorCollection.forEach((error) => {
      ErrorDialog += this.formatErrorMessage(error);
    });
    console.error(ErrorDialog);
    this.setState({ error: ErrorDialog });
  }

  formatErrorMessage(errorObject) {
    let errorMessage = "";
    errorMessage = errorObject.message;
    errorMessage += "\n";
    return errorMessage;
  }

  async getCustomerInformation(details) {
    let customer = undefined;
    let branch = undefined;
    let contact = undefined;

    const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
    const customerIndex = client.initIndex(`${config.algolia.prefix}customers_name_asc`);
    const branchIndex = client.initIndex(`${config.algolia.prefix}customers_branches_name_asc`);
    const contactIndex = client.initIndex(
      `${config.algolia.prefix}customers_branches_contacts_name_asc`
    );

    if (details?.customerId) {
      customer = await customerIndex.getObject(details.customerId);
      branch = await branchIndex.getObject(details.customerBranchId);
      contact = await contactIndex.getObject(details.customerBranchContactId);
    } else {
      customer = emptyCustomer;
      branch = emptyJobBranch;
      contact = emptyBranchContact;
    }
    return { jobBranch: branch, branchContact: contact, customer };
  }

  async getJobsForProject() {
    try {
      const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
      const index = client.initIndex(`${config.algolia.prefix}projects_jobs_number_asc`);
      const response = await index.search({
        query: "",
        filters: "isDeleted:false AND projectId:" + this.props.match.params.id,
        hitsPerPage: 1000,
      });
      return response.hits;
    } catch (e) {
      console.error(e);
    }
  }

  async getQuoteForJob(JobId) {
    try {
      const response = await API.get(
        "telishadeAPI",
        `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${JobId}/quote/blind`
      );
      this.setState({ completedRequests: this.state.completedRequests + 1 });
      return response;
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    if (!this.state.quote) {
      return (
        <Fragment>
          <DeterminateLoader
            maxValue={this.state.maxRequests}
            currentValue={this.state.completedRequests}
          />
          <div className="loader">LOADING...</div>
        </Fragment>
      );
    } else {
      if (this.props.documents.discountView) return <DiscountView />;
      else
        return (
          <Fragment>
            {this.state.error ? (
              <Modal header={"Blind Quote Errors"} displayMessage={this.state.error} />
            ) : (
              ""
            )}
            <div id="Document">
              <LetterHead />
              <ProjectHeader
                customerDetails={this.state.customerDetails}
                projectDetails={this.props.projectDetails}
                type="Quote"
                docNumber={this.props.docNumber + "-B"}
              />
              <BlindsSummary
                isRetail={this.props.isRetail}
                response={this.state.quote}
                grouping={this.props.options.grouping}
                sorting={this.props.options.sorting}
                showDiscounts={this.props.options.showDiscounts}
                projectDiscount={parseFloat(
                  this.props.projectDetails.details.options.discount.retail.percentage
                )}
              />

              <BlindsBillOfRates
                display={this.props.options.billofRates}
                isRetail={this.props.isRetail}
                response={this.state.quote}
              />
              <BlindsTotals
                showDiscounts={this.props.options.showDiscounts}
                isRetail={this.props.isRetail}
                discount={parseFloat(
                  this.props.projectDetails.details.options.discount.retail.percentage
                )}
                response={this.state.quote}
              />
              <QuoteNotes display={this.props.options.itemNotes} response={this.state.quote} />
              <Terms />
              <BlindsExtras
                display={this.props.options.scheduleofItems}
                isRetail={this.props.isRetail}
                response={this.state.quote}
                discount={parseFloat(
                  this.props.projectDetails?.details?.options?.discount?.retail?.percentage || 0
                )}
              />
            </div>
          </Fragment>
        );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    AlgoliaKey: state.key,
    projectDetails: state.projects.projectDetails,
    documents: state.documents,
  };
};

export default withRouter(connect(mapStateToProps)(ProjectQuoteBlinds));
