import React, { Component } from "react";
import { connect } from "react-redux";
import SearchableList from "../../../Generic/Algolia/SearchableList";
//Actions
import { setCurrentComponent, setCurrentProduct, selectID } from "../../../../actions/productsActions";
import config from "../../../../config";

class BlindPricing extends Component {
  HitComponent = ({ hit }) => {
    const listItemClickHandler = () => {
      const currentURL = this.props.history.location.pathname;
      this.props.history.push(currentURL + "/" + hit.objectID);
      this.props.dispatch(selectID(hit.objectID));
    };

    let className = "";
    if (this.props.id === hit.objectID) className = { className: "selected" };
    return (
      <p {...className} onClick={listItemClickHandler}>
        <strong>{hit.details.name}</strong>
      </p>
    );
  };

  clickHandler = (clickedItem) => {
    this.props.history.push("/products/blinds/pricing-groups/" + clickedItem.itemId);
    this.props.dispatch(selectID(clickedItem.itemId));
  };

  componentDidMount() {
    this.props.dispatch(setCurrentComponent("Pricing Groups"));
    this.props.dispatch(setCurrentProduct("Blinds"));
  }

  render() {
    return (
      <>
        <SearchableList
          appId={`${config.algolia.appId}`}
          index={`${config.algolia.prefix}products_price_lists_name_asc`}
          hitComponent={this.HitComponent}
          selectedObjectId={this.props.id}
          filters={`isDeleted:false AND productId:${this.props.currentProductID}`}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return state.products;
};

export default connect(mapStateToProps)(BlindPricing);
