import { SEND_TOAST, FINISH_TOAST } from "../actions/toastActions";

const initalState = {
  toastCollection: []
};

export default function toastReducer(state = initalState, action) {
  switch (action.type) {
    case SEND_TOAST:
      let newToastCollection = [...state.toastCollection, { ...action.payload }];
      return {
        ...state,
        toastCollection: newToastCollection
      };
    case FINISH_TOAST:
      let copyToastCollection = [...state.toastCollection];
      copyToastCollection.splice(action.payload.index, 1);
      return {
        ...state,
        toastCollection: copyToastCollection
      };
    default:
      return state;
  }
}
