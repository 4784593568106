import React, { Component } from "react";
import { connect } from "react-redux";
import PopUp from "../../Generic/PopUpDialog/PopUp";
import { API } from "aws-amplify";
import { withRouter } from "react-router";
import { setRefresh } from "../../../actions/algoliaActions";
import { setLoaderState, setAlgoliaLoaderState } from "../../../actions/loaderActions";

class DeleteProject extends Component {
  APIMethod = async () => {
    try {
      this.props.dispatch(setLoaderState(true));
      this.props.dispatch(setAlgoliaLoaderState(true));
      await API.del(
        "telishadeAPI",
        `/project/${this.props.projectDetails.type}/${this.props.match.params.id}/job/${this.props.match.params.JobNo}`
      );

      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.history.push(`/projects/${this.props.match.params.id}`);
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setLoaderState(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUp
        header="Delete Job?"
        body={"Are you sure you want to delete " + this.props.jobDetails.details.name}
        button="Delete"
        buttoncolour="red"
        button2="cancel"
        buttonClickHandler={this.APIMethod}
        button2ClickHandler={undefined}
      />
    );
  }
}
const mapStateToProps = state => state.projects;
export default withRouter(connect(mapStateToProps)(DeleteProject));
