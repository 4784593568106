import React, { Component } from "react";
import { connect } from "react-redux";
import "./Products.css";
import { toggleSubNav } from "../../actions/sideNavActions";
import { Switch, Redirect, Route } from "react-router-dom";
import Blinds from "./Blinds/Blinds";
import Curtains from "./Curtains/Curtains";
import ProductDetailView from "./ProductDetailView";

class Products extends Component {
  componentDidMount() {
    // Update state of this submenu to open
    this.props.dispatch(toggleSubNav());
  }

  componentWillUnmount() {
    // Update state of this submenu to closed
    this.props.dispatch(toggleSubNav());
  }

  render() {
    return (
      <div className="products">
        <Switch>
          <Route path="/products/blinds/" component={Blinds} />
          <Route path="/products/curtains" component={Curtains} />
          <Route path="/products/:product/:component/:id" component={ProductDetailView} />
          <Redirect to="/products/blinds" />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state.products;
};

export default connect(mapStateToProps)(Products);
