import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { manufacturerFields } from "../fields";
import { API } from "aws-amplify";
import {
  selectManufacturer,
  updateManufacturerDetails
} from "../../../actions/manufacturersActions";
import { setRefresh } from "../../../actions/algoliaActions";
import { setAlgoliaLoaderState, setLoaderState } from "../../../actions/loaderActions";

class AddManufacturer extends Component {
  async APIMethod(formData) {
    this.props.dispatch(setAlgoliaLoaderState(true));
    try {
      const response = await API.post("telishadeAPI", "/manufacturer/", {
        body: { details: formData }
      });

      await this.props.dispatch(selectManufacturer(response.id));
      await this.props.dispatch(updateManufacturerDetails(response.details));
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.history.push(`/manufacturers/${response.id}/branches`);
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setLoaderState(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  }

  render() {
    const fields = [...manufacturerFields];

    return (
      <PopUpForm
        fields={fields}
        header="Add New Manufacturer"
        button="ACCEPT"
        buttoncolour="green"
        button2="CANCEL"
        button2colour="red"
        buttonClickHandler={undefined}
        button2ClickHandler={undefined}
        apiMethod={details => this.APIMethod(details)}
      />
    );
  }
}

export default withRouter(connect()(AddManufacturer));
