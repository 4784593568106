import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SearchableList from "../../Generic/Algolia/SearchableList";
import { MDBBtn, MDBIcon } from "mdbreact";
import AddFAB from "../../Generic/Images/AddFab.png";
import { selectContact, updateContactDetails } from "../../../actions/customersActions";
import config from "../../../config";

class Contacts extends Component {
  HitComponent = ({ hit }) => {
    const listItemClickHandler = () => {
      this.props.dispatch(updateContactDetails(hit.details));
      this.props.dispatch(selectContact(hit.objectID));
      this.props.history.push(
        `/customers/${this.props.match.params.customerId}/contacts/bid/${this.props.match.params.branchId}/contacts/${hit.objectID}`
      );
    };

    let className = "";
    if (this.props.id === hit.objectID) {
      className = { className: "selected" };
    }

    return (
      <Fragment>
        <p {...className} onClick={listItemClickHandler}>
          <strong>
            {hit.details.firstName} {hit.details.lastName}
            <br />
          </strong>
          <strong>Ph: </strong>
          {hit.details.phone}
          <br />
          <strong>Mob: </strong>
          {hit.details.mobile}
          <br />
          <strong>Email: </strong>
          {hit.details.email}
          <br />
          <strong>Fax: </strong>
          {hit.details.fax}
          <br />
        </p>
        {className.className === "selected" ? (
          <Fragment>
            <MDBBtn
              onClick={() => {
                this.props.history.push(
                  `/customers/${this.props.match.params.customerId}/branches/${this.props.match.params.branchId}/contacts/${hit.objectID}/edit`
                );
              }}
              color="blue"
              id="contactEditBtn"
            >
              Edit
            </MDBBtn>
            <MDBBtn
              onClick={() => {
                this.props.history.push(
                  `/customers/${this.props.match.params.customerId}/branches/${this.props.match.params.branchId}/contacts/${hit.objectID}/delete`
                );
              }}
              color="red"
              id="contactDeleteBtn"
            >
              <MDBIcon icon="trash" />
            </MDBBtn>
          </Fragment>
        ) : (
          ""
        )}
      </Fragment>
    );
  };

  render() {
    const { branchId } = this.props.match.params;

    return (
      <Fragment>
        <div className="contact-container">
          <div className="contact-header">
            <h6>Contacts</h6>
          </div>
          <div className="contact-list">
            <SearchableList
              appId={`${config.algolia.appId}`}
              index={`${config.algolia.prefix}customers_branches_contacts_name_asc`}
              hitComponent={this.HitComponent}
              selectedObjectId={this.props.id}
              filters={`isDeleted:false AND branchId:${branchId}`}
            />
            <img
              className={"fabButton"}
              id="addContactPlusBtn"
              alt="Add"
              src={AddFAB}
              onClick={() =>
                this.props.history.push(
                  `/customers/${this.props.match.params.customerId}/branches/${branchId}/contacts/add`
                )
              }
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  return state.customers.contact;
};

export default withRouter(connect(mapStateToProps)(Contacts));
