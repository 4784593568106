import React, { Component, Fragment } from "react";
import { numberWithCommas } from "../../../../../../utilities/utils";

class CurtainsExtras extends Component {
  constructScheduleofItems(jobArray) {
    const newJobArray = JSON.parse(JSON.stringify(jobArray));
    newJobArray.forEach(job => {
      job.quote = this.constructQuoteObjectbyLocation(job.quote);
    });
    return newJobArray.map((job, key) => {
      return (
        <Fragment key={key}>
          <tr className="details-header jobNumber">
            <td colSpan="4">{job.name}</td>
          </tr>
          {job.quote.map((location, key) => {
            return (
              <Fragment key={key}>
                <tr className="details-header">
                  <td colSpan="4">{`Location - ${location.location}`}</td>
                </tr>
                {this.constructLocationInfo(location.locationQuotes)}
              </Fragment>
            );
          })}
        </Fragment>
      );
    });
  }

  constructLocationInfo(locationQuoteArray) {
    let displayObject = {
      fabrics: [],
      tracks: [],
      makeup: { total: 0, qty: 0 },
      install: { total: 0, qty: 0 }
    };
    locationQuoteArray.forEach(quote => {
      let fabricFlag = false;
      let trackFlag = false;
      displayObject.fabrics.forEach(addedFabric => {
        if (addedFabric.name === quote.description.fabric) {
          addedFabric.total += quote.costInfo.right
            ? quote.costInfo.right.fabricCost + quote.costInfo.left.fabricCost
            : quote.costInfo.fabricCost;
          addedFabric.qty++;
          fabricFlag = true;
        }
      });
      if (!fabricFlag) {
        displayObject.fabrics.push({
          name: quote.description.fabric,
          total: quote.costInfo.right
            ? quote.costInfo.right.fabricCost + quote.costInfo.left.fabricCost
            : quote.costInfo.fabricCost,
          qty: 1
        });
      }
      displayObject.tracks.forEach(addedTrack => {
        if (addedTrack.name === quote.description.track) {
          addedTrack.total += this.props.isRetail
            ? quote.costInfo.markedUpTrackTotalCost
            : quote.costInfo.trackTotalCost;
          addedTrack.qty++;
          trackFlag = true;
        }
      });
      if (!trackFlag) {
        displayObject.tracks.push({
          name: quote.description.track,
          total: this.props.isRetail
            ? quote.costInfo.markedUpTrackTotalCost
            : quote.costInfo.trackTotalCost,
          qty: 1
        });
      }
      displayObject.makeup.total += quote.costInfo.right
        ? quote.costInfo.right.makeUpCost + quote.costInfo.left.makeUpCost ||
          quote.costInfo.right.makeUpCost + quote.costInfo.left.makeUpCost
        : quote.costInfo.makeUpCost;
      displayObject.makeup.qty++;
    });
    return (
      <Fragment>
        <tr className="details-header">
          <td>Fabrics</td>
        </tr>
        {displayObject.fabrics.map((fabric, key) => (
          <tr key={key}>
            <td>{fabric.name}</td>
            <td className="data-align-right">
              ${numberWithCommas((fabric.total / fabric.qty).toFixed(2))}
            </td>
            <td className="data-align-right">{fabric.qty}</td>
            <td className="data-align-right">${numberWithCommas(fabric.total.toFixed(2))}</td>
          </tr>
        ))}
        <tr className="details-header">
          <td>Tracks</td>
        </tr>
        {displayObject.tracks.map((track, key) => (
          <tr key={key}>
            <td>{track.name}</td>
            <td className="data-align-right">
              ${numberWithCommas((track.total / track.qty).toFixed(2))}
            </td>
            <td className="data-align-right">{track.qty}</td>
            <td className="data-align-right">${numberWithCommas(track.total.toFixed(2))}</td>
          </tr>
        ))}
        <tr className="">
          <td className="bold-text">Makeup</td>
          <td className="data-align-right">
            ${numberWithCommas((displayObject.makeup.total / displayObject.makeup.qty).toFixed(2))}
          </td>
          <td className="data-align-right">{displayObject.makeup.qty}</td>
          <td className="data-align-right">
            ${numberWithCommas(displayObject.makeup.total.toFixed(2))}
          </td>
        </tr>
        {this.handleComponent("driveMechanism", locationQuoteArray, "Drive Mechanisms")}
        {this.handleComponent("bumph", locationQuoteArray, "Bumphs")}
        {this.handleComponent("leadWeight", locationQuoteArray, "Lead Weights")}
        {this.handleComponent("lining", locationQuoteArray, "Linings")}
        {this.handleComponent("pelmet", locationQuoteArray, "Pelmets")}
        {this.handleComponent("accessory", locationQuoteArray, "Accessories")}
        {this.handleServices(locationQuoteArray)}
      </Fragment>
    );
  }

  handleComponent(ComponentKey, locationQuotes, Heading = ComponentKey) {
    let componentCollection = [];
    locationQuotes.forEach(quote => {
      if (quote.extras[ComponentKey]) {
        let AddedFlag = false;
        componentCollection.forEach(component => {
          if (quote.extras[ComponentKey].id === component.id) {
            component.total += this.calculateExtra(ComponentKey, quote).total;
            component.qty++;
            AddedFlag = true;
          }
        });
        if (!AddedFlag) {
          componentCollection.push({
            name: quote.extras[ComponentKey].details.name,
            id: quote.extras[ComponentKey].id,
            total: this.calculateExtra(ComponentKey, quote).total,
            qty: 1
          });
        }
      }
    });
    if (componentCollection.length > 0) {
      return (
        <Fragment>
          <tr className="details-header">
            <td>{Heading}</td>
          </tr>
          {componentCollection.map((component, key) => (
            <tr key={key}>
              <td>{component.name}</td>
              <td className="data-align-right">
                ${numberWithCommas((component.total / component.qty).toFixed(2))}
              </td>
              <td className="data-align-right">{component.qty}</td>
              <td className="data-align-right">${numberWithCommas(component.total.toFixed(2))}</td>
            </tr>
          ))}
        </Fragment>
      );
    }
  }

  handleServices(locationQuotes) {
    let serviceCollection = [];
    locationQuotes.forEach(quote => {
      quote.extras.services.forEach(service => {
        let AddedFlag = false;
        serviceCollection.forEach(addedService => {
          if (addedService.id === service.id) {
            addedService.total += this.props.isRetail
              ? (parseFloat(service.details.cost) / 100) *
                (100 + parseFloat(service.details.markup))
              : parseFloat(service.details.cost);
            addedService.qty++;
            AddedFlag = true;
          }
        });
        if (!AddedFlag) {
          serviceCollection.push({
            id: service.id,
            name: service.details.name,
            total: this.props.isRetail
              ? (parseFloat(service.details.cost) / 100) *
                (100 + parseFloat(service.details.markup))
              : parseFloat(service.details.cost),
            qty: 1
          });
        }
      });
    });
    if (serviceCollection.length > 0) {
      return (
        <Fragment>
          <tr className="details-header">
            <td>Services</td>
          </tr>
          {serviceCollection.map((service, key) => (
            <tr key={key}>
              <td>{service.name}</td>
              <td className="data-align-right">
                ${numberWithCommas((service.total / service.qty).toFixed(2))}
              </td>
              <td className="data-align-right">{service.qty}</td>
              <td className="data-align-right">${numberWithCommas(service.total.toFixed(2))}</td>
            </tr>
          ))}
        </Fragment>
      );
    }
  }

  constructQuoteObjectbyLocation(quoteArray) {
    let LocationQuoteCollection = [];
    quoteArray.forEach(quote => {
      let AddedFlag = false;
      LocationQuoteCollection.forEach(addedItem => {
        if (quote.description.allDetails.location === addedItem.location) {
          addedItem.locationQuotes.push({ ...quote });
          AddedFlag = true;
        }
      });
      if (!AddedFlag) {
        LocationQuoteCollection.push({
          locationQuotes: [{ ...quote }],
          location: quote.description.allDetails.location
        });
      }
    });
    return LocationQuoteCollection;
  }

  calculateCurtainItemTotal(quoteItem, discount = 0) {
    let totalforItem = 0;
    totalforItem += this.props.isRetail
      ? quoteItem.costInfo.markedUpBasePrice
      : quoteItem.costInfo.basePrice;
    totalforItem = (totalforItem / 100) * (100 - discount);
    totalforItem += this.calculateExtra("bumph", quoteItem).total;
    totalforItem += this.calculateExtra("driveMechanism", quoteItem).total;
    totalforItem += this.calculateExtra("leadWeight", quoteItem).total;
    totalforItem += this.calculateExtra("lining", quoteItem).total;
    totalforItem += this.calculateExtra("pelmet", quoteItem).total;
    totalforItem += this.calculateExtra("accessory", quoteItem).total;
    totalforItem += this.calculateService(quoteItem).total;
    totalforItem += this.props.isRetail
      ? quoteItem.costInfo.markedUpTrackTotalCost
      : quoteItem.costInfo.trackTotalCost;
    return Math.ceil(totalforItem);
  }

  calculateExtra(extraKey, item) {
    let totalPrice = 0;
    let totalQty = 0;
    if (item.extras[extraKey]) {
      if (item.extras[extraKey].details.pricePerMetre) {
        const totalPricePerMetre =
          parseFloat(item.extras[extraKey].details.pricePerMetre) *
          (parseFloat(item.description.width) / 1000);
        const totalPricePerMetreMarkup =
          (totalPricePerMetre / 100) * (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail ? totalPricePerMetreMarkup : totalPricePerMetre;
        totalQty++;
      } else if (item.extras[extraKey].details.cost) {
        const totalCost =
          (parseFloat(item.extras[extraKey].details.cost) / 100) *
          (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += this.props.isRetail
          ? totalCost
          : parseFloat(item.extras[extraKey].details.cost);
        totalQty++;
      }
    }
    return { total: totalPrice, qty: totalQty };
  }

  calculateService(item) {
    let totalPrice = 0;
    let totalQty = 0;
    item.extras.services.forEach(service => {
      const totalWithMarkup =
        (parseFloat(service.details.cost) / 100) * (100 + parseFloat(service.details.markup));
      totalPrice += this.props.isRetail ? totalWithMarkup : parseFloat(service.details.cost);
      totalQty++;
    });
    return { total: totalPrice, qty: totalQty };
  }

  render() {
    return (
      <div
        className="doc-box page-break-before"
        id="ScheduleofItems"
        style={{ display: this.props.display ? "" : "None" }}
      >
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="heading">
              <td colSpan="4">
                <span className="heading-one">PROJECT QUOTE - SCHEDULE OF ITEMS</span>
              </td>
            </tr>
            <tr className="sub-heading">
              <td></td>
              <td>Average Price</td>
              <td>QTY</td>
              <td>Total</td>
            </tr>
            {this.constructScheduleofItems(this.props.response)}
          </tbody>
        </table>
      </div>
    );
  }
}

export default CurtainsExtras;
