import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

class JobHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docType: this.documentReference ? this.documentReference : "--",
    };
  }

  get FormattedAddress() {
    const streetNumber = this.props.branch?.details?.address?.streetNumber
      ? this.props.branch.details.address.streetNumber
      : "";
    const streetName = this.props.branch?.details?.address?.streetName
      ? this.props.branch.details.address.streetName
      : "";
    const city = this.props.branch?.details?.address?.city
      ? this.props.branch.details.address.city
      : "";

    return (
      <Fragment>
        {streetNumber} {streetName} - {city}
      </Fragment>
    );
  }

  get DateNow() {
    var tempDate = new Date();
    return tempDate.toLocaleDateString("en-AU");
  }

  get documentReference() {
    return this.props.type === "Purchase Order"
      ? "PO #"
      : this.props.type === "Quote"
      ? "QUOTE #"
      : this.props.type === "Margin Report"
      ? "Margin Report #"
      : "Ref No.";
  }

  get showDeliveryInformation() {
    if (this.props.customDelivery) {
      return (
        <div id="JobDetails">
          <span id="title">
            <span id="grey-text">
              Delivery Information
              <br />
            </span>
            <span className="whiteSpaceNeeded">{this.props.customDelivery}</span>
          </span>
        </div>
      );
    } else if (this.props.deliveryAddress) {
      if (this.props.deliveryAddress.details) {
        const deliveryAddress = this.props.deliveryAddress.details.address;
        return (
          <div id="JobDetails">
            <span id="title">
              <span id="grey-text">
                Delivery Information
                <br />
                To:
              </span>
              {this.props.deliveryPerson || this.props.branch.city}
            </span>
            <br />
            Address:{" "}
            {`${deliveryAddress.streetNumber} ${deliveryAddress.streetName}, ${deliveryAddress.city}`}
            <br />
            State: {deliveryAddress.state}
            <br />
            Postal Code: {deliveryAddress.postcode}
            <br />
          </div>
        );
      } else return "";
    } else
      return (
        <div id="JobDetails">
          <span id="title">
            <span id="grey-text">
              Delivery Information
              <br />
              To:
            </span>
            {this.props.deliveryPerson || this.state.branch.city}
          </span>
          <br />
          Address:
          <br />
          State:
          <br />
          Postal Code:
          <br />
        </div>
      );
  }

  render() {
    const { customer, contact, jobDetails } = this.props;
    return (
      <Fragment>
        <div id="JobDocHeader">
          <div id="JobDocDetails">
            {this.props.type === "Purchase Order" ? (
              <span id="title">
                <span id="grey-text">Manufacturer Name: </span>{" "}
                {this.props.customManufacturer
                  ? this.props.customManufacturer.details.name
                  : this.props.manufacturerName || ""}
              </span>
            ) : null}
            <br />
            <span id="title">
              <span id="grey-text">Project Name: </span> {this.props.projectDetails?.name || ""}
            </span>
            <br />
            <span id="title">
              <span id="grey-text">Job Name: </span> {jobDetails?.name || ""}
            </span>
            <br />
            <span id="title">
              <span id="grey-text">Client Name: </span>
              {customer.details?.name || ""}
            </span>
            <br />
            <span id="title">
              <span id="grey-text">Contact Name: </span>
              {contact?.details?.firstName || ""} {contact?.details?.lastName || ""}
            </span>
            <br />
            Client Address: {this.FormattedAddress}
            <br />
            Phone: {customer.details?.phone || ""}
            <br />
            Mobile: {customer.details?.mobile || ""}
            <br />
            Email: {customer.details?.email || ""}
            <br />
            {this.props.type === "Purchase Order" ? (
              <span id="title">
                <span id="grey-text">Discount Number: </span>
                {this.props.discountNumber || customer.discountNumber || ""}
                <br />
              </span>
            ) : (
              ""
            )}
            {this.props.type === "Purchase Order" ? (
              <span id="title">
                <span id="grey-text">Account: </span>
                {this.props.account || customer.account || ""}
                <br />
              </span>
            ) : (
              ""
            )}
          </div>
          {this.props.type === "Purchase Order" ? this.showDeliveryInformation : ""}

          <span>
            <span id="title">{this.state.docType}</span> <br />
            <span id="title">Document Date</span> <br />
            {this.props.type === "Purchase Order" ? (
              this.props.dateRequired ? (
                <span id="title">Required Date</span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </span>
          <span id="QuoteNumberAndDate">
            <span id="title">{this.props.docNumber}</span>
            <span id="title">{this.DateNow}</span>
            {this.props.type === "Purchase Order" ? (
              <span id="title">
                {this.props.dateRequired ? this.props.dateRequired.toLocaleDateString("en-AU") : ""}
              </span>
            ) : (
              ""
            )}
          </span>
        </div>
      </Fragment>
    );
  }
}

const mapStatetoProps = (state) => ({
  projectDetails: state.projects.projectDetails.details,
  jobDetails: state.projects.jobDetails.details,
});

export default connect(mapStatetoProps)(JobHeader);
