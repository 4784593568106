import React, { Fragment, Component } from "react";
import { Route, Switch } from "react-router-dom";
import CreateCurtainStyle from "./CreateCurtainStyle";
import CreateCurtainFabric from "./CreateCurtainFabric";
import CreateCurtainDrive from "./CreateCurtainDrive";
import CreateCurtainBumph from "./CreateCurtainBumph";
import CreateCurtainLining from "./CreateCurtainLining";
import CreateCurtainPelmet from "./CreateCurtainPelmet";
import CreateCurtainTrack from "./CreateCurtainTrack";
import CreateCurtainLeadWeight from "./CreateCurtainLeadWeight";
import CreateCurtainFabricSurcharge from "./CreateCurtainFabricSurcharge";
import CreateCurtainPricingGroup from "./CreateCurtainPricingGroup";
import CreateCurtainService from "./CreateCurtainService";
import CreateCurtainRunner from "./CreateCurtainRunner";
import CreateCurtainTape from "./CreateCurtainTape";
import CreateCurtainColourList from "./CreateCurtainColourList";
import CreateCurtainAccessory from "./CreateCurtainAccessory";

class CreateCurtainComponentRoutes extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route path="/products/curtains/style/add" component={CreateCurtainStyle} />
          <Route path="/products/curtains/fabric/add" component={CreateCurtainFabric} />
          <Route path="/products/curtains/drive-mechanism/add" component={CreateCurtainDrive} />
          <Route
            path="/products/curtains/pricing-group/add"
            component={CreateCurtainPricingGroup}
          />
          <Route path="/products/curtains/bumph/add" component={CreateCurtainBumph} />
          <Route path="/products/curtains/lining/add" component={CreateCurtainLining} />
          <Route path="/products/curtains/pelmet/add" component={CreateCurtainPelmet} />
          <Route path="/products/curtains/track/add" component={CreateCurtainTrack} />
          <Route path="/products/curtains/lead-weight/add" component={CreateCurtainLeadWeight} />
          <Route
            path="/products/curtains/fabric-surcharge/add"
            component={CreateCurtainFabricSurcharge}
          />
          <Route path="/products/curtains/service-and-fee/add" component={CreateCurtainService} />
          <Route path="/products/curtains/runner/add" component={CreateCurtainRunner} />
          <Route path="/products/curtains/tape/add" component={CreateCurtainTape} />
          <Route path="/products/curtains/colour-list/add" component={CreateCurtainColourList} />
          <Route exact path="/products/curtains/accessory/add" component={CreateCurtainAccessory} />
        </Switch>
      </Fragment>
    );
  }
}

export default CreateCurtainComponentRoutes;
