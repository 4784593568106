import React, { Component } from "react";
import QuoteBlinds from "./QuoteBlinds/JobQuoteBlinds";
import QuoteCurtains from "./QuoteCurtains/JobQuoteCurtains";
import { MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink, MDBBtn } from "mdbreact";
import { connect } from "react-redux";
import { changeDocumentProduct, toggleDiscountView } from "../../../../../actions/documentsActions";
import PopUpForm from "../../../../Generic/PopUpDialog/PopUpForm";

class JobQuoteWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      retail: this.props.match.params[0] === "wholesale-quote" ? false : true,
      items: {
        default: this.props.currentProduct === "blinds" ? "blinds" : "curtains"
      },
      optionsPopUp: false,
      options: {
        billofRates: false,
        scheduleofItems: false,
        detailed: true,
        grouping: "job",
        itemNotes: false,
        scheduleofItemsDetailed: false
      }
    };
  }

  getValidationTypes = () => {
    return [
      {
        id: "billofRates",
        label: "Show Bill of Rates?",
        type: "select",
        options: [
          { value: true, name: "Yes" },
          { value: false, name: "No" }
        ],
        required: true,
        value: this.state.options.billofRates.toString()
      },
      {
        id: "scheduleofItems",
        label: "Show Schedule of Items?",
        type: "select",
        options: [
          { value: true, name: "Yes" },
          { value: false, name: "No" }
        ],
        required: true,
        value: this.state.options.scheduleofItems.toString()
      },
      {
        id: "scheduleofItemsDetailed",
        label: "Show Details on Schedule of Items?",
        type: "select",
        options: [
          { value: true, name: "Yes" },
          { value: false, name: "No" }
        ],
        required: true,
        value: this.state.options.scheduleofItemsDetailed.toString()
      },
      {
        id: "itemNotes",
        label: "Show Item Notes?",
        type: "select",
        options: [
          { value: true, name: "Yes" },
          { value: false, name: "No" }
        ],
        required: true,
        value: this.state.options.itemNotes.toString()
      },
      {
        id: "detailed",
        label: "Show Detailed View?",
        type: "select",
        options: [
          { value: true, name: "Yes" },
          { value: false, name: "No" }
        ],
        required: true,
        value: this.state.options.detailed.toString()
      },
      {
        id: "grouping",
        label: "Group Items By?",
        type: "select",
        options: [
          { value: "job", name: "Job Name (Default)" },
          { value: "location", name: "Location" },
          { value: "productCode", name: "Product Code" },
          { value: "driveType", name: "Drive Mechanism Type" },
          { value: "style", name: "Style Name" },
          { value: "fabric", name: "Fabric Name" },
          { value: "fabricAndColour", name: "Fabric + Colour" },
          { value: "driveMechanism", name: "Drive Mechanism Name" }
        ],
        required: true,
        value: this.state.options.grouping.toString()
      }
    ];
  };

  handleOptionsPopUp = formData => {
    this.setState({
      options: {
        ...this.state.options,
        billofRates: formData.billofRates === "true",
        scheduleofItems: formData.scheduleofItems === "true",
        scheduleofItemsDetailed: formData.scheduleofItemsDetailed === "true",
        itemNotes: formData.itemNotes === "true",
        detailed: formData.detailed === "true",
        grouping: formData.grouping
      },
      optionsPopUp: false
    });
  };

  togglePills = (type, tab) => e => {
    e.preventDefault();
    if (this.state.items[type] !== tab) {
      this.props.dispatch(changeDocumentProduct(tab));
      let items = { ...this.state.items };
      items[type] = tab;
      this.setState({
        items
      });
    }
  };

  render() {
    return (
      <div className="quote-wrapper">
        {this.state.optionsPopUp ? (
          <PopUpForm
            fields={this.getValidationTypes()}
            apiMethod={this.handleOptionsPopUp}
            header="Quote Options"
            button="Accept"
            buttoncolour="green"
            button2="Cancel"
            button2colour="red"
            button2ClickHandler={() => this.setState({ optionsPopUp: false })}
          />
        ) : (
          ""
        )}
        <MDBNav className="nav-pills fluid align-middle d-flex justify-content-center">
          <MDBNavItem>
            <MDBNavLink
              to="#"
              active={this.state.items["default"] === "blinds"}
              onClick={this.togglePills("default", "blinds")}
            >
              Blinds
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink
              to="#"
              active={this.state.items["default"] === "curtains"}
              onClick={this.togglePills("default", "curtains")}
            >
              Curtains
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBBtn
          color="blue"
          id="quoteDiscountButtonJob"
          onClick={() => this.props.dispatch(toggleDiscountView(true))}
        >
          Discounts
        </MDBBtn>
        <MDBBtn
          color="grey"
          id="quoteOptionsButtonJob"
          onClick={() => this.setState({ optionsPopUp: true })}
        >
          Options
        </MDBBtn>
        <MDBBtn
          color="green"
          id="quoteExportButton"
          onClick={() => {
            // try { THIS IS TEMPORARY
            //   savePDF(document.getElementById("Document"), {
            //     fileName: `${this.props.docNumber}.pdf`,
            //     repeatHeaders: true,
            //     paperSize: "A4",
            //     landscape: false,
            //     scale: 0.4
            //   });
            // } catch (e) {
            //   console.error(e);
            // }
            window.print();
          }}
        >
          Export
        </MDBBtn>
        <MDBTabContent activeItem={this.state.items["default"]}>
          <MDBTabPane tabId="blinds">
            <QuoteBlinds
              isRetail={this.props.match.params[0] === "retail-quote"}
              docNumber={this.props.docNumber}
              options={this.state.options}
            />
          </MDBTabPane>
          <MDBTabPane tabId="curtains">
            <QuoteCurtains
              isRetail={this.props.match.params[0] === "retail-quote"}
              docNumber={this.props.docNumber}
              options={this.state.options}
            />
          </MDBTabPane>
        </MDBTabContent>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.documents };
};

export default connect(mapStateToProps)(JobQuoteWrapper);
