import { TOGGLE_SIDENAV, TOGGLE_SUBNAV } from "../actions/sideNavActions";
import { POPULATE_LINKS } from "../actions/sideNavActions";
// Initial state
const initialState = {
  isMenuOpen: false,
  isSubMenuOpen: false,
  links: [
    {
      text: "Sign In",
      URL: "/signin",
      HTMLID: "sideNavItem"
    }
  ]
};

//Side Nav Reducer
export default function sideNavReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDENAV:
      return { ...state, isMenuOpen: !state.isMenuOpen };
    case POPULATE_LINKS:
      return {
        ...state,
        links: action.links
      };
    case TOGGLE_SUBNAV:
      return {
        ...state,
        isSubMenuOpen: !state.isSubMenuOpen
      };
    default:
      return state;
  }
}
