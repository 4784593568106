import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ProductDetails from "./ProductDetails";
import PopUp from "../Generic/PopUpDialog/PopUp";
import { API } from "aws-amplify";
import { Switch, Route } from "react-router-dom";
import { updateProductDetails } from "../../actions/productsActions";
import { setLoaderState } from "../../actions/loaderActions";
import PopUpForm from "../Generic/PopUpDialog/PopUpForm";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import * as VALIDATIONTYPES from "../Generic/validationTypes";
import EditFieldsHandler from "./Curtains/Components/EditFieldsHandler";
import { setRefresh } from "../../actions/algoliaActions";

class ProductDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationTypes: [
        {
          id: "name",
          label: "Style Name",
          type: "text",
          tooltip: "Please Enter Blind Style Name",
          required: true,
          value: "",
        },
        {
          id: "photo",
          label: "Photo URL",
          type: "text",
          tooltip: "Please Enter Photo URL",
          required: false,
          value: "",
        },
      ],
      failed: false,
      error: "",
    };
    if (this.props.match.params.product === "blinds") {
      if (this.props.match.params.component === "service-and-fee") {
        this.getBlindServiceInformation(this.props.match.params.id);
      } else {
        this.getBlindComponentInformation(
          this.props.match.params.id,
          this.props.match.params.component
        );
      }
    }

    if (this.props.match.params.product === "curtains") {
      if (this.props.match.params.component === "service-and-fee") {
        this.getCurtainServiceInformation(this.props.match.params.id);
      } else if (this.props.match.params.component === "pricing-group") {
        this.getCurtainPriceList(this.props.match.params.id);
      } else if (this.props.match.params.component === "fabric-surcharge") {
        this.getFabricSurchargeInformation(this.props.match.params.id);
      } else {
        this.getCurtainComponentInformation(
          this.props.match.params.id,
          this.props.match.params.component
        );
      }
    }
  }

  async getBlindServiceInformation(ID) {
    try {
      this.props.dispatch(setLoaderState(true));
      const response = await API.get("telishadeAPI", "/product/blind/service-and-fee/" + ID);
      this.props.dispatch(updateProductDetails(response));
      this.props.dispatch(setLoaderState(false));
      //Will Handle the Response to Display the correct editing fields
      let temp = VALIDATIONTYPES.blindService;
      temp[0].value = this.props.productDetails.details.name;
      temp[1].value = this.props.productDetails.details.serviceFeeType;
      temp[2].value = this.props.productDetails.details.cost;
      temp[3].value = this.props.productDetails.details.markup;
      this.setState({ validationTypes: temp });
    } catch (err) {
      console.error(err);
      this.setState({ failed: true, error: err });
      this.props.dispatch(setLoaderState(false));
    }
  }

  async getBlindComponentInformation(ID, Component) {
    try {
      this.props.dispatch(setLoaderState(true));
      const response = await API.get(
        "telishadeAPI",
        "/product/blind/component/" + Component + "/" + ID
      );
      this.props.dispatch(updateProductDetails(response));
      this.props.dispatch(setLoaderState(false));
      //Will Handle the Response to Display the correct editing fields
      this.handleBlindResponse(response, Component);
    } catch (err) {
      console.error(err);
      this.setState({ failed: true, error: err });
      this.props.dispatch(setLoaderState(false));
    }
  }

  handleBlindResponse(data, Component) {
    if (Component === "style") {
      let temp = VALIDATIONTYPES.blindStyle;
      temp[0].value = this.props.productDetails.details.name;
      temp[1].value = this.props.productDetails.details.photo;
      temp[2].value = this.props.productDetails.details.priceLists;
      temp[3].value = this.props.productDetails.details.associatedServices;
      this.setState({ validationTypes: temp });
      return;
    }

    if (Component === "fabric") {
      let temp = VALIDATIONTYPES.blindFabric;
      temp[0].value = this.props.productDetails.details.name;
      temp[1].value = this.props.productDetails.details.photo;
      temp[2].value = this.props.productDetails.details.manufacturerId;
      temp[3].value = this.props.productDetails.details.priceLists;
      temp[4].value = this.props.productDetails.details.associatedServices;
      this.setState({ validationTypes: temp });
      return;
    }

    if (Component === "drive-mechanism-manual") {
      let temp = VALIDATIONTYPES.manualDrive;
      temp[0].value = this.props.productDetails.details.name;
      temp[1].value = this.props.productDetails.details.cost;
      temp[2].value = this.props.productDetails.details.markup;
      temp[3].value = this.props.productDetails.details.manufacturerId;
      temp[4].value = this.props.productDetails.details.associatedServices;
      this.setState({ validationTypes: temp });
      return;
    }
    if (Component === "drive-mechanism-motorised") {
      let temp = VALIDATIONTYPES.motorisedDrive;
      temp[0].value = this.props.productDetails.details.name;
      temp[1].value = this.props.productDetails.details.cost;
      temp[2].value = this.props.productDetails.details.markup;
      temp[3].value = this.props.productDetails.details.power;
      temp[4].value = this.props.productDetails.details.speed;
      temp[5].value = this.props.productDetails.details.lift;
      temp[6].value = this.props.productDetails.details.manufacturerId;
      temp[7].value = this.props.productDetails.details.associatedServices;
      this.setState({ validationTypes: temp });
      return;
    }
    if (Component === "drive-mechanism-link-system") {
      let temp = VALIDATIONTYPES.linkSystemDrive;
      temp[0].value = this.props.productDetails.details.name;
      temp[1].value = this.props.productDetails.details.cost;
      temp[2].value = this.props.productDetails.details.markup;
      temp[3].value = this.props.productDetails.details.maxAngle;
      temp[4].value = this.props.productDetails.details.manufacturerId;
      temp[5].value = this.props.productDetails.details.associatedServices;
      this.setState({ validationTypes: temp });
      return;
    }
    if (Component === "kassette") {
      let temp = VALIDATIONTYPES.blindKassette;
      temp[0].value = this.props.productDetails.details.name;
      temp[1].value = this.props.productDetails.details.height;
      temp[2].value = this.props.productDetails.details.costPerMetre;
      temp[3].value = this.props.productDetails.details.markup;
      temp[4].value = this.props.productDetails.details.manufacturerId;
      temp[5].value = this.props.productDetails.details.associatedServices;
      this.setState({ validationTypes: temp });
      return;
    }
    if (Component === "side-channel") {
      let temp = VALIDATIONTYPES.blindSideChannel;
      temp[0].value = this.props.productDetails.details.name;
      temp[1].value = this.props.productDetails.details.height;
      temp[2].value = this.props.productDetails.details.costPerMetre;
      temp[3].value = this.props.productDetails.details.markup;
      temp[4].value = this.props.productDetails.details.manufacturerId;
      temp[5].value = this.props.productDetails.details.associatedServices;
      this.setState({ validationTypes: temp });
      return;
    }
    if (Component === "base-bar") {
      let temp = VALIDATIONTYPES.blindBaseBar;
      temp[0].value = this.props.productDetails.details.name;
      temp[1].value = this.props.productDetails.details.height;
      temp[2].value = this.props.productDetails.details.costPerMetre;
      temp[3].value = this.props.productDetails.details.markup;
      temp[4].value = this.props.productDetails.details.manufacturerId;
      temp[5].value = this.props.productDetails.details.associatedServices;
      this.setState({ validationTypes: temp });
      return;
    }
    if (Component === "accessory") {
      let temp = VALIDATIONTYPES.blindAccessory;
      temp[0].value = this.props.productDetails.details.name;
      temp[1].value = this.props.productDetails.details.cost;
      temp[2].value = this.props.productDetails.details.markup;
      this.setState({ validationTypes: temp });
      return;
    }
    if (Component === "double-bracket-system") {
      let temp = VALIDATIONTYPES.blindDoubleBracketSystem;
      temp[0].value = this.props.productDetails.details.name;
      temp[1].value = this.props.productDetails.details.cost;
      temp[2].value = this.props.productDetails.details.markup;
      temp[3].value = this.props.productDetails.details.manufacturerId;
      temp[4].value = this.props.productDetails.details.associatedServices;
      this.setState({ validationTypes: temp });
      return;
    }
  }

  async getCurtainComponentInformation(ID, Component) {
    try {
      this.props.dispatch(setLoaderState(true));
      const response = await API.get(
        "telishadeAPI",
        "/product/curtain/component/" + Component + "/" + ID
      );
      this.props.dispatch(updateProductDetails(response));
      this.props.dispatch(setLoaderState(false));
      //Will Handle the Response to Display the correct editing fields
      // this.handleResponse(response, Component);
      this.setState({
        validationTypes: EditFieldsHandler(response, Component),
      });
    } catch (err) {
      console.error(err);
      this.setState({ failed: true, error: err });
      this.props.dispatch(setLoaderState(false));
    }
  }

  async getCurtainPriceList(ID) {
    try {
      this.props.dispatch(setLoaderState(true));
      const response = await API.get("telishadeAPI", "/product/curtain/price-list/" + ID);
      this.props.dispatch(updateProductDetails(response));
      this.props.dispatch(setLoaderState(false));
      //Will Handle the Response to Display the correct editing fields
      this.setState({
        validationTypes: EditFieldsHandler(response, "pricing-group"),
      });
    } catch (err) {
      console.error(err);
      this.setState({ failed: true, error: err });
      this.props.dispatch(setLoaderState(false));
    }
  }

  async getFabricSurchargeInformation(ID) {
    try {
      this.props.dispatch(setLoaderState(true));
      const response = await API.get("telishadeAPI", "/product/curtain/fabric-surcharge/" + ID);
      this.props.dispatch(updateProductDetails(response));
      this.props.dispatch(setLoaderState(false));
      //Will Handle the Response to Display the correct editing fields
      this.setState({
        validationTypes: EditFieldsHandler(response, "fabric-surcharge"),
      });
    } catch (err) {
      console.error(err);
      this.setState({ failed: true, error: err });
      this.props.dispatch(setLoaderState(false));
    }
  }

  async getCurtainServiceInformation(ID) {
    try {
      this.props.dispatch(setLoaderState(true));
      const response = await API.get("telishadeAPI", "/product/curtain/service-and-fee/" + ID);
      this.props.dispatch(updateProductDetails(response));
      this.props.dispatch(setLoaderState(false));
      //Will Handle the Response to Display the correct editing fields
      let temp = VALIDATIONTYPES.blindService;
      temp[0].value = this.props.productDetails.details.name;
      temp[1].value = this.props.productDetails.details.serviceFeeType;
      temp[2].value = this.props.productDetails.details.cost;
      temp[3].value = this.props.productDetails.details.markup;
      this.setState({ validationTypes: temp });
    } catch (err) {
      console.error(err);
      this.setState({ failed: true, error: err });
      this.props.dispatch(setLoaderState(false));
    }
  }

  handleEditClick = () => {
    const currentURL = this.props.history.location.pathname;
    this.props.history.push(currentURL + "/edit");
  };

  EditService = async (formData) => {
    this.props.dispatch(setLoaderState(true));
    let product = "";
    if (this.props.match.params.product === "blinds") product = "blind";
    if (this.props.match.params.product === "curtains") product = "curtain";
    const ID = this.props.match.params.id;
    const body = {
      details: {
        name: formData.name,
        cost: formData.cost,
        markup: formData.markup,
        serviceFeeType: formData.type,
      },
    };
    try {
      const response = await API.put(
        "telishadeAPI",
        "/product/" + product + "/service-and-fee/" + ID,
        {
          body,
        }
      );
      this.props.dispatch(updateProductDetails(response));
      this.props.history.push(
        `/products/${this.props.match.params.product}/${this.props.match.params.component}/${this.props.match.params.id}}`
      );
      this.props.dispatch(setLoaderState(false));
    } catch (e) {
      this.props.dispatch(setLoaderState(false));
      throw e;
    }
  };

  EditComponent = async (formData) => {
    this.props.dispatch(setLoaderState(true));
    let product = "";
    if (this.props.match.params.product === "blinds") product = "blind";
    if (this.props.match.params.product === "curtains") product = "curtain";
    const ID = this.props.match.params.id;
    const Component = this.props.match.params.component;
    const body = { details: formData };
    try {
      const response = await API.put(
        "telishadeAPI",
        "/product/" + product + "/component/" + Component + "/" + ID,
        {
          body,
        }
      );
      this.props.dispatch(updateProductDetails(response));
      this.props.history.push(
        `/products/${this.props.match.params.product}/${this.props.match.params.component}/${ID}`
      );
      this.props.dispatch(setLoaderState(false));
    } catch (e) {
      this.props.dispatch(setLoaderState(false));
      throw e;
    }
  };

  EditPriceList = async (formData) => {
    this.props.dispatch(setLoaderState(true));
    const ID = this.props.match.params.id;
    const body = { details: formData };
    try {
      const response = await API.put("telishadeAPI", "/product/curtain/price-list/" + ID, {
        body,
      });
      this.props.dispatch(setLoaderState(false));
      this.props.dispatch(updateProductDetails(response));
      this.props.history.push(
        `/products/${this.props.match.params.product}/${this.props.match.params.component}/${ID}`
      );
    } catch (e) {
      throw e;
    }
  };

  EditFabricSurcharge = async (formData) => {
    this.props.dispatch(setLoaderState(true));
    const ID = this.props.match.params.id;
    const body = { details: formData };
    try {
      const response = await API.put("telishadeAPI", "/product/curtain/fabric-surcharge/" + ID, {
        body,
      });
      this.props.dispatch(setLoaderState(false));
      this.props.dispatch(updateProductDetails(response));
      this.props.history.push(
        `/products/${this.props.match.params.product}/${this.props.match.params.component}/${ID}`
      );
    } catch (e) {
      throw e;
    }
    return API.put("telishadeAPI", "/product/curtain/fabric-surcharge/" + ID, {
      body,
    });
  };

  //This Simple Function will handle how the body should be formated for all
  //Product Components
  handleEditBody(formData, Component) {
    if (Component === "fabric" || Component === "style") {
      return {
        name: formData.name,
        photo: formData.photo,
        priceLists: formData.priceLists,
      };
    }
    if (Component === "drive-mechanism") {
      if (formData.type === "manual")
        return {
          name: formData.name,
          cost: formData.cost,
          markup: formData.markup,
          driveType: formData.driveType,
        };
      if (formData.type === "motorised")
        return {
          name: formData.name,
          cost: formData.cost,
          markup: formData.markup,
          power: formData.power,
          speed: formData.speed,
          lift: formData.lift,
          driveType: formData.driveType,
        };
      if (formData.type === "linkSystem")
        return {
          name: formData.name,
          cost: formData.cost,
          markup: formData.markup,
          maxAngle: formData.maxAngle,
          driveType: formData.driveType,
        };
    }
    if (Component === "kassette") return { name: formData.name, height: formData.height };
    if (Component === "side-channel") return { name: formData.name, height: formData.height };
    if (Component === "base-bar") return { name: formData.name, height: formData.height };
    if (Component === "accessory")
      return {
        name: formData.name,
        cost: formData.cost,
        markup: formData.markup,
      };
    if (Component === "double-bracket-system")
      return {
        name: formData.name,
        cost: formData.cost,
        markup: formData.markup,
      };
  }

  updateComponentDetails = (response) => {};

  handleCancelClick = () => {
    this.props.history.goBack();
  };

  handleDeleteClick = () => {
    const currentURL = this.props.history.location.pathname;
    this.props.history.push(currentURL + "/delete");
  };

  DeleteService = async () => {
    const id = this.props.match.params.id;
    let product = "";
    if (this.props.match.params.product === "blinds") product = "blind";
    if (this.props.match.params.product === "curtains") product = "curtain";
    try {
      this.props.dispatch(setLoaderState(true));
      await API.del("telishadeAPI", "/product/" + product + "/service-and-fee/" + id);
      this.props.history.push("/products/" + this.props.match.params.product + "/service-and-fee");
      this.props.dispatch(setLoaderState(false));
      this.props.dispatch(setRefresh(true));
    } catch (err) {
      this.setState({ failed: true, error: err });
      console.error(err);
      this.props.dispatch(setLoaderState(false));
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  DeleteFabricSurcharge = async () => {
    const id = this.props.match.params.id;
    let product = "";
    if (this.props.match.params.product === "blinds") product = "blind";
    if (this.props.match.params.product === "curtains") product = "curtain";
    try {
      this.props.dispatch(setLoaderState(true));
      await API.del("telishadeAPI", "/product/" + product + "/fabric-surcharge/" + id);
      this.props.history.push("/products/" + this.props.match.params.product + "/fabric-surcharge");
      this.props.dispatch(setLoaderState(false));
      this.props.dispatch(setRefresh(true));
    } catch (err) {
      this.setState({ failed: true, error: err });
      console.error(err);
      this.props.dispatch(setLoaderState(false));
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  DeletePriceList = async () => {
    const id = this.props.match.params.id;
    let product = "";
    if (this.props.match.params.product === "blinds") product = "blind";
    if (this.props.match.params.product === "curtains") product = "curtain";
    try {
      this.props.dispatch(setLoaderState(true));
      await API.del("telishadeAPI", "/product/" + product + "/price-list/" + id);
      this.props.history.push("/products/" + this.props.match.params.product + "/pricing-group");
      this.props.dispatch(setLoaderState(false));
      this.props.dispatch(setRefresh(true));
    } catch (err) {
      this.setState({ failed: true, error: err });
      console.error(err);
      this.props.dispatch(setLoaderState(false));
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  DeleteComponent = async () => {
    const id = this.props.match.params.id;
    const component = this.props.match.params.component;
    let product = "";
    if (this.props.match.params.product === "blinds") product = "blind";
    if (this.props.match.params.product === "curtains") product = "curtain";
    try {
      this.props.dispatch(setLoaderState(true));
      await API.del("telishadeAPI", "/product/" + product + "/component/" + component + "/" + id);
      if (
        component === "drive-mechanism-link-system" ||
        component === "drive-mechanism-manual" ||
        component === "drive-mechanism-motorised"
      )
        this.props.history.push(
          "/products/" + this.props.match.params.product + "/drive-mechanism"
        );
      else
        this.props.history.push("/products/" + this.props.match.params.product + "/" + component);
      this.props.dispatch(setLoaderState(false));
      this.props.dispatch(setRefresh(true));
    } catch (err) {
      this.setState({ failed: true, error: err });
      console.error(err);
      this.props.dispatch(setLoaderState(false));
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  render() {
    return (
      <div>
        {this.state.failed ? (
          <ErrorHandler
            error={this.state.error}
            goBackClickHandler={() => {
              this.props.history.goBack();
            }}
          />
        ) : (
          ""
        )}
        {this.props.productDetails !== undefined &&
        this.props.productDetails.details !== undefined ? (
          <Fragment>
            <Switch>
              <Route path="/products/:product/:component/:id/delete">
                {this.props.productDetails.details !== undefined ? (
                  this.props.match.params.component === "service-and-fee" ? (
                    <PopUp
                      header="Delete?"
                      body={
                        "Are you sure you want to delete " + this.props.productDetails.details.name
                      }
                      button="Delete"
                      buttoncolour="red"
                      button2="cancel"
                      buttonClickHandler={this.DeleteService}
                      button2ClickHandler={undefined}
                    />
                  ) : this.props.match.params.component === "fabric-surcharge" ? (
                    <PopUp
                      header="Delete?"
                      body={
                        "Are you sure you want to delete " + this.props.productDetails.details.name
                      }
                      button="Delete"
                      buttoncolour="red"
                      button2="cancel"
                      buttonClickHandler={this.DeleteFabricSurcharge}
                      button2ClickHandler={undefined}
                    />
                  ) : this.props.match.params.component === "pricing-group" ? (
                    <PopUp
                      header="Delete?"
                      body={
                        "Are you sure you want to delete " + this.props.productDetails.details.name
                      }
                      button="Delete"
                      buttoncolour="red"
                      button2="cancel"
                      buttonClickHandler={this.DeletePriceList}
                      button2ClickHandler={undefined}
                    />
                  ) : (
                    <PopUp
                      header="Delete?"
                      body={
                        "Are you sure you want to delete " + this.props.productDetails.details.name
                      }
                      button="Delete"
                      buttoncolour="red"
                      button2="cancel"
                      buttonClickHandler={this.DeleteComponent}
                      button2ClickHandler={undefined}
                    />
                  )
                ) : (
                  ""
                )}
              </Route>
              <Route path="/products/blinds/:component/:id/edit">
                {this.props.productDetails.details.priceLists !== undefined &&
                this.state.validationTypes[2] !== undefined ? (
                  <PopUpForm
                    header={"Edit " + this.props.productDetails.details.name}
                    button="Accept"
                    buttoncolour="green"
                    button2="Cancel"
                    button2colour="red"
                    fields={this.state.validationTypes}
                    apiMethod={this.EditComponent}
                    updateMethod={this.updateComponentDetails}
                  />
                ) : this.props.match.params.component === "service-and-fee" ? (
                  <PopUpForm
                    header={"Edit " + this.props.productDetails.details.name}
                    button="Accept"
                    buttoncolour="green"
                    button2="Cancel"
                    button2colour="red"
                    fields={this.state.validationTypes}
                    validationTypes={this.state.validationTypes}
                    apiMethod={this.EditService}
                    updateMethod={this.updateComponentDetails}
                  />
                ) : (
                  <PopUpForm
                    header={"Edit " + this.props.productDetails.details.name}
                    button="Accept"
                    buttoncolour="green"
                    button2="Cancel"
                    button2colour="red"
                    fields={this.state.validationTypes}
                    validationTypes={this.state.validationTypes}
                    apiMethod={this.EditComponent}
                    updateMethod={this.updateComponentDetails}
                  />
                )}
              </Route>
              <Route path="/products/curtains/:component/:id/edit">
                {this.props.match.params.component === "pricing-group" ? (
                  <PopUpForm
                    header={"Edit " + this.props.productDetails.details.name}
                    button="Accept"
                    buttoncolour="green"
                    button2="Cancel"
                    button2colour="red"
                    fields={this.state.validationTypes}
                    apiMethod={this.EditPriceList}
                    updateMethod={this.updateComponentDetails}
                  />
                ) : this.props.match.params.component === "fabric-surcharge" ? (
                  <PopUpForm
                    header={"Edit " + this.props.productDetails.details.name}
                    button="Accept"
                    buttoncolour="green"
                    button2="Cancel"
                    button2colour="red"
                    fields={this.state.validationTypes}
                    apiMethod={this.EditFabricSurcharge}
                    updateMethod={this.updateComponentDetails}
                  />
                ) : this.props.match.params.component === "service-and-fee" ? (
                  <PopUpForm
                    header={"Edit " + this.props.productDetails.details.name}
                    button="Accept"
                    buttoncolour="green"
                    button2="Cancel"
                    button2colour="red"
                    fields={this.state.validationTypes}
                    apiMethod={this.EditService}
                    updateMethod={this.updateComponentDetails}
                  />
                ) : (
                  <PopUpForm
                    header={"Edit " + this.props.productDetails.details.name}
                    button="Accept"
                    buttoncolour="green"
                    button2="Cancel"
                    button2colour="red"
                    fields={this.state.validationTypes}
                    apiMethod={this.EditComponent}
                    updateMethod={this.updateComponentDetails}
                  />
                )}
              </Route>
            </Switch>
            <ProductDetails />
          </Fragment>
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state.products;
};

export default connect(mapStateToProps)(ProductDetailView);
