import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  InstantSearch,
  Configure,
  connectStateResults,
  connectInfiniteHits,
  connectSearchBox,
} from "react-instantsearch-dom";
import "./SearchableList.css";
//Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faEllipsisV } from "@fortawesome/free-solid-svg-icons";
//Material UI
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { getAlgoliaKey } from "../../../utilities/utils";

class InfiniteHits extends Component {
  sentinel = null;

  onSentinelIntersection = (entries) => {
    const { hasMore, refine } = this.props;

    entries.forEach((entry) => {
      if (entry.isIntersecting && hasMore) {
        // In that case we can refine
        refine();
      }
    });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);

    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits } = this.props;

    return (
      <div className="ais-Hits">
        <ul className="ais-Hits-list">
          {hits.map((hit) => (
            <li key={hit.objectID} className="ais-Hits-item">
              <this.props.hitComponent hit={hit} />
            </li>
          ))}
          <li className="ais-InfiniteHits-sentinel" ref={(c) => (this.sentinel = c)} />
        </ul>
      </div>
    );
  }
}

const MyInfiniteHits = connectInfiniteHits(InfiniteHits);

const CustomSearchBox = ({ currentRefinement, isSearchStalled, refine, submit, option }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="ais-SearchBox">
      <form className="ais-SearchBox-form" role="search" noValidate>
        <input
          className="ais-SearchBox-input"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder="Search here.."
          spellCheck="false"
          maxLength="512"
          type="search"
          value={currentRefinement}
          onChange={(event) => refine(event.currentTarget.value)}
        />
        <button
          className="ais-SearchBox-submit"
          onClick={(event) => {
            event.preventDefault();
            refine(currentRefinement);
          }}
          title="Submit the search query."
        >
          {submit}
        </button>
        {option ? (
          <Fragment>
            <button
              className="ais-SearchBox-submit"
              title="Open Menu"
              onClick={(event) => {
                event.preventDefault();
                handleClick(event);
              }}
            >
              <FontAwesomeIcon id="search" icon={faEllipsisV} />
            </button>
            <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
              {option.map((option, key) => (
                <MenuItem key={key} onClick={option.onClickHandler}>
                  {option.text}
                </MenuItem>
              ))}
            </Menu>
          </Fragment>
        ) : (
          ""
        )}
      </form>
    </div>
  );
};

const MySearchBar = connectSearchBox(CustomSearchBox);

class SearchableList extends Component {
  constructor(props) {
    super(props);
    this.state = { key: null, localRefresh: false, clearCache: false };
    this._isMounted = false;
  }

  refresh = () => {
    this.setState({ localRefresh: true });
    setTimeout(() => {
      this.setState({ localRefresh: false });
      this.setState({ clearCache: true }, () => this.setState({ clearCache: false }));
      this.setState({ localRefresh: true }, () => this.setState({ localRefresh: false }));
    }, 0);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.refresh !== this.props.refresh && this.props.refresh === true) {
      this.refresh();
    }
  }

  componentDidMount() {
    if (this !== undefined) {
      this._isMounted = true;
    }
  }

  componentWillUnmount() {
    if (this !== undefined) {
      this._isMounted = false;
    }
  }

  get errorHandler() {
    // Renders nothing, only used to handle error related to API key being invalid
    return connectStateResults(({ error, renewKey, refresh }) => {
      if (error && error.message === '"validUntil" parameter expired (less than current date)') {
        refresh();
      } else if (error) {
        console.error(error);
      }
      return "";
    });
  }

  NoResultsDisplay = connectStateResults(({ searchResults, children, searchState }) => {
    return searchResults && searchResults.nbHits !== 0
      ? children
      : this.props.noResultsComponent !== undefined && searchState.query !== undefined
      ? this.props.noResultsComponent
      : "";
  });

  render() {
    if (this.props.AlgoliaKey.key) {
      const ErrorHandler = this.errorHandler;
      return (
        <div className="searchable-list">
          {this.state.localRefresh ? (
            <div className="loader">LOADING...</div>
          ) : (
            <InstantSearch
              appId={this.props.appId}
              apiKey={getAlgoliaKey(this.props.index, this.props.AlgoliaKey)}
              indexName={this.props.index}
              refresh={this.state.clearCache}
            >
              <Configure filters={this.props.filters} hitsPerPage={this.props.hitsPerPage || 20} distinct />
              {this.props.hideSearchBox ? (
                ""
              ) : (
                <Fragment>
                  <MySearchBar option={this.props.option} submit={<FontAwesomeIcon id="search" icon={faSearch} />} />
                </Fragment>
              )}
              <this.NoResultsDisplay>
                <MyInfiniteHits
                  hitComponent={this.props.hitComponent}
                  selectedObjectId={this.props.selectedObjectId /* Just done for re-render trigger. Need to refactor */}
                />
              </this.NoResultsDisplay>
              <ErrorHandler refresh={this.refresh} />
            </InstantSearch>
          )}
        </div>
      );
    } else if (this.props.noLoader === true) return "";
    else return <div className="loader">LOADING...</div>;
  }
}

const mapStateToProps = (state) => {
  return { ...state.algolia, AlgoliaKey: state.key };
};
export default connect(mapStateToProps)(SearchableList);
