import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SearchableList from "../Generic/Algolia/SearchableList";
import { selectStaff, updateStaffDetails } from "../../actions/staffActions";
import config from "../../config";

class StaffList extends Component {
  displayAvailableContactDetails(details) {
    if (details.phone) {
      return (
        <span>
          <strong>ph: </strong>
          {details.phone}
        </span>
      );
    } else if (details.mobile) {
      return (
        <span>
          <strong>mob: </strong>
          {details.mobile}
        </span>
      );
    } else if (details.email) {
      return (
        <span>
          email: <strong />
          {details.email}
        </span>
      );
    } else return "";
  }

  HitComponent = ({ hit }) => {
    const listItemClickHandler = () => {
      this.props.dispatch(updateStaffDetails(hit.details));
      this.props.dispatch(selectStaff(hit.objectID));
      this.props.history.push(`/staff/${hit.objectID}/addresses`);
    };

    let className = "";
    if (this.props.id === hit.objectID) className = { className: "selected" };
    return (
      <p {...className} onClick={listItemClickHandler}>
        <strong>{hit.details.name}</strong>
        <br />
        {this.displayAvailableContactDetails(hit.details)}
        <br />
      </p>
    );
  };

  render() {
    return (
      <div className="side-list">
        <SearchableList
          appId={`${config.algolia.appId}`}
          index={`${config.algolia.prefix}staff_name_asc`}
          hitComponent={this.HitComponent}
          selectedObjectId={this.props.id}
          filters="isDeleted:false"
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state.staff.staff;
};

export default withRouter(connect(mapStateToProps)(StaffList));
