//Action Type
export const SET_LOADER_STATE = "LOADER::SET_LOADER_STATE";
export const SET_ALGOLIA_LOADER_STATE = "LOADER::SET_ALGOLIA_LOADER_STATE";

//Action Creators
export function setLoaderState(state) {
  return {
    type: SET_LOADER_STATE,
    loaderState: state
  };
}

export function setAlgoliaLoaderState(state) {
  return {
    type: SET_ALGOLIA_LOADER_STATE,
    algoliaLoaderState: state
  };
}
