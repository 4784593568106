import React, { Component } from "react";

class Heading extends Component {
  render() {
    return (
      <tr className="heading">
        <td colSpan={this.props.colSpan}>
          <span className="heading-one">{this.props.title}</span>
        </td>
      </tr>
    );
  }
}

export default Heading;
