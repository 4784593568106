import React, { Component, Fragment } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormControlLabel,
  Checkbox,
  ThemeProvider,
  Menu,
  MenuItem,
  Fab,
  Zoom,
  IconButton,
  Popper,
  Button
} from "@material-ui/core";
import { handleSearchQuery, handleSearchString } from "./SearchFunctions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Theme } from "../../Generic/Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSearch, faPen, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import EditBulkItemsPopUp from "./EditBulkItemsPopUp";
import config from "../../../config";
import SelectAutoComplete from "../../Generic/Algolia/SelectAutoComplete";
import { MDBBtn } from "mdbreact";

class BulkItemList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelection: 0,
      currentSearchString: "",
      currentJobList: this.props.jobs,
      selectedItems: [],
      selectedJobs: [],
      activeEditBtn: false,
      editPopUp: false
    };
  }

  handleSelectAll = () => {
    let newSelectedJobs = [...this.state.selectedJobs];
    let newSelectedItems = [...this.state.selectedItems];
    const itemType = this.state.tabSelection === 0 ? "blinds" : "curtains";
    this.state.currentJobList.forEach(job => {
      if (newSelectedJobs.includes(job.id) && job[itemType]) {
        newSelectedJobs.splice(newSelectedJobs.indexOf(job.id), 1);
        job[itemType].forEach(item => {
          if (newSelectedItems.includes(item.projectJobItemId)) {
            newSelectedItems.splice(newSelectedItems.indexOf(item.projectJobItemId), 1);
          }
        });
      } else if (job[itemType]) {
        newSelectedJobs.push(job.id);
        job[itemType].forEach(item => {
          if (!newSelectedItems.includes(item.projectJobItemId)) {
            newSelectedItems.push(item.projectJobItemId);
          }
        });
      }
    });
    this.setState({ selectedItems: newSelectedItems, selectedJobs: newSelectedJobs });
  };

  handleTabChange = (event, tabSelection) => {
    this.setState({ tabSelection, selectedItems: [], selectedJobs: [] });
  };

  componentDidUpdate() {
    if (this.state.selectedItems.length > 0 && !this.state.activeEditBtn) {
      this.setState({ activeEditBtn: true });
    } else if (this.state.selectedItems.length === 0 && this.state.activeEditBtn) {
      this.setState({ activeEditBtn: false });
    }
  }

  handleJobSelect(jobId, itemType, job) {
    let newSelectedJobs = [...this.state.selectedJobs];
    let newSelectedItems = [...this.state.selectedItems];
    if (newSelectedJobs.includes(jobId)) {
      newSelectedJobs.splice(newSelectedJobs.indexOf(jobId), 1);
      job[itemType].forEach(item => {
        if (newSelectedItems.includes(item.projectJobItemId)) {
          newSelectedItems.splice(newSelectedItems.indexOf(item.projectJobItemId), 1);
        }
      });
    } else {
      newSelectedJobs.push(jobId);
      job[itemType].forEach(item => {
        if (!newSelectedItems.includes(item.projectJobItemId)) {
          newSelectedItems.push(item.projectJobItemId);
        }
      });
    }
    this.setState({ selectedItems: newSelectedItems, selectedJobs: newSelectedJobs });
  }

  handleItemSelect(itemId) {
    let newSelectedItems = [...this.state.selectedItems];
    if (newSelectedItems.includes(itemId)) {
      newSelectedItems.splice(newSelectedItems.indexOf(itemId), 1);
    } else {
      newSelectedItems.push(itemId);
    }
    this.setState({ selectedItems: newSelectedItems });
  }

  createList(jobArray, itemType) {
    return jobArray.map((job, key) => {
      if (job[itemType]) {
        return (
          <ExpansionPanel key={key} TransitionProps={{ unmountOnExit: true }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <FormControlLabel
                aria-label="Select All Items in Job"
                onClick={event => {
                  event.stopPropagation();
                  this.handleJobSelect(job.id, itemType, job);
                }}
                onFocus={event => event.stopPropagation()}
                control={
                  <ThemeProvider theme={Theme}>
                    <Checkbox checked={this.state.selectedJobs.includes(job.id)} color="primary" />
                  </ThemeProvider>
                }
                label={`${job.name}\t${job[itemType].length}items`}
              />
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <ul className="jobItemList">
                {job[itemType].map((item, key) => (
                  <li key={key} onClick={() => this.handleItemSelect(item.projectJobItemId)}>
                    <FormControlLabel
                      aria-label="Select Item"
                      onClick={event => {
                        event.stopPropagation();
                        this.handleItemSelect(item.projectJobItemId);
                      }}
                      onFocus={event => event.stopPropagation()}
                      control={
                        <ThemeProvider theme={Theme}>
                          <Checkbox
                            checked={this.state.selectedItems.includes(item.projectJobItemId)}
                            color="primary"
                          />
                        </ThemeProvider>
                      }
                    />
                    {`ProductCode: ${item.productCode}, Location: ${
                      item.description.allDetails.location
                    }, Style: ${item.description.headerStyle || item.description.rollerStyle}, `}
                    {`Fabric: ${item.description.fabric}, `}
                    {`Drive Mechanism: ${item.description.driveMechanism}, `}
                    {`Drive Type: ${item.description.allDetails.driveType}, `}
                    {`Width: ${item.description.width}, `}
                    {`Drop: ${item.description.drop}, `}
                  </li>
                ))}
              </ul>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        );
      } else return "";
    });
  }

  handleSearchRequest = searchString => {
    let newSearchCollection = [];
    const searchQuery = handleSearchString(searchString);
    const jobList = this.props.jobs;
    jobList.forEach(job => {
      let newJob = { name: job.name, id: job.id };
      if (job.blinds) {
        job.blinds.forEach(blind => {
          if (handleSearchQuery(blind, searchQuery)) {
            if (newJob.blinds === undefined) newJob.blinds = [];
            newJob.blinds.push(blind);
          }
        });
      }
      if (job.curtains) {
        job.curtains.forEach(curtain => {
          if (handleSearchQuery(curtain, searchQuery)) {
            if (newJob.curtains === undefined) newJob.curtains = [];
            newJob.curtains.push(curtain);
          }
        });
      }
      if (newJob.blinds || newJob.curtains) {
        newSearchCollection.push(newJob);
      }
    });
    this.setState({ currentJobList: newSearchCollection });
  };

  render() {
    return (
      <div className="bulkEditTabContainer">
        <ThemeProvider theme={Theme}>
          <AppBar className="bulkEditTabBar" position="static">
            <Tabs value={this.state.tabSelection} onChange={this.handleTabChange}>
              <Tab label="Blinds" />
              <Tab label="Curtains" />
            </Tabs>
            <Button color="secondary" onClick={this.handleSelectAll}>
              Select All
            </Button>
          </AppBar>
        </ThemeProvider>
        <CustomSearchBox
          refine={value => {
            this.handleSearchRequest(value);
          }}
          submit={<FontAwesomeIcon id="search" icon={faSearch} />}
        />
        {this.state.tabSelection === 0 ? (
          <div className="bulkListBlinds bulkList">
            <div>{this.createList(this.state.currentJobList, "blinds")}</div>
          </div>
        ) : (
          <div className="bulkListCurtains bulkList">
            {this.createList(this.state.currentJobList, "curtains")}
          </div>
        )}
        <ThemeProvider theme={Theme}>
          <Zoom unmountOnExit in={this.state.activeEditBtn}>
            <Fab
              onClick={() => this.setState({ editPopUp: true })}
              className="EditFab"
              color="primary"
              aria-label="add"
            >
              <FontAwesomeIcon icon={faPen} />
            </Fab>
          </Zoom>
        </ThemeProvider>
        {this.state.editPopUp ? (
          <EditBulkItemsPopUp
            productType={this.state.tabSelection === 0 ? "Blinds" : "Curtains"}
            project={this.props.project}
            jobList={this.props.jobs}
            selectedItems={this.state.selectedItems}
            cancelClick={() => this.setState({ editPopUp: false })}
            reloadList={this.props.reloadList}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const CustomSearchBox = ({ currentRefinement, isSearchStalled, refine, submit, option }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchText, setSearchText] = React.useState("");
  const [openSearchPanel, setOpenSearchPanel] = React.useState(false);
  const [searchHelperFields, setSearchHelperFields] = React.useState({});

  const handleAutoCompleteSelection = (id, value) => {
    setSearchHelperFields({
      ...searchHelperFields,
      [id]: value.details ? value.details.name : undefined
    });
  };

  const handleTextInput = (id, value) => {
    setSearchHelperFields({
      ...searchHelperFields,
      [id]: value
    });
  };

  const handleSearchHelperClick = () => {
    let newSearchText = "";
    const { style, fabric, productCode, location } = searchHelperFields;
    if (productCode) newSearchText += `productCode:${productCode}, `;
    if (location) newSearchText += `location:${location}`;
    if (style) newSearchText += `style:${style}, `;
    if (fabric) newSearchText += `fabric:${fabric}, `;
    setSearchText(newSearchText);
    refine(newSearchText);
    setOpenSearchPanel(false);
  };

  const handleOpenSearchPanelClick = () => {
    setOpenSearchPanel(!openSearchPanel);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const getSearchRef = () => {
    const ref = document.getElementById("searchBar");
    return ref;
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="ais-SearchBox">
      <form className="ais-SearchBox-form" role="search" noValidate>
        <input
          id="searchBar"
          className="ais-SearchBox-input"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          placeholder="Search here.."
          spellCheck="false"
          maxLength="512"
          type="search"
          value={searchText}
          onChange={event => setSearchText(event.currentTarget.value)}
        />
        <button
          className="ais-SearchBox-submit"
          onClick={event => {
            event.preventDefault();
            refine(searchText);
          }}
          title="Submit the search query."
        >
          {submit}
        </button>
        <IconButton onClick={handleOpenSearchPanelClick} className="searchBarExpand">
          <FontAwesomeIcon id="searchBarExpand" icon={faChevronDown} />
        </IconButton>
        <Popper
          placement="bottom-start"
          anchorEl={getSearchRef}
          id="bulkSearchPopper"
          open={openSearchPanel}
        >
          <div className="popupFormInputField">
            <div className="detailFields">
              Product Code
              <input
                autoComplete="off"
                id={"productCode"}
                step="any" // allow for floats
                size="sm"
                className="projectInput form-control"
                type={"type"}
                value={searchHelperFields.productCode}
                onChange={event => handleTextInput("productCode", event.target.value)}
              />
            </div>
          </div>
          <div className="popupFormInputField">
            <div className="detailFields">
              Location
              <input
                id={"location"}
                step="any" // allow for floats
                size="sm"
                className="projectInput form-control"
                type={"type"}
                value={searchHelperFields.location}
                onChange={event => handleTextInput("location", event.target.value)}
              />
            </div>
          </div>
          <div className="popupFormInputField">
            <div className="detailFields">
              Style
              <SelectAutoComplete
                index={`${config.algolia.prefix}products_components_name_asc`}
                filters={"type:style AND isDeleted:false"}
                handleSelectAutoComplete={(id, value) => handleAutoCompleteSelection(id, value)}
                id={"style"}
              />
            </div>
          </div>
          <div className="popupFormInputField">
            <div className="detailFields">
              Fabric
              <SelectAutoComplete
                index={`${config.algolia.prefix}products_components_name_asc`}
                filters={"type:fabric AND isDeleted:false"}
                handleSelectAutoComplete={(id, value) => handleAutoCompleteSelection(id, value)}
                id={"fabric"}
              />
            </div>
          </div>
          <MDBBtn onClick={handleSearchHelperClick}>Search</MDBBtn>
        </Popper>
        {option ? (
          <Fragment>
            <button
              className="ais-SearchBox-submit"
              title="Open Menu"
              onClick={event => {
                event.preventDefault();
                handleClick(event);
              }}
              currentTab={this.state.tabSelection}
            >
              <FontAwesomeIcon id="search" icon={faEllipsisV} />
            </button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {option.map(option => (
                <MenuItem onClick={option.onClickHandler}>{option.text}</MenuItem>
              ))}
            </Menu>
          </Fragment>
        ) : (
          ""
        )}
        {}
      </form>
    </div>
  );
};

export default BulkItemList;
