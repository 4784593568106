import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DocumentsList from "./DocumentsMenu";
import { toggleMenu } from "../../../actions/projectsActions";

class ProjectMenu extends Component {
  clickHandler = selectedMenu => {
    if (this.props.menuCurrentlyOpen === selectedMenu) {
      this.props.dispatch(toggleMenu("none"));
    } else {
      this.props.dispatch(toggleMenu(selectedMenu));
    }
  };

  render() {
    return (
      <div className="projectLinksList">
        <ul>
          <li onClick={() => this.props.history.replace("/projects/" + this.props.match.params.id)}>
            <div className="menu-item-text">Jobs</div>
          </li>
          <li>
            <NavLink to={"/projects/" + this.props.match.params.id + "/worksheet"}>
              Worksheet
            </NavLink>
          </li>
          <li onClick={() => this.clickHandler("documents")}>
            <div className="menu-item-text">Documents</div>
          </li>
          <li>{this.props.menuCurrentlyOpen === "documents" ? <DocumentsList /> : ""}</li>
          <li>
            <NavLink className="unimplemented" to={this.props.match.url}>
              Customer Confirmation
            </NavLink>
          </li>
          <li>
            <NavLink className="unimplemented" to={this.props.match.url}>
              Additional Documents
            </NavLink>
          </li>
          <li>
            <NavLink className="unimplemented" to={this.props.match.url}>
              Messages
            </NavLink>
          </li>
          <li>
            <NavLink to={"/projects/" + this.props.match.params.id + "/notes"}>Notes</NavLink>
          </li>
          <li>
            <NavLink to={"/projects/" + this.props.match.params.id + "/bulk-edit"}>
              Bulk Edit
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state.projects.documents;
};

export default withRouter(connect(mapStateToProps)(ProjectMenu));
