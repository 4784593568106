import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { toggleSideNav } from "../../actions/sideNavActions";
import HeaderRoutes from "./HeaderRoutes";
import config from "../../config";

//Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { API } from "aws-amplify";
import { populateAccountDetails } from "../../actions/accountActions";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addNew: false
    };
    this.getAccountDetails();
  }
  toggleNav = () => {
    this.props.dispatch(toggleSideNav());
  };

  async getAccountDetails() {
    try {
      const response = await API.get("telishadeAPI", "/account");
      const { firstName, lastName, email, phoneNumber } = response.details;
      this.props.dispatch(
        populateAccountDetails({
          firstName,
          lastName,
          email,
          phoneNumber
        })
      );
    } catch (e) {}
  }

  // Flag so that the developer knows when the app is in dev/staging/prod
  // Dev = red header
  // Prod & staging = normal blue header
  devFlagStyle = () =>
    config.REACT_APP_STAGE === "dev"
      ? {
          color: "white",
          background: "red",
          borderRadius: this.props.isMenuOpen ? "25px" : "0px"
        }
      : {
          borderRadius: this.props.isMenuOpen ? "25px" : "0px"
        };

  render() {
    return (
      <Fragment>
        <div
          className="placeHolderTop"
          style={config.REACT_APP_STAGE === "dev" ? { backgroundColor: "red" } : {}}
        />
        <div className="placeHolderBottom" />
        <nav id="Header" style={this.devFlagStyle()}>
          <div
            className="HeaderItems"
            style={config.REACT_APP_STAGE === "dev" ? { color: "white" } : {}}
          >
            {this.props.isMenuOpen ? (
              <FontAwesomeIcon
                style={config.REACT_APP_STAGE === "dev" ? { color: "white" } : {}}
                className="icon"
                icon={faTimes}
                onClick={this.toggleNav}
              />
            ) : (
              <FontAwesomeIcon
                style={config.REACT_APP_STAGE === "dev" ? { color: "white" } : {}}
                className="icon"
                onClick={this.toggleNav}
                icon={faBars}
              />
            )}
            <h3
              style={config.REACT_APP_STAGE === "dev" ? { color: "white" } : {}}
              className="TelishadeTitle"
            >
              Telishade
            </h3>
            {this.props.account ? (
              <h5
                style={config.REACT_APP_STAGE === "dev" ? { color: "white" } : {}}
                className="TelishadeSub"
              >
                {this.props.account.firstName !== ""
                  ? `${this.props.account.firstName} ${this.props.account.lastName}`
                  : this.props.account.email}
              </h5>
            ) : (
              <div className="textPlaceholder" />
            )}
            <HeaderRoutes />
          </div>
          <div
            className="placeHolderRight"
            style={config.REACT_APP_STAGE === "dev" ? { backgroundColor: "red" } : {}}
          />
        </nav>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.sideNav, account: state.account.accountDetails };
};

export default connect(mapStateToProps)(Header);
