import React, { Component } from "react";
import { connect } from "react-redux";
import { Auth } from "aws-amplify";

//MDB
import { MDBBtn } from "mdbreact";

class SignUpConfirm extends Component {
  constructor(props) {
    super(props);

    // Using just regular React state for the login page...
    // don't see a reason to use redux store
    this.state = {
      email: "",
      confirmationCode: "",
      error: null
    };
  }

  handleChange = event => {
    // Handles editing of the form
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    event.target.className += " was-validated";
    try {
      await Auth.confirmSignUp(this.state.email, this.state.confirmationCode);
      this.setState({ error: `Email address successfully confirmed` });
      // Redirect after 4 seconds to allow message to show... not the best UX
      setTimeout(() => {
        this.props.history.push("/login");
      }, 4000);
    } catch (e) {
      console.error(e);
      // TODO: Add user feedback for when login fails
      this.setState({ failed: true });
      // await this.props.dispatch(logout());
    }
  };

  render() {
    // TODO: Change form elements to use what has been already used,
    // or change other forms to use react-bootstrap like this
    return (
      <div className="loginContainer">
        <div className="filler">
          <h1>Telishade</h1>
        </div>
        <div className="Login card">
          <form
            onSubmit={this.handleSubmit}
            className="needs-validation login-form"
            noValidate
          >
            <div className={this.state.error ? "loginError" : "loginErrorNone"}>
              {this.state.error}
            </div>
            <div className="formRow">
              <label>Email</label>
              <input
                id="email"
                placeholder="Enter Email Address"
                className="form-control"
                value={this.state.email}
                onChange={this.handleChange}
                type="email"
                required
              />
              <div className="invalid-tooltip">
                Please provide a valid email.
              </div>
            </div>
            <div className="formRow">
              <label>Confirmation Code</label>
              <input
                id="confirmationCode"
                placeholder="Enter Confirmation Code"
                className="form-control"
                autoComplete="one-time-code"
                value={this.state.confirmationCode}
                onChange={this.handleChange}
                type="text"
                required
              />
              <div className="invalid-tooltip">
                Please Enter Confirmation Code.
              </div>
            </div>
            <div className="loginBtn">
              <MDBBtn color="blue" type="submit">
                Confirm
              </MDBBtn>
              <a className="forgotPWLink" href="/login">
                Cancel
              </a>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  authentication: state.authentication
});
export default connect(mapStateToProps)(SignUpConfirm);
