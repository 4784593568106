//TODO
// Integrate Discount (as a property received from API)
// Add Surcharges

import React, { Component, Fragment } from "react";

class BlindsTotals extends Component {
  constructor(props) {
    super(props);
    let totalCost = 0;
    let totalCostNoDiscount = 0;
    this.props.quote.forEach(quoteItem => {
      totalCost += this.calculateBlindItemTotal(quoteItem, 0, this.props.jobDiscount);
      totalCostNoDiscount += this.calculateBlindItemTotal(quoteItem);
    });
    const baseSystemsSubtotal = this.getBaseSystemsSubtotal();
    const discount = 30;
    const discountAmount = this.calcDiscountAmount(baseSystemsSubtotal, discount);
    const baseSystemsTotal = baseSystemsSubtotal - discountAmount;

    const extrasSubtotal = this.getExtrasSubtotal();
    const totalExGST = baseSystemsTotal + extrasSubtotal;
    const gst = 10;
    const gstAmount = this.calcGSTAmount(totalExGST, gst);
    const total = this.calcGST(totalExGST, gst);

    this.state = {
      totals: {
        baseSystemsSubtotal: baseSystemsSubtotal,
        discount: discount,
        discountAmount: discountAmount,
        baseSystemsTotal: baseSystemsTotal,
        extrasSubtotal: extrasSubtotal,
        totalExGST: totalExGST,
        gst: gstAmount,
        total: total,
        totalCost,
        totalCostNoDiscount
      }
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.jobDiscount !== prevProps.jobDiscount) {
      let totalCost = 0;
      let totalCostNoDiscount = 0;
      this.props.quote.forEach(quoteItem => {
        totalCost += this.calculateBlindItemTotal(quoteItem, 0, this.props.jobDiscount);
        totalCostNoDiscount += this.calculateBlindItemTotal(quoteItem);
      });

      this.setState({ totals: { ...this.state.totals, totalCost, totalCostNoDiscount } });
    }
  }

  calculateBlindItemTotal(quoteItem, discount = 0, discountObject, isRetail = this.props.isRetail) {
    if (isNaN(discount)) {
      return this.calculateBlindItemTotal(quoteItem, 0, discountObject, isRetail);
    }
    let totalforItem = 0;
    totalforItem += isRetail
      ? (quoteItem.baseCost / 100) * parseInt(quoteItem.baseMarkup) + quoteItem.baseCost
      : quoteItem.baseCost;
    if (discountObject && discountObject.components.length > 0) {
      discountObject.components.forEach(componentDiscount => {
        if (componentDiscount.id === quoteItem.description.allDetails.fabricId) {
          totalforItem =
            (totalforItem / 100) *
            (100 -
              (isRetail
                ? componentDiscount.retail.percentage
                : componentDiscount.wholesale.percentage));
        }
      });
    }
    totalforItem += this.calculateExtra("baseBar", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("driveMechanism", quoteItem, discountObject, isRetail)
      .total;
    totalforItem += this.calculateExtra("kassette", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("linking", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("sideChannel", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("doubleBracket", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateExtra("accessory", quoteItem, discountObject, isRetail).total;
    totalforItem += this.calculateService(quoteItem, discountObject, isRetail).total;
    totalforItem = isRetail ? (totalforItem / 100) * (100 - discount) : totalforItem;
    return totalforItem;
  }

  calculateExtra(extraKey, item, discountObject, isRetail = this.props.isRetail) {
    let totalPrice = 0;
    let totalQty = 0;
    if (item.extras[extraKey]) {
      if (item.extras[extraKey].details.costPerMetre) {
        const totalcostPerMetre =
          parseFloat(item.extras[extraKey].details.costPerMetre) *
          (parseFloat(item.description.width) / 1000);
        const totalcostPerMetreMarkup =
          (totalcostPerMetre / 100) * (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += isRetail ? totalcostPerMetreMarkup : totalcostPerMetre;
        totalQty++;
      } else if (item.extras[extraKey].details.cost) {
        const totalCost =
          (parseFloat(item.extras[extraKey].details.cost) / 100) *
          (100 + parseFloat(item.extras[extraKey].details.markup));
        totalPrice += isRetail ? totalCost : parseFloat(item.extras[extraKey].details.cost);
        totalQty++;
      }
      if (discountObject && discountObject.components.length > 0) {
        discountObject.components.forEach(componentDiscount => {
          if (item.extras[extraKey].id === componentDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (isRetail
                  ? componentDiscount.retail.percentage
                  : componentDiscount.wholesale.percentage));
          }
        });
      }
    }
    return { total: totalPrice, qty: totalQty };
  }

  calculateService(item, discountObject, isRetail = this.props.isRetail) {
    let totalPrice = 0;
    let totalQty = 0;
    item.extras.services.forEach(service => {
      const totalWithMarkup =
        (parseFloat(service.details.cost) / 100) * (100 + parseFloat(service.details.markup));
      totalPrice += isRetail ? totalWithMarkup : parseFloat(service.details.cost);
      totalQty++;
      if (discountObject && discountObject.servicesAndFees.length > 0) {
        discountObject.servicesAndFees.forEach(serviceDiscount => {
          if (service.id === serviceDiscount.id) {
            totalPrice =
              (totalPrice / 100) *
              (100 -
                (isRetail
                  ? serviceDiscount.retail.percentage
                  : serviceDiscount.wholesale.percentage));
          }
        });
      }
    });
    return { total: totalPrice, qty: totalQty };
  }

  // Search for 3 digit multiples and add comma:
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatPercentage = number => {
    return `${number}%`;
  };

  formatCurrency = number => {
    if (Math.sign(number)) {
      // If pos, returns 1, if neg returns -1
      return `$${this.numberWithCommas(Math.ceil(number))}`;
    } else {
      return `-$${this.numberWithCommas(Math.ceil(number * -1))}`;
    }
  };

  calcPriceWithMarkUp(cost, markup) {
    if (!cost) {
      cost = 0;
    }
    let result = parseFloat(cost);
    if (this.props.isRetail) {
      if (parseFloat(markup) > 0) {
        const markupPercent = parseFloat(parseFloat(markup) / 100);

        result += parseFloat(cost) * markupPercent;
      }
    }
    return result;
  }

  calcDiscountAmount(price, discount) {
    let result;

    if (discount > 0) {
      const discountPercent = discount / 100;
      result = price * discountPercent;
    } else {
      result = 0;
    }
    return result;
  }

  getBaseSystemsSubtotal() {
    let result = 0;

    this.props.quote.forEach((lineItem, key) => {
      result += this.calcPriceWithMarkUp(lineItem.baseCost, lineItem.baseMarkup);
    });

    return result;
  }

  getExtrasSubtotal() {
    let result = 0;
    this.props.quote
      .filter(lineItem => lineItem.extras)
      .forEach((lineItem, key) => {
        if (lineItem.extras.driveMechanism) {
          result += this.calcPriceWithMarkUp(
            lineItem.extras.driveMechanism.details.cost,
            lineItem.extras.driveMechanism.details.markup
          );
        }

        if (lineItem.extras.doubleBracket) {
          result += this.calcPriceWithMarkUp(
            lineItem.extras.doubleBracket.details.cost,
            lineItem.extras.doubleBracket.details.markup
          );
        }

        if (lineItem.extras.linking) {
          result += this.calcPriceWithMarkUp(
            lineItem.extras.linking.details.cost,
            lineItem.extras.linking.details.markup
          );
        }

        if (lineItem.extras.sideChannel) {
          // Copy Cost Per Metre x width into "price"
          if (lineItem.description && lineItem.description.width) {
            let price =
              parseFloat(lineItem.extras.sideChannel.details.costPerMetre) *
              (parseFloat(lineItem.description.width) / 1000);

            result += this.calcPriceWithMarkUp(price, lineItem.extras.sideChannel.details.markup);
          } else
            console.error(
              "Line Item " + lineItem.itemNo + " has a cost per metre, but no width is specified"
            );
        }

        if (lineItem.extras.kassette) {
          // Copy Cost Per Metre x width into "price"
          if (lineItem.description && lineItem.description.width) {
            let price =
              parseFloat(lineItem.extras.kassette.details.costPerMetre) *
              (parseFloat(lineItem.description.width) / 1000);

            result += this.calcPriceWithMarkUp(price, lineItem.extras.kassette.details.markup);
          } else
            console.error(
              "Line Item " + lineItem.itemNo + " has a cost per metre, but no width is specified"
            );
        }

        if (lineItem.extras.baseBar) {
          // Copy Cost Per Metre x width into "price"
          if (lineItem.description && lineItem.description.width) {
            let price =
              parseFloat(lineItem.extras.baseBar.details.costPerMetre) *
              (parseFloat(lineItem.description.width) / 1000);

            result += this.calcPriceWithMarkUp(price, lineItem.extras.baseBar.details.markup);
          } else
            console.error(
              "Line Item " + lineItem.itemNo + " has a cost per metre, but no width is specified"
            );
        }

        if (lineItem.extras.accessory) {
          result += this.calcPriceWithMarkUp(
            lineItem.extras.accessory.details.cost,
            lineItem.extras.accessory.details.markup
          );
        }

        if (lineItem.extras.services) {
          lineItem.extras.services.forEach((service, index) => {
            result += this.calcPriceWithMarkUp(service.details.cost, service.details.markup);
          });
        }
      });
    return result;
  }

  calcGST(price, gst) {
    if (gst > 0) {
      return price * (1 + gst / 100);
    } else return price;
  }

  calcGSTAmount(price, gst) {
    if (gst > 0) {
      return price * (gst / 100);
    } else return 0;
  }

  constructMargins() {
    let WholesaleTotal = 0;
    let RetailTotal = 0;
    this.props.quote.forEach(quoteItem => {
      RetailTotal += this.calculateBlindItemTotal(quoteItem, 0, this.props.jobDiscount, true);
      WholesaleTotal += this.calculateBlindItemTotal(quoteItem, 0, this.props.jobDiscount, false);
    });
    const GrossProfit = RetailTotal - WholesaleTotal;
    const ProfitMargin = (GrossProfit / RetailTotal) * 100;
    return (
      <Fragment>
        <tr className="details">
          <td className="padding-cell" />
          <td>Wholesale Total</td>
          <td>{`$${this.numberWithCommas(WholesaleTotal.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Retail Total</td>
          <td>{`$${this.numberWithCommas(RetailTotal.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Gross Profit</td>
          <td>{`$${this.numberWithCommas(GrossProfit.toFixed(2))}`}</td>
        </tr>
        <tr className="details">
          <td className="padding-cell" />
          <td>Profit Margin</td>
          <td>{`${ProfitMargin.toFixed(2)}%`}</td>
        </tr>
      </Fragment>
    );
  }

  render() {
    const { totalCost, totalCostNoDiscount } = this.state.totals;
    return (
      <div className="doc-box">
        <table cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="heading">
              <td className="padding-cell" />
              <td colSpan="2">
                <span className="heading-one">Q U O T E &nbsp; T O T A L S</span>
              </td>
            </tr>
            <tr className="details">
              <td className="padding-cell" />
              <td>Total Costs of Items Ex.GST</td>
              <td>
                {"$"}
                {this.numberWithCommas(totalCostNoDiscount.toFixed(2))}
              </td>
            </tr>
            {totalCostNoDiscount - totalCost !== 0 ? (
              <Fragment>
                <tr className="details">
                  <td className="padding-cell" />
                  <td>Discount </td>
                  <td>
                    {"-$"}
                    {this.numberWithCommas(parseFloat(totalCostNoDiscount - totalCost).toFixed(2))}
                  </td>
                </tr>
                <tr className="details">
                  <td className="padding-cell" />
                  <td>Subtotal EX.GST </td>
                  <td className="border-on-top">
                    {"$"}
                    {this.numberWithCommas(parseFloat(totalCost).toFixed(2))}
                  </td>
                </tr>
              </Fragment>
            ) : (
              <Fragment />
            )}
            <tr className="details">
              <td className="padding-cell" />
              <td>GST</td>
              <td className="border-on-top">
                {this.formatCurrency(parseFloat(totalCost / 100) * 10)}
              </td>
            </tr>
            <tr className="details">
              <td className="padding-cell" />
              <td>Total incl. GST</td>
              <td className="border-on-top">
                {this.formatCurrency(parseFloat(totalCost + (totalCost / 100) * 10))}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="Margins" cellPadding="0" cellSpacing="0">
          <tbody>
            <tr className="comments">
              <td colSpan="3">
                Please Note this Information will not be Shown on the Printed Quote.
              </td>
            </tr>
            <tr className="heading">
              <td className="padding-cell"></td>
              <td colSpan="4">
                <span className="heading-one">Margins</span>
              </td>
            </tr>
            {this.constructMargins()}
          </tbody>
        </table>
      </div>
    );
  }
}

export default BlindsTotals;
