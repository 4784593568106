import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import config from "../../config";

import { MDBBtn } from "mdbreact";
import SearchableList from "../Generic/Algolia/SearchableList";

class ProjectInfo extends Component {
  componentDidMount() {}

  handleEditClick = () => {
    const currentURL = this.props.history.location.pathname;
    this.props.history.push(currentURL + "/edit");
  };

  handleDeleteClick = () => {
    const currentURL = this.props.history.location.pathname;
    this.props.history.push(currentURL + "/delete");
  };

  StaffHitComponent = ({ hit }) => {
    return (
      <p onClick={() => this.props.history.push("/staff/" + hit.objectID)}>{hit.details.name}</p>
    );
  };

  branchHitComponent = ({ hit }) => {
    const { streetName, streetNumber, city, state, postcode } = hit.details.address;
    return (
      <p
        onClick={() =>
          this.props.history.push(
            `/customers/${this.props.project.details.customerId}/contacts/bid/${hit.objectID}`
          )
        }
      >{`${streetNumber} ${streetName},\n${city},\n${state} ${postcode}`}</p>
    );
  };

  customerContactHitComponent = ({ hit }) => {
    const { title, firstName, lastName, phone, email } = hit.details;
    return (
      <p style={{ whiteSpace: "pre-wrap" }}>{`${title}. ${firstName} ${lastName},\n${
        phone ? phone + "\n" : ""
      }${email ? email + "\n" : ""}`}</p>
    );
  };

  CustomerHitComponent = ({ hit }) => {
    return (
      <p
        onClick={() =>
          this.props.history.push(
            "/customers/" +
              hit.objectID +
              "/branches/" +
              this.props.project.details.customerBranchId
          )
        }
      >
        {hit.details.name}
      </p>
    );
  };

  getDateString = dateString => {
    const temp = new Date(dateString);
    return temp.toDateString();
  };

  render() {
    const CurrentProject = this.props.project.details;
    if (CurrentProject !== undefined && CurrentProject !== {}) {
      return (
        <div style={{ height: "30%" }} className="projectDetails">
          <div>
            <strong>Number:</strong> {CurrentProject.number}
          </div>
          <div>
            <strong>Name:</strong> {CurrentProject.name}
          </div>
          <div>
            <strong>Stage:</strong> {CurrentProject.stage}
          </div>
          <div>
            <strong>Start Date:</strong> {this.getDateString(CurrentProject.startDate)}
          </div>
          <div>
            <strong>Estimated Delivery Date:</strong>
            {this.getDateString(CurrentProject.estimatedDelivery)}
          </div>
          <div>
            <strong>Status:</strong> {CurrentProject.status}
          </div>
          <div>
            <strong>Customer: </strong>
            {CurrentProject.customerId !== undefined && CurrentProject.customerId !== "" ? (
              <SearchableList
                noLoader
                appId={`${config.algolia.appId}`}
                index={`${config.algolia.prefix}customers_name_asc`}
                hitComponent={this.CustomerHitComponent}
                filters={
                  "isDeleted:false AND objectID:" + CurrentProject.customerId // idPlaceholder is used otherwise Algolia would ignore objectId field
                }
                hideSearchBox={true}
              />
            ) : (
              "No Customer Assigned"
            )}
          </div>
          <div>
            {CurrentProject.customerBranchId !== undefined &&
            CurrentProject.customerBranchId !== "" ? (
              <SearchableList
                noLoader
                appId={`${config.algolia.appId}`}
                index={`${config.algolia.prefix}customers_branches_name_asc`}
                hitComponent={this.branchHitComponent}
                filters={
                  "isDeleted:false AND objectID:" + CurrentProject.customerBranchId // idPlaceholder is used otherwise Algolia would ignore objectId field
                }
                hideSearchBox={true}
              />
            ) : (
              "No Customer Assigned"
            )}
          </div>
          <div>
            <strong>Customer Contact: </strong>
            {CurrentProject.customerBranchContactId !== undefined &&
            CurrentProject.customerBranchContactId !== "" ? (
              <SearchableList
                noLoader
                appId={`${config.algolia.appId}`}
                index={`${config.algolia.prefix}customers_branches_contacts_name_asc`}
                hitComponent={this.customerContactHitComponent}
                filters={
                  "isDeleted:false AND objectID:" + CurrentProject.customerBranchContactId // idPlaceholder is used otherwise Algolia would ignore objectId field
                }
                hideSearchBox={true}
              />
            ) : (
              "No Customer Assigned"
            )}
          </div>
          <div>
            <strong>Quote Date:</strong> {this.getDateString(CurrentProject.quoteDate)}
          </div>
          <div>
            <strong>Project Manager: </strong>
            {CurrentProject.staffId !== undefined && CurrentProject.staffId !== "" ? (
              <SearchableList
                noLoader
                appId={`${config.algolia.appId}`}
                index={`${config.algolia.prefix}staff_name_asc`}
                hitComponent={this.StaffHitComponent}
                filters={
                  "isDeleted:false AND objectID:" + CurrentProject.staffId // idPlaceholder is used otherwise Algolia would ignore objectId field
                }
                hideSearchBox={true}
              />
            ) : (
              "No Staff Assigned"
            )}
          </div>
          <div>
            <strong>Project Discount: </strong>
            {CurrentProject.options.discount.retail.percentage !== undefined &&
            CurrentProject.options.discount.retail.percentage !== "" &&
            CurrentProject.options.discount.retail.percentage !== "0"
              ? `${CurrentProject.options.discount.retail.percentage}%`
              : "No discount"}
          </div>

          <div id="acceptButton">
            <MDBBtn onClick={this.handleEditClick} color="blue" id="editBtn">
              Edit
            </MDBBtn>
            <MDBBtn onClick={this.handleDeleteClick} color="red" id="editBtn">
              Delete
            </MDBBtn>
          </div>
        </div>
      );
    } else {
      return <div className="projectDetails">Nothing Selected</div>;
    }
  }
}

const mapStateToProps = state => {
  return state.projects;
};

export default withRouter(connect(mapStateToProps)(ProjectInfo));
