import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SearchableList from "../../Generic/Algolia/SearchableList";
import { MDBBtn, MDBIcon } from "mdbreact";
import { selectBranch, updateBranchDetails } from "../../../actions/manufacturersActions";
import AddFAB from "../../Generic/Images/AddFab.png";
import config from "../../../config";

class BranchList extends Component {
  HitComponent = ({ hit }) => {
    const listItemClickHandler = () => {
      this.props.dispatch(updateBranchDetails(hit.details));
      this.props.dispatch(selectBranch(hit.objectID));
      this.props.history.push(`/manufacturers/${hit.manufacturerId}/branches/${hit.objectID}`);
    };

    // Change the Classname to selected if ID matches clickHandler
    let className = "";
    if (this.props.id === hit.objectID || this.props.match.params.branchId === hit.objectID) {
      className = { className: "selected" };
    }

    return (
      <Fragment>
        <p {...className} onClick={listItemClickHandler}>
          <b>
            {hit.details.name}
            {hit.details.adressType !== "" ? (
              <em>
                <br />
                {hit.details.addressType}
                <br />
              </em>
            ) : (
              <Fragment />
            )}
          </b>
          {hit.details.address.streetNumber ? `${hit.details.address.streetNumber} ` : ""}
          {hit.details.address.streetName ? hit.details.address.streetName : "No Street Address"}
          <br />
          {hit.details.address.city ? `${hit.details.address.city}` : ""}{" "}
          {hit.details.address.state} {hit.details.address.postcode}
          <br />
        </p>
        {className.className === "selected" ? (
          <Fragment>
            {/* EDIT BRANCH DETAILS BUTTON */}
            <MDBBtn
              onClick={() => {
                this.props.history.push(
                  `/manufacturers/${hit.manufacturerId}/branches/${hit.objectID}/edit`
                );
              }}
              color="blue"
              id="brEditBtn"
            >
              Edit
            </MDBBtn>
            {/* DELETE BRANCH BUTTON */}
            <MDBBtn
              onClick={() => {
                this.props.history.push(
                  `/manufacturers/${hit.manufacturerId}/branches/${hit.objectID}/delete`
                );
              }}
              color="red"
              id="brDeleteBtn"
            >
              <MDBIcon icon="trash" />
            </MDBBtn>
          </Fragment>
        ) : (
          ""
        )}
      </Fragment>
    );
  };

  render() {
    return (
      <div className="branch-container">
        <div className="branch-header">
          <h6>Branch List</h6>
        </div>
        <div className="branch-list">
          <SearchableList
            appId={`${config.algolia.appId}`}
            index={`${config.algolia.prefix}manufacturers_branches_name_asc`}
            hitComponent={this.HitComponent}
            selectedObjectId={this.props.id}
            filters={`isDeleted:false AND manufacturerId:${this.props.match.params.manufacturerId}`}
          />
          <img
            className={"fabButton"}
            id="addBranchPlusBtn"
            alt="Add"
            src={AddFAB}
            onClick={() =>
              this.props.history.push(
                `/manufacturers/${this.props.match.params.manufacturerId}/branches/add`
              )
            }
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return state.manufacturers.branch;
};

export default withRouter(connect(mapStateToProps)(BranchList));
