import React, { Component } from "react";
import PopUpForm from "../../../Generic/PopUpDialog/PopUpForm";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { setRefresh } from "../../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../../actions/loaderActions";

class CreateCurtainPricingGroup extends Component {
  handleCancelClick = () => {
    this.props.history.goBack();
  };

  APIMethod = async (formData) => {
    try {
      this.props.dispatch(setAlgoliaLoaderState(true));
      const body = {
        details: {
          name: formData.name,
          cost: formData.cost,
          markup: formData.markup,
          headerAllowance: formData.headerAllowance,
          hemAllowance: formData.hemAllowance,
          returnAllowance: formData.returnAllowance,
          minCost: formData.minCost,
          makeupCost: formData.makeupCost,
          maxLength: formData.maxLength,
          manualFabrication: formData.manualFabrication,
        },
      };
      await API.post("telishadeAPI", "/product/curtain/price-list", { body });
      this.props.history.push(`/products/curtains/pricing-group`);
      this.props.dispatch(setRefresh(true));
      this.props.dispatch(setAlgoliaLoaderState(false));
    } catch (e) {
      this.props.dispatch(setAlgoliaLoaderState(false));
      throw e;
    } finally {
      // Refresh any algolia components currently mounted 1 second after updating
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
      }, 0);
    }
  };

  fields = [
    {
      id: "name",
      label: "Price List Name",
      type: "text",
      tooltip: "Please Enter Price List Name",
      required: true,
      value: "",
    },
    {
      id: "cost",
      label: "Cost (per M)",
      type: "number",
      tooltip: "Please Enter Cost Value",
      required: true,
      value: 0,
    },
    {
      id: "markup",
      label: "Price List Markup",
      type: "number",
      tooltip: "Please Enter Markup Value",
      required: false,
      value: 0,
    },
    {
      id: "minCost",
      label: "Minimum Cost",
      type: "number",
      tooltip: "Please Enter Minimum Cost",
      required: false,
      value: 0,
    },
    {
      id: "makeupCost",
      label: "Makeup Cost",
      type: "number",
      tooltip: "Please Enter Makeup Cost",
      required: false,
      value: 0,
    },
    {
      id: "maxLength",
      label: "Max Length",
      type: "number",
      tooltip: "Please Enter Max Length",
      required: false,
      value: 0,
    },
    {
      id: "manualFabrication",
      label: "Manual Fabrication",
      type: "number",
      tooltip: "Please Enter Manual Fabrication",
      required: false,
      value: 0,
    },
  ];

  render() {
    return (
      <PopUpForm
        fields={this.fields}
        apiMethod={this.APIMethod}
        header="Add New Curtain Pricing Group"
        button="Accept"
        buttoncolour="green"
        button2="Cancel"
        button2colour="red"
      />
    );
  }
}

export default connect()(CreateCurtainPricingGroup);
