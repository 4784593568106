import React, { Component } from "react";
import { connect } from "react-redux";
import { setFormatterCache } from "../../../../actions/worksheetActions";

class AlgoliaFormatter extends Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value, text: "" };
    const algoliasearch = require("algoliasearch");
    const client = algoliasearch(props.API.id, props.API.key);
    this.index = client.initIndex(props.index);
    if (props.value) {
      this.getObject(props);
    }
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getObject(props) {
    if (!this.props.formatterCache) {
      setTimeout(() => {
        this.getObject(props);
      }, 500);
    } else {
      if (
        this.props.formatterCache[props.cacheId] &&
        this.props.formatterCache[props.cacheId][props.value]
      ) {
        if (this._isMounted) {
          this.setState({
            value: props.value,
            text: this.props.formatterCache[props.cacheId][props.value]
          });
        } else {
          this.state = {
            value: props.value,
            text: this.props.formatterCache[props.cacheId][props.value]
          };
        }
      } else {
        try {
          this.index.getObject(props.value, (error, result) => {
            if (error && error.statusCode !== 404) console.error(error);
            if (result && this.state.text !== result.name) {
              this.setState({ value: props.value, text: result.details.name });
              this.props.dispatch(
                setFormatterCache({
                  ...this.props.formatterCache,
                  [props.cacheId]: {
                    ...this.props.formatterCache[props.cacheId],
                    [props.value]: result.details.name
                  }
                })
              );
            }
          });
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.index.getObject(nextProps.value, (error, result) => {
        if (error) console.error(error);
        if (result && this.state.text !== result.name && result.details !== undefined) {
          this.setState({ value: nextProps.value, text: result.details.name });
        }
      });
    }
    return true;
  }

  render() {
    if (this.props.value === "") return <div title={""}>{""}</div>;
    else return <div title={this.state.text}>{this.state.text}</div>;
  }
}

const mapStatetoProps = state => {
  return { formatterCache: state.worksheet.formatterCache };
};

export default connect(mapStatetoProps)(AlgoliaFormatter);
