import React, { Component } from "react";
import { connect } from "react-redux";
import PopUp from "../../Generic/PopUpDialog/PopUp";
import { contactFields } from "../fields";
import { API } from "aws-amplify";
import { setRefresh } from "../../../actions/algoliaActions";
import { withRouter } from "react-router";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";

class DeleteContact extends Component {
  get fields() {
    // Make a Deep copy of the Fields that will be passed in
    // for editing
    let fields = JSON.parse(JSON.stringify(contactFields));
    const details = { ...this.props.details };

    for (let i = 0; i < fields.length; i++) {
      // Match the field ID with the value kept in details:
      fields[i].value = details[fields[i].id];
    }
    return fields;
  }

  APIMethod = async () => {
    try {
      const { manufacturerId, branchId, contactId } = this.props.match.params;
      this.props.dispatch(setAlgoliaLoaderState(true));
      await API.del(
        "telishadeAPI",
        `/manufacturer/${manufacturerId}/branch/${branchId}/contact/${contactId}`
      );
      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.history.replace(`/manufacturers/${manufacturerId}/branches/${branchId}`);
      this.props.dispatch(setRefresh(true));
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUp
        header="Delete Contact?"
        body={"Are you sure you want to delete this contact?"}
        button="Delete"
        buttoncolour="red"
        button2="cancel"
        buttonClickHandler={this.APIMethod}
        button2ClickHandler={undefined}
      />
    );
  }
}

const mapStateToProps = state => state.manufacturers.contact;
export default withRouter(connect(mapStateToProps)(DeleteContact));
