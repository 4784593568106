import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import algoliasearch from "algoliasearch";
import config from "../../config";
import CustomerList from "./CustomerList";
import Details from "./CustomerDetails";
import CustomerTabs from "./CustomerTabs";
import AddCustomer from "./PopUps/AddCustomer";
import AddBranch from "./PopUps/AddBranch";
import AddContact from "./PopUps/AddContact";
import EditCustomer from "./PopUps/EditCustomer";
import EditBranch from "./PopUps/EditBranch";
import EditContact from "./PopUps/EditContact";
import DeleteCustomer from "./PopUps/DeleteCustomer";
import DeleteBranch from "./PopUps/DeleteBranch";
import DeleteContact from "./PopUps/DeleteContact";
import "./Customers.css";

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultRoute: undefined
    };

    if (this.props.customer.id === null) {
      this.getFirstCustomerForRouting();
    } else {
      if (this._isMounted) {
        this.setState({ defaultRoute: this.props.customer.id });
      }
    }
  }

  async getFirstCustomerForRouting() {
    if (!this.props.AlgoliaKey.key) {
      setTimeout(() => {
        this.getFirstCustomerForRouting();
      }, 500);
    } else {
      try {
        const client = algoliasearch(`${config.algolia.appId}`, this.props.AlgoliaKey.key);
        const customerIndex = client.initIndex(`${config.algolia.prefix}customers_name_asc`);
        const customers = await customerIndex.search({
          query: "",
          filters: `isDeleted:false`,
          hitsPerPage: 1
        });
        const firstCustomer = customers.hits[0];
        if (this._isMounted) {
          this.setState({ defaultRoute: firstCustomer.objectID });
        }
      } catch (e) {
        console.error(e);
      }
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className="customers">
        <CustomerList />

        <div className="selected-customer-window">
          <Details />
          <CustomerTabs customerId={this.state.defaultRoute} />
          <Switch>
            <Route exact path="/customers/add" component={AddCustomer} />
            <Route path="/customers/:customerId/edit" component={EditCustomer} />
            <Route path="/customers/:customerId/delete" component={DeleteCustomer} />
            <Route path="/customers/:customerId/branches/add" component={AddBranch} />
            <Route path="/customers/:customerId/branches/:branchId/edit" component={EditBranch} />
            <Route
              path="/customers/:customerId/branches/:branchId/delete"
              component={DeleteBranch}
            />
            <Route
              path="/customers/:customerId/branches/:branchId/contacts/add"
              render={routeProps => <AddContact {...this.props} {...routeProps} />}
            />
            <Route
              path="/customers/:customerId/branches/:branchId/contacts/:contactId/edit"
              render={routeProps => <EditContact {...this.props} {...routeProps} />}
            />
            <Route
              path="/customers/:customerId/branches/:branchId/contacts/:contactId/delete"
              render={routeProps => <DeleteContact {...this.props} {...routeProps} />}
            />
            <Redirect
              to={
                this.props.customer.id !== null
                  ? `/customers/${this.props.customer.id}/projects`
                  : this.state.defaultRoute
                  ? `/customers/${this.state.defaultRoute}/projects`
                  : "/customers/"
              }
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { ...state.customers, AlgoliaKey: state.key };
};

export default withRouter(connect(mapStateToProps)(Customers));
