import React, { Component } from "react";
import { connect } from "react-redux";
import PopUpForm from "../../Generic/PopUpDialog/PopUpForm";
import { manufacturerFields } from "../fields";
import { API } from "aws-amplify";
import {
  selectManufacturer,
  updateManufacturerDetails
} from "../../../actions/manufacturersActions";
import { setRefresh } from "../../../actions/algoliaActions";
import { setAlgoliaLoaderState } from "../../../actions/loaderActions";

class EditManufacturer extends Component {
  get fields() {
    let fields = JSON.parse(JSON.stringify(manufacturerFields)); // Making a deep copy
    const details = { ...this.props.details };
    for (let i = 0; i < fields.length; i++) {
      fields[i].value = details[fields[i].id];
    }

    return fields;
  }

  APIMethod = async formData => {
    try {
      const { manufacturerId } = this.props.match.params;
      this.props.dispatch(setAlgoliaLoaderState(true));
      const response = await API.put("telishadeAPI", `/manufacturer/${this.props.id}`, {
        body: { details: formData }
      });
      this.props.dispatch(setAlgoliaLoaderState(false));
      this.props.dispatch(setRefresh(true));
      await this.props.dispatch(updateManufacturerDetails(response.details));
      await this.props.dispatch(selectManufacturer(response.id));
      this.props.history.push(`/manufacturers/${manufacturerId}/branches`);
    } catch (error) {
      throw error;
    } finally {
      setTimeout(() => {
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setRefresh(true));
        this.props.dispatch(setRefresh(false));
        this.props.dispatch(setAlgoliaLoaderState(false));
      }, 0);
    }
  };

  render() {
    return (
      <PopUpForm
        fields={this.fields}
        header="Edit Manufacturer"
        button="ACCEPT"
        buttoncolour="green"
        button2="CANCEL"
        button2colour="red"
        apiMethod={this.APIMethod}
      />
    );
  }
}
const mapStateToProps = state => state.manufacturers.manufacturer;
export default connect(mapStateToProps)(EditManufacturer);
